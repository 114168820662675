import React, {useState} from 'react';
import './style.css';
import SectionLayout from '../Layout/sectionLayout';
import ComponentTitle from '../CommonComponentTitle';
import {ApplicationString} from '../../../Localization/Locales/en';
import {Carousel} from 'antd';
import {
  eventDetailsImage1,
  eventDetailsImage2,
  eventDetailsImage3,
  eventDetailsImage4,
  eventDetailsImage5,
  eventDetailsImage6,
  eventDetailsImage7,
  eventDetailsImage8,
} from '../../../Resource/images';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {applicationConfig} from '../../../Util/config';

const EventInfoCard = ({
  date,
  eventName,
  description,
  location,
  imageSrc,
  editData,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const images =
    editData?.highlights_ids?.length > 0
      ? editData?.highlights_ids?.map((obj) => obj.name)
      : [];

  const handleAfterChange = (current) => {
    setCurrentIndex(current);
  };

  let carouselRef = null;
  const handlePrev = () => {
    carouselRef.prev();
  };

  const handleNext = () => {
    carouselRef.next();
  };
  return (
    <React.Fragment>
      {images.length > 0 && (
        <SectionLayout>
          <div className='event-highlight-conatiner'>
            <ComponentTitle
              currentOpeningResponsive={true}
              textColor='black'
              title={
                ApplicationString['userEventInfoCardComponent.mainTitleText']
              }
              hideButton={true}
            />
            <div className='event-highlight-section2'>
              <button
                id={`event-highlight-left-action-button`}
                className='prev-button'
                onClick={handlePrev}>
                <LeftOutlined />
              </button>
              <Carousel
                dots={false}
                ref={(node) => (carouselRef = node)}
                afterChange={handleAfterChange}
                prefixCls='event-highlight-carousel'>
                {images.map((image, index) => {
                  const imageSrc = `${applicationConfig.UPLOAD_URL}/${image}`;
                  return (
                    <img
                      key={index}
                      src={imageSrc}
                      crossOrigin='anonymous'
                      alt='carousel image'
                      className='event-highlights-image-carousel'
                    />
                  );
                })}
              </Carousel>
              <button
                id={`event-highlight-right-action-button`}
                className='next-button'
                onClick={handleNext}>
                <RightOutlined />
              </button>
            </div>
          </div>
        </SectionLayout>
      )}
    </React.Fragment>
  );
};

export default EventInfoCard;

import React, {useEffect} from 'react';
import {Button, Typography, Divider, Alert} from 'antd';
import './style.css';
import {formatCurrencyByLocalChapter} from '../../../Util/commonUtility';
import {useDispatch, useSelector} from 'react-redux';
import {getAllChapters} from '../../../Store/Actions';

const ConfirmationAction = ({
  heading,
  onConfirm,
  onCancel,
  data,
  submitName,
  totalCartPrice,
  usedForAddAttendees,
  usedForSponsor = false,
  disable = false,
  extra = false,
  usedForCart = false,
}) => {
  const localChapterData = useSelector(
    (state) => state.chapters.localChapterData,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!localChapterData) {
      dispatch(getAllChapters());
    }
  }, [localChapterData]);

  return (
    <div className='main-container-payment'>
      <div
        className={`extra-message ${
          usedForCart
            ? totalCartPrice?.refund_credit
              ? 'extra-message-cart-left-credit'
              : 'extra-message-cart-left'
            : 'extra-message-left'
        }`}>
        {extra && (
          <div className='attendees-description-container'>
            <Alert
              prefixCls='attendees-description-alert'
              message={extra}
              type='info'
              showIcon
            />
          </div>
        )}
      </div>
      <div className='main-confirmation-content-container'>
        <div className='confirmation-container confirmation-max-width action-con'>
          <div
            className={`${
              totalCartPrice?.refund_credit
                ? 'price-container-credit'
                : 'price-container'
            } `}>
            <div>
              {totalCartPrice?.discount || totalCartPrice?.refund_credit ? (
                <div>
                  <div className='discount-price-container'>
                    <label className='price-label'>Subtotal:</label>
                    <p className='price-txt'>
                      {formatCurrencyByLocalChapter(
                        totalCartPrice?.total_price_per_cart,
                        localChapterData,
                      )}
                    </p>
                  </div>
                  {totalCartPrice?.discount ? (
                    <div className='discount-price-container'>
                      <label className='price-label'>Discount: </label>
                      <p className='price-txt line-break'>
                        {formatCurrencyByLocalChapter(
                          totalCartPrice?.discount,
                          localChapterData,
                        )}
                      </p>
                    </div>
                  ) : null}
                  {totalCartPrice?.refund_credit ? (
                    <div className='discount-price-container'>
                      <label className='price-label'>Use credit: </label>
                      <p className='price-txt line-break'>
                        {formatCurrencyByLocalChapter(
                          usedForSponsor
                            ? totalCartPrice?.refund_credit
                            : totalCartPrice?.refund_credit_for_regular_attendees,
                          localChapterData,
                        )}
                      </p>
                    </div>
                  ) : null}
                  <Divider prefixCls='price-cart-divider' />
                  <div className='discount-price-container'>
                    <label className='price-label total-text-cart total-blue'>
                      TOTAL
                    </label>
                    <p className='price-txt final-price'>
                      {formatCurrencyByLocalChapter(
                        totalCartPrice?.discounted_price,
                        localChapterData,
                      )}
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  className={`${
                    usedForAddAttendees && 'attendees-text'
                  }  price-txt`}>
                  <p>
                    {formatCurrencyByLocalChapter(
                      totalCartPrice?.total_price_per_cart,
                      localChapterData,
                    )}
                  </p>
                </div>
              )}
            </div>
          </div>

          <Button
            className='confirmation-action-button'
            prefixCls='proceedToPayBtn'
            type='primary'
            onClick={() => onConfirm()}
            disabled={disable}
            // disabled={!data || data?.length === 0}
          >
            {submitName}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationAction;

import React, {useEffect, useState} from 'react';
import {Button, Form, Upload} from 'antd';
import CommonEditor from '../../CommonComponents/TextEditor';
import {ApplicationString} from '../../../Localization/Locales/en';
import TextArea from 'antd/es/input/TextArea';
import {UploadOutlined} from '@ant-design/icons';
import {
  UploadFileMaxSizeForImage,
  UploadFileMaxSizeForImages,
} from '../../../Util/commonUtility';
import {applicationConfig} from '../../../Util/config';
import {useDispatch} from 'react-redux';
import {FETCH_ERROR} from '../../../Constant/ActionTypes';

const PbssInternationalComponent = (props) => {
  const [form] = Form.useForm();
  const {EditData, updatePbssInternational} = props;
  const [validImageFileList, setValidImageFileList] = useState([]);
  const dispatch = useDispatch();
  const filesImageFormats = ['image/jpeg', 'image/png', 'image/jpg'];

  const uploadPropsImage = {
    name: 'file',
    multiple: true,
    maxCount: 20,
    beforeUpload: (file) => {
      const isRightFormat = filesImageFormats.includes(file.type);

      if (!isRightFormat) {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Accepted file formats: JPG and PNG only',
        });
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < UploadFileMaxSizeForImage;
      if (!isLt2M) {
        dispatch({
          type: FETCH_ERROR,
          payload: `File must smaller than ${UploadFileMaxSizeForImage}MB!`,
        });
        return false;
      }
      setValidImageFileList((prev) => {
        if (prev.length >= 20) {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Maximum 20 files can be uploaded',
          });
          return prev;
        }
        return [...prev, file];
      });

      return isRightFormat && isLt2M;
    },
  };

  const removeImageFileHandler = (file) => {
    setValidImageFileList((prevFileList) => {
      return prevFileList.filter((item) => item?.uid !== file?.uid);
    });
  };

  useEffect(() => {
    if (EditData) {
      form.setFieldsValue({
        ...EditData,
      });
      EditData?.highlights_ids?.length > 0
        ? setValidImageFileList((prev) =>
            EditData?.highlights_ids?.map((item) => {
              return {
                uid: item?.id,
                name: item?.name,
                status: 'done',
                url: `${applicationConfig.UPLOAD_URL}/${item?.name}`,
              };
            }),
          )
        : setValidImageFileList((prev) => []);
    }
  }, [EditData]);

  const onFinish = (values) => {
    const ImageIds = [];
    const data = new FormData();
    data.append('special_announcement', values.special_announcement);
    if (EditData) {
      validImageFileList.forEach((validFile) => {
        EditData?.highlights_ids?.some((file) => file?.id === validFile?.uid)
          ? ImageIds.push(validFile?.uid)
          : data.append('highlights', validFile?.originFileObj || validFile);
      });
      data.append('highlights_ids', `[${ImageIds}]`);
    } else {
      validImageFileList?.forEach((file) =>
        data.append(`highlights`, file?.originFileObj || file),
      );
    }
    updatePbssInternational(data, EditData.id);
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };
  const onReset = () => {
    form.resetFields();
    setValidImageFileList([]);
  };

  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='basic'
          onFinish={onFinish}
          layout='vertical'
          className='two-column-form'
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          initialValues={''}>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Special Announcement'
                name='special_announcement'
                rules={[
                  {
                    required: true,
                    message: 'Special Announcement should not be empty',
                  },
                ]}>
                <TextArea placeholder='Enter special announcement' />
              </Form.Item>
              <Form.Item
                name='highlights'
                label={'Event Highlights'}
                // valuePropName='fileList'
                getValueFromEvent={normFile}>
                <Upload
                  prefixCls='upload-list-name-fix-class-admin'
                  className='common-input-user genral-form height-auto pd-y-20'
                  {...uploadPropsImage}
                  accept='.jpg, .jpeg, .png'
                  onRemove={removeImageFileHandler}
                  customRequest={dummyRequest}
                  fileList={validImageFileList}
                  showUploadList={true}>
                  <div>
                    <UploadOutlined className='upload-logo-admin' />
                    <div>
                      <span className='upload-text-logos-admin'>
                        Highlights
                      </span>
                    </div>
                    <div className='upload-type-admin'>
                      <span className='upload-desc-admin'>
                        Accepts only jpg,jpeg and png files.
                      </span>
                      <br />
                      <span className='upload-desc-admin'>
                        {`Maximum file size is ${UploadFileMaxSizeForImages}MB.`}
                      </span>
                    </div>
                  </div>
                </Upload>
              </Form.Item>
            </div>
          </div>

          <Form.Item>
            <div className='button-container'>
              <Button
                className='common-submit-login common-form-submit common-form-cancel'
                htmlType='button'
                id='adminStem-resetButton-reset'
                onClick={onReset}>
                {ApplicationString['dynamicSearch.button.reset']}
              </Button>
              <Button
                id='adminStem-submitButton-button'
                type='primary'
                htmlType='submit'
                className='common-submit-login common-form-submit'>
                {ApplicationString['dynamicSearch.button.update']}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>{' '}
    </div>
  );
};

export default PbssInternationalComponent;

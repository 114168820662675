import React, {useEffect, useState} from 'react';
import {Modal, Button, Divider, Input, Form} from 'antd';
import {
  NoEmptySpaceInput,
  OnlyAlphabetInputValidator,
  validateCompanyName,
  validateEmailInput,
  validatePhoneNumber,
} from '../../../../../Util/commonUtility';
import {createSpeakers} from '../../../../../Store/Actions';
import {useDispatch} from 'react-redux';
import CommonEditor from '../../../../CommonComponents/TextEditor';
// import './style.css';

function AddSpeakerModal({visible, onCancel, setIsSpeakerModalVisible}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [editorCommentState, setEditorCommentState] = useState(`<p></p>`);

  // const onReset = () => {
  //   setIsSpeakerModalVisible(false)
  // };

  const handleFinish = (values) => {
    delete values.confirm_email;
    const Data = {
      ...values,
      comments: editorCommentState,
    };
    dispatch(createSpeakers(Data, form, setIsSpeakerModalVisible));
  };

  const onFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      title={'Add New Speaker'}
      width={1000}
      footer={false}
      prefixCls='speaker-modal-container'>
      <Divider />
      <div>
        <div>
          <Form
            form={form}
            name='basic'
            onFinish={handleFinish}
            layout='vertical'
            className='two-column-form'
            onFinishFailed={onFinishFailed}
            autoComplete='off'>
            <div className='input-container'>
              <div className='form-column'>
                <Form.Item
                  label='First Name'
                  name='first_name'
                  rules={[
                    {
                      required: true,
                      message: 'Please fill out speakers first name',
                    },
                    {
                      validator: (_, value) =>
                        NoEmptySpaceInput(value, 'First name'),
                    },
                  ]}>
                  <Input
                    prefixCls='common-input-user'
                    className='genral-form'
                    placeholder='Enter speaker first name'
                  />
                </Form.Item>
                <Form.Item
                  label='Email'
                  name='email'
                  rules={[
                    {
                      validator: (_, value) =>
                        validateEmailInput(value, 'Email'),
                    },
                  ]}>
                  <Input
                    prefixCls='common-input-user'
                    className='genral-form'
                    placeholder='Enter speaker email'
                  />
                </Form.Item>
                <Form.Item
                  label='Address'
                  name='address'
                  rules={[
                    {
                      validator: (_, value) =>
                        NoEmptySpaceInput(value, 'Address'),
                    },
                  ]}>
                  <Input
                    prefixCls='common-input-user'
                    className='genral-form'
                    placeholder='Enter speaker address'
                  />
                </Form.Item>

                <Form.Item
                  label='Degree/Affiliation'
                  name='title'
                  rules={[
                    {
                      validator: (_, value) =>
                        NoEmptySpaceInput(value, 'Title'),
                    },
                  ]}>
                  <Input
                    prefixCls='common-input-user'
                    className='genral-form'
                    placeholder='Enter speaker Degree/Affiliation'
                  />
                </Form.Item>
              </div>

              <div className='form-column'>
                <Form.Item
                  label='Last Name'
                  name='last_name'
                  rules={[
                    {
                      validator: (_, value) =>
                        NoEmptySpaceInput(value, 'Last name'),
                    },
                  ]}>
                  <Input
                    prefixCls='common-input-user'
                    className='genral-form'
                    placeholder='Enter speaker last name'
                  />
                </Form.Item>

                <Form.Item
                  label='Confirm email'
                  name='confirm_email'
                  dependencies={['email']}
                  rules={[
                    {
                      required: false,
                      message: 'Email should not be empty',
                    },
                    {
                      validator: (_, value) => {
                        validateEmailInput(value, 'Email');
                        if (!value || form.getFieldValue('email') === value) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error('Email IDs must match'),
                          );
                        }
                      },
                    },
                  ]}>
                  <Input
                    prefixCls='common-input-user'
                    className='genral-form'
                    placeholder='Enter speaker confirm email'
                  />
                </Form.Item>
                <Form.Item
                  label='Phone Number'
                  name='phone_number'
                  rules={[
                    {
                      validator: (_, value) =>
                        validatePhoneNumber(value, 'Phone number'),
                    },
                  ]}>
                  <Input
                    prefixCls='common-input-user'
                    className='genral-form'
                    placeholder='Enter speaker phone number'
                  />
                </Form.Item>
                <Form.Item
                  label='Company Name'
                  name='company_name'
                  rules={[
                    {
                      validator: (_, value) =>
                        validateCompanyName(value, 'Company name'),
                    },
                  ]}>
                  <Input
                    prefixCls='common-input-user'
                    className='genral-form'
                    placeholder='Enter speaker company name'
                  />
                </Form.Item>
              </div>
            </div>
            <div className='input-container'>
              <div className='form-column'>
                <Form.Item
                  label='Details'
                  name='details'
                  rules={[
                    {
                      validator: (_, value) =>
                        NoEmptySpaceInput(value, 'Details'),
                    },
                  ]}>
                  <Input
                    prefixCls='common-input-user'
                    className='genral-form'
                    placeholder='Enter speaker details'
                  />
                </Form.Item>
              </div>
            </div>
            <div className='input-container'>
              <div className='form-column'>
                <Form.Item label='Comments' name='comments'>
                  <CommonEditor
                    value={editorCommentState}
                    onChange={setEditorCommentState}
                    text={'comments'}
                    placeholderValue={'comments'}
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item>
              <div className='button-container speaker-justify-right'>
                <div className='form-flex-button'>
                  <Button
                    type='primary'
                    htmlType='submit'
                    //disabled={submit ? true : false}
                    className='common-submit-login common-form-submit'>
                    Submit
                  </Button>
                  <Button
                    className='common-submit-login common-form-submit common-form-cancel'
                    htmlType='button'
                    id='JobsComponent-button-clear'
                    onClick={onCancel}>
                    Cancel
                  </Button>
                </div>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
}

export default AddSpeakerModal;

import React, {useEffect, useState} from 'react';
import {getAllApprovedJobs} from '../../../../Store/Actions';
import ApprovedJobListing from '../../../../Components/UserComponents/Jobs/ApprovedJobListing';
import {useDispatch, useSelector} from 'react-redux';

const ApprovedJobsContainer = () => {
  const {getApprovedJobs} = useSelector(({jobs}) => jobs);
  const [allApprovedJobsData, setAllApprovedJobsData] = useState([]);
  const [allSearchLocation, setAllSearchLocation] = useState([]);
  let dispatch = useDispatch();

  useEffect(() => {
    const Data = getApprovedJobs?.data?.jobs;
    if (getApprovedJobs) {
      setAllApprovedJobsData(Data);
      const names = Data.map((item) => {
        return {label: item.location, value: item.location};
      });

      const removeDuplicates = (array) => {
        const uniqueItems = new Set(array.map((item) => JSON.stringify(item)));
        return Array.from(uniqueItems).map((item) => JSON.parse(item));
      };

      const filteredLoction = removeDuplicates(names);

      setAllSearchLocation([
        {value: '', label: 'Clear Filter'},
        ...filteredLoction,
      ]);
    } else {
      dispatch(getAllApprovedJobs());
    }
  }, [getApprovedJobs]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div>
      <ApprovedJobListing
        locationOptionsList={allSearchLocation}
        allApprovedJobsData={allApprovedJobsData}
      />
    </div>
  );
};

export default ApprovedJobsContainer;

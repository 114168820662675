import React, {useEffect, useState} from 'react';
import EditSponsorshipInfo from './../../../../Components/AdminComponents/Chapters/EditSponsorshipInfo/EditSponsorshipInfo';
import {useDispatch, useSelector} from 'react-redux';
import {fetchChapterById, updateChapterById} from '../../../../Store/Actions';
import {useNavigate, useParams} from 'react-router-dom';

const EditSponsorshipInfoContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {chapterId} = useParams();
  const [editData, setEditData] = useState();
  const {fetchChapterData} = useSelector(({chapters}) => chapters);

  useEffect(() => {
    if (fetchChapterData) {
      setEditData({
        ...fetchChapterData?.data,
        sponsorship_info: fetchChapterData?.data?.sponsorship_info
          ? JSON.parse(fetchChapterData?.data?.sponsorship_info)
          : null,
      });
    }
  }, [fetchChapterData]);

  const fetchChapter = async (id) => {
    dispatch(fetchChapterById(id));
  };

  useEffect(() => {
    if (chapterId) {
      fetchChapter(chapterId);
    }
  }, []);

  const updateChapter = async (Data, id) => {
    dispatch(updateChapterById(Data, id, navigate));
  };
  return (
    <div>
      <EditSponsorshipInfo
        updateChapter={(id, Data) => {
          updateChapter(id, Data);
        }}
        EditData={editData}
      />
    </div>
  );
};

export default EditSponsorshipInfoContainer;

import React, {useState} from 'react';
import {Table, Select, Input, InputNumber, Form, Skeleton} from 'antd';
import {useDispatch} from 'react-redux';
import '../../../Ads/CommonCartTable/index.css';
import AdminActionsDropdown from '../../../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {
  NoEmptySpaceInput,
  formatCurrency,
  validateMonthsInput,
} from '../../../../../Util/commonUtility';
import {editJobs} from '../../../../../Store/Actions';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  jobFeesOptions,
  ...restProps
}) => {
  let inputElement;
  switch (dataIndex) {
    case 'job_fee_id':
      inputElement = (
        <Form.Item
          name='job_fee_id'
          rules={[
            {required: true, message: 'Job fee type should not be empty'},
          ]}>
          <Select
            placeholder={`Select jobs type`}
            options={jobFeesOptions}></Select>
        </Form.Item>
      );
      break;

    case 'job_months':
      inputElement = (
        <Form.Item
          name='months'
          style={{margin: 0}}
          rules={[
            {required: true, message: ''},
            {
              validator: (_, value) => validateMonthsInput(value, 'months'),
            },
          ]}>
          <Input placeholder={`Enter months`} />
        </Form.Item>
      );
      break;

    case 'employer':
      inputElement = (
        <Form.Item
          name={'employer'}
          style={{margin: 0}}
          rules={[
            {
              required: true,
              message: 'Employer should not be empty',
            },
            {
              validator: (_, value) => NoEmptySpaceInput(value, 'Employer'),
            },
          ]}>
          <Input placeholder={`Enter your ${title}`} />
        </Form.Item>
      );
      break;

    case 'position':
      inputElement = (
        <Form.Item
          name={'position'}
          style={{margin: 0}}
          rules={[
            {
              required: true,
              message: 'Position should not be empty',
            },
            {
              validator: (_, value) => NoEmptySpaceInput(value, 'Position'),
            },
          ]}>
          <Input placeholder={`Enter your ${title}`} />
        </Form.Item>
      );
      break;

    default:
      inputElement = (
        <Form.Item
          name={dataIndex}
          style={{margin: 0}}
          rules={[
            {
              validator: (_, value) => NoEmptySpaceInput(value, title),
            },
          ]}>
          <Input placeholder={`Enter your ${title}`} />
        </Form.Item>
      );
  }

  return (
    <td {...restProps}>{editing ? <div>{inputElement}</div> : children}</td>
  );
};

const JobCartTable = ({
  jobsData,
  text = '',
  jobFeesOptions,
  setISJobEditing,
}) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const dispatch = useDispatch();

  const isEditing = (record) => record.id === editingKey;
  const columns = [
    {
      title: 'Employer Name',
      dataIndex: 'employer',
      editable: true,
      className: 'vendor-name',
      width: '10%',
    },
    {
      title: 'Position',
      dataIndex: 'position',
      editable: true,
      width: '20%',
      render: (textDesc, record) => {
        return <div className='cart-desc'>{textDesc}</div>;
      },
    },
    {
      title: `Job Type`,
      dataIndex: `job_fee_id`,
      editable: true,
      width: '10%',
      render: (textDesc, record) => {
        const originalJobFee = jobFeesOptions?.find(
          (obj) => obj?.details === record?.job_fee_note,
        )?.fee;
        return (
          <div className='cart-type'>
            {record?.job_fee_note + '-' + formatCurrency(originalJobFee)}
          </div>
        );
      },
    },
    {
      title: 'Job Months',
      dataIndex: 'job_months',
      editable: true,
      width: '10%',
      render: (text, record) => {
        return <div className='cart-months'>{jobsData[0]?.months}</div>;
      },
    },
    {
      title: 'Fees',
      dataIndex: `job_fee`,
      width: '10%',
      render: (text, record) => {
        return <div className='cart-fees'>{formatCurrency(text)}</div>;
      },
    },
  ];

  const edit = (record) => {
    setISJobEditing(true);
    const findJobFeesId = jobFeesOptions?.find(
      (obj) => obj?.details === record?.job_fee_note,
    )?.id;
    setEditingKey(record.id);
    form.setFieldsValue({
      ...record,
      job_fee_id: findJobFeesId,
      job_months: jobsData[0]?.months,
    });
  };

  const cancel = () => {
    setISJobEditing(false);
    form.resetFields();
    setEditingKey('');
  };

  const save = async (jobFeesData) => {
    setISJobEditing(false);
    const row = await form.validateFields();
    const Data = {
      employer: row?.employer,
      position: row?.position,
      months: `${row?.months}`,
      attachment_url: jobsData[0]?.attachment_url,
      contact_info: jobsData[0]?.contact_info,
      location: jobsData[0]?.location,
      description: jobsData[0]?.description,
      requirement: jobsData[0]?.requirement,
      logo_ids: `[${jobsData[0]?.logo_ids[0]?.id || ''}]`,
      start_date: jobsData[0]?.start_date,
    };
    dispatch(
      editJobs(
        {...Data, job_fee_id: `${row?.job_fee_id}`},
        jobFeesData.id,
        form,
        setEditingKey,
      ),
    );
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === `${text}Type` ? 'select' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
      <Form form={form}>
        <Table
          rowKey={'id'}
          showExpandColumn={false}
          dataSource={jobsData}
          scroll={{x: 1000}}
          components={{
            body: {
              cell: (props) => (
                <EditableCell jobFeesOptions={jobFeesOptions} {...props} />
              ),
            },
          }}
          columns={mergedColumns}
          pagination={false}
          prefixCls='common-cart-table'
        />
      </Form>
    </div>
  );
};

export default JobCartTable;

import {combineReducers} from 'redux';

import CommonReducer from './Common';
import UserReducer from './users';
import ChapterReducer from './chapters';
import KeywordReducer from './keywords';
import JobsReducer from './jobs';
import JobFeesReducer from './jobFees';
import AdsReducer from './ads';
import SponsorsReducer from './sponsors';
import EventTypesReducer from './eventTypes';
import AdFeesReducer from './adFees';
import SpeakersReducer from './speakers';
import CompaniesReducer from './companies';
import CouponCodesReducer from './couponCodes';
import EmailCodesReducer from './emailCodes';
import EventOptionGroupsReducer from './eventOptionGroups';
import AuthReducer from './auth';
import EventOptionsReducer from './eventOptions';
import EventsReducer from './events';
import EventTemplateSchedulerReducer from './eventTemplateScheduler';
import EponsorAttendeesReducer from './sponsorAttendees';
import {LOGOUT} from '../../Constant/ActionTypes';
import EventRegister from './eventRegistration';
import pageSizeReducer from './pageSize';
import EventRefundReducer from './eventRefund';
import SpecialPaymentReducer from './specialPayment';
import dynamicSearchReducer from './dynamicSearch';
import StemReducer from './stem';
import CommitteeMemberReducer from './committeeMembers';
import PbssInternationalReducer from './pbssInternational';

const appReducer = combineReducers({
  sponsorAttendees: EponsorAttendeesReducer,
  common: CommonReducer,
  users: UserReducer,
  chapters: ChapterReducer,
  keywords: KeywordReducer,
  ads: AdsReducer,
  jobs: JobsReducer,
  jobFees: JobFeesReducer,
  sponsors: SponsorsReducer,
  eventTypes: EventTypesReducer,
  adFees: AdFeesReducer,
  speakers: SpeakersReducer,
  auth: AuthReducer,
  companies: CompaniesReducer,
  couponCodes: CouponCodesReducer,
  emailCodes: EmailCodesReducer,
  eventOptionGroups: EventOptionGroupsReducer,
  eventOptions: EventOptionsReducer,
  events: EventsReducer,
  eventTemplateSchedulers: EventTemplateSchedulerReducer,
  eventRegister: EventRegister,
  pageSizes: pageSizeReducer,
  eventRefund: EventRefundReducer,
  specialPayment: SpecialPaymentReducer,
  dynamicSearch: dynamicSearchReducer,
  stem: StemReducer,
  committeeMembers: CommitteeMemberReducer,
  pbssInternational: PbssInternationalReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
export default rootReducer;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  DefaultPaginationValues,
  queryString,
} from '../../../Util/commonUtility';
import {
  deleteEventOptionsById,
  getAllEventOptionsByEventId,
  getAllEventOptionsByQueryString,
  getAllEventOptionsWithoutPagination,
} from './../../../Store/Actions/eventOptions';
import EventOptionsComponent from '../../../Components/AdminComponents/EventOptions';
import {useParams} from 'react-router-dom';

const EventOptionsContainer = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {getAdminEventOptionsData, allEventOptionsWithoutPagination} =
    useSelector(({eventOptions}) => eventOptions);

  const [eventOptionsData, setEventOptionsData] = useState();
  const memorizedPageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.eventOptions,
  );
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.eventOptions,
  );
  const [pagination, setPagination] = useState({
    page: memorizedPageNumber || DefaultPaginationValues.PAGE,
    pagecount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [sortParams, setSortParams] = useState({
    field: 'event_option_type',
    order: 'descend',
  });
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);
  const [totalEventOptionsCount, setTotalEventOptionsCount] = useState(10);

  const searchValues = useSelector(
    ({dynamicSearch}) => dynamicSearch.searchValues.eventOptions,
  );

  const [
    allEventOptionWithoutPaginationData,
    setAllEventOptionWithoutPaginationData,
  ] = useState();
  const [
    eventOptionsFetchingWithoutPagination,
    setEventOptionsFetchingWithoutPagination,
  ] = useState(false);
  useEffect(() => {
    if (getAdminEventOptionsData) {
      setEventOptionsData(getAdminEventOptionsData?.data?.eventOptions);
      setPagination({
        ...pagination,

        totalCount: getAdminEventOptionsData?.data?.totalCount,
      });
      setTotalEventOptionsCount(getAdminEventOptionsData?.data?.totalCount);
    } else {
      fetchEventOptionsByEventId(id);
    }
  }, [getAdminEventOptionsData]);

  useEffect(() => {
    fetchEventOptionsByEventId(id);
  }, [id]);

  useEffect(() => {
    if (allEventOptionsWithoutPagination) {
      setAllEventOptionWithoutPaginationData(
        allEventOptionsWithoutPagination?.data?.eventOptions,
      );
    }
  }, [allEventOptionsWithoutPagination?.data?.eventOptions]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      fetchEventOptionsByEventId(id);
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged]);

  const handlePaginationChange = (current, pageSize) => {
    setPagination({
      ...pagination,
      page: current,
      pagecount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
  };

  const fetchEventOptionsWithoutPagination = async (searchValues) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(
      getAllEventOptionsWithoutPagination(
        id,
        sortField,
        sortOrder,
        searchQueryData,
        totalEventOptionsCount,
        setEventOptionsFetchingWithoutPagination,
      ),
    );
  };
  const fetchEventOptionsByEventId = (id) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    dispatch(getAllEventOptionsByEventId(id, sortField, sortOrder));
  };

  const deleteEventOptions = (id) => {
    dispatch(deleteEventOptionsById(id));
  };

  return (
    <div>
      <EventOptionsComponent
        allEventOptionsData={eventOptionsData}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        handleTableChange={handleTableChange}
        sortParams={sortParams}
        fetchEventOptionsWithoutPagination={(searchValues) => {
          fetchEventOptionsWithoutPagination(searchValues);
        }}
        eventOptionsFetchingWithoutPagination={
          eventOptionsFetchingWithoutPagination
        }
        allEventOptionWithoutPaginationData={
          allEventOptionWithoutPaginationData
        }
        searchValues={searchValues}
        deleteEventOptions={(id) => {
          deleteEventOptions(id);
        }}
      />
    </div>
  );
};

export default EventOptionsContainer;

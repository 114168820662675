import React from 'react';

const SectionLayout = ({uploadButtonStyle = false, children, extendClass}) => {
  return (
    <React.Fragment>
      <div
        className={`main-section-conatiner common-container-section ${extendClass} ${
          uploadButtonStyle && 'uploadStyle'
        }`}>
        {children}
      </div>
    </React.Fragment>
  );
};
export default SectionLayout;

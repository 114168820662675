import React, {useEffect, useState} from 'react';
import {Button, Col, Result, Row, Skeleton} from 'antd';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {verifyEmail} from '../../../../Store/Actions';
import SectionLayout from '../../../CommonComponents/Layout/sectionLayout';

const VerifyEmailComponent = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(
    document.location.search,
  );
  const [verifyEmailSuccess, setVerifyEmailSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  let dispatch = useDispatch();
  const token = searchParams.get('token');
  const handleContactEmail = () => {
    window.location.href = 'mailto:info@pbss.org';
  };

  useEffect(() => {
    if (token) {
      const Data = {
        verification_token: token,
      };
      dispatch(verifyEmail(Data, setVerifyEmailSuccess, setLoading, navigate));
    }
  }, [token]);

  const VerificationSuccess = () => {
    return (
      <div>
        <p>
          Your verification was successful! You can now log in to your account.
        </p>
        <p>
          Thank you for verifying your email. If you need any assistance, feel
          free to contact our support team.
          <span> </span>
          <span className='unsubscribe-contact' onClick={handleContactEmail}>
            info@pbss.com
          </span>
          .
        </p>
      </div>
    );
  };

  const VerificationExpired = () => {
    return (
      <div>
        <p>
          Your verification time has expired. Please log in to your account, and
          a new verification email will be sent to your registered email
          address.
        </p>
        <p>If you need further assistance, please contact our support team.</p>
        <span> </span>
        <span className='unsubscribe-contact' onClick={handleContactEmail}>
          info@pbss.com
        </span>
        .
      </div>
    );
  };

  return (
    <div>
      <Row justify='center' align='middle' style={{minHeight: '60vh'}}>
        <Col span={24}>
          <SectionLayout>
            {loading ? (
              <Skeleton active />
            ) : verifyEmailSuccess ? (
              <Result
                status='success'
                title={'Email Verified Successfully!!'}
                subTitle={VerificationSuccess()}
                extra={[
                  <Button
                    type='primary'
                    key='goto_signin'
                    className='goto_signin_button'
                    onClick={() => navigate('/login')}>
                    Back to Login
                  </Button>,
                ]}
              />
            ) : (
              <Result
                status='error'
                title={'Email Verification Time Expired!!'}
                subTitle={VerificationExpired()}
                extra={[
                  <Button
                    type='primary'
                    key='goto_signin'
                    className='goto_signin_button'
                    onClick={() => navigate('/login')}>
                    Back to Login
                  </Button>,
                ]}
              />
            )}
          </SectionLayout>
        </Col>
      </Row>
    </div>
  );
};

export default VerifyEmailComponent;

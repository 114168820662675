import React, {useState, useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  createEvents,
  duplicateEventsById,
  editEvents,
  getEventsById,
} from '../../../../Store/Actions/events';
import EventsFormComponent from '../../../../Components/AdminComponents/Events/EventsForm';
import {
  createSelectionForAdminOptionsUtil,
  createSelectionOptionsUtil,
} from '../../../../Util/commonUtility';
import {
  getAllChaptersByQueryString,
  getAllCompanies,
  getAllSpeakersByQuery,
  getAllChapters,
  getAllEventTypesByQuery,
  getAllKeywordsByQuerySearch,
  resetKeywordSearchData,
  getAllSpeakersByQuerySearch,
  getAllEventTypesByQuerySearch,
  getAllChaptersByQueryStringForSearch,
} from '../../../../Store/Actions';
import {RESET_EVENT_EDIT_DATA} from '../../../../Constant/ActionTypes';
import {useOnClickOutside} from '../../../../hooks/useOnClickOutside';

const EventsFormContainer = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const {allChaptersQueryData} = useSelector(({chapters}) => chapters);
  const {allEventTypesQueryData} = useSelector(({eventTypes}) => eventTypes);
  const {getEventsData} = useSelector(({events}) => events);
  const [editData, setEditData] = useState();
  const {allChapters} = useSelector(({chapters}) => chapters);
  const {allSpeakersQueryData} = useSelector(({speakers}) => speakers);
  const {allKeywordsQueryData} = useSelector(({keywords}) => keywords);
  const {allCompanies} = useSelector(({companies}) => companies);
  const [chapterList, setChapterList] = useState([]);
  const [speakerOptions, setSpeakerOptions] = useState([]);
  const [CompaniesList, setCompaniesList] = useState([]);
  const [keywordsOptions, SetKeywordsOptions] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [chapterOptions, setChapterOptions] = useState();
  const [eventTypeOptions, setEventTypeOptions] = useState();
  const [wantToLeaveFormPopup, setWantToLeaveFormPopup] = useState(false);
  const [
    wantToLeaveFormPopupHandleOkLink,
    setWantToLeaveFormPopupHandleOkLink,
  ] = useState('/admin/events');
  useEffect(() => {
    dispatch(resetKeywordSearchData(false));
  }, []);

  const eventsFormRef = useRef(null);
  const promptHandler = (e) => {
    const anchorTag = e.target.closest('a');

    if (e.target.textContent === 'Now') {
      setWantToLeaveFormPopup(false);
      return;
    }

    if (e.target.tagName === 'A') {
      setWantToLeaveFormPopupHandleOkLink(e.target.pathname);
      setWantToLeaveFormPopup(true);
    } else if (anchorTag) {
      setWantToLeaveFormPopupHandleOkLink(anchorTag.pathname);
      setWantToLeaveFormPopup(true);
    }
  };
  useOnClickOutside(eventsFormRef, promptHandler);

  // Are you sure you want to leave form? popup handlers

  const popUpHandleOk = () => {
    setWantToLeaveFormPopup(false);
    navigate(wantToLeaveFormPopupHandleOkLink);
  };

  const PopupHandleCancel = () => {
    setWantToLeaveFormPopup(false);
  };

  useEffect(() => {
    if (getEventsData) {
      setEditData(getEventsData?.data);
    }

    return () => {
      dispatch({type: RESET_EVENT_EDIT_DATA});
    };
  }, [getEventsData]);

  useEffect(() => {
    if (allChapters) {
      const visibleChapters = allChapters?.data?.chapters?.filter((chapter) => {
        return chapter?.is_visible_on_map === true;
      });
      setChapterList(
        createSelectionForAdminOptionsUtil(visibleChapters, 'id', 'name'),
      );
    } else {
      dispatch(getAllChapters());
    }
  }, [allChapters]);

  // useEffect(() => {
  //   if (allChaptersQueryData) {
  //     setChapterList(
  //       createSelectionForAdminOptionsUtil(
  // allChaptersQueryData?.data?.chapters,
  //         'id',
  //         'name',
  //       ),
  //     );
  //   } else {
  //     dispatch(
  //       getAllChaptersByQueryString('', '', '', {
  //         page: 1,
  //         pageCount: 5,
  //       }),
  //     );
  //   }
  // }, [allChaptersQueryData?.data?.chapters]);

  useEffect(() => {
    if (allChaptersQueryData?.forSearch) {
      const visibleChapters =
        allChaptersQueryData?.forSearch?.data?.chapters?.filter((chapter) => {
          return chapter?.is_visible_on_map === true;
        });
      setChapterOptions(
        createSelectionForAdminOptionsUtil(visibleChapters, 'id', 'name'),
      );
    } else {
      dispatch(
        getAllChaptersByQueryStringForSearch('', '', '', {
          page: 1,
          pageCount: 100,
        }),
      );
    }
  }, [allChaptersQueryData?.forSearch?.data?.chapters]);

  useEffect(() => {
    if (allEventTypesQueryData.forSearch) {
      setEventTypeOptions(
        createSelectionForAdminOptionsUtil(
          allEventTypesQueryData.forSearch?.data?.eventTypes,
          'id',
          'name',
        ),
      );
      setFetching(false);
    } else {
      dispatch(
        getAllEventTypesByQuerySearch('', '', '', {
          page: 1,
          pageCount: 100,
        }),
      );
    }
  }, [allEventTypesQueryData.forSearch?.data?.eventTypes]);

  // useEffect(() => {
  //   if (allSpeakers) {
  //     setSpeakerOptions(
  //       createSelectionForAdminOptionsUtil(
  //         allSpeakers?.data?.speakers,
  //         'id',
  //         'first_name',
  //         'last_name',
  //       ),
  //     );
  //   } else {
  //     dispatch(getAllSpeakers());
  //   }
  // }, [allSpeakers]);

  useEffect(() => {
    if (allSpeakersQueryData.forSearch) {
      setSpeakerOptions(
        createSelectionForAdminOptionsUtil(
          allSpeakersQueryData.forSearch?.data?.speakers,
          'id',
          'first_name',
          'last_name',
        ),
      );
      setFetching(false);
    } else {
      dispatch(
        getAllSpeakersByQuerySearch(
          {
            page: 1,
            pagecount: 10,
          },
          '',
          '',
          '',
        ),
      );
    }
  }, [allSpeakersQueryData.forSearch]);

  useEffect(() => {
    if (allKeywordsQueryData.forSearch) {
      SetKeywordsOptions(
        createSelectionOptionsUtil(
          allKeywordsQueryData.forSearch?.data?.keywords,
          'id',
          'name',
        ),
      );
      setFetching(false);
    } else {
      dispatch(
        getAllKeywordsByQuerySearch('', '', '', {
          page: 1,
          pageCount: 10,
        }),
      );
    }
  }, [allKeywordsQueryData.forSearch]);

  useEffect(() => {
    if (allCompanies) {
      setCompaniesList(
        createSelectionOptionsUtil(allCompanies?.data?.companies, 'id', 'name'),
      );
    } else {
      dispatch(getAllCompanies());
    }
  }, [allCompanies]);

  const postEvents = async (Data) => {
    dispatch(createEvents(Data, navigate));
  };

  const updateEvents = async (Data, id) => {
    dispatch(editEvents(Data, id, navigate));
  };

  const fetchEventsById = async (id) => {
    dispatch(getEventsById(id));
  };

  return (
    <div ref={eventsFormRef}>
      <EventsFormComponent
        postEvents={(Data) => {
          postEvents(Data);
        }}
        updateEvents={(id, Data) => {
          updateEvents(id, Data);
        }}
        fetchEventsById={(id) => {
          fetchEventsById(id);
        }}
        wantToLeaveFormPopup={wantToLeaveFormPopup}
        handleCancel={PopupHandleCancel}
        handleOk={popUpHandleOk}
        EditData={editData}
        chaptersList={chapterList}
        CompaniesList={CompaniesList}
        speakerOptions={speakerOptions}
        setSpeakerOptions={setSpeakerOptions}
        keywordsOptions={keywordsOptions}
        SetKeywordsOptions={SetKeywordsOptions}
        chapterOptions={chapterOptions}
        setChapterOptions={setChapterOptions}
        eventTypeOptions={eventTypeOptions}
        setEventTypeOptions={setEventTypeOptions}
        fetching={fetching}
        setFetching={setFetching}
      />
    </div>
  );
};

export default EventsFormContainer;

import React, {useEffect, useState} from 'react';
import './style.css';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CloseOutlined,
  DollarCircleFilled,
  DollarCircleOutlined,
} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import {
  EventRegistrationStatus,
  EventRegistrationType,
  createSelectionOptionsUtil,
  eventStatusRegistrationClosed,
  formatCurrency,
  getStatusInfo,
  getStatusInfoMyEvent,
  maxTagPlaceholder,
  statusEnums,
  tagRender,
  truncateHtmlToWords,
} from '../../../../Util/commonUtility';
import ReceiptComponent from '../../receiptComponent';
import StatusUpdateModal from '../../CommonModal';
import {useDispatch} from 'react-redux';
import {Badge, Button, Divider, Tag} from 'antd';
import {editRefundStatusById} from './../../../../Store/Actions/events';
import ClockTimeSVG from '../../../../Assets/svgComponents/ClockTimeSVG';
import moment from 'moment';
import {createSelectionForAdminOptionsUtil} from './../../../../Util/commonUtility';
import {FETCH_ERROR} from '../../../../Constant/ActionTypes';
import EventDetailsModal from './EventRegistrationModal';
const MyEventCard = ({
  eventType,
  paid,
  registration_status,
  eventName,
  date,
  chapter,
  attendees,
  isExpand,
  eventRegistrationType,
  receiptData,
  usedForMyEvents = false,
  sponsors,
  eventId,
  status,
  eventStartDate,
  allRegistrationWaitingList,
}) => {
  let dispatch = useDispatch();
  const [expanded, setExpanded] = useState(isExpand);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [attendeeIds, setAttendeeIds] = useState([]);
  const [refundToAccount, setRefundToAccount] = useState(false);
  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState(false);
  const [showUpdateStatus, setShowUpdateStatus] = useState([]);
  const [eventOptionId, setEventOptionId] = useState();

  const Navigate = useNavigate();
  // Item is attendee, so only attendee with status of 3 which is Paid and total_cost > 0 will shown for refund
  let filteredAttendees;
  if (eventType === EventRegistrationType.waitingList) {
    filteredAttendees = attendees;
  } else {
    filteredAttendees = (
      eventRegistrationType === 'sponsorship' ? sponsors : attendees
    )?.filter((item) => {
      return (
        item?.status === EventRegistrationStatus.PAID &&
        Number(item?.paid_amount) + Number(item?.credit_amount) > 0
      );
    });
  }
  const SelectOptionForAttendees = createSelectionForAdminOptionsUtil(
    filteredAttendees,
    'id',
    'attendee_first_name',
    'attendee_last_name',
  );
  const handleChange = (value) => {
    setShowUpdateStatus(value);
    setAttendeeIds(value);
  };
  const handleRefundVisible = () => {
    setIsModalVisible(true);
  };
  const hideModal = () => {
    setIsModalVisible(false);
  };

  const handleUpdateStatus = () => {
    if (!attendeeIds || attendeeIds?.length <= 0) {
      dispatch({type: FETCH_ERROR, payload: 'Please select attendee(s)'});
      return;
    }
    const Data = {
      eventRegistrationIds: attendeeIds,
      status: 2,
    };
    dispatch(editRefundStatusById(Data));
    setIsModalVisible(false);
  };

  const handleCompletePayment = (e) => {
    e.preventDefault();
    if (parseInt(status) === eventStatusRegistrationClosed) {
      dispatch({
        type: FETCH_ERROR,
        payload: `We're sorry, but registration is closed for this event. You'll be added to the waiting list.`,
      });
    }
    if (eventRegistrationType === 'sponsorship') {
      Navigate(`/eventSponsorCart/${eventId}/${eventOptionId}`);
    } else {
      Navigate(`/attendeesCart/${eventId}`);
    }
  };

  const handleEditRegistrationForToBePaid = (e) => {
    e.preventDefault();
    if (parseInt(status) === eventStatusRegistrationClosed) {
      dispatch({
        type: FETCH_ERROR,
        payload: `We're sorry, but registration is closed for this event. You'll be added to the waiting list.`,
      });
    }
    if (eventRegistrationType === 'sponsorship') {
      Navigate(`/eventSponsorCart/${eventId}/${eventOptionId}`);
    } else {
      Navigate(`/attendeesCart/${eventId}`);
    }
  };

  const handleEventDetailCancel = () => {
    setIsEventDetailsOpen(false);
  };

  const renderButtons = () => {
    const isEventExpired = eventStartDate
      ? dayjs(eventStartDate).isBefore(dayjs(), 'minute')
      : false;
    const hasFilteredAttendees = filteredAttendees?.length > 0;
    const isPaymentStatusPaid = paid;

    if (isEventExpired) {
      return false;
    } else {
      const buttons = [];

      if (
        hasFilteredAttendees &&
        isPaymentStatusPaid &&
        eventRegistrationType !== EventRegistrationType.waitingList
      ) {
        buttons.push(
          <Button
            type='primary'
            className='common-button-refund'
            key='cancel'
            onClick={handleRefundVisible}>
            Ask for Refund
          </Button>,
        );
      }
      if (
        !paid &&
        eventRegistrationType !== EventRegistrationType.waitingList
      ) {
        buttons.push(
          <Button
            type='primary'
            className='common-button-refund'
            key='cancel'
            onClick={handleEditRegistrationForToBePaid}>
            Edit Registration
          </Button>,
        );
      }
      return buttons;
    }
  };

  const getSponsorshipOptionIds = (sponsors) => {
    return sponsors
      ?.filter(
        (sponsor) =>
          sponsor?.status === EventRegistrationStatus.TO_BE_PAID &&
          sponsor?.is_sponsor === true,
      )
      .map((sponsor) => {
        const sponsorship = sponsor?.event_registration_options?.sponsorship;
        return sponsorship?.event_sponsorship_perk?.event_option_id || null;
      })
      .filter((eventOptionId) => eventOptionId !== null);
  };

  useEffect(() => {
    if (sponsors) {
      const sponsorshipOptionIds = getSponsorshipOptionIds(sponsors);
      setEventOptionId(sponsorshipOptionIds);
    }
  }, [sponsors]);

  useEffect(() => {
    setShowUpdateStatus([]);
  }, [eventRegistrationType]);

  return (
    <React.Fragment>
      {/* <div className='event-wrapper-container'> */}
      <StatusUpdateModal
        showUpdateStatus={showUpdateStatus}
        isModalCentred={'centered'}
        refundBool={false}
        isColorSchemeUser={true}
        statusHeading='Select Attendee For Refund'
        visible={isModalVisible}
        onCancel={hideModal}
        submitText='Refund'
        mode={'multiple'}
        maxTagCount={'responsive'}
        maxTagPlaceholder={maxTagPlaceholder}
        tagRender={tagRender}
        handleChange={handleChange}
        handleUpdateJobStatus={handleUpdateStatus}
        selectionOptions={SelectOptionForAttendees}
        setRefundToAccount={setRefundToAccount}
        usedForMyEvents={true}
      />

      <div className='common-events-listing-card-container'>
        <div className='common-events-listing-card'>
          <div className='common-events-listing-wrapper'>
            <div className='common-events-listing-card-content-wrapper'>
              <div className='cardLeftGroup'>
                <div className='timeLocationDetails'>
                  <div className='locationDateDetails'>
                    <span className='locationText'>{chapter}</span>
                    <div className='extraDetails-desktop'>
                      <span>{eventType}</span>
                    </div>
                    <span className='date-text-myevents'>
                      {moment(date).utcOffset(0)?.format('DD MMM YYYY')}{' '}
                    </span>
                  </div>
                </div>
                <div className='details-for-mobile'>
                  <div className='extraDetails-mobile'>
                    <span>{eventType}</span>
                  </div>
                </div>
                <div className='common-event-card-title-description-container'>
                  <div
                    className='upcoming-event-card-title hyperlink'
                    onClick={(e) => {
                      e.stopPropagation();
                      Navigate(`/eventDetails/${eventId}`);
                    }}>
                    {eventName}
                  </div>
                </div>
              </div>
              {eventRegistrationType !== EventRegistrationType.waitingList && (
                <div
                  className={`cardRightGroup borderLeft borderLeftNone ${
                    usedForMyEvents && 'usedForMyEvents'
                  }`}>
                  <div className='common-events-listing-card-buttons-container'>
                    {!paid && (
                      <div className='common-events-listing-card-download-button-container '>
                        <button
                          className='download-button myevents-complete-payment-btn-container'
                          id={`myProfileMyEvents-complete-payment-button`}
                          onClick={handleCompletePayment}>
                          <div className='docs'>
                            Complete Payment <ArrowRightOutlined />
                          </div>
                        </button>
                      </div>
                    )}
                    {paid && (
                      <div className='common-events-listing-card-buttons-container'>
                        <div className='common-events-listing-card-download-button-container'>
                          <ReceiptComponent
                            eventRegistrationType={eventRegistrationType}
                            eventData={receiptData}
                            allowDownload={true}
                          />
                        </div>
                      </div>
                    )}
                    {renderButtons() && (
                      <div className='common-events-listing-card-refund-button-container'>
                        {renderButtons()}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <EventDetailsModal
              paidEvent={paid}
              eventOptionId={eventOptionId}
              eventRegistrationType={eventRegistrationType}
              eventName={eventName}
              attendees={attendees}
              sponsors={sponsors}
              filteredAttendees={filteredAttendees}
              isOpen={isEventDetailsOpen}
              paymentStatus={registration_status}
              handleRefund={handleRefundVisible}
              onCancel={handleEventDetailCancel}
              eventStartDate={eventStartDate}
              eventId={eventId}
              allRegistrationWaitingList={allRegistrationWaitingList}
            />

            {/* <div className='common-events-listing-card-status-container'>
              <div className='common-events-listing-card-status'>
                <img
                  src={getColorStatus(registration_status).svg}
                  alt='status-icon'
                  className='myEvents-listing-card-statusImg'
                />
                <span
                  className={getColorStatus(registration_status)?.className}>
                  {registration_status?.length
                    ? getColorStatus(
                        registration_status,
                      )?.nameText.toUpperCase()
                    : 'PENDING'}
                </span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MyEventCard;

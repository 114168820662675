import React, {useEffect, useState} from 'react';
import {Button, Card, Form, Input, InputNumber} from 'antd';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {ApplicationString} from '../../../../Localization/Locales/en';
import {
  NoEmptySpaceInput,
  OnlyAlphabetInputValidator,
  NumberWithDecimalValidation,
  validatePhoneNumber,
  NumberWithoutDecimalValidation,
  validateCompanyName,
} from '../../../../Util/commonUtility';

const CompaniesFormComponent = (props) => {
  const [submit, setSubmit] = useState(false);
  const [submitLabel, setSubmitLabel] = useState(
    ApplicationString['common.button.submitForm'],
  );
  const [form] = Form.useForm();
  let navigate = useNavigate();
  const {postCompanies, EditData, updateCompanies, fetchCompaniesById} = props;

  useEffect(() => {
    if (id) {
      fetchCompaniesById(id);
      setSubmitLabel(ApplicationString['common.button.updateForm']);
    }
  }, []);
  useEffect(() => {
    if (id) {
      EditData &&
        form.setFieldsValue({
          ...EditData,
        });
    }
  }, [EditData]);

  const onFinish = (values) => {
    const Data = {
      ...values,
      phone_number: values?.phone_number ? values?.phone_number : null,
      sales_tax_rate: values?.sales_tax_rate
        ? parseInt(values?.sales_tax_rate)
        : undefined,
    };
    if (id) {
      updateCompanies(Data, EditData.id);
      // form.resetFields();
    } else {
      postCompanies(Data);
      // form.resetFields();
    }
    setSubmit(true);
  };
  const {id} = useParams();
  const onFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };
  const onReset = () => {
    form.resetFields();
    setSubmit(false);
  };
  const backToCompanies = () => {
    navigate('/admin/companies');
  };

  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='basic'
          onFinish={onFinish}
          initialValues={''}
          layout='vertical'
          className='two-column-form'
          onFinishFailed={onFinishFailed}
          autoComplete='off'>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Name'
                name='name'
                rules={[
                  {
                    required: true,
                    message: 'Company name should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      validateCompanyName(value, 'Company name'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter the company name'
                />
              </Form.Item>

              <Form.Item
                label='Sales Tax Rate'
                name='sales_tax_rate'
                rules={[
                  {
                    required: true,
                    message: 'Sales tax rate should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      NumberWithoutDecimalValidation(value, 'Sales tax rate'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  min={0}
                  controls={false}
                  placeholder='Enter the sales tax rate.'
                />
              </Form.Item>
              <Form.Item
                label='City'
                name='city'
                rules={[
                  {
                    required: true,
                    message: 'City should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      OnlyAlphabetInputValidator(value, 'City'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter the city name'
                />
              </Form.Item>

              <Form.Item
                label='State'
                name='state'
                rules={[
                  {
                    required: true,
                    message: 'State should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      OnlyAlphabetInputValidator(value, 'State'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter the state name'
                />
              </Form.Item>
              <Form.Item
                label='Fax Number'
                name='fax_number'
                rules={[
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Fax number'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter the fax number'
                />
              </Form.Item>
            </div>

            <div className='form-column'>
              <Form.Item
                label='Address'
                name='address'
                rules={[
                  {
                    required: true,
                    message: 'Address should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Address'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter the address'
                />
              </Form.Item>
              <Form.Item
                label='Postal Code'
                name='postal_code'
                rules={[
                  {
                    required: true,
                    message: 'Postal code should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Postal code'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter the postal code'
                />
              </Form.Item>
              <Form.Item
                label='Phone Number'
                name='phone_number'
                rules={[
                  {
                    required: true,
                    message: 'Phone number should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      validatePhoneNumber(value, 'Phone number'),
                  }, // Custom validator
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter the phone number'
                />
              </Form.Item>
              <Form.Item
                label='Country'
                name='country'
                rules={[
                  {required: true, message: 'Country should not be empty'},
                  {
                    validator: (_, value) =>
                      OnlyAlphabetInputValidator(value, 'Country'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter the country name'
                />
              </Form.Item>
              <Form.Item
                label='Invoice Description'
                name='invoice_description'
                rules={[
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Invoice description'),
                  },
                ]}>
                <Input.TextArea
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter the invoice description'
                />
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Payment Terms'
                name='payment_terms'
                rules={[
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Payment terms'),
                  },
                ]}>
                <Input.TextArea
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter the payment terms'
                />
              </Form.Item>
            </div>
          </div>

          <Form.Item>
            <div className='button-container'>
              <Button
                id='adminCompanies-backButton-button'
                className='common-submit-login common-form-submit common-form-cancel'
                onClick={() => {
                  backToCompanies();
                }}>
                {ApplicationString['common.button.backForm']}
              </Button>
              <div className='form-flex-button'>
                <Button
                  id='adminCompanies-submitButton-button'
                  type='primary'
                  htmlType='submit'
                  //disabled={submit ? true : false}
                  className='common-submit-login common-form-submit'>
                  {submitLabel}
                </Button>
                <Button
                  className='common-submit-login common-form-submit common-form-cancel'
                  htmlType='button'
                  id='adminCompanies-resetButton-button'
                  onClick={onReset}>
                  {ApplicationString['dynamicSearch.button.reset']}
                </Button>{' '}
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CompaniesFormComponent;

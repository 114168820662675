import React, {useEffect, useState} from 'react';
import {getAllChapters} from '../../../Store/Actions';
import {useDispatch, useSelector} from 'react-redux';
import {formatMyEventsData} from '../../../Util/commonUtility';
import {LoadMoreSize} from './../../../Util/commonUtility';
import MyEventComponent from '../../../Components/UserComponents/MyEvent';

const MyEventContainer = () => {
  let dispatch = useDispatch();
  const {allMyEvents} = useSelector(({events}) => events);
  const localChapterData = useSelector(
    (state) => state?.chapters?.localChapterData,
  );

  const [countData, setCountData] = useState(LoadMoreSize);
  const [userCredit, setUserCredit] = useState(0);
  const [eventData, setEventData] = useState([]);
  const [formattedEventData, setFormattedEventData] = useState([]);
  const [fetchNewData, setFetchNewData] = useState(false);

  useEffect(() => {
    if (allMyEvents) {
      setEventData(allMyEvents);
    } else {
      setFetchNewData(true);
    }
  }, [allMyEvents]);

  useEffect(() => {
    if (!localChapterData) {
      dispatch(getAllChapters());
    }
  }, [localChapterData]);

  useEffect(() => {
    if (eventData) {
      setFormattedEventData(
        eventData?.data?.myEvents?.length > 0
          ? formatMyEventsData(eventData?.data?.myEvents)
          : null,
      );
    }
  }, [eventData]);

  const handleLoadMore = () => {
    setCountData(countData * 2);
  };

  return (
    <MyEventComponent
      handleLoadMore={handleLoadMore}
      eventData={eventData}
      formattedEventData={formattedEventData}
      setFormattedEventData={setFormattedEventData}
      userCredit={userCredit}
      localChapterData={localChapterData}
      setFetchNewData={setFetchNewData}
      fetchNewData={fetchNewData}
      setUserCredit={setUserCredit}
    />
  );
};

export default MyEventContainer;

import React, {useEffect, useRef} from 'react';
import './style.css';
import Event2Listing from '../../CommonComponents/EventListing2.0';
import SponsorListing from '../../CommonComponents/SponsorListing';
import {useDispatch} from 'react-redux';
import ChapterPageheroText from '../../CommonComponents/ChapterPageHeroText';
import {Col, Row} from 'antd';
import PastEventsListing from '../../CommonComponents/PastEventsListing';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import {useParams} from 'react-router-dom';
import PbssInMap from '../../CommonComponents/PbssInCommonMap';
import ChapterBannerComponent from '../../CommonComponents/ChapterBannerComponent';
import StandingCommiteeListing from '../../CommonComponents/StandingCommiteeListing';
import {ApplicationString} from '../../../Localization/Locales/en';
import CustomizedTable from '../../CommonComponents/CommonTable';
import CustomCollapse from '../../CommonComponents/CommonAccordian';
const PbssChapterComponent = ({
  allEventSponsorLogos,
  handleYearFilter,
  pagination,
  totalEventCount,
  handleLoadMore,
  pastEventData,
  handleChapterFilter,
  handleEventTypeFilter,
  filterBydate,
  chaptersList,
  eventTypeList,
  countLoadMoreData,
  filteredEvents,
  selectedEventTypeValue,
  selectedChapterValue,
  selectedDateValue,
  dateToggle,
  dateOrderHandler,
  handlePaginationChange,
  normalizedName,
  totalPastEventsPages,
  selectedYearValue,
  chapterWiseCommitteeData,
  chapterHeading,
  chapterContent,
  chapterFullName,
}) => {
  const sectionRef = useRef(null);

  const collapseData = chapterContent?.sponsorship_info
    ? chapterContent?.sponsorship_info?.map((item) => {
        return {
          title: item.key,
          content: item.value,
        };
      })
    : [];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const handleScrollToSection = (e) => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({behavior: 'smooth'}); // Smooth scroll to the section
    }
  };

  const getChapterWelcomeLine = (chapter) => {
    switch (chapter) {
      case 'Seattle':
        return {
          message: (
            <>
              Welcome to the{' '}
              <label className='chapter-welcomeline'>Seattle</label>{' '}
              Pharmaceutical & BioScience Society, <br /> a chapter of PBSS
              International
            </>
          ),
          chapterTitleName: 'Seattle',
          hideSubheading: false,
        };
      case 'Boston':
        return {
          message: (
            <>
              Welcome to the{' '}
              <label className='chapter-welcomeline'>Boston</label>{' '}
              Pharmaceutical & BioScience Society, <br /> a chapter of PBSS
              International
            </>
          ),
          chapterTitleName: 'Boston',
          hideSubheading: false,
        };
      case 'Vancouver':
        return {
          message: (
            <>
              Welcome to the{' '}
              <label className='chapter-welcomeline'>Vancouver</label>{' '}
              Pharmaceutical & BioScience Society, <br /> a chapter of PBSS
              International
            </>
          ),
          chapterTitleName: 'Vancouver',
          hideSubheading: false,
        };
      case 'Korea':
        return {
          message: (
            <>
              Welcome to the{' '}
              <label className='chapter-welcomeline'>Korea</label>{' '}
              Pharmaceutical & BioScience Society, <br /> a chapter of PBSS
              International
            </>
          ),
          chapterTitleName: 'Korea',
          hideSubheading: false,
        };
      default:
        return {
          message:
            'Welcome to the Pharmaceutical & BioScience Society International - ',
          chapterTitleName: '',
          hideSubheading: true, // Set to true to hide the subheading
        };
    }
  };

  const chapterWiseAboutName = () => {
    if (!chapterTitleName) {
      return `About PBSS - ${chapterFullName}`;
    } else {
      return `About ${chapterFullName} PBSS`;
    }
  };

  const renderBenefits = (benefits) => {
    return (
      <div
        className='perks-inter'
        dangerouslySetInnerHTML={{__html: benefits}}
      />
    );
  };

  const {message, chapterTitleName, hideSubheading} =
    getChapterWelcomeLine(normalizedName);

  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRowChapter'>
        <Col span={24}>
          <ChapterBannerComponent
            chaptersList={chaptersList}
            pageHeadingComponent={
              <ChapterPageheroText
                heading={message}
                chapterTitleName={chapterTitleName}
                onScroll={handleScrollToSection}
                chapterHide={hideSubheading}
                subheading={hideSubheading ? normalizedName : ''}
                bgColor='#f6f9fc'
                chapterFullName={chapterFullName}
              />
            }
          />
        </Col>
        <Col span={24}>
          <PbssInMap
            mapSize={300}
            chaptersList={chaptersList}
            specialAnnouncementHeading={chapterContent?.special_announcement}
            usedForAdsOnly
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Event2Listing
            filteredEvents={filteredEvents}
            handleChapterFilter={handleChapterFilter}
            handleEventTypeFilter={handleEventTypeFilter}
            filterBydate={filterBydate}
            isChapSelect={true}
            chaptersList={chaptersList}
            eventTypeList={eventTypeList}
            countLoadMoreData={countLoadMoreData}
            totalEventCount={totalEventCount}
            handleLoadMore={handleLoadMore}
            selectedChapterValue={selectedChapterValue}
            selectedEventTypeValue={selectedEventTypeValue}
            selectedDateValue={selectedDateValue}
            dateToggle={dateToggle}
            dateOrderHandler={dateOrderHandler}
          />
        </Col>
        <Col span={24}>
          <PastEventsListing
            handlePaginationChange={(page, pageSize) => {
              handlePaginationChange(page, pageSize);
            }}
            pastEventData={pastEventData}
            pagination={pagination}
            handleYearFilter={handleYearFilter}
            totalPastEventsPages={totalPastEventsPages}
            selectedYearValue={selectedYearValue}
            usedForBio={true}
          />
        </Col>
        <div ref={sectionRef}></div>

        {chapterContent?.about_chapter && (
          <Col span={24}>
            <SectionLayout>
              <div className='Commitee-listing-heading chapter-margin'>
                <p>{chapterWiseAboutName()}</p>
              </div>
              <span className='chapter-content-span'>
                {chapterContent?.about_chapter}
              </span>
            </SectionLayout>
          </Col>
        )}

        {chapterContent?.mission && (
          <Col span={24}>
            <SectionLayout>
              <div className='Commitee-listing-sub-heading chapter-margin'>
                <p className='chapter-heading-underline'>Our Mission</p>
              </div>
              <span className='chapter-content-span'>
                {chapterContent?.mission}
              </span>
            </SectionLayout>
          </Col>
        )}

        {collapseData?.length > 0 && (
          <Col span={24}>
            <SectionLayout>
              <div className='Commitee-listing-sub-heading chapter-margin'>
                <p className='chapter-heading-underline'>Sponsorship details</p>
              </div>
              <CustomCollapse data={collapseData} titleHide />
            </SectionLayout>
          </Col>
        )}

        {chapterWiseCommitteeData?.length > 0 && (
          <Col span={24}>
            <SectionLayout>
              <StandingCommiteeListing
                data={chapterWiseCommitteeData}
                heading={chapterHeading}
              />
            </SectionLayout>
          </Col>
        )}

        {chapterContent?.contact_us &&
        chapterContent.contact_us.replace(/(<([^>]+)>|\s)/g, '').trim() ? (
          <Col span={24}>
            <SectionLayout>
              <div className='Commitee-listing-sub-heading chapter-margin'>
                <p className='chapter-heading-underline'>Contact Us</p>
              </div>
              <span className='chapter-content-span'>
                {renderBenefits(chapterContent?.contact_us)}
              </span>
            </SectionLayout>
          </Col>
        ) : null}

        {allEventSponsorLogos?.length > 0 && (
          <Col span={24}>
            <SponsorListing
              sponsorData={allEventSponsorLogos}
              addScroll={true}
            />
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};
export default PbssChapterComponent;

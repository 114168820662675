import React from 'react';
import {Link} from 'react-router-dom';
import './style.css';

const ChapterPageheroText = ({
  heading,
  subheading,
  bgColor,
  pd,
  onScroll,
  chapterTitleName,
  chapterHide,
}) => {
  const handleScrollToSection = () => {
    onScroll();
  };

  const chapterWiseAboutLine = () => {
    if (subheading) {
      return `About PBSS - ${subheading}`;
    } else {
      return `About ${chapterTitleName} PBSS`;
    }
  };

  return (
    <>
      <div
        className='hero-text-container'
        style={{background: `${bgColor}`, padding: `${pd}`}}>
        <div className='text-container'>
          <span className='heading'>{heading}</span>
          <span className='subheading'>
            {`${subheading}`} {chapterHide && 'Chapter'}
          </span>
        </div>
        <Link to='#' onClick={handleScrollToSection} className='hero-link'>
          {chapterWiseAboutLine()}
        </Link>
      </div>
    </>
  );
};

export default ChapterPageheroText;

import React from 'react';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';
import {Col, Row} from 'antd';
import CustomCollapse from '../../CommonComponents/CommonAccordian';
import {useNavigate} from 'react-router-dom';

const FaqComponent = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRow'>
        <Col span={24}>
          <SponsorOptionPageHeroText title={'Frequently Asked Questions'} />
        </Col>
        <Col span={24}>
          <SectionLayout>
            <CustomCollapse titleHide />
          </SectionLayout>
          <SectionLayout>
            <div className='faq-section-contact-us chapter-margin'>
              <p className='chapter-heading-underline'>Contact Us:</p>
            </div>
            <span className='faq-contact-us-content'>
              <a onClick={() => navigate('/contactUs')} target='_blank'>
                Click here{' '}
              </a>{' '}
              or email to <a href='mailto:info@pbss.org'>info@PBSS.org</a>
            </span>
          </SectionLayout>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FaqComponent;

export const logo = require('./Images/Logos/PBSS-logo.png');
export const whiteLogo = require('./Images/Logos/PBSS-logo.png');
export const smallLogo = require('../Assets/ico.png');

//ChapterWise-logos
// export const international = require('../Assets/ChapterWise-logos/PBSS-logo 2023-International.png');
export const internationalCompressed = require('../Assets/ChapterWise-logos/PBSS-logo 2024-white-international.png');
export const internationalCompressedFooterLogo = require('../Assets/ChapterWise-logos/PBSS-logo 2023-International.webp');
export const eventDetailsImage1 = require('../Assets/event-1.jpg');
export const eventDetailsImage2 = require('../Assets/event-2.jpg');
export const eventDetailsImage3 = require('../Assets/event-3.jpg');
export const eventDetailsImage4 = require('../Assets/event-4.jpg');
export const eventDetailsImage5 = require('../Assets/event-5.jpg');
export const eventDetailsImage6 = require('../Assets/event-6.jpg');
export const eventDetailsImage7 = require('../Assets/event-7.jpg');
export const eventDetailsImage8 = require('../Assets/event-8.jpg');

//standing committees
export const Shichang_Miao = require('../Assets/Standing_Commitee/Shichang Miao.jpeg');
export const Alistair_Stewart = require('../Assets/Standing_Commitee/Alistair Stewart.jpg');
export const Ariane_Jansma = require('../Assets/Standing_Commitee/Ariane Jansma.jpg');
export const Barbara_Seachao = require('../Assets/Standing_Commitee/Barbara Seachao.png');
export const Betty_Chang = require('../Assets/Standing_Commitee/Betty Chang.jpg');
export const Chandra_Prakash = require('../Assets/Standing_Commitee/Chandra Prakash.jpg');
export const Cuiping_Chen = require('../Assets/Standing_Commitee/Cuiping Chen.jpg');
export const Cyrus_Khojasteh = require('../Assets/Standing_Commitee/Cyrus Khojasteh.jpg');
export const Diane_Ramsden = require('../Assets/Standing_Commitee/Diane Ramsden.jpg');
export const Donald_McCarthy = require('../Assets/Standing_Commitee/Donald McCarthy.jpg');
export const Donald_Wong = require('../Assets/Standing_Commitee/Donald Wong.jpg');
export const Doris_Zane = require('../Assets/Standing_Commitee/Doris Zane.jpg');
export const Haojing_Rong = require('../Assets/Standing_Commitee/Haojing Rong.jpg');
export const Jaimie_Rueter = require('../Assets/Standing_Commitee/Jaimie Rueter.jpg');
export const Jennifer_Yang = require('../Assets/Standing_Commitee/Jennifer Yang.jpg');
export const Jing_Tao_Wu = require('../Assets/Standing_Commitee/Jing-Tao Wu.jpg');
export const Karen_Cao = require('../Assets/Standing_Commitee/Karen Cao.jpg');
export const Karuppiah_Kannan = require('../Assets/Standing_Commitee/Karuppiah Kannan.jpg');
export const Kevin_Grimes = require('../Assets/Standing_Commitee/Kevin Grimes.jpg');
export const Kevin_McLure = require('../Assets/Standing_Commitee/Kevin McLure.jpg');
export const Lilia_Koriazova = require('../Assets/Standing_Commitee/Lilia Koriazova.jpg');
export const Lisa_Cheng = require('../Assets/Standing_Commitee/Lisa Cheng.jpeg');
export const Mangala_Hariharan = require('../Assets/Standing_Commitee/Mangala Hariharan.jpg');
export const Minli_Xie = require('../Assets/Standing_Commitee/Minli Xie.jpg');
export const Prakash_Bolleddula = require('../Assets/Standing_Commitee/Prakash Bolleddula.jpg');
export const Renee_Mosi = require('../Assets/Standing_Commitee/Renee Mosi.jpg');
export const Robyn_Rourick = require('../Assets/Standing_Commitee/Robyn Rourick.jpg');
export const Sarah_Bodary = require('../Assets/Standing_Commitee/Sarah Bodary.jpg');
export const Sanjeev_Thohan = require('../Assets/Standing_Commitee/Sanjeev Thohan.jpeg');
export const Snow_Ge = require('../Assets/Standing_Commitee/Snow Ge.jpg');
export const Steve_Richards = require('../Assets/Standing_Commitee/Steve Richards.jpg');
export const Surinder_Kaur = require('../Assets/Standing_Commitee/Surinder Kaur.jpg');
export const Thomas_Pillow = require('../Assets/Standing_Commitee/Thomas Pillow.jpg');
export const Young_Shin = require('../Assets/Standing_Commitee/Young Shin.jpg');
export const Wenfeng_Xu = require('../Assets/Standing_Commitee/Wenfeng Xu.JPG');
export const Walter_Korz = require('../Assets/Standing_Commitee/Walter Korz.JPG');
export const Evelina_Rubinchik = require('../Assets/Standing_Commitee/Evelina Rubinchik.JPG');
export const Heather_Zhang = require('../Assets/Standing_Commitee/Heather Zhang.PNG');
export const Michael_Varney = require('../Assets/Standing_Commitee/Michael_Varney.jpg');
export const Peter_Korytko = require('../Assets/Standing_Commitee/Peter_Korytko.png');

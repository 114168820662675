import {Card, Col, Row} from 'antd';
import React from 'react';
import SponsorPerksCard from '../../SponsorPerksComponent';
import SectionLayout from '../../Layout/sectionLayout';
import './style.css';
import {useParams} from 'react-router-dom';

const SponsorPerksListing = ({
  sponsorPerksData,
  removeSponsorShipFromCartByEventId,
}) => {
  const {id} = useParams();

  const sponsorAddedToCart =
    sponsorPerksData &&
    sponsorPerksData?.find((obj) => obj.addedToCart === true);
  return (
    <SectionLayout>
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <div className='sponsorPerksListing'>
            <Row gutter={[0, 36]}>
              {sponsorPerksData?.map((data, index) => {
                const spot =
                  data?.qty - data?.used_qty >= 0
                    ? data?.qty - data?.used_qty
                    : 0;
                if (spot === 0) {
                  return (
                    <Col key={`${data?.title} - ${index}`} span={24}>
                      <SponsorPerksCard
                        multiple
                        title={data?.name}
                        sponsorshipType={data?.sponsorship_type}
                        spot={'0'}
                        totalSpot={data?.qty >= 0 ? data?.qty : null}
                        price={data?.price}
                        perks={data?.event_sponsorship_perk}
                        id={data?.id}
                        eventId={data?.event_id}
                        isSponsorshipAddedToCart={
                          data?.addedToCart ? data?.addedToCart : false
                        }
                        removeSponsorShipFromCartByEventId={
                          removeSponsorShipFromCartByEventId
                        }
                        sponsorAddedToCart={sponsorAddedToCart}
                        isAddedToWaitingList
                      />
                    </Col>
                  );
                } else {
                  return (
                    <Col key={`${data?.title} - ${index}`} span={24}>
                      <SponsorPerksCard
                        multiple
                        title={data?.name}
                        sponsorshipType={data?.sponsorship_type}
                        spot={spot}
                        totalSpot={data?.qty >= 0 ? data?.qty : 0}
                        price={data?.price}
                        perks={data?.event_sponsorship_perk}
                        id={data?.id}
                        eventId={data?.event_id}
                        isSponsorshipAddedToCart={
                          data?.addedToCart ? data?.addedToCart : false
                        }
                        removeSponsorShipFromCartByEventId={
                          removeSponsorShipFromCartByEventId
                        }
                        sponsorAddedToCart={sponsorAddedToCart}
                      />
                    </Col>
                  );
                }
              })}
            </Row>
          </div>
        </Col>
      </Row>
    </SectionLayout>
  );
};

export default SponsorPerksListing;

import React, {useEffect, useState} from 'react';
import ComponentTitle from '../CommonComponentTitle';
import './style.css';
import SponsorCard from './MajorSponsorCards';
import icon1 from '../../../Assets/sponsorSvg/image 74.png';
import icon2 from '../../../Assets/sponsorSvg/image 75.png';
import icon3 from '../../../Assets/sponsorSvg/image 76.png';
import SectionLayout from '../Layout/sectionLayout';
import MajorSponsorCard from './MajorSponsorCards';
import {calculateMajorSponsorsToShow} from '../../../Util/commonUtility';

const MajorSponsorListing = ({sponsorData, addScroll = false}) => {
  let sponsorsToShow = calculateMajorSponsorsToShow(
    window.outerWidth,
    sponsorData?.length,
    addScroll,
  );
  const [allMjSponsor, setAllMjSponsorData] = useState(sponsorData);
  const [shouldRender, setshouldRender] = useState(false);
  const [endIndexSponsor, setEndIndexSponsor] = useState(10);
  const [startIndexSponsor, setStartIndexSponsor] = useState(0);
  const [numSponsors, setNumSponsors] = useState(sponsorsToShow);
  const [sponsorSingleColumn, setSponsorSingleColumn] = useState(false);

  useEffect(() => {
    if (sponsorData) {
      setAllMjSponsorData(sponsorData);
    }
  }, [sponsorData]);
  const handleNextMajorSponsor = () => {
    if (endIndexSponsor < sponsorData.length) {
      setStartIndexSponsor(endIndexSponsor);
      setEndIndexSponsor(endIndexSponsor + numSponsors);
    }
  };

  const handlePrevMajorSponsor = () => {
    if (startIndexSponsor - numSponsors >= 0) {
      setEndIndexSponsor(startIndexSponsor);
      setStartIndexSponsor(startIndexSponsor - numSponsors);
    }
  };

  useEffect(() => {
    const Data = sponsorData?.slice(
      startIndexSponsor,
      startIndexSponsor + numSponsors,
    );
    setAllMjSponsorData(Data);
  }, [endIndexSponsor, startIndexSponsor, numSponsors]);

  useEffect(() => {
    setStartIndexSponsor(0);
    setEndIndexSponsor(numSponsors);
  }, [numSponsors]);

  useEffect(() => {
    const handleWindowResize = () => {
      let sponsorsToShow = calculateMajorSponsorsToShow(
        window.outerWidth,
        sponsorData?.length,
        addScroll,
      );
      setNumSponsors(sponsorsToShow);
      if (window.outerWidth < 550) {
        setshouldRender(true);
      } else {
        setshouldRender(false);
      }
      if (window.outerWidth <= 350) {
        setSponsorSingleColumn(true);
      } else {
        setSponsorSingleColumn(false);
      }
    };
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [sponsorData]);

  return (
    <React.Fragment>
      <div className='mj-main-conatiner'>
        <ComponentTitle
          OnNext={handleNextMajorSponsor}
          OnPrev={handlePrevMajorSponsor}
          hideButton={shouldRender}
          textColor={'black'}
          title='Major Sponsors'
        />
        <div
          className={`${addScroll ? 'mj-sponsor-scroll' : 'mj-sponsor-grid'} ${
            sponsorSingleColumn
              ? addScroll
                ? ''
                : 'mj-sponsor-single-column'
              : ''
          }`}>
          {allMjSponsor?.length > 0
            ? allMjSponsor?.slice(0, sponsorsToShow)?.map((item, index) => {
                return (
                  <MajorSponsorCard
                    key={index}
                    icon={item?.logo_ids ? item?.logo_ids[0]?.name : ''}
                    sponsorName={item.company_name}
                  />
                );
              })
            : ''}
        </div>
        {addScroll ? (
          ''
        ) : (
          <div className='btn-conatiner'>
            <ComponentTitle />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default MajorSponsorListing;

import React from 'react';
import './style.css';
import {useNavigate} from 'react-router-dom';
import placeholder from '../../../../Assets/placeholder.png';
import {applicationConfig} from '../../../../Util/config';
import {Image} from 'antd';
import sponsorImg from '../../../../Assets/placeholder.png';

const SponsorCard = ({
  icon,
  sponsorName,
  navigationName,
  buttonName,
  navigatePath,
}) => {
  const navigate = useNavigate();
  return (
    <div className='common-sponsor-section1'>
      {
        <Image
          crossOrigin='anonymous'
          preview={false}
          className='sponsor-icon'
          src={
            icon
            ? `${applicationConfig.UPLOAD_URL}/${icon}` : sponsorImg
          }
        />
      }
      {sponsorName ? (
        <p>{sponsorName}</p>
      ) : buttonName ? (
        <button className='nav-login-as' onClick={() => navigate(navigatePath)}>
          {buttonName}
        </button>
      ) : (
        ''
      )}
    </div>
  );
};

export default SponsorCard;

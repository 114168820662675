import React, {useEffect, useState} from 'react';
import {Table, Button, Modal} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {PageSizeArray, PaginationPosition} from '../../../Util/commonUtility';
import {useSelector} from 'react-redux';

const WaitingListComponent = (props) => {
  let navigate = useNavigate();
  const pageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.waitingList,
  );
  const {
    waitingListData,
    paging,
    handlePaginationChange,
    sortParams,
    handleTableChange,
  } = props;

  const [showWaitingListMessage, setShowWaitingListMessage] = useState(false);
  const handleWaitingListMessageClose = () => {
    setShowWaitingListMessage(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: true,
      sortOrder: sortParams.field === 'first_name' && sortParams.order,
      render: (_, record) => `${record.first_name} ${record.last_name}`,
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      sortOrder: sortParams.field === 'email' && sortParams.order,
    },
    {
      title: 'Registration Type',
      dataIndex: 'registration_type',
      key: 'registration_type',
      sorter: true,
      sortOrder: sortParams.field === 'registration_type' && sortParams.order,
      render: (text) => {
        const capitalizedLetter = text.charAt(0).toUpperCase() + text.slice(1);
        return `${capitalizedLetter}`;
      },
    },
    {
      title: 'Local Chapter',
      dataIndex: 'local_chapter',
      key: 'local_chapter',
      sorter: false,
    },
  ];

  return (
    <div className='main-container'>
      <div className='search-container' style={{gap: '10px'}}>
        <Button
          onClick={() => navigate('/admin/events')}
          id='EventTemplateSchedulerComponent-button-filterSearch'>
          Back To Events
        </Button>
      </div>
      <div className='listing-container'>
        <Table
          bordered
          className='admin-table'
          columns={columns}
          dataSource={waitingListData}
          rowKey={'id'}
          scroll={{x: 786}}
          onChange={handleTableChange}
          pagination={{
            onChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            current: paging.page,
            total: paging.totalCount,
            pageSize: pageSize || paging.pagecount,
            pageSizeOptions: PageSizeArray,
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            showTotal: (total) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>

      <Modal
        title={
          <>
            <InfoCircleOutlined /> Waiting List Notification
          </>
        }
        visible={showWaitingListMessage}
        onOk={handleWaitingListMessageClose}
        onCancel={handleWaitingListMessageClose}
        okText='Got it'>
        <p>
          This option is full. You will be added to the waiting list, and we
          will notify you by email if more spots become available.
        </p>
      </Modal>
    </div>
  );
};

export default WaitingListComponent;

import React, {useEffect, useState} from 'react';
import {Alert, Col, Row} from 'antd';
import {useParams} from 'react-router-dom';
import SponsorPerksListing from '../../CommonComponents/SponsorListing/SponsorPerksListing';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import CustomCollapse from '../../CommonComponents/CommonAccordian';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';
import {ApplicationString} from '../../../Localization/Locales/en';
import NavigationComponent from '../../CommonComponents/CommonBackButton';
import './style.css';

const SponsorshipOptionComponent = ({
  allEventOptionsRegistrationData,
  getEventSponsorOptions,
  removeSponsorShipFromCart,
}) => {
  const {id} = useParams();
  const [sponsorshipOption, setSponsorOptionData] = useState([
    {
      id: 1,
      name: 'Gold Sponsor',
      sponsorship_type: 'Premium',
      qty: 10,
      used_qty: 3,
      price: 5000,
      event_sponsorship_perk: {
        benefits:
          '<ul><li>Logo on main stage</li><li>Mention in press release</li><li>Free booth space</li></ul>',
      },
      event_id: 101,
      addedToCart: false,
    },
    {
      id: 2,
      name: 'Silver Sponsor',
      sponsorship_type: 'Standard',
      qty: 5,
      used_qty: 5,
      price: 3000,
      event_sponsorship_perk: {
        benefits:
          '<ul><li>Logo on website</li><li>Social media shoutouts</li></ul>',
      },
      event_id: 101,
      addedToCart: false,
    },
    {
      id: 3,
      name: 'Bronze Sponsor',
      sponsorship_type: 'Basic',
      qty: 20,
      used_qty: 15,
      price: 1000,
      event_sponsorship_perk: {
        benefits: '<ul><li>Logo on banners</li></ul>',
      },
      event_id: 101,
      addedToCart: true,
    },
  ]);

  useEffect(() => {
    if (allEventOptionsRegistrationData) {
      setSponsorOptionData(allEventOptionsRegistrationData);
    }
  }, [allEventOptionsRegistrationData]);

  useEffect(() => {
    if (id) {
      getEventSponsorOptions(id);
    }
  }, [id]);

  const removeSponsorShipFromCartByEventId = () => {
    removeSponsorShipFromCart(id);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <Row gutter={[0, 10]} className='pbssMainContainerRow'>
        <Col span={24}>
          <SponsorOptionPageHeroText
            title={ApplicationString['userSponsorshipOptionComponent.heading']}
            subTitle={
              ApplicationString['userSponsorshipOptionComponent.subheading']
            }
          />
        </Col>
        <Col span={24}>
          <SectionLayout>
            <div className='hero-subheading'>
              Contact us at <a href='mailto:info@pbss.org'>info@pbss.org</a> if
              you have a question or run into a problem with your registration.
            </div>
          </SectionLayout>
        </Col>
        <Col span={24}>
          <SectionLayout>
            <NavigationComponent
              name={'Go Back to Attendee Registration'}
              urlPath={`addAttendees/${id}`}
            />
          </SectionLayout>
        </Col>
        <Col span={24}>
          {sponsorshipOption?.length > 0 ? (
            <SponsorPerksListing
              sponsorPerksData={sponsorshipOption}
              removeSponsorShipFromCartByEventId={
                removeSponsorShipFromCartByEventId
              }
            />
          ) : (
            <SectionLayout>
              <Alert
                message={
                  ApplicationString['userSponsorshipOption.alertMessage']
                }
                className='no-event-sponsor-alert'
                type='info'
                showIcon
              />
            </SectionLayout>
          )}
        </Col>
        <Col span={24}>
          <SectionLayout>
            <CustomCollapse />
          </SectionLayout>
        </Col>
      </Row>
    </>
  );
};

export default SponsorshipOptionComponent;

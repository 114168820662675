import React, {useEffect, useState} from 'react';
import {Table, Form, Tag, Skeleton, Alert} from 'antd';
import {
  filterFoodOptionColumnsForSponsorAttendee,
  formatCurrency,
  uniqueSponsorPerksValueEnum,
} from '../../../Util/commonUtility';
import {DollarOutlined} from '@ant-design/icons';
import {useParams} from 'react-router-dom';
import './style.css';
import AdminActionsDropdown from '../AdminActionsDropdown/AdminActionsDropdown';
import {ApplicationString} from '../../../Localization/Locales/en';
import SponsorEditableCell from '../SponsorEditableCell';

const EventsCommonCartTable = ({
  allEventOptionsFoodData,
  onEdit,
  attendeesDataWithPerks,
  eventType,
  sponsorPerksData,
  sponsorOption,
  alertInformation,
}) => {
  const {id, transactionId} = useParams();
  const AttendeeDinnerLimit =
    sponsorPerksData?.eventSponsorshipPerk?.dinner_with_speaker;
  const AttendeeDinnerPrice =
    sponsorPerksData?.eventSponsorshipPerk?.dinner_with_speaker_price;
  const AttendeePresentationLimit =
    sponsorPerksData?.eventSponsorshipPerk?.workshop_attendees;
  const AttendeePresentationPrice =
    sponsorPerksData?.eventSponsorshipPerk?.attendees_price;
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [attendeePresentationChecked, setAttendeePresentationChecked] =
    useState(false);
  const [attendeeDinnerChecked, setAttendeeDinnerChecked] = useState(false);
  const isEditing = (record) => record.id === editingKey;

  const handleAttendeePresentationChange = (e) => {
    setAttendeePresentationChecked(e.target.checked);
  };
  const handleAttendeeDinnerChange = (e) => {
    setAttendeeDinnerChecked(e.target.checked);
  };

  const edit = (record) => {
    setAttendeeDinnerChecked(record?.attending_dinner);
    setAttendeePresentationChecked(record?.attending_presentation);
    const filterEventOptionData = allEventOptionsFoodData?.find(
      (obj) =>
        obj.id === record.event_registration_options?.food?.event_option_id,
    );
    form.setFieldsValue({
      ...record,
      attendee_email_confirm: record?.attendee_email
        ? record?.attendee_email
        : '',
      food_option: record.event_registration_options?.food
        ? record.event_registration_options?.food?.price ===
          filterEventOptionData?.discounted_price
          ? filterEventOptionData?.value
          : record.event_registration_options?.food?.event_option_id
        : 'no-food',
    });
    setEditingKey(record.id);
  };
  const save = async (data) => {
    try {
      const values = await form.validateFields();
      const registrationOptions = data?.event_registration_options;
      const checkIsSponsorOption =
        typeof values.food_option === 'string' &&
        values.food_option?.includes(uniqueSponsorPerksValueEnum);
      const splittedValue = checkIsSponsorOption
        ? Number(values?.food_option?.split('-')[0])
        : values.food_option;

      const editData = {
        ...values,
        event_option_perk_id: sponsorPerksData?.eventSponsorshipPerk?.id,
        is_reps: true,
        sponsorship_option_id:
          sponsorPerksData?.eventSponsorshipPerk?.event_option_id,
        event_id: Number(id),
        attending_presentation: attendeePresentationChecked,
        attending_dinner: attendeeDinnerChecked,
        food_option:
          values.food_option === 'no-food' ||
          eventType?.toLowerCase() === 'webcast'
            ? {}
            : {
                ...(checkIsSponsorOption && {
                  is_discounted: true,
                }),
                event_option_id: splittedValue,
                id: registrationOptions?.food?.id,
              },
      };

      onEdit(editData, editingKey, setEditingKey, form);
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const cancel = () => {
    form.resetFields();
    setEditingKey('');
  };

  const getValue = (text) => {
    let value = null;
    let showIcon = false;
    if (text === 0) {
      value = 'Free';
      showIcon = true;
    }
    return {value, showIcon};
  };

  useEffect(() => {
    if (tableData?.length === 0) {
      const loading =
        !tableData || (Array.isArray(tableData) && tableData.length === 0);
      setLoading(loading);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
    setTableData(attendeesDataWithPerks);
  }, [attendeesDataWithPerks]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      editable: true,
      width: '15%',
      render: (text, record) => {
        const fullName = `${record.attendee_first_name || 'Name'} ${
          record.attendee_last_name || ''
        }`;
        return <Tag prefixCls='regularAttendeesTag'>{fullName}</Tag>;
      },
    },
    {
      title: 'Email',
      dataIndex: 'attendee_email',
      key: 'email',
      width: '18%',
      editable: true,
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text || 'Email'}</Tag>;
      },
    },

    {
      title: 'Lunch',
      dataIndex: 'option',
      key: 'registration_and_food_option',
      hidden: eventType?.toLowerCase() === 'webcast' ? true : false,
      editable: true,
      width: '20%',
      render: (_, record) => {
        const {event_registration_options: options} = record || {};
        const food = options?.food || {};
        const foodName = food?.name || 'No Food';
        const foodPrice = food?.price;

        return (
          <div className='option-tag-container'>
            {eventType !== 'Webcast' && (
              <Tag prefixCls='regularAttendeesTag'>
                Food: {foodName}
                {' - ' + formatCurrency(foodPrice, 'USD')}
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title:
        AttendeePresentationPrice === 0
          ? 'Attending Meeting Presentation?'
          : `Attending Meeting Presentation at $${AttendeePresentationPrice}?`,
      dataIndex: 'attending_presentation',
      key: 'attending_presentation',
      width: '12%',
      hidden: AttendeePresentationLimit ? false : true,
      editable: true,
      render: (text) => {
        return (
          <div className='checkbox-editableCell'>{text ? 'Yes' : 'No'}</div>
        );
      },
    },
    {
      title:
        AttendeeDinnerPrice === 0
          ? 'Attending Dinner?'
          : `Attending Dinner at $${AttendeeDinnerPrice}?`,
      dataIndex: 'attending_dinner',
      key: 'attending_dinner',
      width: '9%',
      editable: true,
      hidden: AttendeeDinnerLimit ? false : true,
      render: (text) => {
        return (
          <div className='checkbox-editableCell'>{text ? 'Yes' : 'No'}</div>
        );
      },
    },
    {
      title: 'Company/Affiliation',
      dataIndex: 'attendee_company',
      key: 'company',
      width: '15%',
      editable: true,
      render: (text) => {
        return (
          <Tag prefixCls='regularAttendeesTag'>{text || 'Company Name'}</Tag>
        );
      },
    },
    {
      title: 'Price',
      dataIndex: 'total_cost',
      key: 'price',
      width: '5%',
      render: (text) => {
        return (
          <h4 className='regularAttendees-price'>{formatCurrency(text)}</h4>
        );
      },
    },
    {
      title: ' ',
      dataIndex: 'is_reps',
      key: 'is_reps',
      width: '3%',
      render: (_, record) => {
        const {value, showIcon} = getValue(record?.total_cost);
        return (
          value && (
            <div className='custom-tag'>
              {showIcon && <DollarOutlined className='custom-tag-text' />}
              {value}
            </div>
          )
        );
      },
    },
    {
      title: ' ',
      dataIndex: 'action',
      width: '6%',
      render: (_, record) => {
        return (
          <AdminActionsDropdown
            record={record}
            usedForAttendeeTable={true}
            editBool={true}
            attendeeEditingKey={editingKey}
            handleCancelClick={cancel}
            handleSaveClick={save}
            deleteBool={false}
            handleEditClick={edit}
          />
        );
      },
    },
  ];

  const filterColumns = (columns) => {
    return columns.filter((column) => {
      return !column.hidden;
    });
  };

  const columnsWithEdit = filterFoodOptionColumnsForSponsorAttendee(
    filterColumns(columns),
    eventType,
    isEditing,
  );

  const renderBenefits = (benefits) => {
    return (
      <div
        className='perks-inter'
        dangerouslySetInnerHTML={{__html: benefits}}
      />
    );
  };

  return (
    <>
      {sponsorOption?.event_id || sponsorPerksData ? (
        <Form form={form}>
          <Skeleton
            loading={loading}
            prefixCls='table-skeleton'
            active
            paragraph={{rows: 1}}>
            <div className='attendees-content-container'>
              <div className='attendees-label'>
                {
                  ApplicationString[
                    'userAddAttendeeComponent.sponsorDetail.perksLabel'
                  ]
                }
              </div>
              <div className='attendees-description-container'>
                {alertInformation && alertInformation !== '<p></p>' && (
                  <div className='attendees-description-container'>
                    <Alert
                      prefixCls='attendees-description-alert'
                      message={renderBenefits(alertInformation)}
                      type='info'
                      showIcon
                    />
                  </div>
                )}
              </div>
            </div>{' '}
            <Table
              components={{
                body: {
                  cell: (props) => (
                    <SponsorEditableCell
                      index={props.index}
                      allEventOptionsFoodData={allEventOptionsFoodData}
                      eventType={eventType}
                      attendeesData={attendeesDataWithPerks}
                      handleAttendeePresentationChange={
                        handleAttendeePresentationChange
                      }
                      checkedAttendeePresentation={attendeePresentationChecked}
                      handleAttendeeDinnerChange={handleAttendeeDinnerChange}
                      checkedAttendeeDinner={attendeeDinnerChecked}
                      SponsorPerks={sponsorPerksData?.eventSponsorshipPerk}
                      sponsorOption={sponsorOption}
                      transactionId={transactionId}
                      {...props}
                    />
                  ),
                },
              }}
              prefixCls='attendees-table-container'
              scroll={{x: columns?.length * 150}}
              dataSource={attendeesDataWithPerks}
              pagination={false}
              columns={columnsWithEdit}
            />
          </Skeleton>
        </Form>
      ) : (
        ''
      )}
    </>
  );
};

export default EventsCommonCartTable;

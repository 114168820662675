import React, {useState} from 'react';
import {logo} from '../../../Resource/images';
import './mainLayout.scss';
import {Dropdown, Layout, Menu, Space} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {Link, useNavigate} from 'react-router-dom';
import './style.css';
import AppInfoView from '../../CommonComponents/AppInfoView/appInfoView';
import InitialFooter from '../../CommonComponents/FooterInc';
import Footer from '../../CommonComponents/CommonFooter';
import {getAllChapters, setChapterById} from '../../../Store/Actions';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {
  createSelectionMenuOptionsUtil,
  createSelectionOptionsUtilForEmailToMembers,
} from '../../../Util/commonUtility';
import ToggleSwitch from '../ToggleSwith';
import {useJWTAuthActions} from '../../../Api/JWTAuthProvider';
const {Header, Content} = Layout;
// import pbssIn from '../../../Assets/PBSS-logo 2023-internationalCompressed (1) .png';
import ProfileInfo from '../ProfileView';
import arrowDown from './../../../Assets/svgs/arrowDown.svg';
import ProfileInfo2 from '../ProfileView2';
import {
  internationalCompressedFooterLogo,
  internationalCompressed,
} from '../../../Resource/images';

import {applicationConfig} from '../../../Util/config';

const MainLayout = (props) => {
  const dispatch = useDispatch();
  const {allChapters} = useSelector(({chapters}) => chapters);
  const {logout} = useJWTAuthActions();
  const [chapterList, setChapterList] = useState([]);
  const [shouldRender, setshouldRender] = useState(false);
  const [dropDownMenu, setDropDownMenu] = useState(false);
  const [layoutWidth, setLayoutWidth] = useState(false);
  const [isLogin, setIsLogin] = useState(
    JSON.parse(localStorage.getItem('userInfo')),
  );
  const currentPath = window.location.pathname;
  const splitted = currentPath.split('/');
  const splittedChapter = splitted[splitted?.length - 1];
  let chapterUrlLogoDark = internationalCompressedFooterLogo;
  let chapterUrlLogo = internationalCompressed;

  useEffect(() => {
    setIsLogin(localStorage.getItem('userInfo'));
    if (allChapters) {
      const visibleChapters = allChapters?.data?.chapters?.filter((chapter) => {
        return chapter?.is_visible_on_map === true;
      });
      setChapterList(
        createSelectionOptionsUtilForEmailToMembers(
          visibleChapters,
          'id',
          'name',
        ),
      );
    } else {
      dispatch(getAllChapters());
    }
  }, [allChapters]);

  let navigate = useNavigate();
  const [selectedRole, setSelectedRole] = useState();

  useEffect(() => {
    const isLoginUser = JSON.parse(localStorage.getItem('userInfo'));
    if (isLoginUser?.role === 'admin') {
      setSelectedRole('admin');
    } else if (isLoginUser?.role === 'user') {
      setSelectedRole('user');
    }
  }, []);
  // const handleSelectChange = (value) => {
  //   setSelectedRole(value);
  //   if (value === 'admin') {
  //     navigate('/admin/chapters');
  //   } else if (value === 'user') {
  //     navigate('/');
  //   }
  // };
  // const handleChapterChange = (value) => {
  //   dispatch(setChapterById(value));
  //   let chapterName = chapterList.find((item) => item.value === value)?.label;
  //   navigate(`/chapter/${chapterName}`);
  // };

  const onMenuItemClick = (e) => {
    dispatch(setChapterById(e?.key));
    navigate(`/${e?.key}`);
  };
  const visibleChapters = chapterList?.filter((chapter) => {
    return chapter?.is_visible_on_map === true;
  });

  //Logo rendering chapter wise
  if (visibleChapters?.find((obj) => obj.normalized_name === splittedChapter)) {
    const filteredChapterUrl = visibleChapters?.find(
      (chapter) => chapter?.normalized_name === splittedChapter,
    );
    const logoName = filteredChapterUrl?.logo_ids[0]?.name;
    const logoDarkName = filteredChapterUrl?.dark_logo_ids[0]?.name;
    const logoUrl = `${applicationConfig.UPLOAD_URL}/${logoName}`;
    const logoUrlDark = `${applicationConfig.UPLOAD_URL}/${logoDarkName}`;
    chapterUrlLogo = logoName ? logoUrl : internationalCompressed;
    if (currentPath !== '/') {
      chapterUrlLogoDark = logoDarkName ? logoUrlDark : internationalCompressed;
    }
  } else {
    chapterUrlLogo = internationalCompressed;
  }

  const renderIcon = (layoutWidth, screenSize) => {
    return layoutWidth < screenSize ? null : <img src={arrowDown} />;
  };

  const navBarItems = [
    {
      id: 'header-mainPage-9',
      label: 'Main Page',
      key: ' ',
    },
    {
      id: 'header-aboutpbss-1',
      label: 'About PBSS',
      key: 'aboutUs',
    },
    {
      id: 'header-events-2',
      label: 'Events',
      key: 'events',
      icon: <img src={arrowDown} />,
      children: [
        {
          label: 'Pick a Location',
          key: 'PickLocation',
          children: [
            ...createSelectionMenuOptionsUtil(
              visibleChapters,
              'normalized_name',
              'name',
            ),
            {label: 'PBSS International', key: ''},
          ],
        },
        {
          label: 'Event Types Info',
          key: 'EventTypes',
          children: [
            {label: 'Seminar Luncheons', key: 'eventType/seminarLuncheons'},
            {label: 'Workshops', key: 'eventType/workshops'},
            {label: 'Symposia', key: 'eventType/symposia'},
            {label: 'Webinars', key: 'eventType/webinars'},
            {label: 'Dinner Seminars', key: 'eventType/dinnerSeminars'},
          ],
        },
      ],
    },
    {
      id: 'header-sponsorships-2',
      label: 'Sponsorships',
      key: 'sponsorshipInfo',
    },
    {
      id: 'header-jobs-4',
      label: 'Job Postings',
      key: 'jobs/approved',
    },
    {
      id: 'header-resources-5',
      label: 'Resources',
      key: 'resources',
      icon: renderIcon(layoutWidth, 1254),
      children: [
        {label: 'STEM outreach', key: 'stemOutreach'},
        {label: 'Partner Organizations of PBSS', key: 'Partners'},
      ],
    },
    {
      id: 'header-contactus-6',
      label: 'Contact Us',
      key: 'contactUs',
    },
    {
      id: 'header-faq-7',
      label: 'FAQ',
      key: 'faq',
    },
    {
      id: 'header-myEventCart-8',
      label: 'My Cart',
      key: 'myCart',
    },
  ];

  const currActivePath = (items) => {
    if (currentPath.includes('chapter')) {
      const eventChilds = items.find((item) => item.key === 'events');
      return eventChilds.children[0]?.children.find(
        (item) => currentPath === `/${item.key}`,
      )?.key;
    } else if (currentPath === '/eventVendorShow') {
      return 'eventVendorShow';
    } else if (currentPath === '/eventMajorSponsor') {
      return 'eventMajorSponsor';
    } else if (currentPath === '/sponsorshipInfo/SF-Bay') {
      return 'sponsorshipInfo/SF-Bay';
    } else if (currentPath === '/sponsorshipInfo/Boston') {
      return 'sponsorshipInfo/Boston';
    } else if (currentPath === '/sponsorshipInfo/San-Diego') {
      return 'sponsorshipInfo/San-Diego';
    } else if (currentPath === '/sponsorshipInfo/Vancouver') {
      return 'sponsorshipInfo/Vancouver';
    } else if (currentPath === '/sponsorshipInfo/Korea') {
      return 'sponsorshipInfo/Korea';
    } else if (currentPath === '/eventType/seminarLuncheons') {
      return 'eventType/seminarLuncheons';
    } else if (currentPath === '/eventType/workshops') {
      return 'eventType/workshops';
    } else if (currentPath === '/eventType/symposia') {
      return 'eventType/symposia';
    } else if (currentPath === '/eventType/dinnerSeminars') {
      return 'eventType/dinnerSeminars';
    } else if (currentPath === '/eventType/webinars') {
      return 'eventType/webinars';
    } else if (currentPath === '/') {
      return ' ';
    } else {
      return items.find((item) => currentPath.includes(item.key))?.key;
    }
  };

  const handleLogoutClick = () => {
    logout();
  };
  useEffect(() => {
    const handleWindowResize = () => {
      setLayoutWidth(window.outerWidth);
      if (window.outerWidth < 900) {
        setshouldRender(true);
      } else {
        setshouldRender(false);
      }
      if (window.outerWidth < 1620) {
        setDropDownMenu(true);
      } else {
        setDropDownMenu(false);
      }
    };
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // useEffect(() => {
  //   id && dispatch(setChapterById(id));
  // }, [id]);

  const dropDownMenuItems = (
    <Menu>
      <div className='button-container-header extend-settings'>
        {/* <Select
          style={{width: '100%'}}
          id='chapter-select'
          prefixCls='chapter'
          showSearch
          optionFilterProp='children'
          filterOption={(input, option) =>
            (option?.label ?? '').includes(input)
          }
          placeholder={'Select Chapters'}
          options={chapterList}
          suffixIcon={<CaretDownOutlined />}
          className='chapter-select'></Select> */}
      </div>
      <ProfileInfo logout={handleLogoutClick} />
    </Menu>
  );
  const dropDownProfile = (
    <Menu>
      <ProfileInfo logout={handleLogoutClick} />
    </Menu>
  );
  return (
    <Layout style={{background: 'none'}} className='layout'>
      <div className='nav-header-sticky'>
        <div className='nav-header-container'>
          <div className='nav-haeder-wapper'>
            {shouldRender ? (
              <Header className='pbss-in-header' prefixCls='nav-bar'>
                <Link to={'/'}>
                  <img
                    className={'header-chapter-logo'}
                    crossOrigin='anonymous'
                    src={chapterUrlLogo}
                    alt='logo'
                    id='header-logo-home-1'
                  />
                </Link>
                <div className='button-container-header'>
                  <ToggleSwitch
                    chapterLogo={chapterUrlLogo}
                    chapterList={visibleChapters}
                    logout={handleLogoutClick}
                    profileView={<ProfileInfo2 />}
                  />
                </div>
              </Header>
            ) : (
              <Header className='pbss-in-header' prefixCls='nav-bar'>
                <Link to={'/'}>
                  <img
                    className={'header-chapter-logo'}
                    src={chapterUrlLogo}
                    crossOrigin='anonymous'
                    alt='logo'
                    id='header-logo-home-2'
                  />
                </Link>
                <Menu
                  id={navBarItems.id}
                  onClick={() => {
                    console.info(currActivePath(navBarItems));
                  }}
                  // onClick={onMenuItemClick}
                  prefixCls='pbss-hader-menu'
                  popupOffset={[0, 10]}
                  theme='light'
                  mode='horizontal'
                  selectedKeys={currActivePath(navBarItems)}
                  // items={navBarItems}
                >
                  {navBarItems.map((item) =>
                    item.children ? (
                      <Menu.SubMenu
                        key={item.key}
                        icon={item.icon}
                        title={item.label}>
                        {item.children.map((child) =>
                          child.children ? (
                            <Menu.SubMenu key={child.key} title={child.label}>
                              {child.children.map((subChild) => (
                                <Menu.Item
                                  key={subChild.key}
                                  icon={subChild.icon}>
                                  <Link to={`/${subChild.key}`}>
                                    {subChild.label}
                                  </Link>
                                </Menu.Item>
                              ))}
                            </Menu.SubMenu>
                          ) : (
                            <Menu.Item key={child.key} icon={child.icon}>
                              <Link to={`/${child.key}`}>{child.label}</Link>
                            </Menu.Item>
                          ),
                        )}
                      </Menu.SubMenu>
                    ) : (
                      <Menu.Item key={item.key} icon={item.icon}>
                        <Link to={`/${item.key}`}>{item.label}</Link>
                      </Menu.Item>
                    ),
                  )}
                </Menu>

                {/* {dropDownMenu ? (
                  <Space>
                    <Dropdown.Button
                      icon={<img src={arrowDown} />}
                      className='custom-profile-button'
                      prefixCls='custom-dropdown-button'
                      overlay={dropDownMenuItems}
                      placement='bottomRight'>
                      <UserOutlined />
                      My Account{' '}
                    </Dropdown.Button>
                  </Space>
                ) : ( */}
                <div className='button-container-header'>
                  {isLogin ? (
                    ''
                  ) : (
                    <>
                      {currentPath === '/register' ? (
                        ''
                      ) : (
                        <button
                          onClick={() => {
                            navigate('/register');
                          }}
                          id='join'
                          className='common-button-header bg-remove extend-button-w extend-settings-button'>
                          Join PBSS
                        </button>
                      )}
                      {currentPath === '/login' ? (
                        ''
                      ) : (
                        <button
                          onClick={() => {
                            navigate('/login');
                          }}
                          id='login'
                          className='common-button-header extend-settings-button'>
                          Login
                        </button>
                      )}
                    </>
                  )}
                  {/* <Select
                      id='chapter-select'
                      prefixCls='chapter'
                      showSearch
                      optionFilterProp='children'
                     filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                      placeholder={'Select Chapters'}
                      onChange={handleChapterChange}
                      defaultValue={defaultChapterValue}
                      value={
                        defaultChapterValue
                          ? defaultChapterValue
                          : 'Select Chapter'
                      }
                      options={chapterList}
                      suffixIcon={<CaretDownOutlined />}
                      className='chapter-select'></Select> */}
                  {isLogin ? (
                    <Space>
                      <Dropdown
                        className='custom-profile-button'
                        prefixCls='custom-dropdown-button'
                        overlay={dropDownProfile}
                        placement='bottomRight'
                        trigger={['click']}>
                        <button
                          id='header-dropdown-profile'
                          className='user-profile-button-menu'
                          style={{cursor: 'pointer'}}>
                          <UserOutlined />
                          My Account{' '}
                          <img src={arrowDown} alt='Dropdown Arrow' />
                        </button>
                      </Dropdown>
                    </Space>
                  ) : (
                    ''
                  )}
                </div>
                {/* )} */}
              </Header>
            )}
          </div>
        </div>
      </div>
      <AppInfoView />
      <Content className='site-layout-content main-layout-container'>
        <div className='site-layout-content-box'>{props.children}</div>
      </Content>
      <div className='footer-main-container-wrapper'>
        <div className='footer-main-container intial-footer-background'>
          <div className='wapper-main-footer'>
            <InitialFooter
              chapterList={chapterList}
              chapterLogo={chapterUrlLogoDark}
            />
          </div>
        </div>
        <div className='footer-main-container sm-footer-background'>
          <div className='wapper-main-footer'>
            <Footer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MainLayout;

import React, {useState, useEffect, useRef, useMemo} from 'react';
import {Button, Card, DatePicker, Form, Input, Select} from 'antd';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import CommonEditor from '../../../CommonComponents/TextEditor';
import {ApplicationString} from '../../../../Localization/Locales/en';
import {
  NoEmptySpaceInput,
  emailTemplatesTypeCode,
} from '../../../../Util/commonUtility';
import {storeEmailtemplateType} from './../../../../Store/Actions/emailCodes';
import {useDispatch} from 'react-redux';
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
// import CommonModal from '../../../CommonComponents/CommonModal';
// import {CodeOutlined} from '@ant-design/icons';
const EmailCodeFormComponent = (props) => {
  const [submit, setSubmit] = useState(false);
  const [submitLabel, setSubmitLabel] = useState('Submit');
  const [form] = Form.useForm();
  let Navigate = useNavigate();
  let dispatch = useDispatch();
  const {
    postEmailCodes,
    editData,
    updateEmailCodes,
    fetchAds,
    fetchEmailCodesById,
  } = props;
  const {id} = useParams();
  const [editorState, setEditorState] = useState(`<p></p>`);
  const [dynamicVariables, setDynamicVariables] = useState();

  useEffect(() => {
    if (id) {
      fetchEmailCodesById(id);
      setSubmitLabel('Update');
    }
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(storeEmailtemplateType(editData?.email_template_type));
      setEditorState(editData?.email_template);
      const dynamicVariable = emailTemplatesTypeCode.find(
        (template) => template?.value == editData?.email_template_type,
      )?.dynamicValues;
      setDynamicVariables((prevValue) => dynamicVariable);
      editData &&
        form.setFieldsValue({
          ...editData,
          email_template: editorState,
        });
    }
  }, [editData]);

  const onFinish = (values) => {
    if (id) {
      updateEmailCodes({...values, email_template: editorState}, id);
      // form.resetFields();
      // Navigate('/admin/emailTemplates');
    } else {
      postEmailCodes({...values, email_template: editorState});
      // form.resetFields();
      // Navigate('/admin/emailTemplates');
    }
    setSubmit(true);
  };

  const setDynamicVariable = (value, option) => {
    setDynamicVariables((prevValue) => option?.dynamicValues);
  };

  const onReset = () => {
    form.resetFields();
  };
  const backToEmailTemplate = () => {
    Navigate('/admin/emailTemplates');
  };

  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='emailTemplateForm'
          onFinish={onFinish}
          layout='vertical'
          initialValues={''}
          className='two-column-form'
          autoComplete='off'>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                name='details'
                label='Details'
                rules={[
                  {
                    required: true,
                    message: 'Please input details',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Details'),
                  },
                ]}>
                <TextArea
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter your details'
                />
              </Form.Item>
              {/* <Form.Item
                label='Email Template Type'
                name='email_template_type'
                rules={[
                  {
                    required: true,
                    message: 'Please select email template type',
                  },
                ]}>
                <Select
                  prefixCls='common-select-register'
                  className='space'
                  placeholder='Select email template type'
                  // showSearch
                  allowClear
                  onChange={(value, option) =>
                    setDynamicVariable(value, option)
                  }
                  options={emailTemplatesTypeCode}
                />
              </Form.Item> */}
              <CommonEditor
                Variables={dynamicVariables}
                value={editorState}
                onChange={setEditorState}
                text={'email_templates'}
                placeholderValue={'email template'}
              />
            </div>
          </div>
          <Form.Item>
            <div className='button-container'>
              <Button
                className='common-submit-login common-form-submit common-form-cancel'
                onClick={() => {
                  backToEmailTemplate();
                }}>
                {ApplicationString['common.button.backForm']}
              </Button>
              <div className='form-flex-button'>
                <Button
                  type='primary'
                  htmlType='submit'
                  //disabled={submit ? true : false}
                  className='common-submit-login common-form-submit'>
                  {submitLabel}
                </Button>
                <Button
                  className='common-submit-login common-form-submit common-form-cancel'
                  htmlType='button'
                  id='JobsComponent-button-clear'
                  onClick={onReset}>
                  {ApplicationString['dynamicSearch.button.reset']}
                </Button>{' '}
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>{' '}
    </div>
  );
};

export default EmailCodeFormComponent;

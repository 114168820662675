import React, {useEffect, useState} from 'react';
import {Button, Card, Form, Input, Select} from 'antd';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {ApplicationString} from '../../../../Localization/Locales/en';
import {
  OnlyAlphabetInputValidator,
  NumberWithDecimalValidation,
  validateString,
  PriceLimitationValidation,
  NumberWithoutDecimalValidation,
  OnlyAlphabetInputValidatorWithSpecialCharacters,
} from '../../../../Util/commonUtility';
const JobFeesFormComponent = (props) => {
  const [submit, setSubmit] = useState(false);
  const [submitLabel, setSubmitLabel] = useState(
    ApplicationString['common.button.submitForm'],
  );
  const [form] = Form.useForm();
  let navigate = useNavigate();
  const {postJobFees, EditData, updateJobFees, fetchJobFeesById} = props;

  useEffect(() => {
    if (id) {
      fetchJobFeesById(id);
      setSubmitLabel(ApplicationString['common.button.updateForm']);
    }
  }, []);
  useEffect(() => {
    if (id) {
      EditData &&
        form.setFieldsValue({
          ...EditData,
        });
    }
  }, [EditData]);

  const backToJobFees = () => {
    navigate('/admin/jobfees');
  };

  const onFinish = (values) => {
    const Data = {
      ...values,
      fee: values?.fee ? parseInt(values?.fee) : undefined,
    };
    if (id) {
      updateJobFees(Data, EditData.id);
      // form.resetFields();
    } else {
      postJobFees(Data);
      // form.resetFields();
    }
    setSubmit(true);
  };
  const {id} = useParams();
  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };
  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='basic'
          onFinish={onFinish}
          layout='vertical'
          className='two-column-form'
          initialValues={''}
          onFinishFailed={onFinishFailed}
          autoComplete='off'>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Fee'
                name='fee'
                rules={[
                  {required: true, message: 'Fee should not be empty'},
                  {
                    validator: (_, value) =>
                      NumberWithoutDecimalValidation(value, 'Fee', 9999999),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter fee for job'
                />
              </Form.Item>
              <Form.Item
                label='Details'
                name='details'
                rules={[
                  {required: true, message: 'Details should not be empty'},
                  {
                    validator: (_, value) =>
                      OnlyAlphabetInputValidatorWithSpecialCharacters(
                        value,
                        'Details',
                      ),
                  },
                ]}>
                <Input.TextArea
                  prefixCls='common-input-user'
                  className='genral-form'
                  rows={4}
                  placeholder='Enter details for job fees'
                />
              </Form.Item>
            </div>
          </div>

          <Form.Item>
            <div className='button-container'>
              <Button
                id='adminJobFees-backButton-button'
                className='common-submit-login common-form-submit common-form-cancel'
                onClick={() => {
                  backToJobFees();
                }}>
                {ApplicationString['common.button.backForm']}
              </Button>
              <div className='form-flex-button'>
                <Button
                  id='adminJobFees-submitButton-button'
                  type='primary'
                  htmlType='submit'
                  //disabled={submit ? true : false}
                  className='common-submit-login common-form-submit'>
                  {submitLabel}
                </Button>
                <Button
                  id='adminJobFees-resetButton-button'
                  className='common-submit-login common-form-submit common-form-cancel'
                  htmlType='button'
                  // id='JobsComponent-button-clear'
                  onClick={onReset}>
                  {ApplicationString['dynamicSearch.button.reset']}
                </Button>{' '}
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default JobFeesFormComponent;

import {FilterOutlined} from '@ant-design/icons';
import React from 'react';
import {Button} from 'antd';
import {ApplicationString} from '../../../Localization/Locales/en';
import {ProtectedResource} from '../ProtectedResource';
import plus from '../../../Assets/Plus.svg';

const HeaderSearchCard = ({
  showFilter = true,
  showAddButton = true,
  resource = '',
  buttonTitle = '',
  toggleClick = () => {},
  addClick = () => {},
  dataTestId = '',
  permission = '',
  expanded = false,
  extra,
}) => {
  return (
    <div className='search-container' style={{gap: '10px'}}>
      {showFilter && (
        <Button onClick={toggleClick} id='AdFeeComponent-button-filterSearch'>
          {expanded ? <FilterOutlined /> : <FilterOutlined />}{' '}
          {ApplicationString['dynamicSearch.button.heading']}
        </Button>
      )}
      {extra && extra}
      {showAddButton && (
        <ProtectedResource resource={resource} permission={permission}>
          <Button
            type='primary'
            onClick={addClick}
            id={dataTestId}
            className='common-button'>
            <div className='icon-container'>
              <img src={plus} alt='plus' className='icon-plus' />
              <span className='add-content'>{buttonTitle}</span>
            </div>
          </Button>
        </ProtectedResource>
      )}
    </div>
  );
};

export default HeaderSearchCard;

import {
  ADD_ATTENDEE,
  ADD_SPONSOR_SUCCESS,
  APPLY_COUPONCODES,
  CANCEL_GROUP_OF_REGISTRATION_BY_EVENT_ID,
  DELETE_ATTENDEES_BY_ID,
  ERROR_REGISTRATION,
  GET_ALL_ATTENDEES_BY_TRANSACTION_ID,
  GET_ALL_ATTENDEES_BY_USER_EVENTID,
  GET_ALL_EVENT_SPONSORS_LOGOS,
  GET_ALL_REGISTRATION,
  GET_ALL_REGISTRATION_CSV_DOWNLOAD,
  MEMORIZED_REFUND_ATTENDEES,
  PAYMENT_EVENT_REGISTRATION_CLIENT_KEYS,
  RESET_MEMORIZED_REFUND_ATTENDEES,
  SET_COUPONCODES_ERROR_MESSAGE,
  UPDATE_ATTENDEES_BY_ID,
  UPDATE_REGISTRATION_OPTOINS_BY_ID,
  GET_ALL_WAITING_LIST,
  UPDATE_SPONOSOR_BY_ID,
  VERIFY_COUPONCODES,
  GET_ALL_USER_WAITING_LIST,
} from '../../Constant/ActionTypes';

const initialState = {
  allAttendees: false,
  allAttendeesAfterPayment: false,
  allRegistrationDetailsByEvent: false,
  allRegistrationWaitingListByEvent: false,
  allUserRegistrationWaitingList: false,
  allRegistrationDetailsByEventForCSV: false,
  isCreateAttendee: false,
  isUpdateAttendee: false,
  eventRegistrationClientKeys: false,
  selectedInitiatedRefundIds: false,
  getAllEventRegistrationLogos: false,
};

const EventRegister = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ATTENDEE:
      return {
        ...state,
        isCreateAttendee: true,
        allAttendees: false,
        isUpdateAttendee: false,
        allUserRegistrationWaitingList: false,
        couponCodeErrorMessage: false,
      };

    case MEMORIZED_REFUND_ATTENDEES:
      return {
        ...state,
        selectedInitiatedRefundIds: action.payload,
      };
    case GET_ALL_EVENT_SPONSORS_LOGOS:
      return {
        ...state,
        getAllEventRegistrationLogos: action.payload,
      };
    case RESET_MEMORIZED_REFUND_ATTENDEES:
      return {
        ...state,
        selectedInitiatedRefundIds: false,
      };
    case GET_ALL_ATTENDEES_BY_USER_EVENTID:
      return {
        ...state,
        allAttendees: action.payload,
        isCreateAttendee: false,
        isUpdateAttendee: false,
      };
    case GET_ALL_ATTENDEES_BY_TRANSACTION_ID:
      return {
        ...state,
        allAttendeesAfterPayment: action.payload,
        isCreateAttendee: false,
        isUpdateAttendee: false,
      };
    case UPDATE_ATTENDEES_BY_ID:
      return {
        ...state,
        isUpdateAttendee: true,
        allAttendees: false,
        allAttendeesAfterPayment: false,
      };
    case UPDATE_SPONOSOR_BY_ID:
      return {
        ...state,
        allAttendees: false,
        allAttendeesAfterPayment: false,
      };
    case DELETE_ATTENDEES_BY_ID:
      return {
        ...state,
        allAttendees: false,
        allAttendeesAfterPayment: false,
      };
    case VERIFY_COUPONCODES:
      return {
        ...state,
        allAttendees: false,
        allAttendeesAfterPayment: false,
      };
    case APPLY_COUPONCODES:
      return {
        ...state,
        allAttendees: false,
        allAttendeesAfterPayment: false,
      };
    case SET_COUPONCODES_ERROR_MESSAGE:
      return {
        ...state,
        couponCodeErrorMessage: action.payload,
      };
    case ADD_SPONSOR_SUCCESS:
      return {
        ...state,
        allAttendees: false,
        allAttendeesAfterPayment: false,
      };
    case GET_ALL_REGISTRATION:
      return {
        ...state,
        allRegistrationDetailsByEvent: action.payload,
      };
    case GET_ALL_WAITING_LIST:
      return {
        ...state,
        allRegistrationWaitingListByEvent: action.payload,
      };
    case GET_ALL_USER_WAITING_LIST:
      return {
        ...state,
        allUserRegistrationWaitingList: action.payload,
      };
    case GET_ALL_REGISTRATION_CSV_DOWNLOAD:
      return {
        ...state,
        allRegistrationDetailsByEventForCSV: action.payload,
      };
    case UPDATE_REGISTRATION_OPTOINS_BY_ID:
      return {
        ...state,
        allRegistrationDetailsByEvent: false,
        allAttendees: false,
        allAttendeesAfterPayment: false,
      };
    case CANCEL_GROUP_OF_REGISTRATION_BY_EVENT_ID:
      return {
        ...state,
        allRegistrationDetailsByEvent: false,
      };
    case PAYMENT_EVENT_REGISTRATION_CLIENT_KEYS:
      return {
        ...state,
        eventRegistrationClientKeys: action.payload,
      };
    default:
      return state;
  }
};

export default EventRegister;

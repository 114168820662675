import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Upload,
  Radio,
  Space,
  Divider,
  Empty,
  Modal,
} from 'antd';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {
  InfoCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  OnlineEvents,
  commonSelectAll,
  eventStatusOptions,
  queryString,
  tagRender,
  maxTagPlaceholder,
  disablePrevDatesHandler,
  dateTimeValidator,
  validateEventString,
  validatePlaceForEvents,
  showTimeDefaultValue,
  NoEmptySpaceWithLimitedWordsInput,
  UploadFileMaxSize,
  UploadFileMaxSizeForImage,
} from '../../../../Util/commonUtility';
import {ApplicationString} from '../../../../Localization/Locales/en';
import dayjs from 'dayjs';
import CommonEditor from '../../../CommonComponents/TextEditor';
import {message, Spin} from 'antd';
import {useDispatch} from 'react-redux';
import {
  getAllEventTypesByQuerySearch,
  getAllKeywordsByQuerySearch,
  getAllSpeakersByQuerySearch,
} from '../../../../Store/Actions';
import {applicationConfig} from '../../../../Util/config';
import AddSpeakerModal from './SpeakersModal';
import {FETCH_ERROR} from '../../../../Constant/ActionTypes';

const EventsFormComponent = (props) => {
  const {
    postEvents,
    EditData,
    chaptersList,
    chapterOptions,
    eventTypeOptions,
    CompaniesList,
    updateEvents,
    fetching,
    fetchEventsById,
    speakerOptions,
    keywordsOptions,
    SetKeywordsOptions,
    setChapterOptions,
    setEventTypeOptions,
    setFetching,
    setSpeakerOptions,
    wantToLeaveFormPopup,
    handleOk,
    handleCancel,
  } = props;
  const [submitLabel, setSubmitLabel] = useState(
    ApplicationString['common.button.submitForm'],
  );
  const [editorBioState, setEditorBioState] = useState(`<p></p>`);
  const [editorInfoState, setEditorInfoState] = useState(`<p></p>`);
  const [editorSpeakersBio, setEditorSpeakersBio] = useState(`<p></p>`);
  const [selectedLocalChapter, setSelectedLocalChapter] = useState(null);
  const [toggleOnline, setToggleOnline] = useState(null);
  const [validFileList, setValidFileList] = useState([]);
  const [validImageFileList, setValidImageFileList] = useState([]);
  const [toggleAvailability, setToggleAvailability] = useState(null);
  const [isSpeakerModalVisible, setIsSpeakerModalVisible] = useState(false);

  const handleChapterWithoutLocalForOnline =
    chaptersList &&
    chaptersList?.filter((chapter) => chapter?.value !== selectedLocalChapter);
  const ChapterData = handleChapterWithoutLocalForOnline?.filter(
    (chapter) => chapter?.value !== selectedLocalChapter,
  );
  const ChapterDataArray = ChapterData?.map((chapter) => {
    return chapter?.value;
  });
  const [chapterWithoutLocalForOnline, setChapterWithoutLocalForOnline] =
    useState(ChapterDataArray);
  const [chapterWithoutLocalForWebcast, setChapterWithoutLocalForWebcast] =
    useState(ChapterDataArray);

  const dateFormat = 'YYYY-MM-DD h:mm A';

  const [form] = Form.useForm();
  const [speakerForm] = Form.useForm();

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedKeywords, setSelectedKeywords] = useState([]);

  const filesFormats = ['application/pdf'];
  const filesImageFormats = ['image/jpeg', 'image/png', 'image/jpg'];

  const uploadProps = {
    name: 'file',
    multiple: true,
    maxCount: 10,
    // listType: 'picture',
    beforeUpload: (file) => {
      const isRightFormat = filesFormats.includes(file.type);
      if (!isRightFormat) {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Accepted file format: PDF only',
        });
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < UploadFileMaxSize;
      if (!isLt2M) {
        dispatch({
          type: FETCH_ERROR,
          payload: `File must smaller than ${UploadFileMaxSize}MB.`,
        });
        return false;
      }

      setValidFileList((prev) => [file]);
      return isRightFormat && isLt2M;
    },
  };
  let count = 0;

  const uploadPropsImage = {
    name: 'file',
    multiple: true,
    maxCount: 5,
    beforeUpload: (file) => {
      const isRightFormat = filesImageFormats.includes(file.type);

      if (!isRightFormat) {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Accepted file formats: JPG and PNG only',
        });
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < UploadFileMaxSizeForImage;
      if (!isLt2M) {
        dispatch({
          type: FETCH_ERROR,
          payload: `File must smaller than ${UploadFileMaxSizeForImage}MB!`,
        });
        return false;
      }
      setValidImageFileList((prev) => {
        if (prev.length >= 5) {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Maximum 5 files can be uploaded',
          });
          return prev;
        }
        return [...prev, file];
      });

      return isRightFormat && isLt2M;
    },
  };

  const removeFileHandler = (file) => {
    setValidFileList((prevFileList) => {
      return prevFileList.filter((item) => item?.uid !== file?.uid);
    });
  };
  const removeImageFileHandler = (file) => {
    setValidImageFileList((prevFileList) => {
      return prevFileList.filter((item) => item?.uid !== file?.uid);
    });
  };

  // useEffect(() => {
  //   if (id && EditData?.online_for_chapters) {
  //     setChapterWithoutLocalForOnline(EditData?.online_for_chapters);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (id && EditData?.webcast_available_for_chapters) {
  //     setChapterWithoutLocalForWebcast(
  //       EditData?.webcast_available_for_chapters,
  //     );
  //   }
  // }, []);

  useEffect(() => {
    if (id || chaptersList) {
      EditData?.online_for_chapters?.length > 0
        ? setChapterWithoutLocalForOnline(
            EditData?.online_for_chapters?.map((data) => data.id),
          )
        : setChapterWithoutLocalForOnline(ChapterDataArray);
    } else {
      setChapterWithoutLocalForOnline(ChapterDataArray);
    }
  }, [chaptersList, selectedLocalChapter, toggleOnline]);

  useEffect(() => {
    if (id || chaptersList) {
      EditData?.webcast_available_for_chapters?.length > 0
        ? setChapterWithoutLocalForWebcast(
            EditData?.webcast_available_for_chapters?.map((data) => data.id),
          )
        : setChapterWithoutLocalForWebcast(ChapterDataArray);
    } else {
      setChapterWithoutLocalForWebcast(ChapterDataArray);
    }
  }, [chaptersList, selectedLocalChapter, toggleAvailability]);

  useEffect(() => {
    form.setFieldsValue({
      online_for_chapters: chapterWithoutLocalForOnline,
    });
  }, [chapterWithoutLocalForOnline]);

  useEffect(() => {
    form.setFieldsValue({
      webcast_available_for_chapters: chapterWithoutLocalForWebcast,
    });
  }, [chapterWithoutLocalForWebcast]);

  // useEffect(() => {
  //   const selectAllData = speakerOptions?.filter(
  //     (data) => data?.value !== 'all',
  //   );
  //   const speakerData = selectAllData?.map((item) => {
  //     return item?.value;
  //   });

  //   form.setFieldsValue({
  //     speakers: speakerData,
  //   });
  // }, []);

  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleOnlineChange = (values) => {
    if (values?.target?.value) {
      setToggleOnline(true);
      setToggleAvailability(false);
    } else {
      if (form.getFieldValue('is_available_for_webcast')) {
        setToggleAvailability(true);
        setToggleOnline(false);
      } else {
        setToggleAvailability(false);
        setToggleOnline(false);
      }
    }
  };
  const handleAvailabilityChange = (values) => {
    if (values?.target?.value === true) {
      setToggleAvailability(true);
      setToggleOnline(false);
    } else {
      setToggleAvailability(false);
    }
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleLocalChapterChange = (value) => {
    setSelectedLocalChapter(value);
  };

  const handleSpeakerSearch = (value) => {
    setSpeakerOptions([]);
    setFetching(true);
    const Data = {
      first_name: value,
    };
    const searchQueryData = queryString(Data);
    dispatch(
      getAllSpeakersByQuerySearch(
        {
          page: 1,
          pagecount: 10,
        },
        '',
        '',
        searchQueryData,
      ),
    );
  };
  const handleSpeakerAllowClear = () => {
    dispatch(
      getAllSpeakersByQuerySearch({
        page: 1,
        pagecount: 10,
      }),
    );
  };
  const handleSpeakerMouseLeave = () => {
    if (speakerOptions?.length === 0) {
      dispatch(
        getAllSpeakersByQuerySearch({
          page: 1,
          pagecount: 10,
        }),
      );
    }
  };
  const handleEventTypeSearch = (value) => {
    setEventTypeOptions([]);
    setFetching(true);
    const Data = {
      name: value,
    };
    const searchQueryData = queryString(Data);
    dispatch(
      getAllEventTypesByQuerySearch('', '', searchQueryData, {
        page: 1,
        pageCount: 10,
      }),
    );
  };
  const handleEventTypeAllowClear = () => {
    dispatch(
      getAllEventTypesByQuerySearch('', '', '', {
        page: 1,
        pageCount: 100,
      }),
    );
  };
  const handleEventTypeMouseLeave = () => {
    if (eventTypeOptions?.length === 0) {
      dispatch(
        getAllEventTypesByQuerySearch('', '', '', {
          page: 1,
          pageCount: 100,
        }),
      );
    }
  };

  const handleKeywordSearch = (value) => {
    SetKeywordsOptions([]);
    setFetching(true);
    const Data = {
      name: value,
    };
    const searchQueryData = queryString(Data);
    dispatch(
      getAllKeywordsByQuerySearch('', '', searchQueryData, {
        page: 1,
        pageCount: 10,
      }),
    );
  };
  const handleKeywordAllowClear = () => {
    dispatch(
      getAllKeywordsByQuerySearch('', '', '', {
        page: 1,
        pageCount: 10,
      }),
    );
  };
  const handleKeywordMouseLeave = () => {
    if (keywordsOptions?.length === 0) {
      dispatch(
        getAllKeywordsByQuerySearch('', '', '', {
          page: 1,
          pageCount: 10,
        }),
      );
    }
  };

  const handleKeywordChange = (value) => {
    setSelectedKeywords(value);
  };

  const beforeUploadFlyer = (file) => {
    const reader = new FileReader();
    const fileSize = file.size / 1024;
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
      };
      if (fileSize > 2) {
        message.error('File size must not exceed 2MB!');
        return Upload.LIST_IGNORE;
      }
      return true;
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (id) {
      fetchEventsById(id);
      setSubmitLabel(ApplicationString['common.button.updateForm']);
    }
  }, []);
  const timeFormat = 'HH:mm';

  const byPassIntialRenderRef = useRef({
    effect: false,
  });

  useEffect(() => {
    const webCastEvent = eventTypeOptions?.find(
      (type) => type?.label.toLowerCase() === 'webcast',
    );
    if (EditData) {
      if (byPassIntialRenderRef.effect) {
        if (toggleOnline) {
          form.setFieldsValue({
            event_type_id: webCastEvent,
          });
        } else {
          form.setFieldsValue({
            event_type_id: {
              label: EditData?.event_type_id?.name,
              value: EditData?.event_type_id?.id,
            },
          });
        }
      }
    } else {
      if (toggleOnline) {
        form.setFieldsValue({
          event_type_id: webCastEvent,
        });
      } else {
        form.setFieldsValue({
          event_type_id: null,
        });
      }
    }
    byPassIntialRenderRef.effect = true;
  }, [toggleOnline, eventTypeOptions]);

  useEffect(() => {
    const dateValues = EditData?.formattedDateTime;
    if (id == EditData?.id) {
      if (EditData) {
        // Editdata?.flyer_url && setValidFileList
        EditData?.flyer_url &&
          setValidFileList((prev) => [
            {
              uid: EditData?.flyer_url?.id,
              name: EditData?.flyer_url?.name,
              status: 'done',
              url: `${applicationConfig.UPLOAD_URL}/${EditData?.flyer_url?.name}`,
            },
          ]);
        EditData?.flyer_image_ids &&
          setValidImageFileList((prev) =>
            EditData?.flyer_image_ids?.map((item) => {
              return {
                uid: item?.id,
                name: item?.name,
                status: 'done',
                url: `${applicationConfig.UPLOAD_URL}/${item?.name}`,
              };
            }),
          );

        EditData?.is_available_for_webcast
          ? setToggleAvailability(true)
          : setToggleAvailability(false);
        EditData?.is_online ? setToggleOnline(true) : setToggleOnline(false);
        form.setFieldsValue({
          ...EditData,
          event_end_date_time: dateValues?.event_end_date_tz
            ? dayjs(
                `${dateValues?.event_end_date_tz} ${dateValues?.event_end_time_tz}`,
              )
            : null,
          event_start_date_time: dateValues?.event_start_date_tz
            ? dayjs(
                dateValues?.event_start_date_tz +
                  ' ' +
                  dateValues?.event_start_time_tz,
              )
            : null,
          reg_end_date_time: dateValues?.reg_end_date_tz
            ? dayjs(
                dateValues?.reg_end_date_tz + ' ' + dateValues?.reg_end_time_tz,
              )
            : null,
          reg_start_date_time: dateValues?.reg_start_date_tz
            ? dayjs(
                dateValues?.reg_start_date_tz +
                  ' ' +
                  dateValues?.reg_start_time_tz,
              )
            : null,
          date: EditData?.date ? dayjs(EditData?.date) : null,
          refund_last_date: EditData?.refund_last_date
            ? dayjs(EditData?.refund_last_date)
            : '',
          local_chapter_id: {
            ...EditData?.local_chapter_id,
            label: EditData?.local_chapter_id?.name,
            value: EditData?.local_chapter_id?.id,
          },
          // speakers: EditData?.speakers?.map((item, index) => {
          //   return {
          //     ...item,
          //     label: item?.first_name + ' ' + item?.last_name,
          //     value: item?.id,
          //   };
          // }),
          speaker_name: EditData?.speaker_name,
          // company_id: {
          //   ...EditData?.company_id,
          //   label: EditData?.company_id?.name,
          //   value: EditData?.company_id?.id,
          // },
          organizers: EditData?.organizers?.join(', '),
          event_type_id: {
            ...EditData?.event_type_id,
            label: EditData?.event_type_id?.name,
            value: EditData?.event_type_id?.id,
          },
          webcast_available_for_chapters:
            EditData?.webcast_available_for_chapters?.map((data) => data.id),
          online_for_chapters: EditData?.online_for_chapters?.map(
            (data) => data.id,
          ),
          event_keywords: EditData?.keywords?.map((item, index) => {
            return {
              ...item,
              label: item?.name,
              value: item?.id,
            };
          }),
        });
        setChapterWithoutLocalForOnline(
          EditData?.online_for_chapters?.map((data) => data.id),
        );
        setChapterWithoutLocalForWebcast(
          EditData?.webcast_available_for_chapters?.map((data) => data.id),
        );
        setSelectedKeywords(
          EditData?.keywords?.map((item) => {
            return item?.id;
          }),
        );
        setEditorBioState(EditData?.event_bio);
        setEditorInfoState(EditData?.event_info);
        setEditorSpeakersBio(EditData?.speaker_bio);
      }
    }
  }, [EditData]);

  const handleSelectChange = (value) => {
    const selectAllData = handleChapterWithoutLocalForOnline?.filter(
      (data) => data?.value !== 'all',
    );
    const selectAllDataId = selectAllData?.map((data) => data.value);
    if (value.includes('all')) {
      form.setFieldsValue({
        webcast_available_for_chapters: selectAllDataId,
      });
      setChapterWithoutLocalForWebcast(selectAllDataId);
    } else {
      setChapterWithoutLocalForWebcast(value);
    }
  };
  const handleSelectSpeakerChange = (value) => {
    const selectAllData = speakerOptions?.filter(
      (data) => data?.value !== 'all',
    );
    const speakerData = selectAllData?.map((item) => {
      return item?.value;
    });
    if (value.includes('all')) {
      form.setFieldsValue({
        speakers: speakerData,
      });
    }
  };
  const handleSelectOnlineChange = (value) => {
    const selectAllData = handleChapterWithoutLocalForOnline?.filter(
      (data) => data?.value !== 'all',
    );
    const selectAllDataId = selectAllData?.map((data) => data.value);
    if (value.includes('all')) {
      form.setFieldsValue({
        online_for_chapters: selectAllDataId,
      });
      setChapterWithoutLocalForOnline(selectAllDataId);
    } else {
      setChapterWithoutLocalForOnline(value);
    }
  };

  const appendFormattedDateTime = (key, value, data) => {
    if (value) {
      data.append(key, value.format('YYYY-MM-DD HH:mm:ss'));
    }
  };

  const onFinish = (values) => {
    const ImageIds = [];
    const data = new FormData();

    if (EditData) {
      EditData?.flyer_url && EditData?.flyer_url?.id === validFileList[0]?.uid
        ? data.append('flyer_url', `${validFileList[0]?.name}`)
        : data.append(
            'flyer',
            validFileList[0]?.originFileObj || validFileList[0] || null,
          );
      validImageFileList.forEach((validFile) => {
        EditData?.flyer_image_ids?.some((file) => file?.id === validFile?.uid)
          ? ImageIds.push(validFile?.uid)
          : data.append('flyer_images', validFile?.originFileObj || validFile);
      });
      data.append('flyer_image_ids', `[${ImageIds}]`);
    } else {
      data.append(
        'flyer',
        validFileList[0]?.originFileObj || validFileList[0] || null,
      );
      validImageFileList?.forEach((file) =>
        data.append(`flyer_images`, file?.originFileObj || file),
      );
      // data.append(
      //   'flyer_image',
      //   validImageFileList?.map((file) => file?.originFileObj || file),
      // );
    }
    data.append('name', values?.name);
    data.append('details', values?.details);
    data.append('place', values?.place);
    data.append(
      'event_type_id',
      values?.event_type_id?.value || values?.event_type_id?.value === 0
        ? values?.event_type_id?.value
        : values?.event_type_id,
    );
    data.append('event_info', editorInfoState);
    data.append('event_bio', editorBioState);
    data.append('speaker_bio', editorSpeakersBio);
    data.append('registration_price', values?.registration_price);
    data.append('seats', values?.seats ? parseInt(values?.seats) : 0);
    data.append('status', values?.status);
    values?.is_available_for_webcast &&
      data.append(
        'is_available_for_webcast',
        values?.is_available_for_webcast
          ? values?.is_available_for_webcast
          : false,
      );
    data.append(
      'local_chapter_id',
      values?.local_chapter_id?.value || values?.local_chapter_id,
    );
    toggleAvailability &&
      data.append(
        'webcast_available_for_chapters',
        JSON.stringify(chapterWithoutLocalForWebcast),
      );
    data.append('event_keywords', `[]`);
    data.append('speaker_name', values?.speaker_name);
    data.append('flyer_text', values?.flyer_text);
    data.append(
      'organizers',
      JSON.stringify(values?.organizers.split(',').map((item) => item.trim())),
    );
    data.append('is_online', values?.is_online);
    toggleOnline &&
      data.append(
        'online_for_chapters',
        JSON.stringify(chapterWithoutLocalForOnline),
      );

    appendFormattedDateTime(
      'event_start_date_time',
      values?.event_start_date_time,
      data,
    );
    appendFormattedDateTime(
      'event_end_date_time',
      values?.event_end_date_time,
      data,
    );
    appendFormattedDateTime(
      'reg_start_date_time',
      values?.reg_start_date_time,
      data,
    );
    appendFormattedDateTime(
      'reg_end_date_time',
      values?.reg_end_date_time,
      data,
    );
    appendFormattedDateTime('refund_last_date', values?.refund_last_date, data);

    if (id) {
      updateEvents(data, EditData?.id);
    } else {
      postEvents(data);
    }
  };
  const {id} = useParams();
  const onFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };

  const onReset = () => {
    form.resetFields();
    setEditorBioState(`<p></p>`);
    setEditorInfoState(`<p></p>`);
    setEditorSpeakersBio(`<p></p>`);
    form.setFieldsValue({
      event_keywords: [],
    });
    setSelectedKeywords([]);
  };
  const backToEvent = () => {
    navigate('/admin/events');
  };
  // const regex = /^[a-zA-Z ]*$/;
  // const validatePlace = (_, value, callback) => {
  //   const emptySpaceRegex = /\s{4,}/g;
  //   if (!value) {
  //     callback();
  //   } else if (!value.match(regex)) {
  //     callback('Input should include only alphabets!');
  //   } else if (value && value.trim() === '') {
  //     return Promise.reject(new Error('Input should not be empty!'));
  //   } else if (value && emptySpaceRegex.test(value)) {
  //     return Promise.reject(new Error('Too many consecutive spaces!'));
  //   } else {
  //     callback();
  //   }
  // };
  const handleAddSpeakerModal = () => {
    setIsSpeakerModalVisible(true);
  };
  const handleCancelSpeakerModal = () => {
    setIsSpeakerModalVisible(false);
  };

  const dropdownAddSpeakerRender = (menu) => {
    return (
      <>
        {menu}
        <Divider
          style={{
            margin: '8px 0',
          }}
        />

        <Space prefixCls='speaker-modal-space'>
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={handleAddSpeakerModal}
            className='common-submit-login common-form-submit speakers-form'>
            Add New Speaker
          </Button>
        </Space>
      </>
    );
  };
  let eventTypeOptionRender = eventTypeOptions;

  if (toggleAvailability === false && toggleOnline === false) {
    //here checked with the 'webcast' and 'webcast ' because in our data client added both values in event types
    const webcastWithoutSpaceFilter = eventTypeOptions
      ?.filter((obj) => obj.label?.toLowerCase() !== 'webcast')
      ?.filter((obj) => obj.label?.toLowerCase() !== 'webcast ');
    eventTypeOptionRender = webcastWithoutSpaceFilter;
  }

  return (
    <div className='head-main-container'>
      <Modal
        open={wantToLeaveFormPopup}
        centered
        title={
          ApplicationString['userEventFormAdminLeavePopupComponent.heading']
        }
        prefixCls='event-form-want-to-leave-popup'
        okText={
          ApplicationString['userEventFormAdminLeavePopupComponent.button.exit']
        }
        cancelText={
          ApplicationString[
            'userEventFormAdminLeavePopupComponent.button.continueEditing'
          ]
        }
        onOk={handleOk}
        onCancel={handleCancel}>
        <p>
          {
            ApplicationString[
              'userEventFormAdminLeavePopupComponent.subheading'
            ]
          }
        </p>
      </Modal>
      <AddSpeakerModal
        setIsSpeakerModalVisible={setIsSpeakerModalVisible}
        visible={isSpeakerModalVisible}
        onCancel={handleCancelSpeakerModal}
      />
      {id && (
        <div className='search-container' style={{gap: '10px'}}>
          <Button
            type='default'
            onClick={() => navigate(`/admin/eventRegistration/${id}`)}
            id='EventTemplateSchedulerComponent-button-filterSearch'>
            Events Registration
          </Button>
        </div>
      )}
      <div className='main-form-container'>
        <Form
          form={form}
          name='eventFormComponent'
          onFinish={onFinish}
          initialValues={''}
          layout='vertical'
          className='two-column-form'
          onFinishFailed={onFinishFailed}
          autoComplete='off'>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Is this an online only event?'
                name='is_online'
                valuePropName='value'
                rules={[
                  {
                    required: true,
                    message: 'Is event for online should not be empty',
                  },
                ]}>
                <Radio.Group
                  prefixCls='common-radio-group'
                  optionType='button'
                  onChange={handleOnlineChange}
                  buttonStyle='solid'>
                  {Object.entries(OnlineEvents).map(([key, value]) => (
                    <Radio key={key} value={value}>
                      {key}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </div>
            <div className='form-column'>
              {toggleOnline === false && (
                <>
                  <Form.Item
                    label='Webcast for Non-local Attendees?'
                    name='is_available_for_webcast'
                    rules={[
                      {
                        required: true,
                        message:
                          'Webcast for non-local attendees should not be empty',
                      },
                    ]}>
                    <Radio.Group
                      prefixCls='common-radio-group'
                      optionType='button'
                      buttonStyle='solid'
                      onChange={handleAvailabilityChange}>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </>
              )}
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                name='event_type_id'
                label='Select Your Default Event Type'
                rules={[
                  {
                    required: true,
                    message: 'Event type should not be empty',
                  },
                ]}>
                <Select
                  id='adminEvents-defaultEvents-select'
                  prefixCls='common-select-register'
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  onSearch={handleEventTypeSearch}
                  onMouseLeave={handleEventTypeMouseLeave}
                  allowClear
                  onClear={handleEventTypeAllowClear}
                  notFoundContent={
                    fetching ? (
                      <Spin size='small' />
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  className='space'
                  disabled={toggleOnline}
                  placeholder={'Select your default event types'}
                  // onSearch={handleEventTypeSearch}
                  // notFoundContent={fetching ? <Spin size='small' /> : null}
                  options={eventTypeOptionRender}></Select>
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                initialValue={''}
                label='Event Name'
                name='name'
                rules={[
                  {
                    required: true,
                    message: 'Event name should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      validateEventString(value, 'Event name'),
                  },
                ]}>
                <Input
                  id='adminEvents-addName-input'
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter name of the event'
                />
              </Form.Item>
              <Form.Item
                label='Start Date'
                name='event_start_date_time'
                rules={[
                  {
                    required: true,
                    message: 'Start date should not be empty',
                  },
                  ({getFieldValue}) => ({
                    validator: async (_, value) => {
                      try {
                        await dateTimeValidator(value, 'Event start date');
                        const regEndDate = getFieldValue('reg_end_date_time');
                        if (value && value.isAfter(regEndDate)) {
                          return Promise.resolve();
                        } else {
                          return value
                            ? Promise.reject(
                                'Event start date and time must be after registration end date and time',
                              )
                            : '';
                        }
                      } catch (error) {
                        return Promise.reject(error.message);
                      }
                    },
                  }),
                ]}>
                <DatePicker
                  id='adminEvents-selectDate-eventDate'
                  disabledDate={(current) =>
                    disablePrevDatesHandler(current, true)
                  }
                  showTime={showTimeDefaultValue}
                  prefixCls='common-input-user'
                  className='genral-form width-100'
                  format={dateFormat}
                  placeholder='Select event start date'
                  minuteStep={5}
                />
              </Form.Item>

              <Form.Item
                label='Registration Start Date'
                name='reg_start_date_time'
                rules={[
                  {
                    required: true,
                    message: 'Registration start date should not be empty',
                  },
                ]}>
                <DatePicker
                  id='adminEvents-selectDate-registrationDate'
                  disabledDate={(current) =>
                    disablePrevDatesHandler(current, true)
                  }
                  showTime={showTimeDefaultValue}
                  prefixCls='common-input-user'
                  className='genral-form width-100'
                  format={dateFormat}
                  placeholder='Select registration start date'
                  minuteStep={5}
                />
              </Form.Item>

              {/* <Form.Item label='Details' name='details'>
                <Input
 prefixCls='common-input-user'
                  className='genral-form'                  placeholder='Enter your details'
                />
              </Form.Item> */}
              <Form.Item
                initialValue={[]}
                label='Event Organizers'
                name='organizers'
                rules={[
                  {
                    required: true,
                    message: 'Event organizers should not be empty',
                  },
                  {
                    validator: (_, value) => {
                      const regex = /\s{4,}/g;
                      // added extra validation cause it was causing second error message from antd
                      if (value && value?.length <= 0) {
                        return Promise.reject(new Error(''));
                      }
                      if (value && value.trim() === '') {
                        return Promise.reject(
                          new Error('Event organizers cannot include space!'),
                        );
                      } else if (value && regex.test(value)) {
                        return Promise.reject(
                          new Error('Too many consecutive spaces!'),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}>
                <Input
                  id='adminEvents-eventOrganizers-input'
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter event organizers, separated by comma'
                />
              </Form.Item>

              {/* <Form.Item
                label='Online Event'
                name='is_online'
                valuePropName='value'>
                <Select
                  prefixCls='common-select-register'
                  placeholder='Select Online Event'
                  onChange={handleOnlineChange}>
                  {Object.entries(OnlineEvents).map(([key, value]) => (
                    <Option key={key} value={value}>
                      {key}
                    </Option>
                  ))}
                </Select>
              </Form.Item> */}
            </div>

            <div className='form-column'>
              <Form.Item
                initialValue={''}
                label='Place'
                name='place'
                rules={[
                  {required: true, message: 'Place should not be empty'},
                  {
                    validator: (_, value) =>
                      validatePlaceForEvents(value, 'Place'),
                  },
                ]}>
                <Input
                  id='adminEvents-eventLocation-input'
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter event location'
                />
              </Form.Item>
              {/* <Form.Item
                label='Date'
                name='date'
                rules={[
                  {
                    required: true,
                    message: 'Please select Date ',
                  },
                ]}>
                <DatePicker
 prefixCls='common-input-user'
                  className='genral-form width-100'                  placeholder='Select date of the event'
                />
              </Form.Item> */}

              <Form.Item
                label='End Date'
                name='event_end_date_time'
                rules={[
                  {
                    required: true,
                    message: 'End date Should not be empty',
                  },
                  ({getFieldValue}) => ({
                    validator: async (_, value) => {
                      try {
                        await dateTimeValidator(value, 'Event end date');
                        const startDate = getFieldValue(
                          'event_start_date_time',
                        );
                        if (!startDate || !value) {
                          return Promise.resolve();
                        }
                        if (value && value.isAfter(startDate)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            'Event end date and time must be after event start date and time',
                          );
                        }
                      } catch (error) {
                        return Promise.reject(error.message);
                      }
                    },
                  }),
                ]}>
                <DatePicker
                  id='adminEvents-eventEndDate-dateSelect'
                  showTime={showTimeDefaultValue}
                  disabledDate={(current) =>
                    disablePrevDatesHandler(current, true)
                  }
                  prefixCls='common-input-user'
                  className='genral-form width-100'
                  format={dateFormat}
                  placeholder='Select event end date'
                  minuteStep={5}
                />
              </Form.Item>

              <Form.Item
                label='Registration End Date'
                name='reg_end_date_time'
                rules={[
                  {
                    required: true,
                    message: 'Registration end date should not be empty',
                  },
                  ({getFieldValue}) => ({
                    validator: (_, value) => {
                      try {
                        const startDate = getFieldValue('reg_start_date_time');
                        if (!startDate || !value) {
                          return Promise.resolve();
                        }
                        if (value && value.isAfter(startDate)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            'Registration end date and time must be after registration start date and time',
                          );
                        }
                      } catch (error) {
                        return Promise.reject(error.message);
                      }
                    },
                  }),
                ]}>
                <DatePicker
                  id='adminEvents-eventRegistrationEndDate-dateSelect'
                  showTime={showTimeDefaultValue}
                  disabledDate={(current) =>
                    disablePrevDatesHandler(current, true)
                  }
                  prefixCls='common-input-user'
                  className='genral-form width-100'
                  format={dateFormat}
                  placeholder='Select registration end date'
                  minuteStep={5}
                />
              </Form.Item>
              {/* {toggleOnline === false && (
                <>
                  <Form.Item
                    label='Availability For Webcast'
                    name='is_available_for_webcast'>
                    <Select
                      prefixCls='common-select-register'
                      placeholder='Select Available For Webcast'
                      onChange={handleAvailabilityChange}>
                      {Object.entries(EventWebCast).map(([key, value]) => (
                        <Option key={key} value={value}>
                          {value ? 'Yes' : 'No'}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              )} */}

              {/* <Form.Item
                label='Select Speakers for event'
                name='speakers'
                rules={[
                  {
                    required: true,
                    message: 'Speakers should not be empty!',
                  },
                ]}>
                <Select
                  id='adminEvents-eventSpeakers-select'
                  maxTagCount={'responsive'}
                  maxTagPlaceholder={maxTagPlaceholder}
                  prefixCls='common-select-register'
                  showSearch
                  mode='multiple'
                  // optionFilterProp='children'
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  onChange={handleSelectSpeakerChange}
                  onSearch={handleSpeakerSearch}
                  onMouseLeave={handleSpeakerMouseLeave}
                  allowClear
                  onClear={handleSpeakerAllowClear}
                  virtual={false}
                  dropdownRender={dropdownAddSpeakerRender}
                  notFoundContent={
                    fetching ? (
                      <Spin size='small' />
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  className='space'
                  maxTagTextLength={10}
                  tagRender={tagRender}
                  placeholder={'Select speakers for this event'}
                  options={speakerOptions}></Select>
              </Form.Item> */}
              <Form.Item
                label='Add Speakers for event'
                name='speaker_name'
                rules={[
                  {
                    required: true,
                    message: 'Speakers should not be empty',
                  },
                ]}>
                <Input
                  id='adminEvents-eventLocation-input'
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Add speakers for this event'
                />
              </Form.Item>

              {/* <Form.Item
                name='company_id'
                label='Select Your Default Company'
                rules={[
                  {
                    required: true,
                    message: 'Company should not be empty!',
                  },
                ]}>
                <Select
                  id='adminEvents-defaultCompany-select'
                  prefixCls='common-select-register'
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  className='space'
                  placeholder={'Select your default company'}
                  options={CompaniesList}></Select>
              </Form.Item> */}
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                initialValue={''}
                label='Registration Fees'
                name='registration_price'>
                <Input
                  id='adminEvents-addName-input'
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter registration fees of the event'
                />
              </Form.Item>
            </div>
          </div>

          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Select a Local Chapters'
                name='local_chapter_id'
                rules={[
                  {
                    required: true,
                    message: 'Local chapter should not be empty',
                  },
                ]}>
                <Select
                  id='adminEvents-localChapters-select'
                  prefixCls='common-select-register'
                  showSearch
                  allowClear
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  // onSearch={handleChapterSearch}
                  // notFoundContent={fetching ? <Spin size='small' /> : null}
                  className='space'
                  placeholder={'Select local chapters for this event'}
                  onChange={handleLocalChapterChange}
                  options={chapterOptions}></Select>
              </Form.Item>
            </div>
            <div className='form-column'>
              <Form.Item
                initialValue={'1'}
                label='Status'
                name='status'
                rules={[
                  {
                    required: true,
                    message: 'Event status should not be empty',
                  },
                ]}>
                <Select
                  id='adminEvents-eventStatus-select'
                  prefixCls='common-select-register'
                  placeholder='Select event status'
                  options={eventStatusOptions}>
                  {/* {Object.values(EventStatus).map((status) => (
                    <Option key={status} value={status}>
                      {status}
                    </Option>
                  ))} */}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              {toggleAvailability && (
                <>
                  <Form.Item
                    label='Webcast for Non-local Attendees'
                    name='webcast_available_for_chapters'
                    rules={[
                      {
                        required: true,
                        message:
                          'Webcast for non-local attendees should not be empty',
                      },
                    ]}
                    initialValue={chapterWithoutLocalForWebcast}>
                    <Select
                      id='adminEvents-availableChapters-select'
                      prefixCls='common-select-register'
                      showSearch
                      maxTagCount={'responsive'}
                      maxTagPlaceholder={maxTagPlaceholder}
                      mode='multiple'
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option?.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={
                        'Select your available chapters for online event'
                      }
                      onChange={handleSelectChange}
                      tagRender={tagRender}
                      value={chapterWithoutLocalForWebcast}
                      options={
                        chaptersList && [
                          ...commonSelectAll(
                            chaptersList.filter(
                              (chapter) =>
                                chapter?.value !== selectedLocalChapter,
                            ),
                          ),
                        ]
                      }></Select>
                  </Form.Item>
                </>
              )}
              {toggleOnline && (
                <>
                  <Form.Item
                    label='Availability of Online Event'
                    name='online_for_chapters'
                    // initialValue={chapterWithoutLocalForOnline}
                    rules={[
                      {
                        required: true,
                        message:
                          'Availability for online event should not be empty',
                      },
                    ]}>
                    <Select
                      prefixCls='common-select-register'
                      showSearch
                      mode='tags'
                      maxTagCount={'responsive'}
                      maxTagPlaceholder={maxTagPlaceholder}
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option?.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={
                        'Select your available chapters for online event'
                      }
                      onChange={handleSelectOnlineChange}
                      tagRender={tagRender}
                      value={chapterWithoutLocalForOnline}
                      options={
                        chaptersList && [...commonSelectAll(chaptersList)]
                      }></Select>
                  </Form.Item>
                </>
              )}
            </div>
          </div>

          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                initialValue={''}
                label={
                  <div className='detailLabelAndTextMessageContainer'>
                    <span className='detailsLabel'>Details</span>
                    <span className='detailsTextMessage'>
                      <InfoCircleOutlined /> This field is carried from the old
                      PBSS website. If the content is added here, it will be
                      reflected on the website. Max. limit is 240 words.
                    </span>
                  </div>
                }
                name='details'
                rules={[
                  {
                    validator: (_, value) =>
                      NoEmptySpaceWithLimitedWordsInput(value, 'Details'),
                  },
                ]}>
                <Input.TextArea
                  id='adminEvents-details-input'
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter your event details'
                />
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label={
                  <div className='detailLabelAndTextMessageContainer'>
                    <span className='detailsLabel'>Event Description</span>
                    <span className='detailsTextMessage'></span>
                  </div>
                }
                name='event_bio'>
                <CommonEditor
                  value={editorBioState}
                  onChange={setEditorBioState}
                  text={'event_bio'}
                  placeholderValue={'event description'}
                />
              </Form.Item>
              <Form.Item label='Event Agenda' name='event_info'>
                <CommonEditor
                  value={editorInfoState}
                  onChange={setEditorInfoState}
                  text={'event_info'}
                  placeholderValue={'event agenda'}
                />
              </Form.Item>
              <Form.Item label="Speakers' Bio" name='speaker_bio'>
                <CommonEditor
                  value={editorInfoState}
                  onChange={setEditorSpeakersBio}
                  text={'speakers_bio'}
                  placeholderValue={'speakers_bio'}
                />
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                name='flyer'
                label={'Upload Flyer'}
                // valuePropName='fileList'
                getValueFromEvent={normFile}>
                <Upload
                  prefixCls='upload-list-name-fix-class-admin'
                  className='common-input-user genral-form height-auto pd-y-20'
                  {...uploadProps}
                  accept='.pdf'
                  onRemove={removeFileHandler}
                  customRequest={dummyRequest}
                  fileList={validFileList}
                  showUploadList={true}>
                  <div>
                    <UploadOutlined className='upload-logo-admin' />
                    <div>
                      <span className='upload-text-logos-admin'>
                        Upload Flyer
                      </span>
                    </div>
                    <div className='upload-type-admin'>
                      <span className='upload-desc-admin'>
                        Accepts only pdf files.
                      </span>
                      <br />
                      <span className='upload-desc-admin'>
                        {`Maximum file size is ${UploadFileMaxSize}MB.`}
                      </span>
                    </div>
                  </div>
                </Upload>
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                name='flyer_images'
                label={'Upload Flyer Images'}
                // valuePropName='fileList'
                getValueFromEvent={normFile}>
                <Upload
                  prefixCls='upload-list-name-fix-class-admin'
                  className='common-input-user genral-form height-auto pd-y-20'
                  {...uploadPropsImage}
                  accept='.jpg, .jpeg, .png'
                  onRemove={removeImageFileHandler}
                  customRequest={dummyRequest}
                  fileList={validImageFileList}
                  showUploadList={true}>
                  <div>
                    <UploadOutlined className='upload-logo-admin' />
                    <div>
                      <span className='upload-text-logos-admin'>
                        Upload Flyer Images
                      </span>
                    </div>
                    <div className='upload-type-admin'>
                      <span className='upload-desc-admin'>
                        Accepts only jpg,jpeg and png files.
                      </span>
                      <br />
                      <span className='upload-desc-admin'>
                        {`Maximum file size is ${UploadFileMaxSizeForImage}MB.`}
                      </span>
                    </div>
                  </div>
                </Upload>
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                initialValue={''}
                label={'Flyer Text'}
                name='flyer_text'>
                <Input
                  id='adminEvents-flyerText-input'
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter flyer text'
                />
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                initialValue={0}
                label={
                  <div className='detailLabelAndTextMessageContainer'>
                    <span className='detailsLabel'>Seats</span>
                    <span className='detailsTextMessage'>
                      <InfoCircleOutlined /> This field is carried from the old
                      PBSS website.
                    </span>
                  </div>
                }
                name='seats'>
                <Input
                  id='adminEvents-seatNumbers-input'
                  prefixCls='common-input-user'
                  className='genral-form'
                  disabled
                  min={0}
                  controls={false}
                  placeholder='Enter number of seats for this event'
                />
              </Form.Item>
            </div>
          </div>

          <Form.Item>
            <div className='button-container'>
              <Button
                id='adminEvents-backButton-button'
                className='common-submit-login common-form-submit common-form-cancel'
                onClick={() => {
                  backToEvent();
                }}>
                {ApplicationString['common.button.backForm']}
              </Button>
              <div className='form-flex-button'>
                <Button
                  id='adminEvents-submitButton-button'
                  type='primary'
                  htmlType='submit'
                  // //disabled={submit ? true : false}
                  className='common-submit-login common-form-submit'>
                  {submitLabel}
                </Button>
                <Button
                  id='adminEvents-resetButton-button'
                  className='common-submit-login common-form-submit common-form-cancel'
                  htmlType='button'
                  onClick={onReset}>
                  {ApplicationString['dynamicSearch.button.reset']}
                </Button>{' '}
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>{' '}
    </div>
  );
};
export default EventsFormComponent;

import React, {useEffect, useState} from 'react';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import {Select, Form, Tag, Row, Col} from 'antd';

import './style.css';
import ConfirmationAction from '../../CommonComponents/PaymentConfirmationAction';
import {useNavigate, useParams} from 'react-router-dom';

import {
  UploadFileMaxSize,
  formatCurrency,
  getLocalStorageUserInfo,
  validatePaymentPrice,
} from '../../../Util/commonUtility';

import EventsCommonCartTable from '../../CommonComponents/EventCartCommonTable';
import {useDispatch} from 'react-redux';
import SponsorPerksCard from '../../CommonComponents/SponsorPerksComponent';
import CustomCollapse from '../../CommonComponents/CommonAccordian';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';
import NavigationComponent from '../../CommonComponents/CommonBackButton';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {ApplicationString} from '../../../Localization/Locales/en';
import SponsorDetailCartForm from './SponsorDetailsCartForm';
import SponsorModal from './../../CommonComponents/SponsorModal/index';
import {applicationConfig} from './../../../Util/config';
import {FETCH_ERROR} from '../../../Constant/ActionTypes';
import {UploadOutlined} from '@ant-design/icons';

const EventSponsorCartComponent = ({
  eventName,
  setListAttendeesData,
  totalCartPrice,
  eventRegisterData,
  allSponsorAttendeesData,
  sponsorData,
  deleteSponsorAttendee,
  paymentByUserRegistration,
  allEventOptionsFoodData,
  updateSponsorAttendee,
  updateSponsor,
  applyCouponCode,
  appliedCartCoupon,
  SponsorAttendeeOptionById,
  sponsorPerksData,
  attendeesDataWithPerks,
  eventType,
  setListAttendeesDataWithPerks,
  fetchSponsorAttendeesList,
  postSponsorAttendeesById,
}) => {
  const {id, optionId, transactionId} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  const [isAddAttendee, setIsAddAttendee] = useState(false);
  const [mealOptions, setMealOptions] = useState(true);
  const isEditing = (record) => record.id === editingKey;
  const [sponsorOption, setSelectOptionsValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [eventSponsorshipOption, setEventSponsorshipOption] = useState();
  const [eventSponsorPerksData, setEventSponsorPerksData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventSponsorData, setEventSponsor] = useState([]);
  const [validBannerFileList, setValidBannerFileList] = useState([]);
  const [validLogoFileList, setValidLogoFileList] = useState([]);
  const localUserData = getLocalStorageUserInfo();

  useEffect(() => {
    if (sponsorPerksData) {
      setEventSponsorPerksData(sponsorPerksData);
    } else {
      SponsorAttendeeOptionById(optionId);
    }
  }, [sponsorPerksData, optionId]);

  const edit = (record) => {
    const EditData = {
      ...record,
      attendee_email_confirm: record?.attendee_email
        ? record?.attendee_email
        : '',
      food_option: record.event_registration_options?.food
        ? record.event_registration_options?.food?.event_option_id
        : 'no-food',
      registration_option:
        record.event_registration_options?.registration?.event_option_id,
      coupon_code: record?.discount_code,
    };
    form.setFieldsValue({
      ...EditData,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    if (isAddAttendee) {
      allSponsorAttendeesData.pop();
      setListAttendeesData([...allSponsorAttendeesData]);
    }
    setIsAddAttendee(false);
    setEditingKey('');
  };

  const save = async (data) => {
    const row = await form.validateFields();
    const sponsorData = {
      ...row,
      // is_reps: totalRepresentatives > 2 ? false : true,
      event_option_perk_id: eventSponsorshipOption?.event_sponsorship_perk?.id,
      sponsorship_option_id: eventSponsorshipOption?.event_option_id,
      event_id: Number(id),
      food_option:
        row.food_option === 'no-food' || eventType?.toLowerCase() === 'webcast'
          ? {}
          : {
              type: 'food',
              event_option_id: row?.food_option,
              qty: 1,
              id: data?.food_option?.id,
            },
      registration_option: {
        type: 'registration',
        event_option_id: row?.registration_option,
        qty: 1,
        id: data?.registration_option?.id,
      },
    };
    if (isAddAttendee) {
      postAttendeesById(sponsorData, setEditingKey, form);
      setIsAddAttendee(false);
    } else {
      updateSponsorAttendee(sponsorData, data?.id, setEditingKey, form);
    }
  };

  const handleDelete = (id) => {
    deleteSponsorAttendee(id);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      editable: true,
      width: '15%',
      render: (text, record) => {
        return (
          <Tag prefixCls='regularAttendeesTag'>
            {record.attendee_first_name} {record.attendee_last_name}
          </Tag>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'attendee_email',
      editable: true,
      width: '18%',
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
      },
    },
    {
      title: 'Option',
      dataIndex: 'option',
      key: 'registration_and_food_option',
      editable: true,
      width: '15%',
      render: (_, record) => {
        const registrationName =
          record?.event_registration_options?.registration?.name;
        const price = record?.event_registration_options?.registration?.price;
        const foodName =
          record?.event_registration_options?.food?.name || 'No Food';
        const foodPrice = record?.event_registration_options?.food?.price;
        return (
          <div className='option-tag-container'>
            <Tag prefixCls='regularAttendeesTag'>
              Registration: {registrationName} - {formatCurrency(price, 'USD')}
            </Tag>
            {eventType !== 'Webcast' && (
              <Tag prefixCls='regularAttendeesTag'>
                Food: {foodName}{' '}
                {foodPrice ? '- ' + formatCurrency(foodPrice, 'USD') : ''}
              </Tag>
            )}
          </div>
        );
      },
    },

    {
      title: 'Company/Affiliation',
      dataIndex: 'attendee_company',
      key: 'company',
      width: '15%',
      editable: true,
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
      },
    },
    {
      title: 'Price',
      dataIndex: 'total_price_per_attendee',
      width: '5%',
      render: (text) => {
        return (
          <h4 className='regularAttendees-price'>{formatCurrency(text)}</h4>
        );
      },
    },

    {
      title: ' ',
      dataIndex: 'action',
      width: '6%',
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <AdminActionsDropdown
            record={record}
            usedForAttendeeTable={true}
            editBool={true}
            attendeeEditingKey={editingKey}
            handleCancelClick={cancel}
            handleSaveClick={save}
            deleteBool={false}
            handleEditClick={edit}
            handleDeleteClick={() => handleDelete(record.id)}
          />
        );
      },
    },
  ];

  const handleConfirm = (data) => {
    if (validatePaymentPrice(totalCartPrice?.discounted_price, dispatch))
      return;
    paymentByUserRegistration(id, optionId);
  };

  const handleCancel = () => {
    navigate(`/eventSponsor/${id}`);
  };

  const onMealSelect = () => {
    setMealOptions(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (allSponsorAttendeesData?.length === 0) {
      const loading =
        !allSponsorAttendeesData || allSponsorAttendeesData.length === 0;
      setLoading(loading);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [allSponsorAttendeesData]);

  useEffect(() => {
    const eventOptionData =
      sponsorData?.event_registration_options?.sponsorship;
    if (eventOptionData) {
      setEventSponsorshipOption(eventOptionData);
    }
  }, [sponsorData]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const uploadLogoFormats = ['image/png', 'image/jpeg', 'image/jpg'];
  const uploadBannerFormats = [
    'application/pdf',
    'image/png',
    'image/jpeg',
    'image/jpg',
  ];

  const beforeUploadLogos = {
    name: 'file',
    multiple: true,
    maxCount: 10,
    beforeUpload: (file) => {
      const isRightFormat = uploadLogoFormats.includes(file.type);
      if (!isRightFormat) {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Accepted file formats: JPG and PNG only',
        });
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < UploadFileMaxSize;
      if (!isLt2M) {
        dispatch({
          type: FETCH_ERROR,
          payload: `File must smaller than ${UploadFileMaxSize}MB.`,
        });
        return false;
      }
      if (file?.type?.includes('image')) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            if (img.width >= 200 && img.height >= 200) {
              setValidLogoFileList((prev) => [file]);
              resolve(isRightFormat && isLt2M);
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: 'Image size must be greater than 200x200 pixels.',
              });
              setValidLogoFileList((prev) =>
                prev.filter((f) => f.uid !== file.uid),
              );
              reject();
            }
          };
          img.onerror = reject;
        });
      } else {
        setValidLogoFileList((prev) => [file]);
        return isRightFormat && isLt2M;
      }
    },
  };

  const beforeUploadBanner = {
    name: 'file2',
    multiple: true,
    maxCount: 10,
    // listType: 'picture',
    beforeUpload: (file) => {
      const isRightFormat = uploadBannerFormats.includes(file.type);
      if (!isRightFormat) {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Accepted file formats: PDF, JPG and PNG only',
        });
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < UploadFileMaxSize;
      if (!isLt2M) {
        dispatch({
          type: FETCH_ERROR,
          payload: `File must smaller than ${UploadFileMaxSize}MB.`,
        });
        return false;
      }
      if (file?.type?.includes('image')) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            if (img.width >= 200 && img.height >= 200) {
              setValidBannerFileList((prev) => [file]);
              resolve(isRightFormat && isLt2M);
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: 'Image size must be greater than 200x200 pixels.',
              });
              setValidBannerFileList((prev) =>
                prev.filter((f) => f.uid !== file.uid),
              );
              reject();
            }
          };
          img.onerror = reject;
        });
      } else {
        setValidBannerFileList((prev) => [file]);
        return isRightFormat && isLt2M;
      }
    },
  };

  const removeBannerFileHandler = (file) => {
    setValidBannerFileList((prevFileList) => {
      return prevFileList.filter((item) => item?.uid !== file?.uid);
    });
  };

  const removeLogoFileHandler = (file) => {
    setValidLogoFileList((prevFileList) => {
      return prevFileList.filter((item) => item?.uid !== file?.uid);
    });
  };

  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const onFinishSponsor = (
    values,
    setSponsorCreatedSuccessFully,
    setEditDetails,
  ) => {
    const eventOptionID =
      eventSponsorPerksData?.eventSponsorshipPerk?.event_option_id;
    const SponsorData =
      Object.keys(eventSponsorData).length > 0
        ? {
            ...values,
            event_id: id,
          }
        : {
            ...values,
            sponsorship_option_id: eventOptionID,
            event_id: id,
          };

    const formData = new FormData();
    Object.entries(SponsorData).forEach(([key, value]) => {
      if (key === 'logo') {
        if (sponsorData) {
          sponsorData?.logos?.length > 0
            ? sponsorData?.logos[0]?.id === validLogoFileList[0]?.uid
              ? formData.append('logo_ids', `[${sponsorData?.logos[0]?.id}]`)
              : formData.append(
                  'logos',
                  validLogoFileList[0]?.originFileObj ||
                    validLogoFileList[0] ||
                    [],
                )
            : formData.append(
                'logos',
                validLogoFileList[0]?.originFileObj ||
                  validLogoFileList[0] ||
                  [],
              );
        }
      } else if (key === 'flyer') {
        if (sponsorData) {
          sponsorData?.banners?.length > 0
            ? sponsorData?.banners[0]?.id === validBannerFileList[0]?.uid
              ? formData.append(
                  'banner_ids',
                  `[${sponsorData?.banners[0]?.id}]`,
                )
              : formData.append(
                  'banners',
                  validBannerFileList[0]?.originFileObj ||
                    validBannerFileList[0] ||
                    [],
                )
            : formData.append(
                'banners',
                validBannerFileList[0]?.originFileObj ||
                  validBannerFileList[0] ||
                  [],
              );
        }
      } else if (key === 'sponsorship_option') {
        const new_value = JSON.stringify(value);
        formData.append(key, value ? new_value : '');
      } else {
        formData.append(key, value ? value : '');
      }
    });
    if (Object.keys(eventSponsorData).length > 0) {
      updateSponsor(formData, values?.id, setEditDetails);
    } else {
      postSponsorAttendeesById(
        formData,
        setSponsorCreatedSuccessFully,
        setEditDetails,
      );
    }
    handleClose();
  };
  useEffect(() => {
    const eventOptionData =
      sponsorData?.event_registration_options?.sponsorship;
    const label =
      eventOptionData?.name + '-' + formatCurrency(eventOptionData?.price);
    if (eventOptionData) {
      setEventSponsorshipOption(eventOptionData);
      setSelectOptionsValue(eventOptionData);
    }
    setEventSponsor(sponsorData);

    sponsorData?.banners?.length > 0 &&
      setValidBannerFileList((prev) => [
        {
          uid: sponsorData?.banners[0]?.id,
          name: sponsorData?.banners[0]?.name,
          status: 'done',
          url: `${applicationConfig.UPLOAD_URL}/${sponsorData?.banners[0]?.name}`,
        },
      ]);
    sponsorData?.logos?.length > 0 &&
      setValidLogoFileList((prev) => [
        {
          uid: sponsorData?.logos[0]?.id,
          name: sponsorData?.logos[0]?.name,
          status: 'done',
          url: `${applicationConfig.UPLOAD_URL}/${sponsorData?.logos[0]?.name}`,
        },
      ]);
  }, [sponsorData]);

  return (
    <React.Fragment>
      <SponsorModal
        eventSponsorPerksData={eventSponsorPerksData}
        handleClose={handleClose}
        eventSponsorData={eventSponsorData}
        isModalOpen={isModalOpen}
        onFinish={onFinishSponsor}
        localUserData={localUserData}
        removeLogoFileHandler={removeLogoFileHandler}
        removeBannerFileHandler={removeBannerFileHandler}
        dummyRequest={dummyRequest}
        validLogoFileList={validLogoFileList}
        validBannerFileList={validBannerFileList}
        beforeUploadLogos={beforeUploadLogos}
        beforeUploadBanner={beforeUploadBanner}
        fetchSponsorAttendeesList={(id) => {
          fetchSponsorAttendeesList(id);
        }}
      />
      <Row gutter={[0, 10]} className='pbssMainContainerRow'>
        <Col span={24}>
          {transactionId ? (
            <SponsorOptionPageHeroText
              title={
                ApplicationString['userAttendeeCartComponent.afterPayment']
              }
              subTitle={
                eventName ||
                ApplicationString[
                  'userAttendeeCartComponent.subHeading.afterPayment'
                ]
              }
              hideDivider={true}
            />
          ) : (
            <SponsorOptionPageHeroText
              title={
                ApplicationString['userAttendeeCartComponent.confirm&PayText']
              }
              subTitle={eventName}
              extra={false}
            />
          )}
        </Col>
        <Col span={24}>
          <SectionLayout>
            {!transactionId && (
              <NavigationComponent
                name={
                  ApplicationString[
                    'userEventSponsorCartComponent.backToSponsorship'
                  ]
                }
                urlPath={`eventSponsor/${id}/${optionId}`}
              />
            )}
          </SectionLayout>
        </Col>
        <Col span={24}>
          <SectionLayout>
            <SponsorPerksCard
              hideButton
              usedForEdit
              multiple={false}
              title={eventSponsorPerksData?.name}
              sponsorshipType={eventSponsorPerksData?.sponsorship_type}
              spot={
                transactionId
                  ? null
                  : eventSponsorPerksData?.qty -
                      eventSponsorPerksData?.used_qty >
                    0
                  ? eventSponsorPerksData?.qty - eventSponsorPerksData?.used_qty
                  : null
              }
              totalSpot={
                eventSponsorPerksData?.qty >= 0
                  ? eventSponsorPerksData?.qty
                  : null
              }
              price={eventSponsorPerksData?.price}
              perks={eventSponsorPerksData?.eventSponsorshipPerk}
              id={eventSponsorPerksData?.id}
              eventId={eventSponsorPerksData?.event_id}
              hideUpload={eventSponsorPerksData?.sponsorship_type == '3'}
              handleClickUpload={showModal}
              handleCloseUpload={handleClose}
              upload
              optionId={optionId}
              attendeeDataOptionId={
                eventSponsorData?.event_registration_options?.sponsorship
                  ?.event_sponsorship_perk?.event_option_id
              }
            />
          </SectionLayout>
        </Col>
        <Col span={24}>
          <SectionLayout>
            <SponsorDetailCartForm
              alertInformation={
                eventSponsorPerksData?.eventSponsorshipPerk
                  ?.sponsor_contact_content
              }
              eventSponsorData={sponsorData}
              localUserData={localUserData}
              onFinish={onFinishSponsor}
              onlyForView={transactionId}
            />
          </SectionLayout>
        </Col>
        {eventSponsorPerksData?.sponsorship_type == '3' ? (
          <Col span={24}>
            <SectionLayout uploadButtonStyle>
              <button
                onClick={showModal}
                className='reg-navigation-btn upload-height'>
                <UploadOutlined />{' '}
                {
                  ApplicationString[
                    'userEventSponsorComponent.sponsorPerks.editUploadLogo'
                  ]
                }
              </button>
            </SectionLayout>
          </Col>
        ) : (
          ''
        )}
        <Col span={24}>
          <SectionLayout>
            <EventsCommonCartTable
              alertInformation={
                eventSponsorPerksData?.eventSponsorshipPerk
                  ?.sponsor_perk_content
              }
              sponsorOption={sponsorOption}
              allEventOptionsFoodData={allEventOptionsFoodData}
              attendeesDataWithPerks={attendeesDataWithPerks}
              sponsorData={sponsorData}
              eventType={eventType}
              onEdit={(id, Data, setEditingKey, form) => {
                updateSponsorAttendee(id, Data, setEditingKey, form);
              }}
              sponsorPerksData={eventSponsorPerksData}
              setListAttendeesDataWithPerks={setListAttendeesDataWithPerks}
            />
          </SectionLayout>
        </Col>

        {!transactionId && (
          <>
            <Col span={24}>
              <SectionLayout>
                <ConfirmationAction
                  heading={
                    ApplicationString[
                      'userAttendeeCartComponent.totalAmountToBePaid'
                    ]
                  }
                  data={eventRegisterData}
                  submitName={
                    ApplicationString[
                      'userAttendeeCartComponent.confirm&PayText'
                    ]
                  }
                  onConfirm={handleConfirm}
                  onCancel={handleCancel}
                  totalCartPrice={totalCartPrice}
                  usedForSponsor={true}
                />
              </SectionLayout>
            </Col>
          </>
        )}
        <Col span={24}>
          <SectionLayout>
            <CustomCollapse />
          </SectionLayout>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default EventSponsorCartComponent;

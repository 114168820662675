import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Select, Upload} from 'antd';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {ApplicationString} from '../../../../Localization/Locales/en';
import {InfoCircleOutlined, UploadOutlined} from '@ant-design/icons';
import {
  UploadFileMaxSizeForProfile,
  validateNumberRange,
} from '../../../../Util/commonUtility';
import {useDispatch} from 'react-redux';
import {FETCH_ERROR} from '../../../../Constant/ActionTypes';
import {applicationConfig} from '../../../../Util/config';
import CommonEditor from '../../../CommonComponents/TextEditor';

const CommitteeMemberFormComponent = (props) => {
  const [submitLabel, setSubmitLabel] = useState(
    ApplicationString['common.button.submitForm'],
  );
  const [form] = Form.useForm();
  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    postCommitteeMember,
    EditData,
    updateCommitteeMember,
    fetchCommitteeMemberById,
    chaptersList,
  } = props;

  const [profileFileList, setProfileFileList] = useState([]);
  const [editorDetailsState, setEditorDetailsState] = useState(`<p></p>`);

  useEffect(() => {
    if (id) {
      fetchCommitteeMemberById(id);
      setSubmitLabel(ApplicationString['common.button.updateForm']);
    }
  }, []);

  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const removeImageFileHandler = (file) => {
    setProfileFileList((prevFileList) => {
      return prevFileList.filter((item) => item?.uid !== file?.uid);
    });
  };
  useEffect(() => {
    if (id && EditData) {
      form.setFieldsValue({
        ...EditData,
      });
      setEditorDetailsState(EditData?.details);
      EditData?.profile_id?.id
        ? setProfileFileList([
            {
              uid: EditData?.profile_id?.id,
              name: EditData?.profile_id?.name,
              status: 'done',
              url: `${applicationConfig.UPLOAD_URL}/${EditData?.profile_id?.name}`,
            },
          ])
        : setProfileFileList([]);
    }
  }, [EditData]);

  const backToCommitteeMember = () => {
    navigate('/admin/committeeMember');
  };
  const onFinish = (values) => {
    const data = new FormData();
    data.append('name', values?.name);
    data.append('type', 'chapter');
    data.append('order_number', values.order_number);
    data.append('details', editorDetailsState);
    values?.designation && data.append('designation', values?.designation);
    data.append('local_chapter_id', parseInt(values?.local_chapter_id));

    if (profileFileList?.length > 0) {
      if (EditData && EditData?.profile_id?.id) {
        EditData?.profile_id?.id === profileFileList[0]?.uid
          ? data.append('profile_id', `${EditData?.profile_id?.id}`)
          : data.append(
              'profile',
              profileFileList[0].originFileObj || profileFileList[0],
            );
      } else {
        data.append(
          'profile',
          profileFileList[0].originFileObj || profileFileList[0],
        );
      }
    }

    if (id) {
      updateCommitteeMember(data, EditData.id);
    } else {
      postCommitteeMember(data);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };
  const onReset = () => {
    form.resetFields();
    setEditorDetailsState(`<p></p>`);
  };
  const filesImageFormats = ['image/jpeg', 'image/png', 'image/jpg'];

  const uploadProps = {
    name: 'profile',
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      const isRightFormat = filesImageFormats.includes(file.type);
      if (!isRightFormat) {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Accepted file formats: JPG and PNG only',
        });
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < UploadFileMaxSizeForProfile;
      if (!isLt2M) {
        dispatch({
          type: FETCH_ERROR,
          payload: `File must smaller than ${UploadFileMaxSizeForProfile}MB!`,
        });
        return false;
      }
      setProfileFileList([file]);
      return isRightFormat && isLt2M;
    },
  };

  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='basic'
          onFinish={onFinish}
          layout='vertical'
          className='two-column-form'
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          initialValues={''}>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Name'
                name='name'
                rules={[
                  {
                    required: true,
                    message: 'Committee Member Name should not be empty',
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter name'
                />
              </Form.Item>
            </div>

            <div className='form-column'>
              {' '}
              <Form.Item
                label='Order Of Committee Member'
                name='order_number'
                rules={[
                  {
                    required: true,
                    message: 'Order of committee member should not be empty',
                  },
                  {
                    validator: validateNumberRange,
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter order of chapter'
                />
              </Form.Item>{' '}
            </div>
          </div>

          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Chapter Type'
                name='local_chapter_id'
                rules={[
                  {
                    required: true,
                    message:
                      'Local chapter for committee member should not be empty',
                  },
                ]}>
                <Select
                  prefixCls='common-select-register'
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  placeholder={'Select a local chapter for committee member'}
                  options={chaptersList}></Select>
              </Form.Item>
              <Form.Item
                label={
                  <div className='detailLabelAndTextMessageContainer'>
                    <span className='detailsLabel'>Designation</span>
                    <span className='detailsTextMessage'>
                      <InfoCircleOutlined /> This field displays the designation
                      in the committee member card section of the PBSS chapter
                      page.
                    </span>
                  </div>
                }
                name='designation'>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter designation'
                />
              </Form.Item>
              <Form.Item
                label={
                  <div className='detailLabelAndTextMessageContainer'>
                    <span className='detailsLabel'>Details</span>
                    <span className='detailsTextMessage'></span>
                  </div>
                }
                name='details'>
                <CommonEditor
                  value={editorDetailsState}
                  onChange={setEditorDetailsState}
                  text={'details'}
                  placeholderValue={'details'}
                />
              </Form.Item>
              <Form.Item
                label='Profile Upload'
                name='profile'
                getValueFromEvent={normFile}>
                <Upload
                  {...uploadProps}
                  fileList={profileFileList}
                  onRemove={removeImageFileHandler}
                  prefixCls='upload-list-name-fix-class-admin'
                  className='common-input-user genral-form height-auto pd-y-20'
                  customRequest={dummyRequest}
                  showUploadList={true}
                  accept='.jpg, .jpeg, .png'>
                  <div>
                    <UploadOutlined className='upload-logo-admin' />
                    <div>
                      <span className='upload-text-logos-admin'>
                        Upload Profile
                      </span>
                    </div>
                    <div className='upload-type-admin'>
                      <span className='upload-desc-admin'>
                        Accepts only jpg,jpeg and png files.
                      </span>
                      <br />
                      <span className='upload-desc-admin'>
                        {`Maximum file size is ${UploadFileMaxSizeForProfile}MB.`}
                      </span>
                    </div>
                  </div>
                </Upload>
              </Form.Item>
            </div>
          </div>
          <Form.Item>
            <div className='button-container'>
              <Button
                id='adminCommitteeMember-backButton-button'
                className='common-submit-login common-form-submit common-form-cancel'
                onClick={() => {
                  backToCommitteeMember();
                }}>
                {ApplicationString['common.button.backForm']}
              </Button>
              <div className='form-flex-button'>
                <Button
                  id='adminCommitteeMember-submitButton-button'
                  type='primary'
                  htmlType='submit'
                  className='common-submit-login common-form-submit'>
                  {submitLabel}
                </Button>
                <Button
                  className='common-submit-login common-form-submit common-form-cancel'
                  htmlType='button'
                  id='adminCommitteeMember-resetButton-reset'
                  onClick={onReset}>
                  {ApplicationString['dynamicSearch.button.reset']}
                </Button>{' '}
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>{' '}
    </div>
  );
};

export default CommitteeMemberFormComponent;

import React from 'react';
import ChangePasswordContainer from '../../../Containers/UserContainer/ChangePassword';

const ChangePasswordScreen = () => {
  return (
    <div>
      <ChangePasswordContainer />
    </div>
  );
};

export default ChangePasswordScreen;

import React, {useState} from 'react';
import './style.css';
import {Link} from 'react-router-dom';
import {formatTimeZoneString} from '../../../../Util/commonUtility';
import ClockTimeSVG from '../../../../Assets/svgComponents/ClockTimeSVG';

const ChapterEventCard = ({
  eventType,
  dateObj,
  chapter,
  registerText,
  isExpand,
  setClickedEventID,
  setIsRegistrationModalClosed,
  usedForChapPage = false,
  eventName,
  id,
  status,
}) => {
  const [expanded, setExpanded] = useState(isExpand);
  const [hoveredElement, setHoveredElement] = useState(null);

  const handleKeyDown = (e) => {
    if (e.ctrlKey && e.key === 'Enter' && hoveredElement) {
      window.open(hoveredElement, '_blank');
    }
  };

  const toggleDescription = () => {
    setExpanded(!expanded);
  };

  return (
    <React.Fragment>
      <div
        className='event-new-card-container'
        onMouseEnter={() => setHoveredElement(`/eventDetails/${id}`)}
        onMouseLeave={() => setHoveredElement(null)}
        onKeyDown={handleKeyDown}
        tabIndex={0} // Allows the div to be focusable for keyboard events
      >
        <Link to={`/eventDetails/${id}`} className='event-card-link'>
          <div
            className='upcoming-event-card'
            id={`event-new-card-container-${id}`}>
            <div className='upcoming-event-card-content-wrapper'>
              <div
                className={`cardLeftGroup ${
                  usedForChapPage && 'cardLeftGroupForChapterpage'
                }`}>
                <div className='timeLocationDetails'>
                  <div className='locationDateDetails'>
                    <span className='locationText'>{chapter}</span>
                    <div className='extraDetails-desktop'>
                      <span id={`home-event-extra-details-button-${id}`}>
                        {eventType}
                      </span>
                    </div>
                    <span className='dateText'>
                      {dateObj?.event_start_date}
                    </span>
                  </div>
                  <div className='timeExtraDetails'>
                    <span className='timeText'>
                      <ClockTimeSVG />
                      {`${dateObj?.event_start_time} - ${
                        dateObj?.event_end_time
                      }  ${formatTimeZoneString(dateObj?.time_zone)}`}
                    </span>
                    <div className='extraDetails-mobile'>
                      <span>{eventType}</span>
                    </div>
                  </div>
                </div>
                <div className='upcoming-event-card-title hyperlink'>
                  {eventName}
                </div>
              </div>
              <Link
                className={`cardRightGroup borderLeft ${
                  usedForChapPage && 'cardRightGroupChapter'
                }`}
                to={`/addAttendees/${id}`}
                onMouseEnter={() => setHoveredElement(`/addAttendees/${id}`)}
                onMouseLeave={() => setHoveredElement(null)}>
                <button
                  id={`home-register-to-event-button-${id}`}
                  onClick={(e) => {
                    if (Number(status) === 2) {
                      setIsRegistrationModalClosed(true);
                      setClickedEventID(id);
                    }
                  }}
                  className={`registerButton ${
                    usedForChapPage && 'registerButtonUsedForChapPage'
                  }`}>
                  {registerText}
                </button>
              </Link>
            </div>
          </div>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default ChapterEventCard;

import React, {useEffect, useState} from 'react';
import {Button, Card, DatePicker, Form, Input, Radio, Select} from 'antd';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {ApplicationString} from '../../../../Localization/Locales/en';
import {KeyWordStatus, NoEmptySpaceInput} from '../../../../Util/commonUtility';
const KeywordFormComponent = (props) => {
  const [submit, setSubmit] = useState(false);
  const [submitLabel, setSubmitLabel] = useState(
    ApplicationString['common.button.submitForm'],
  );
  const [form] = Form.useForm();
  let Navigate = useNavigate();
  const {postKeyword, EditData, updateKeyword, fetchKeyword} = props;

  useEffect(() => {
    if (id) {
      fetchKeyword(id);
      setSubmitLabel(ApplicationString['common.button.updateForm']);
    }
  }, []);

  useEffect(() => {
    if (id) {
      EditData &&
        form.setFieldsValue({
          ...EditData,
        });
    }
  }, [EditData]);

  const onFinish = (values) => {
    if (id) {
      updateKeyword(values, EditData.id);
      // form.resetFields();
      // Navigate('/admin/keywords');
    } else {
      postKeyword(values);
      // form.resetFields();
      // Navigate('/admin/keywords');
    }
    setSubmit(true);
  };

  const {id} = useParams();
  const onFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };
  const onReset = () => {
    form.resetFields();
  };
  const backToKeyword = () => {
    Navigate('/admin/interests');
  };

  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='basic'
          onFinish={onFinish}
          layout='vertical'
          className='two-column-form'
          onFinishFailed={onFinishFailed}
          initialValues={''}
          autoComplete='off'>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Area of Interest'
                name='name'
                rules={[
                  {
                    required: true,
                    message: 'Area of Interest should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Area of Interest'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter name for area of interest'
                />
              </Form.Item>
              <Form.Item label='Status' name='status'>
                <Radio.Group
                  prefixCls='common-radio-group'
                  options={KeyWordStatus}
                  optionType='button'
                  buttonStyle='solid'
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item>
            <div className='button-container'>
              <Button
                id='adminInterest-backButton-button'
                className='common-submit-login common-form-submit common-form-cancel'
                onClick={() => {
                  backToKeyword();
                }}>
                {ApplicationString['common.button.backForm']}
              </Button>
              <div className='form-flex-button'>
                <Button
                  id='adminInterest-submitButton-button'
                  type='primary'
                  htmlType='submit'
                  //disabled={submit ? true : false}
                  className='common-submit-login common-form-submit'>
                  {submitLabel}
                </Button>
                <Button
                  className='common-submit-login common-form-submit common-form-cancel'
                  htmlType='button'
                  id='adminInterest-resetButton-button'
                  onClick={onReset}>
                  {ApplicationString['dynamicSearch.button.reset']}
                </Button>{' '}
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default KeywordFormComponent;

import React, {useDebugValue, useState} from 'react';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import PageHeading from '../../CommonComponents/PageHeading';
import {
  Table,
  Space,
  Button,
  Form,
  Popconfirm,
  Tag,
  Skeleton,
  Col,
  Row,
  message,
  Alert,
} from 'antd';
import {
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
  RightOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import EditableCell from '../../CommonComponents/EditableCell';
import './style.css';
import ConfirmationAction from '../../CommonComponents/PaymentConfirmationAction';
import {useNavigate, useParams} from 'react-router-dom';
import CommonAlert from '../../CommonComponents/CommonAlert';
import {useEffect} from 'react';
import {
  filterFoodOptionColumnsForAttendee,
  formValueChangeHandler,
  formatCurrency,
} from '../../../Util/commonUtility';
import AddAttendeeeComponent from '../../CommonComponents/AttendeeAddComponent';
import CustomCollapse from '../../CommonComponents/CommonAccordian';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import NavigationComponent from '../../CommonComponents/CommonBackButton';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';
import {ApplicationString} from '../../../Localization/Locales/en';
import {useThrottle} from '../../../hooks/useThrottle';
import {useDispatch} from 'react-redux';
import {FETCH_ERROR} from '../../../Constant/ActionTypes';
import CommonUserModal from './../../CommonComponents/CommonUserModal/index';
import WaitingList from './WaitingList';

const AddAttendeeComponent = ({
  allEventOptionsFoodData,
  allEventOptionsRegistrationData,
  AddAttendee,
  eventType,
  attendeesData,
  setAttendeesData,
  deleteAttendeeById,
  editAttendeeById,
  totalCartPrice,
  eventName,
  allAttendeesData,
  allRegistrationWaitingList,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams();
  const [isAttendeeModalOpen, setIsAttendeeModalOpen] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  const [isAddAttendee, setIsAddAttendee] = useState(false);
  const [disableAttendeeClicks, setDisableAttendeeClicks] = useState(false);
  const [disablePaymentClicks, setDisablePaymentClicks] = useState(false);
  const [disableEditClicks, setDisableEditClicks] = useState(false);
  const isEditing = (record) => record.id === editingKey;
  const [loading, setLoading] = useState(false);

  localStorage.setItem('isUserLoginFromAddAttendee', id);
  const newAttendee = {
    id: '',
    name: '',
    email: '',
    eventId: '',
    price: '',
    registration_option: '',
    food_option: '',
  };
  const edit = (record) => {
    const EditData = {
      ...record,
      attendee_email_confirm: record?.attendee_email
        ? record?.attendee_email
        : '',
      food_option:
        record?.food_option === ''
          ? []
          : record.event_registration_options?.food?.event_option_id ||
            'no-food',
      registration_option:
        record.event_registration_options?.registration?.event_option_id,
    };
    if (!isAddAttendee) {
      form.setFieldsValue({
        ...EditData,
      });
    }
    setEditingKey(record.id);
  };

  const cancel = () => {
    if (isAddAttendee) {
      attendeesData.pop();
      setAttendeesData([...attendeesData]);
    }
    form.resetFields();
    setIsAddAttendee(false);
    setEditingKey('');
    setDisableAttendeeClicks(false);
    setDisablePaymentClicks(false);
    setDisableEditClicks(false);
  };
  const save = async (attendeeData) => {
    try {
      const row = await form.validateFields();
      const Data = {
        ...row,
        event_id: id && parseInt(id),
        food_option:
          row.food_option === 'no-food' ||
          eventType?.toLowerCase() === 'webcast'
            ? {}
            : {
                type: 'food',
                event_option_id: row.food_option,
                qty: 1,
              },
        registration_option: {
          type: 'registration',
          event_option_id: row.registration_option,
          qty: 1,
        },
      };

      if (isAddAttendee) {
        AddAttendee(
          Data,
          setEditingKey,
          form,
          setIsAddAttendee,
          setDisableAttendeeClicks,
          setDisablePaymentClicks,
          setDisableEditClicks,
        );
      } else {
        const EditData = {
          ...row,
          food_option:
            row.food_option === 'no-food' ||
            eventType?.toLowerCase() === 'webcast'
              ? {}
              : {
                  type: 'food',
                  event_option_id: row?.food_option,
                  qty: 1,
                  id: attendeeData?.event_registration_options?.food?.id,
                },
          registration_option: {
            type: 'registration',
            event_option_id: row?.registration_option,
            qty: 1,
            id: attendeeData?.event_registration_options?.registration?.id,
          },
        };
        editAttendeeById(
          EditData,
          attendeeData.id,
          setEditingKey,
          form,
          setDisableAttendeeClicks,
          setDisablePaymentClicks,
          setDisableEditClicks,
        );
      }
    } catch (errInfo) {
      console.error('Validation Failed:', errInfo);
    }
  };

  const addRow = () => {
    const existingAttendee = attendeesData.find((obj) =>
      Object.keys(newAttendee).every((key) => obj[key] === newAttendee[key]),
    );
    if (existingAttendee) {
      setDisableAttendeeClicks(true);
      return;
    } else {
      setIsAddAttendee(true);
      edit(newAttendee);
      setAttendeesData([...attendeesData, newAttendee]);
      setDisableEditClicks(true);
    }
  };
  const filterEmptyAttendeeData = attendeesData?.filter(
    (obj) => obj.id !== '1',
  );
  const handleConfirm = useThrottle(() => {
    if (allAttendeesData?.length <= 0 || disablePaymentClicks) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Please save the attendee details first.',
      });
      return;
    }
    setIsAttendeeModalOpen(true);
  }, 2000);

  const handleCancel = () => {
    navigate(`/eventDetails/${id}`);
  };

  useEffect(() => {
    const existingAttendee = attendeesData?.find((obj) =>
      Object.keys(newAttendee).every((key) => obj[key] === newAttendee[key]),
    );
    if (attendeesData?.length > 0 && existingAttendee) {
      setDisableAttendeeClicks(false);
    }
  }, [attendeesData]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    const newRow = {
      id: '1',
      name: '',
      email: '',
      eventid: '',
      price: '',
      registration_option: '',
      food_option: '',
    };
    if (attendeesData && attendeesData?.length === 0) {
      setDisableAttendeeClicks(true);
      const loading = !attendeesData || attendeesData.length === 0;
      setLoading(loading);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setIsAddAttendee(true);
      edit(newRow);
      setAttendeesData([...attendeesData, newRow]);
    }
  }, [attendeesData]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      editable: true,
      width: '15%',
      render: (text, record) => {
        return (
          <Tag prefixCls='regularAttendeesTag'>
            {record.attendee_first_name} {record.attendee_last_name}
          </Tag>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'attendee_email',
      key: 'email',
      width: '18%',
      editable: true,
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
      },
    },

    {
      title: 'Option',
      dataIndex: 'option',
      key: 'registration_and_food_option',
      width: '20%',
      editable: true,
      render: (_, record) => {
        const registrationName = record.registration_option?.name;
        const registrationPrice = record.registration_option?.price;
        const foodName = record.food_option?.name || 'No Food';
        const foodPrice = record.food_option?.price;
        return (
          <div className='option-tag-container'>
            <Tag prefixCls='regularAttendeesTag'>
              Registration: {registrationName} -{' '}
              {formatCurrency(registrationPrice, 'USD')}
            </Tag>
            {eventType !== 'Webcast' && (
              <Tag prefixCls='regularAttendeesTag'>
                Food: {foodName}{' '}
                {foodPrice ? '- ' + formatCurrency(foodPrice, 'USD') : ''}
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: 'Company/Affiliation',
      dataIndex: 'attendee_company',
      key: 'company',
      width: '15%',
      editable: true,
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
      },
    },
    {
      title: 'Price',
      dataIndex: 'total_cost',
      width: '5%',
      render: (text) => {
        return (
          <h4 className='regularAttendees-price'>{formatCurrency(text)}</h4>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '6%',
      render: (_, record) => {
        const isEditingRecord = record.id === editingKey;
        return (
          <AdminActionsDropdown
            record={record}
            usedForAttendeeTable={true}
            editBool={true}
            deleteBool={true}
            attendeeEditingKey={editingKey}
            handleCancelClick={cancel}
            handleSaveClick={save}
            handleEditClick={edit}
            handleDeleteClick={() => deleteAttendeeById(record.id)}
            isAddAttendee={isAddAttendee}
            disableEditClicks={disableEditClicks}
            setDisableEditClicks={setDisableEditClicks}
            setDisableAttendeeClicks={setDisableAttendeeClicks}
            setDisablePaymentClicks={setDisablePaymentClicks}
            text={ApplicationString[`userEventSponsorComponent.deleteText`]}
          />
        );
      },
    },
  ];

  const mergedColumns = filterFoodOptionColumnsForAttendee(
    columns,
    eventType,
    isEditing,
  );
  const onConfirm = () => {
    navigate(`/attendeesCart/${id}`);
  };

  const handleConfirmModalCancel = () => {
    setIsAttendeeModalOpen(false);
  };

  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRow'>
        <div className='stickyEventName'>
          <CommonUserModal
            visible={isAttendeeModalOpen}
            onCancel={handleConfirmModalCancel}
            onConfirm={onConfirm}
            confirmBtnText={'Agree & Continue'}
            content={
              <React.Fragment>
                <div className='payment-modal'>
                  {ApplicationString['userAddAttendeeComponent.popup']}
                  <a href='mailto:info@pbss.org'>info@pbss.org</a>{' '}
                  {ApplicationString['userAddAttendeeComponent.popup1']}
                </div>
              </React.Fragment>
            }
          />
          <SponsorOptionPageHeroText
            title={eventName || 'Attendee'}
            subTitle={'Attendee Registration'}
          />
        </div>
        <Col span={24}>
          <SectionLayout>
            <div className='hero-subheading'>
              Contact us at <a href='mailto:info@pbss.org'>info@pbss.org</a>{' '}
              {ApplicationString['userAddAttendeeComponent.message']}
            </div>
          </SectionLayout>
        </Col>
        <Col span={24}>
          <SectionLayout>
            <div className='addAttendees-button-wrapper'>
              <NavigationComponent
                name={'Go Back to Event Details'}
                urlPath={`eventDetails/${id}`}
              />
              <Button
                onClick={() => {
                  navigate(`/sponsorshipOption/${id}`);
                }}
                className='reg-navigation-btn'>
                {
                  ApplicationString[
                    'userAddAttendeeComponent.registerAs.sponsorText'
                  ]
                }
              </Button>
              <Button
                onClick={() => {
                  navigate(`/myEvents`);
                }}
                className='reg-navigation-btn'>
                {
                  ApplicationString[
                    'userAddAttendeeComponent.registerAs.myEvents'
                  ]
                }
              </Button>
            </div>
          </SectionLayout>
        </Col>
        {allEventOptionsRegistrationData &&
        allEventOptionsRegistrationData?.length > 0 ? (
          <>
            <Col span={24}>
              <SectionLayout>
                <Form
                  form={form}
                  onValuesChange={(changedValues) =>
                    formValueChangeHandler(form, changedValues)
                  }>
                  <Skeleton
                    loading={loading}
                    prefixCls='table-skeleton'
                    active
                    paragraph={{rows: attendeesData?.length}}>
                    <div className='attendees-label'>
                      {
                        ApplicationString[
                          'userAddAttendeeComponent.regularAttendee.label'
                        ]
                      }
                    </div>
                    <Table
                      prefixCls='attendees-table-container'
                      showExpandColumn={false}
                      components={{
                        body: {
                          cell: (props) => (
                            <EditableCell
                              allEventOptionsFoodData={allEventOptionsFoodData}
                              allEventOptionsRegistrationData={
                                allEventOptionsRegistrationData
                              }
                              eventType={eventType}
                              attendeesData={attendeesData}
                              {...props}
                            />
                          ),
                        },
                      }}
                      pagination={false}
                      scroll={{x: columns?.length * 150}}
                      dataSource={attendeesData}
                      columns={mergedColumns}
                    />
                  </Skeleton>
                </Form>
              </SectionLayout>
            </Col>
            <Col span={24}>
              <SectionLayout extendClass='addAttendeeWrapperEventUserComponent'>
                <AddAttendeeeComponent
                  disable={disableAttendeeClicks}
                  addRow={addRow}
                  setDisablePaymentClicks={setDisablePaymentClicks}
                />
              </SectionLayout>
            </Col>
            {filterEmptyAttendeeData?.length > 0 && (
              <Col span={24}>
                <SectionLayout>
                  <ConfirmationAction
                    heading='Total amount to be paid'
                    data={attendeesData}
                    submitName={'Proceed to Confirm'}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                    totalCartPrice={totalCartPrice}
                    usedForAddAttendees={true}
                    extra={ApplicationString['attendee-screen-extra-message']}
                  />
                </SectionLayout>
              </Col>
            )}
          </>
        ) : (
          <Col span={24}>
            <SectionLayout>
              <Alert
                message={ApplicationString['userNormalAttendee.alertMessage']}
                className='no-event-sponsor-alert'
                type='info'
                showIcon
              />
            </SectionLayout>
          </Col>
        )}
        {allRegistrationWaitingList?.length > 0 && (
          <Col span={24}>
            <SectionLayout>
              <WaitingList data={allRegistrationWaitingList} />
            </SectionLayout>
          </Col>
        )}
        <Col span={24}>
          <SectionLayout>
            <CustomCollapse />
          </SectionLayout>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AddAttendeeComponent;

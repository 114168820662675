import React, {useEffect, useState} from 'react';
import {Button, Form, Upload} from 'antd';
import CommonEditor from '../../CommonComponents/TextEditor';
import {ApplicationString} from '../../../Localization/Locales/en';
import {applicationConfig} from '../../../Util/config';
import {UploadOutlined} from '@ant-design/icons';
import {UploadFileMaxSize} from '../../../Util/commonUtility';
import {FETCH_ERROR} from '../../../Constant/ActionTypes';
import {useDispatch} from 'react-redux';

const StemAdminComponent = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {EditData, updateStem} = props;
  const [editorState, setEditorState] = useState(`<p></p>`);
  const [validImageFileList, setValidImageFileList] = useState([]);
  const filesImageFormats = ['image/jpeg', 'image/png', 'image/jpg'];

  const uploadPropsImage = {
    name: 'file',
    multiple: true,
    maxCount: 20,
    beforeUpload: (file) => {
      const isRightFormat = filesImageFormats.includes(file.type);

      if (!isRightFormat) {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Accepted file formats: JPG and PNG only',
        });
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < UploadFileMaxSize;
      if (!isLt2M) {
        dispatch({
          type: FETCH_ERROR,
          payload: `File must smaller than ${UploadFileMaxSize}MB.`,
        });
        return false;
      }
      setValidImageFileList((prev) => {
        if (prev.length >= 5) {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Maximum 5 files can be uploaded',
          });
          return prev;
        }
        return [...prev, file];
      });

      return isRightFormat && isLt2M;
    },
  };
  const removeImageFileHandler = (file) => {
    setValidImageFileList((prevFileList) => {
      return prevFileList.filter((item) => item?.uid !== file?.uid);
    });
  };
  useEffect(() => {
    if (EditData) {
      form.setFieldsValue({
        ...EditData,
      });
      EditData?.stem_image_ids?.length > 0
        ? setValidImageFileList((prev) =>
            EditData?.stem_image_ids?.map((item) => {
              return {
                uid: item?.id,
                name: item?.name,
                status: 'done',
                url: `${applicationConfig.UPLOAD_URL}/${item?.name}`,
              };
            }),
          )
        : setValidImageFileList((prev) => []);
    }
  }, [EditData]);

  const onFinish = (values) => {
    const ImageIds = [];

    const data = new FormData();
    data.append('stem', values.stem);
    if (EditData) {
      validImageFileList.forEach((validFile) => {
        EditData?.stem_image_ids?.some((file) => file?.id === validFile?.uid)
          ? ImageIds.push(validFile?.uid)
          : data.append('stem_images', validFile?.originFileObj || validFile);
      });
      data.append('stem_image_ids', `[${ImageIds}]`);
    } else {
      validImageFileList?.forEach((file) =>
        data.append(`stem_images`, file?.originFileObj || file),
      );
    }
    updateStem(data, EditData.id);
  };
  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };
  const onReset = () => {
    form.resetFields();
    setValidImageFileList([]);
  };
  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='basic'
          onFinish={onFinish}
          layout='vertical'
          className='two-column-form'
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          initialValues={''}>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Stem Editor'
                name='stem'
                rules={[
                  {required: true, message: 'Stem editor should not be empty'},
                ]}>
                <CommonEditor
                  value={editorState}
                  onChange={setEditorState}
                  text={'stem_editor'}
                  placeholderValue={'stem editor'}
                />
              </Form.Item>
              <div className='upload-admin'>
                <Form.Item name='stem_images' getValueFromEvent={normFile}>
                  <Upload
                    className='common-input-user genral-form height-auto'
                    {...uploadPropsImage}
                    accept='image/*'
                    customRequest={dummyRequest}
                    onRemove={removeImageFileHandler}
                    fileList={validImageFileList}
                    showUploadList={true}>
                    <div>
                      <UploadOutlined className='upload-logo-admin' />
                      <div>
                        <span className='upload-text-logos-admin'>
                          Upload Banner
                        </span>
                      </div>
                      <div className='upload-type-admin'>
                        <span className='upload-desc-admin'>
                          Accepts only image files (png, jpg, jpeg).{' '}
                          {`Maximum
                          file size is ${UploadFileMaxSize}MB.`}
                        </span>
                        <br />
                      </div>
                    </div>
                  </Upload>
                </Form.Item>
              </div>
            </div>
          </div>

          <Form.Item>
            <div className='button-container'>
              <Button
                className='common-submit-login common-form-submit common-form-cancel'
                htmlType='button'
                id='adminStem-resetButton-reset'
                onClick={onReset}>
                {ApplicationString['dynamicSearch.button.reset']}
              </Button>
              <Button
                id='adminStem-submitButton-button'
                type='primary'
                htmlType='submit'
                className='common-submit-login common-form-submit'>
                {ApplicationString['dynamicSearch.button.update']}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>{' '}
    </div>
  );
};

export default StemAdminComponent;

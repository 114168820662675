import React, {useEffect, useState} from 'react';
import {Table, Button, Popover, Divider, Row, Col, Image} from 'antd';
import {EyeOutlined, FilterOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {
  defaultDropdownValue,
  DescriptionItem,
  PageSizeArray,
  PaginationPosition,
  ReturnNAOnEmpty,
} from '../../../Util/commonUtility';
import {ApplicationString} from '../../../Localization/Locales/en';
import DynamicSearchForm from '../../CommonComponents/DynamicSearch';
import plus from '../../../Assets//Plus.svg';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {useSelector} from 'react-redux';
import CustomizedTable from '../../CommonComponents/CommonTable';
import AdminViewDetailsDrawer from '../../CommonComponents/AdminViewDetailsDrawer';
import {applicationConfig} from '../../../Util/config';

const CommitteeMemberComponent = (props) => {
  const [expanded, setExpanded] = useState(false);
  const {
    allCommitteeMembersData,
    paging,
    handlePaginationChange,
    deleteCommitteeMember,
    fetchCommitteeMembers,
    sortParams,
    handleTableChange,
    chaptersOptions,
  } = props;
  const ps = useSelector(({pageSizes}) => pageSizes.pageSizes.adFees);
  const [open, setOpen] = useState(false);
  const [openDrawerDetails, setOpenDrawerDetails] = useState(false);

  let navigate = useNavigate();
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const handleSearch = (values) => {
    fetchCommitteeMembers(values);
  };

  const handleEditClick = async (id) => {
    navigate(`/admin/committeeMember/update/${id}`);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const committeeMembersFilters = [
    {label: 'Committee Member Name', name: 'name', initialValue: ''},
    {
      label: 'Chapter Name',
      name: 'local_chapter_id',
      type: 'dropdown',
      initialValue: defaultDropdownValue,
    },
  ];

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 60,
    },
    {
      title: 'Order Of Chapter',
      dataIndex: 'order_number',
      key: 'order_number',
      width: 200,
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },

    {
      title: 'Chapter Name',
      dataIndex: 'chapterName',
    },
    {
      title: 'Details',
      dataIndex: 'details',
      render: (text) => {
        return (
          <>
            <div className='fixed-size-text'>
              <Popover
                prefixCls='extend-popover'
                content={<CustomizedTable TableData={text} />}
                trigger='hover'
                title='Details'
                arrow={false}>
                <Button type='text' prefixCls='common-button-popover'>
                  <EyeOutlined /> View Details
                </Button>
              </Popover>{' '}
            </div>
          </>
        );
      },
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      render: (text) => ReturnNAOnEmpty(text),
    },

    {
      title: '',
      key: 'action',
      width: '6%',
      render: (_, record) => (
        <AdminActionsDropdown
          record={record}
          editBool={true}
          deleteBool={true}
          handleEditClick={handleEditClick}
          handleDeleteClick={deleteCommitteeMember}
          text='Committee Member'
          viewBool={true}
          handleView={showDrawer}
        />
      ),
    },
  ];
  columns.forEach((column) => {
    if (column.key !== 'action') {
      column.sorter = true;
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });

  // Drawer functions ----------------------------------------------------
  const showDrawer = (record) => {
    setOpenDrawerDetails(record);
    setOpen(true);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const DrawerContent = () => {
    const {name, details, designation, chapterName, profile_id} =
      openDrawerDetails || {};

    return (
      <>
        <Row>
          <Col span={24}>
            <DescriptionItem title='Name' content={name} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem title='Local Chapter' content={chapterName} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem title='Designation' content={designation} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Details'
              content={<CustomizedTable TableData={details} />}
            />
          </Col>
        </Row>

        <Divider />
        <Row gutter={20}>
          <Col span={24}>
            <DescriptionItem
              usedForCommittee
              title='Profile Image'
              content={
                <>
                  {profile_id?.id ? (
                    <Image
                      prefixCls='admin-committee-profile-image'
                      crossOrigin='anonymous'
                      key={profile_id?.id}
                      width={'100%'}
                      height={'400px'}
                      src={`${applicationConfig.UPLOAD_URL}/${profile_id?.name}`}
                    />
                  ) : (
                    <p>No Profile Image Uploaded</p>
                  )}
                </>
              }
            />
          </Col>
        </Row>
        <Divider />
      </>
    );
  };
  return (
    <div className='main-container'>
      <AdminViewDetailsDrawer
        content={DrawerContent}
        visible={open}
        text={'Committee Member'}
        onCloseDrawer={onCloseDrawer}
        editNavigate={`/admin/committeeMember/update/${openDrawerDetails?.id}`}
      />
      <div className='search-container' style={{gap: '10px'}}>
        <Button onClick={toggleExpand} id='AdFeeComponent-button-filterSearch'>
          {expanded ? <FilterOutlined /> : <FilterOutlined />}{' '}
          {ApplicationString['dynamicSearch.button.heading']}
        </Button>
        <Button
          type='primary'
          onClick={() => navigate('/admin/committeeMember/add')}
          id='AdFeeComponent-button-addAdFees'
          className='common-button'>
          <div className='icon-container'>
            <img src={plus} alt='plus' className='icon-plus' />
            <span className='add-content'>
              {ApplicationString['CommitteeMemberComponent.button.addForm']}
            </span>
          </div>
        </Button>
      </div>
      {expanded && (
        <DynamicSearchForm
          columns={committeeMembersFilters}
          chaptersOptions={chaptersOptions}
          onSearch={handleSearch}
          title={'committeeMembers'}
        />
      )}
      <div className='listing-container'>
        <Table
          bordered
          className='admin-table'
          columns={columns}
          dataSource={allCommitteeMembersData}
          rowKey={'id'}
          scroll={{x: 786}}
          onChange={handleTableChange}
          // sticky={true}
          pagination={{
            onChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            current: paging.page,
            total: paging.totalCount,
            pageSize: ps || paging.pagecount,
            pageSizeOptions: PageSizeArray,
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            showTotal: (total, range) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>
    </div>
  );
};

export default CommitteeMemberComponent;

import React from 'react';
import {
  // formatDate,
  getStatusInfo,
  truncateHtmlToWords,
} from '../../../../../Util/commonUtility';
import './style.css';
import {CalendarOutlined} from '@ant-design/icons';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {Badge, Button} from 'antd';
import JobsFormComponent from '../../JobsForm';
import {ApplicationString} from '../../../../../Localization/Locales/en';
import view_more_svg from '../../../../../Assets/view-more-right-arrow.svg';

const CommonJobsListingCard = (props) => {
  const {
    jobLocation,
    jobLastDate,
    jobEmployer,
    jobTitle,
    jobDescription,
    jobId,
    jobStatus,
    jobFeesOptions,
    jobFees,
  } = props;
  const navigate = useNavigate();
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const viewDetailsJob = () => {
    navigate(`/jobs/${jobId}`);
  };

  return (
    <React.Fragment>
      <div className='common-job-listing-card-container'>
        <div className='common-job-listing-card'>
          <div className='common-job-listing-card-content-wrapper'>
            <div className='common-job-listing-locationDateDetails'>
              <div>
                {' '}
                <span className='common-job-listing-locationText'>
                  {jobLocation ? jobLocation : 'N/A'}
                </span>
              </div>

              <div>
                <span className='common-job-listing-dateText'>
                  <span className='dateText-text'>Posted on</span>
                  {`   `}
                  <span className='dateText-date'>
                    <CalendarOutlined />
                    {` `}
                    {jobLastDate
                      ? `${moment(jobLastDate)
                          .utcOffset(0)
                          .format('YYYY-MM-DD')}`
                      : 'N/A'}
                  </span>
                </span>
              </div>
            </div>
            <div className='common-job-left-right-group-wrapper'>
              <div className='common-job-listing-cardLeftGroup '>
                <div
                  className='common-job-listing-card-titleEmployerDetails hyperlink'
                  onClick={viewDetailsJob}>
                  <span className='titleEmployerDetails-employer'>
                    {jobEmployer}
                  </span>
                  <span className='titleEmployerDetails-title'>{`(${jobTitle})`}</span>
                </div>
                {
                  <JobsFormComponent
                    isModalOpen={editModalOpen}
                    setIsModalOpen={setEditModalOpen}
                    editData={props}
                    jobFeesOptions={jobFeesOptions}
                  />
                }
              </div>
              <div className='common-job-listing-cardRightGroup'>
                <div className='common-job-listing-card-action-container'>
                  {jobStatus === 'draft' && jobFees > 0 && (
                    <Button
                      className='common-ads-listing-card-button'
                      onClick={() => navigate(`cart/${jobId}`)}>
                      <span className='link-no-style'>Pay</span>
                    </Button>
                  )}
                  {jobStatus === 'draft' && (
                    <Button
                      className='common-ads-listing-card-button'
                      onClick={() => setEditModalOpen(!editModalOpen)}>
                      Edit Job
                    </Button>
                  )}
                </div>

                <Button
                  className='view-more-svg-button'
                  id={`common-job-listing-viewDetailsButton_${jobId}`}
                  onClick={viewDetailsJob}>
                  {
                    ApplicationString[
                      'userApprovedJobsListingComponent.button.viewDetails'
                    ]
                  }
                  <img
                    src={view_more_svg}
                    alt='view_more_svg'
                    className='view-more-svg'
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CommonJobsListingCard;

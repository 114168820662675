import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import WaitingListComponent from '../../../Components/AdminComponents/WaitingList';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';
import {queryString} from '../../../Util/commonUtility';
import {getRegistrationWaitingListById} from '../../../Store/Actions/eventRegister';
import {useParams} from 'react-router-dom';

const WaitingListContainer = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {allRegistrationWaitingListByEvent} = useSelector(
    ({eventRegister}) => eventRegister,
  );
  const memorizedPageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.waitingList,
  );
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.waitingList,
  );

  const [allRegistrationWaitingList, setAllRegistrationWaitingList] =
    useState();
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);

  const [pagination, setPagination] = useState({
    page: memorizedPageNumber || 1,
    pageCount: memorizedPageSize || 10,
    totalPages: 1,
    totalCount: 0,
  });
  const [sortParams, setSortParams] = useState({
    field: 'id',
    order: 'descend',
  });

  useEffect(() => {
    fetchWaitingList();
  }, []);

  useEffect(() => {
    if (allRegistrationWaitingListByEvent) {
      setAllRegistrationWaitingList(
        allRegistrationWaitingListByEvent?.waitingList,
      );
      setPagination({
        ...pagination,
        page: allRegistrationWaitingListByEvent?.currentPage,
        totalPages: allRegistrationWaitingListByEvent?.totalPages,
        totalCount: allRegistrationWaitingListByEvent?.totalCount,
      });
    }
  }, [allRegistrationWaitingListByEvent]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      fetchWaitingList();
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged]);

  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('waitingList', pageSize));
    dispatch(setPageNumber('waitingList', current));
    setPagination({
      ...pagination,
      page: current,
      pageCount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
  };

  const fetchWaitingList = async (searchValues) => {
    try {
      const sortField = sortParams.field || '';
      const sortOrder = sortParams.order || '';
      const searchQueryData = queryString(searchValues);
      dispatch(
        getRegistrationWaitingListById(
          pagination,
          sortField,
          sortOrder,
          searchQueryData,
          id,
        ),
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <WaitingListComponent
        waitingListData={allRegistrationWaitingList || []}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        handleTableChange={(pagination, filter, sorter) => {
          handleTableChange(pagination, filter, sorter);
        }}
        sortParams={sortParams}
      />
    </div>
  );
};

export default WaitingListContainer;

import {HttpCode} from '../../Constant/HttpCode';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SOMETHING_WENT_WRONG,
  SHOW_MESSAGE,
  UPDATING_CONTENT,
  GET_ALL_EVENTS,
  GET_ALL_EVENTS_BY_QUERY,
  DELETE_EVENTS_SUCCESS,
  DELETE_EVENTS_BY_ID,
  POST_EVENTS_SUCCESS,
  POST_EVENTS,
  UPDATE_EVENTS_SUCCESS,
  UPDATE_EVENTS_BY_ID,
  GET_EVENTS_BY_ID,
  GET_EVENTS_BY_CHAPTERS,
  DUPLICATE_EVENTS_BY_ID,
  DUPLICATE_EVENTS_SUCCESS,
  UPDATE_EVENTS_STATUS_SUCCESS,
  UPDATE_EVENT_STATUS,
  GET_ALL_MY_EVENTS,
  GET_ALL_USER_EVENTS,
  UPDATE_EVENT_REFUND_STATUS,
  GET_ALL_PAST_EVENTS,
  SET_FORM_STATE_SUCESS,
  UPDATE_EVENTS_STATUS_REFUND_SUCCESS,
  APPROVE_EVENTS_STATUS_REFUND_SUCCESS,
  GET_ALL_EVENTS_BY_QUERY_SEARCH,
  UPDATE_REFUND_STATUS,
  GET_ALL_MY_EVENTS_CART,
  GET_ALL_COMMITTEE_MEMBERS_BY_CHAPTERS,
} from '../../Constant/ActionTypes';
import {
  DefaultListingViewMoreCount,
  commonSortOrder,
  formatMyEventsData,
  handleJwtExpireMsg,
} from '../../Util/commonUtility';
import Urls from '../../Constant/Urls';
import {httpClient} from '../../Api/client';
import {setEventNameState, setFormState} from './chapters';

export const getAllEvents = (eventType, chapterId, date) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.EVENTS_GET_ALL}?event_type_id=${
          eventType ? eventType : ''
        }&local_chapter_id=${chapterId ? chapterId : ''}&?date=${
          date ? date : ''
        }&status=0`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_EVENTS, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAllPastEvents = (pagination, year, chapterId, name) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.PAST_EVENTS_GET_ALL}?page=${
          pagination?.page
        }&local_chapter_id=${chapterId}&pageCount=${
          pagination?.pagecount
        }&year=${year}&name=${name ? name : ''}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_PAST_EVENTS, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const getAllUserEvents = (
  eventType,
  chapterId,
  date,
  sortField = '',
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.EVENTS_USER_GET_ALL}?event_type_id=${eventType}&local_chapter_id=${chapterId}&sortField=${sortField}&sortOrder=${date}&status=0&page=1&pageCount=100`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_USER_EVENTS, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const getAllUserCommitteeMembersByChapters = (chapterId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.EVENTS_USER_GET_ALL_COMMITTEE_MEMBERS}?&sortOrder=asc&sortField=order_number&local_chapter_id=${chapterId}&page=1&pageCount=100`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: GET_ALL_COMMITTEE_MEMBERS_BY_CHAPTERS,
          payload: data?.data,
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAllMyEvents = (
  date,
  status,
  year,
  name,
  setFormattedEventData,
  setUserCredit,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.EVENTS_GET_ALL_MYEVENTS}?date=${date ? date : ''}&status=${
          status ? status : ''
        }&year=${year ? year : ''}&name=${
          name ? name : ''
        }&pageCount=100&page=1`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_MY_EVENTS, payload: data?.data});
        setFormattedEventData &&
          setFormattedEventData(
            data?.data?.data?.myEvents > 0
              ? formatMyEventsData(data?.data?.data?.myEvents)
              : [],
          );
        setUserCredit && setUserCredit(data?.data?.data?.userCredit);
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const getAllMyEventsCart = (
  status = '',
  setUserCredit,
  name = '',
  year = '',
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.EVENTS_GET_ALL_MYEVENTS}?status=${status}&name=${name}&pageCount=100&year=${year}&page=1`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_MY_EVENTS_CART, payload: data?.data});
        setUserCredit && setUserCredit(data?.data?.data?.userCredit);
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const getEventsByChapters = (chapterId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.EVENTS_GET_BY_CHAPTERS}?chapterId=${chapterId}&status=0`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_EVENTS_BY_CHAPTERS, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAllEventsByQueryString = (
  pagination,
  sortField,
  sortOrder,
  searchQueryData,
  setFetchingEvents,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.EVENTS_GET_ALL}?page=${pagination.page}&pageCount=${
          pagination.pageCount
        }&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${searchQueryData}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: GET_ALL_EVENTS_BY_QUERY,
          payload: data?.data,
        });
        setFetchingEvents && setFetchingEvents(false);
        dispatch(setEventNameState(false));
      }
    } catch (error) {
      let errorMessage = '';
      setFetchingEvents && setFetchingEvents(false);
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAllEventsByQueryStringSearch = (
  pagination = {
    page: '',
    pagecount: '',
  },
  sortField = '',
  sortOrder = '',
  searchQueryData = '',
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.EVENTS_GET_ALL}?page=${pagination.page}&pageCount=${
          pagination.pagecount
        }&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${searchQueryData}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: GET_ALL_EVENTS_BY_QUERY_SEARCH,
          payload: data?.data,
        });
        dispatch(setEventNameState(false));
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteEventsById = (id) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.delete(
        `${Urls.EVENTS_DELETE_BY_ID.replace('#{eventsId}', id)}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: DELETE_EVENTS_BY_ID});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const createEvents = (Data, navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.post(Urls.EVENTS_ADD, Data);
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: POST_EVENTS});
        dispatch({type: SET_FORM_STATE_SUCESS, payload: true});
        navigate('/admin/events');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const editEvents = (Data, id, navigate) => {
  return async (dispatch) => {
    dispatch({type: UPDATING_CONTENT});
    try {
      let data = await httpClient.patch(
        `${Urls.EVENTS_UPDATE_BY_ID.replace('#{eventsId}', id)}`,
        Data,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: UPDATE_EVENTS_BY_ID});
        dispatch(setFormState(true));
        navigate('/admin/events');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const duplicateEventsById = (id, navigate) => {
  return async (dispatch) => {
    dispatch({type: UPDATING_CONTENT});
    try {
      let data = await httpClient.get(
        `${Urls.EVENTS_DUPLICATE_BY_ID.replace('#{eventsId}', id)}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: DUPLICATE_EVENTS_BY_ID});
        navigate(`/admin/events/update/${data?.data?.data?.id}`);
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getEventsById = (id) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.EVENTS_GET_BY_ID.replace('#{eventsId}', id)}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_EVENTS_BY_ID, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const editEventStatusById = (id, status) => {
  return async (dispatch) => {
    dispatch({type: UPDATING_CONTENT});
    try {
      let data = await httpClient.patch(
        `${Urls.EVENTS_UPDATE_STATUS_BY_ID.replace('#{eventsId}', id).replace(
          '#{eventsStatus}',
          status,
        )}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: UPDATE_EVENT_STATUS});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const editRefundStatusById = (
  Data,
  setSelectedRowKeys,
  setShowApproveReject,
) => {
  return async (dispatch) => {
    dispatch({type: UPDATING_CONTENT});
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.patch(
        Urls.EVENTS_UPDATE_REFUND_STATUS_BY_ID,
        Data,
      );
      let successMessage = '';
      if (data.status === HttpCode.Ok) {
        if (Data?.status === 2) {
          successMessage = UPDATE_EVENTS_STATUS_REFUND_SUCCESS;
        } else {
          successMessage = APPROVE_EVENTS_STATUS_REFUND_SUCCESS;
        }
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: UPDATE_EVENT_REFUND_STATUS});
        dispatch({type: UPDATE_REFUND_STATUS, payload: data?.data});
        setShowApproveReject && setShowApproveReject(false);
        setSelectedRowKeys && setSelectedRowKeys([]);
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

import React, {useEffect, useState} from 'react';
import './style.css';
import {
  CheckOutlined,
  CloseOutlined,
  RollbackOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {Collapse, Divider} from 'antd';
import CollapseSvg from '../../../Assets/svgComponents/CollapseSvg';
import ExpandSvg from '../../../Assets/svgComponents/ExpandSvg';
import {useNavigate} from 'react-router-dom';
import {
  SponsorshipTypeMapping,
  checkValuesForPerks,
  formatCurrency,
} from '../../../Util/commonUtility';
import NavigationComponent from '../CommonBackButton';
import {ApplicationString} from '../../../Localization/Locales/en';
import {useDispatch} from 'react-redux';
import {deleteUnPaidSponsorshipFromCart} from '../../../Store/Actions';
const {Panel} = Collapse;

const SponsorPerksCard = ({
  multiple,
  title,
  spot,
  price,
  perks,
  id,
  eventId,
  handleClickUpload,
  hideButton,
  hideUpload,
  isSponsorshipAddedToCart = false,
  removeSponsorShipFromCartByEventId,
  sponsorAddedToCart,
  optionId,
  attendeeDataOptionId,
  isAddedToWaitingList = false,
  usedForEdit = false,
}) => {
  const [activeKey, setActiveKey] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 550);
  let navigate = useNavigate();
  let dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 550);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderBenefits = (benefits) => {
    return (
      <div
        className='perks-inter'
        dangerouslySetInnerHTML={{__html: benefits}}
      />
    );
  };

  const handleCollapseChange = (keys) => {
    setActiveKey(keys[0]);
  };

  useEffect(() => {
    if (optionId && eventId && attendeeDataOptionId) {
      if (Number(optionId) !== attendeeDataOptionId) {
        dispatch(deleteUnPaidSponsorshipFromCart(eventId));
      }
    }
  }, [optionId, eventId, attendeeDataOptionId]);

  const onClickHandler = () => {
    if (id !== sponsorAddedToCart?.id) {
      removeSponsorShipFromCartByEventId();
    }
    navigate(`/eventSponsor/${eventId}/${id}`);
  };

  const handleUploadAssets = () => {
    handleClickUpload();
  };

  const content = [
    {
      title: (
        <div className='perks-header'>
          <div className='perks-header-layout'>
            <div className='perks-type'>
              <p>{title}</p>

              {spot && <span>{`${spot} spot(s) remaining`}</span>}
            </div>
            <div className='perks-pricing-navigation'>
              <p>{formatCurrency(price)}</p>
              {hideButton ? (
                ''
              ) : (
                <div
                  className={`perks-pricing-navigation-buttons-container ${
                    !isSponsorshipAddedToCart && 'align-right-perks-button'
                  }`}>
                  <button disabled={!multiple} onClick={onClickHandler}>
                    {isSponsorshipAddedToCart
                      ? ApplicationString[
                          'userEventSponsorComponent.sponsorPerks.eventContinueSponsorButton'
                        ]
                      : isAddedToWaitingList
                      ? ApplicationString[
                          'userEventSponsorComponent.sponsorPerks.addWaitingListButton'
                        ]
                      : ApplicationString[
                          'userEventSponsorComponent.sponsorPerks.eventSponsorButton'
                        ]}
                  </button>
                  {isSponsorshipAddedToCart && (
                    <button onClick={removeSponsorShipFromCartByEventId}>
                      {
                        ApplicationString[
                          'userEventSponsorComponent.sponsorPerks.removeFromCart'
                        ]
                      }
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ),
      content: (
        <div className='perks-list-container-html' key={id}>
          {renderBenefits(perks?.benefits)}
          {hideButton ? (
            ''
          ) : (
            <>
              <button
                className='mobileGetSponsorButton'
                onClick={onClickHandler}>
                {
                  ApplicationString[
                    'userEventSponsorComponent.sponsorPerks.eventSponsorButton'
                  ]
                }{' '}
              </button>
              {isSponsorshipAddedToCart && (
                <button
                  className='mobileGetSponsorButton'
                  onClick={removeSponsorShipFromCartByEventId}>
                  {
                    ApplicationString[
                      'userEventSponsorComponent.sponsorPerks.removeFromCart'
                    ]
                  }
                </button>
              )}
            </>
          )}
          {hideUpload ? (
            <button
              onClick={handleUploadAssets}
              className='upload-sponsor-assets upload-sponsor-assets-mobile '>
              <UploadOutlined /> Upload Company/Sponsor Logo
            </button>
          ) : (
            ''
          )}
        </div>
      ),
    },
  ];
  return (
    <>
      {perks?.benefits && (
        <div className='main-perks-card'>
          {isMobile ? (
            <Collapse
              expandIcon={({isActive}) => (
                <div className='expand-icon'>
                  {isActive ? <CollapseSvg /> : <ExpandSvg />}
                </div>
              )}
              expandIconPosition='end'
              prefixCls='collapse-perks-card'
              onChange={handleCollapseChange}
              accordion={true}>
              {content.map((item, index) => (
                <Panel
                  header={item.title}
                  key={index}
                  className={activeKey === index ? 'active-panel' : ''}>
                  {item.content}
                </Panel>
              ))}
            </Collapse>
          ) : (
            content.map((item) => (
              <>
                {item.title}
                {item.content}
              </>
            ))
          )}
        </div>
      )}
    </>
  );
};

export default SponsorPerksCard;

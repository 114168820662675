import React, {useEffect, useState} from 'react';
import SectionLayout from '../Layout/sectionLayout';
import {Alert, Col, Row} from 'antd';
import CommonAddNewListingWithFilter from '../Common_AddNewListing_with_Filter';
import MyEventCard from './MyEventCard';
import {useDispatch} from 'react-redux';
import {
  EventRegistrationType,
  myEventsCategoryOptions,
  statusEnums,
  statusOptions,
} from '../../../Util/commonUtility';
import {getAllMyEvents} from '../../../Store/Actions/events';
import useDebounceCallBack from '../../../hooks/useDebounce';
import EventTypeFilter from '../EventTypeFilter';

const MyEventListingV2 = ({
  formattedEventData,
  setFormattedEventData,
  fetchNewData,
  setFetchNewData,
  setUserCredit,
}) => {
  let dispatch = useDispatch();
  const [selectedYearValue, setSelectedYearValue] = useState('');
  const [selectedCategoryValue, setSelectedCategoryValue] = useState('');
  const [filterValues, setFilterValues] = useState(statusEnums.PAID);
  const [searchValues, setSearchValues] = useState('');
  const [eventType, setEventType] = useState(EventRegistrationType.regular);
  const [filteredEvents, setFilteredEvents] = useState([]);

  const filterEvents = (events, type) => {
    return events?.filter((event) => {
      if (type === EventRegistrationType.regular) {
        return event?.attendees?.some((attendee) => attendee?.status !== 1);
      } else if (type === EventRegistrationType.sponsorship) {
        return event?.sponsors?.some((sponsor) => sponsor?.status !== 1);
      } else if (type === EventRegistrationType.waitingList) {
        return event?.waitingList?.length > 0;
      } else if (type === 'both') {
        return (
          event?.attendees?.some((attendee) => attendee?.status !== 1) &&
          event?.sponsors?.some((sponsor) => sponsor?.status !== 1)
        );
      }
      return false;
    });
  };

  useEffect(() => {
    if (formattedEventData) {
      const filtered = filterEvents(formattedEventData, eventType);
      setFilteredEvents(filtered);
    } else {
      setFilteredEvents(null);
    }
  }, [eventType, formattedEventData]);

  // 1. On Search:
  const onSearch = () => {
    dispatch(
      getAllMyEvents(
        selectedCategoryValue,
        filterValues,
        selectedYearValue,
        searchValues,
        setFormattedEventData,
        setUserCredit,
      ),
    );
  };

  useEffect(() => {
    if (fetchNewData) {
      dispatch(
        getAllMyEvents(
          selectedCategoryValue,
          filterValues,
          selectedYearValue,
          searchValues,
          setFormattedEventData,
          setUserCredit,
        ),
      );
      setFetchNewData(false);
    }
  }, [fetchNewData]);

  //2. Search On Change:
  const searchOnChange = useDebounceCallBack((e) => {
    let search = e.target.value;
    if (search) {
      setSearchValues(e.target.value);
    } else {
      setSearchValues('');
    }
  }, 1500);

  //3. UseEffect on searchvalue change
  useEffect(() => {
    if (searchValues || filterValues) {
      dispatch(
        getAllMyEvents(
          selectedCategoryValue,
          filterValues,
          selectedYearValue,
          searchValues,
          setFormattedEventData,
          setUserCredit,
        ),
      );
    } else {
      dispatch(
        getAllMyEvents(
          selectedCategoryValue,
          filterValues,
          selectedYearValue,
          searchValues,
          setFormattedEventData,
          setUserCredit,
        ),
      );
    }
  }, [searchValues]);

  // Search Logic Finished ---------------------------------------

  // Year Filter Logic -------------------------------------
  const handleYearFilter = (yearValue) => {
    if (!yearValue) {
      dispatch(
        getAllMyEvents(
          selectedCategoryValue,
          filterValues,
          '',
          searchValues,
          setFormattedEventData,
        ),
      );
      setSelectedYearValue('');
    } else {
      dispatch(
        getAllMyEvents(
          selectedCategoryValue,
          filterValues,
          yearValue,
          searchValues,
          setFormattedEventData,
        ),
      );
      setSelectedYearValue(yearValue);
    }
  };
  // Year Filter Logic Finished -------------------------------

  // Status Filter Logic ----------------------------------------------------
  const handleChange = (value) => {
    if (!value && value !== 0 && value !== -1) {
      dispatch(
        getAllMyEvents(
          selectedCategoryValue,
          '',
          selectedYearValue,
          searchValues,
          setFormattedEventData,
        ),
      );
      setFilterValues('');
    } else {
      dispatch(
        getAllMyEvents(
          selectedCategoryValue,
          value,
          selectedYearValue,
          searchValues,
          setFormattedEventData,
        ),
      );
      setFilterValues(value);
    }
  };
  // Status Filter Logic Finished -------------------------------------------

  // Category Filter Logic ---------------------------------------------
  // Category Includes Attendee, Upcoming, this week and this month options
  const handleCategoryFilter = (categoryValue) => {
    if (!categoryValue) {
      dispatch(
        getAllMyEvents(
          '',
          filterValues,
          selectedYearValue,
          searchValues,
          setFormattedEventData,
        ),
      );
      setSelectedCategoryValue('');
    } else {
      dispatch(
        getAllMyEvents(
          categoryValue,
          filterValues,
          selectedYearValue,
          searchValues,
          setFormattedEventData,
        ),
      );
      setSelectedCategoryValue(categoryValue);
    }
  };
  // Category Filter Logic Finished -----------------------------------------
  return (
    <React.Fragment>
      <SectionLayout>
        <Row gutter={[0, 32]}>
          <Col span={24}>
            <CommonAddNewListingWithFilter
              selectedYearValue={selectedYearValue}
              selectedStatusValue={statusEnums.PAID}
              selectedCategoryValue={selectedCategoryValue}
              addNew={false}
              usedForMyEvents={true}
              registerforNewEvent={true}
              year
              search
              pageName={'My Event'}
              status={false}
              registrtion_filter={
                <EventTypeFilter
                  setEventType={setEventType}
                  isUsedForWaitingList
                />
              }
              // myEventsFilterByAction={filterByAction}
              // myEventsActiveButton={activeButton}
              selectPlaceholder={'Status'}
              List={statusOptions}
              categoryList={myEventsCategoryOptions}
              searchOnChange={searchOnChange}
              onSearch={onSearch}
              handleChange={handleChange}
              handleYearFilter={handleYearFilter}
              handleCategoryFilter={handleCategoryFilter}
              placeHolder='Search by event name'
            />
          </Col>
          {eventType === EventRegistrationType.waitingList ? (
            <Col span={24}>
              <div className='event2-listing-cards'>
                {filteredEvents?.length > 0 ? (
                  filteredEvents?.map((event, index) => {
                    return (
                      <MyEventCard
                        paid={false}
                        key={event?.id}
                        eventRegistrationType={eventType}
                        eventId={event?.id}
                        attendees={[
                          ...(event?.waitingList?.map((item) => {
                            return {
                              ...item,
                              attendee_first_name: item.first_name,
                              attendee_last_name: item.last_name,
                              attendee_email: item.email,
                            };
                          }) || []),
                        ]}
                        chapter={event?.chapter}
                        date={event?.date}
                        eventName={event?.eventName}
                        eventType={event?.eventType}
                        eventInfo={event?.eventInfo}
                        sponsorshipAvailable={event?.sponsorshipAvailable}
                        registration_status={event?.registration_status}
                        status={event?.status}
                        usedForMyEvents={true}
                        sponsors={event?.sponsors}
                        refundLastDate={event?.refund_last_date}
                      />
                    );
                  })
                ) : (
                  <Alert
                    message='No Waiting List Available'
                    className='no-jobs-alert'
                    type='info'
                    showIcon
                  />
                )}
              </div>
            </Col>
          ) : (
            <Col span={24}>
              <div className='event2-listing-cards'>
                {filteredEvents?.length > 0 ? (
                  filteredEvents?.map((event) => {
                    const toBePaidSponsor =
                      event?.sponsors?.length > 0
                        ? event?.sponsors?.filter(
                            (sponsor) => sponsor?.status === 1,
                          )
                        : [];
                    return (
                      <MyEventCard
                        paid
                        key={event?.id}
                        eventRegistrationType={eventType}
                        eventId={event?.id}
                        attendees={[...(event?.attendees || [])]}
                        chapter={event?.chapter}
                        date={event?.date}
                        eventName={event?.eventName}
                        eventType={event?.eventType}
                        eventInfo={event?.eventInfo}
                        sponsorshipAvailable={event?.sponsorshipAvailable}
                        registration_status={event?.registration_status}
                        status={event?.status}
                        receiptData={event}
                        usedForMyEvents={true}
                        sponsors={event?.sponsors}
                        eventStartDate={event?.event_start_date}
                        toBePaidSponsor={toBePaidSponsor}
                      />
                    );
                  })
                ) : (
                  <Alert
                    message='No Events Available'
                    className='no-jobs-alert'
                    type='info'
                    showIcon
                  />
                )}
              </div>
            </Col>
          )}
        </Row>
      </SectionLayout>
    </React.Fragment>
  );
};

export default MyEventListingV2;

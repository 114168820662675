import React, {useEffect, useState} from 'react';
import {Table} from 'antd';
import './index.css';
import {useNavigate} from 'react-router-dom';
import {
  PageSizeArray,
  PaginationPosition,
  ReturnNAOnEmpty,
  chapterFilters,
} from '../../../Util/commonUtility';
import DynamicSearchForm from '../../CommonComponents/DynamicSearch';
import {ApplicationString} from '../../../Localization/Locales/en';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {useSelector} from 'react-redux';
import CommonTableColumnTextView from '../../CommonComponents/CommonTableColumnTextView';
import HeaderSearchCard from '../../CommonComponents/HeaderSearchCard';
import {CREATE} from '../../../Constant/enums';
import EditChapterContent from './EditChapterContent';

const ChaptersComponent = (props) => {
  let navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chapterData, setChapterData] = useState('');

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const {
    allChaptersData,
    searchValues,
    fetchChapters,
    paging,
    handlePaginationChange,
    DeleteChapters,
    sortParams,
    handleTableChange,
  } = props;

  const ps = useSelector(({pageSizes}) => pageSizes.pageSizes.chapters);
  const handleSearch = (values) => {
    fetchChapters(values);
  };

  const handleEditClick = async (id) => {
    navigate(`/admin/chapters/update/${id}`);
  };

  useEffect(() => {
    searchValues && setExpanded(true);
  }, [searchValues]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: '',
      width: '5%',
    },
    {
      title: 'Order Of Chapter',
      dataIndex: 'order_number',
      key: 'order_number',
      width: '10%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'User',
      dataIndex: 'nvp_user',
      key: 'user',
    },
    {
      title: 'Email',
      dataIndex: 'pp_email',
      key: 'email',
    },
    {
      title: 'Time Zone',
      dataIndex: 'time_zone',
      key: 'time_zone',
      render: (text) => {
        return (
          <CommonTableColumnTextView
            columnText={ReturnNAOnEmpty(text)}
            isToolTip={false}
          />
        );
      },
    },
    {
      title: 'Visible On Map',
      dataIndex: 'is_visible_on_map',
      key: 'is_visible_on_map',
      render: (text) => {
        return (
          <div className='refund-text'>
            <CommonTableColumnTextView
              columnText={text ? 'Yes' : 'No'}
              isToolTip={false}
            />
          </div>
        );
      },
    },
    {
      title: 'Payment gateway',
      dataIndex: 'payment_gateway',
    },
    {
      title: '',
      key: 'action',
      width: '6%',
      fixed: 'right',
      render: (_, record) => {
        return (
          <AdminActionsDropdown
            record={record}
            editBool={true}
            deleteBool={true}
            handleDeleteClick={DeleteChapters}
            handleEditClick={handleEditClick}
            text='Chapter'
            resource='chapters'
            chapterContentBool={true}
            onClickChapterContent={showModal}
            sponsorshipInfoBool={true}
          />
        );
      },
    },
  ];
  columns.forEach((column) => {
    if (column.key !== 'action') {
      column.sorter = true;
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });

  const showModal = (record) => {
    setChapterData(record);
    setIsModalOpen(true);
  };
  return (
    <div className='main-container'>
      <EditChapterContent
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        chapterName={chapterData?.name}
        record={chapterData}
      />
      <HeaderSearchCard
        resource='chapters'
        buttonTitle={ApplicationString['chaptersComponent.button.addForm']}
        toggleClick={toggleExpand}
        addClick={() => navigate('/admin/chapters/add')}
        dataTestId='AdFeeComponent-button-addChapters'
        permission={CREATE}
        expanded={expanded}
      />

      {expanded && (
        <DynamicSearchForm
          columns={chapterFilters}
          onSearch={handleSearch}
          savedFilteredValues={searchValues}
          title={'chapters'}
        />
      )}

      <div className='listing-container'>
        <Table
          bordered
          className='admin-table'
          onChange={handleTableChange}
          columns={columns}
          dataSource={allChaptersData}
          // sticky={true}
          scroll={{x: allChaptersData?.length * 150}}
          key={allChaptersData?.map((item) => {
            item.id;
          })}
          pagination={{
            onChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            current: paging.page,
            total: paging.totalCount,
            pageSize: ps || paging.pageCount,
            pageSizeOptions: PageSizeArray,
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            showTotal: (total, range) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>
    </div>
  );
};

export default ChaptersComponent;

import {
  GET_PBSS_INTERNATIONAL,
  UPDATE_PBSS_INTERNATIONAL,
} from '../../Constant/ActionTypes';

const initialState = {
  allPbssInternational: false,
  isUpdatePbssInternational: false,
};
const PbssInternationalReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PBSS_INTERNATIONAL:
      return {
        ...state,
        allPbssInternational: action.payload,
      };
    case UPDATE_PBSS_INTERNATIONAL:
      return {
        ...state,
        isUpdatePbssInternational: true,
        allPbssInternational: false,
      };
    default:
      return state;
  }
};

export default PbssInternationalReducer;

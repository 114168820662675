import { Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import './style.css';
import LoginForm from '../../CommonComponents/LoginForm';
import CommonPageHeroText from '../../CommonComponents/CommonPageHeroText';
import { ApplicationString } from '../../../Localization/Locales/en';
import CommonWantToSeeAdsListing from '../../CommonComponents/CommonWantToSeeAdsListing';
import TabFilter from '../../CommonComponents/UserTabFilter';
import { JobsAndAdsTabs } from '../../../Util/commonUtility';

const LoginComponent = ({ allApprovedJobsData }) => {
  const [tabKey, setTabKey] = useState('ads');
  const [shouldRender, setShouldRender] = useState(false);
  const handleWindowResize = () => {
    setShouldRender(window.innerWidth > 1000);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <React.Fragment>
      <div className='login-form-container'>
        <CommonPageHeroText
          heading={ApplicationString['userLoginComponent.heading']}
          subheading={ApplicationString['userLoginComponent.subheading']}
        />
        {shouldRender && (
          <SectionLayout>
            <div className='align-right'>
              <TabFilter setTabKey={setTabKey} tabs={JobsAndAdsTabs} />{' '}
            </div>
          </SectionLayout>
        )}
        <SectionLayout>
          <div className='login-container'>
            <div className='form-container extend-login-form'>
              <LoginForm />
              <Divider prefixCls='login-divider' type='vertical' />
              <div className='wapper-main-container'>
                <div className='main-opening-container main-opening-con-login'>
                  <CommonWantToSeeAdsListing
                    containerHeight='auto'
                    isVisible={false}
                    tabKey={tabKey}
                  />
                </div>
              </div>

            </div>
          </div>
        </SectionLayout>
      </div>
    </React.Fragment>
  );
};

export default LoginComponent;

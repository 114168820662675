import React, {useEffect, useState} from 'react';
import {Input, Button, Form} from 'antd';
import './style.css';
import {useDispatch, useSelector} from 'react-redux';
import {
  FETCH_ERROR,
  SET_COUPONCODES_ERROR_MESSAGE,
} from './../../../Constant/ActionTypes';

const PromotionalCodeInputCart = ({
  title,
  buttonText,
  onRedeemClick,
  appliedCartCoupon,
  showValidationBelowInput = true,
}) => {
  const [promoCode, setPromoCode] = useState('');
  const [disable, setDisable] = useState(false);
  const [form] = Form.useForm();
  const {couponCodeErrorMessage} = useSelector(
    ({eventRegister}) => eventRegister,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (appliedCartCoupon) {
      setPromoCode(appliedCartCoupon);
      form.setFieldsValue({code: appliedCartCoupon});
      setDisable(true);
    }
  }, [appliedCartCoupon]);

  const handleRedeemClick = (values) => {
    if (values?.code) {
      onRedeemClick(values);
    } else {
      dispatch({
        type: FETCH_ERROR,
        payload: `Please enter coupon code!`,
      });
    }
  };
  const handleChange = (e) => {
    setPromoCode(e.target.value);
    dispatch({type: SET_COUPONCODES_ERROR_MESSAGE, payload: ''});
  };
  return (
    <React.Fragment>
      <div className='promo-code-container'>
        <p>{title}</p>
        <div className='promo-sub-conatiner cart'>
          <Form
            onFinish={handleRedeemClick}
            prefixCls='promo-sub-form'
            form={form}>
            <Form.Item name='code'>
              <Input
                placeholder='Enter promotional code'
                onChange={(e) => handleChange(e)}
                disabled={disable ? true : false}
              />
            </Form.Item>

            <Button
              type='text'
              htmlType='submit'
              className='promo-sub-button'
              disabled={disable ? true : false}>
              {buttonText}
            </Button>
          </Form>
        </div>
      </div>
      {promoCode && showValidationBelowInput && couponCodeErrorMessage && (
        <div>
          <p className='promo-code-error-message'>{couponCodeErrorMessage}</p>
        </div>
      )}
    </React.Fragment>
  );
};

export default PromotionalCodeInputCart;

import {Button, Form, Input, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import CommonEditor from '../../../CommonComponents/TextEditor';
import {ApplicationString} from '../../../../Localization/Locales/en';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';

const EditSponsorshipInfo = (props) => {
  const [form] = Form.useForm();
  const {EditData, updateChapter} = props;
  const [editorState, setEditorState] = useState(`<p></p>`);
  useEffect(() => {
    EditData &&
      form.setFieldsValue({
        ...EditData,
      });
  }, [EditData]);

  const onFinish = (values) => {
    const data = new FormData();

    data.append('name', EditData.name);
    data.append('nvp_user', EditData.nvp_user);
    data.append('pp_email', EditData.pp_email);
    data.append('latitude', EditData.latitude);
    data.append('longitude', EditData.longitude);
    data.append('order_number', EditData.order_number);
    data.append('details', EditData.details);
    data.append('normalized_name', EditData.normalized_name);
    data.append('is_visible_on_map', EditData.is_visible_on_map);
    data.append('payment_gateway', EditData.payment_gateway);
    data.append('time_zone', EditData.time_zone);
    data.append('local_currency', EditData.local_currency);
    data.append(
      'logo_ids',
      EditData?.logo_ids?.length > 0 ? `[${EditData.logo_ids[0].id}]` : '[]',
    );
    data.append(
      'banner_ids',
      EditData?.banner_ids?.length > 0
        ? `[${EditData.banner_ids[0].id}]`
        : '[]',
    );
    data.append('special_announcement', EditData?.special_announcement);
    data.append('sponsorship_info', JSON.stringify(values?.sponsorship_info));
    data.append('about_chapter', EditData?.about_chapter);
    data.append('mission', EditData?.mission);
    updateChapter(data, EditData.id);
  };
  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };
  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='basic'
          onFinish={onFinish}
          layout='vertical'
          className='two-column-form'
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          initialValues={''}>
          <div className='input-container'>
            <div className='form-column'>
              <Form.List name='sponsorship_info'>
                {(fields, {add, remove}) => (
                  <>
                    {fields.map(({key, name, ...restField}) => (
                      <>
                        <Space
                          key={key}
                          sponsorship_info
                          style={{
                            display: 'flex',
                            marginBottom: 8,
                          }}
                          prefixCls='sponsorshipinfo'
                          direction='vertical'
                          align='baseline'>
                          <Form.Item
                            {...restField}
                            label={'Enter Sponsorship Info Heading'}
                            name={[name, 'key']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing key',
                              },
                            ]}>
                            <Input.TextArea
                              rows={3}
                              prefixCls='common-input-user'
                              className='genral-form'
                              placeholder='Enter the heading for the sponsorship info'
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label={'Enter Sponsorship Info Content'}
                            name={[name, 'value']}>
                            <CommonEditor
                              usedForNested
                              value={editorState}
                              onChange={setEditorState}
                              nestedIndex={key}
                              text={'sponsorshipInfo_editor'}
                              placeholderValue={'sponsorshipInfo'}
                            />
                          </Form.Item>
                        </Space>
                        <Form.Item>
                          <div className='sponsor-button-container'>
                            <Button
                              type='dashed'
                              onClick={() => remove(name)}
                              block
                              icon={<DeleteOutlined />}>
                              Delete SponsorshipInfo Content
                            </Button>
                          </div>
                        </Form.Item>
                      </>
                    ))}
                    <Form.Item>
                      <div className='sponsor-button-container'>
                        <Button
                          type='dashed'
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}>
                          Add SponsorshipInfo Content
                        </Button>
                      </div>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          </div>

          <Form.Item>
            <div className='button-container chapter-sponsorship-info'>
              <Button
                className='common-submit-login common-form-submit common-form-cancel'
                htmlType='button'
                id='adminStem-resetButton-reset'
                onClick={onReset}>
                {ApplicationString['dynamicSearch.button.reset']}
              </Button>
              <Button
                id='adminStem-submitButton-button'
                type='primary'
                htmlType='submit'
                className='common-submit-login common-form-submit'>
                {ApplicationString['dynamicSearch.button.update']}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>{' '}
    </div>
  );
};

export default EditSponsorshipInfo;

import React, {useEffect, useRef, useState} from 'react';
import {useReactToPrint} from 'react-to-print';
import html2canvas from 'html2canvas';
import './style.css';
import {Divider, Table, Tag, Typography} from 'antd';
import {
  formatCurrency,
  formatCurrencyByLocalChapter,
  formatDate,
} from '../../../Util/commonUtility';
import {useDispatch, useSelector} from 'react-redux';
import logo from '../../../Resource/Images/Logos/PBSS-logo.png';
import {getAllChapters} from './../../../Store/Actions/chapters';

const categorizeAndGroupByTransitionId = (attendees) => {
  const filteredAttendees = attendees?.filter(
    (attendee) => attendee?.status !== 1,
  );
  const grouped = filteredAttendees?.reduce((acc, attendee) => {
    const transitionId = attendee.transaction_id;
    if (!acc[transitionId]) {
      acc[transitionId] = [];
    }
    acc[transitionId].push(attendee);
    return acc;
  }, {});

  return Object.keys(grouped)?.map((key, index) => ({
    key: key,
    label: `Payment ${index + 1}`,
    attendees: grouped[key],
  }));
};

const calculateTotals = (data) => {
  return data?.reduce(
    (totals, item) => {
      if (item?.status !== 1) {
        totals.totalCost += parseFloat(item?.total_cost || 0);
        totals.totalDiscountedPrice += parseFloat(item?.discount_amount || 0);
      }
      return totals;
    },
    {totalCost: 0, totalDiscountedPrice: 0},
  );
};

const ReceiptComponent = ({
  eventData,
  allowDownload,
  eventRegistrationType,
}) => {
  const [eventRegistrationTypeReceiptData, setEventRegistrationReceiptData] =
    useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDiscount_amount, setDiscount_amount] = useState(0);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Event Receipt',
    image: true,
    imageType: 'image/jpeg',
    imageQuality: 1,
    imageTimeout: 5000,
    removeAfterPrint: false,
    onBeforeGetContent: () => {
      // Disable any unnecessary elements or styles before generating PDF
      // componentRef.current.style.display = 'block';
    },
    onAfterPrint: () => {
      // Cleanup or reset if needed after printing
      // componentRef.current.style.display = 'none';
    },
  });
  const dispatch = useDispatch();
  const localChapterData = useSelector(
    (state) => state.chapters.localChapterData,
  );

  const refundMode = (isBankRefund, refund_status) => {
    if (refund_status) {
      return isBankRefund ? 'PBSS Credit' : 'Refund to Bank';
    } else {
      return 'N/A';
    }
  };

  useEffect(() => {
    if (!localChapterData) {
      dispatch(getAllChapters());
    }
  }, [localChapterData]);

  useEffect(() => {
    if (eventRegistrationType === 'sponsorship') {
      const {totalCost, totalDiscountedPrice} = calculateTotals(
        eventData?.sponsors,
      );
      setEventRegistrationReceiptData(
        categorizeAndGroupByTransitionId(eventData?.sponsors),
      );
      setTotalPrice(totalCost);
      setTotalCost(totalCost - totalDiscountedPrice);
      setDiscount_amount(totalDiscountedPrice);
    } else {
      const {totalCost, totalDiscountedPrice} = calculateTotals(
        eventData?.attendees,
      );
      setEventRegistrationReceiptData(
        categorizeAndGroupByTransitionId(eventData?.attendees),
      );
      setTotalPrice(totalCost);
      setTotalCost(totalCost - totalDiscountedPrice);
      setDiscount_amount(totalDiscountedPrice);
    }
  }, [eventRegistrationType]);

  return (
    <React.Fragment>
      <div ref={componentRef} className='my-receipt' style={{display: 'none'}}>
        <div className='recept-container'>
          <div className='event-wapper'>
            <div className='event-name'>
              <label className='label'>Registration Receipt</label>
              <label className='label'>Event Name </label>
              <p className='value'>{eventData?.eventName}</p>
            </div>
            <img className='receipt-logo' src={logo} />
          </div>
          <Divider />
          <h4 className='sponsor-detail-table-heading'>Event Details </h4>
          <div className='event-details-container'>
            <div className='event-details'>
              {eventData?.eventType && (
                <div className='detail'>
                  <label className='label'>Event Type:</label>
                  <p className='value'>{eventData?.eventType}</p>
                </div>
              )}
              {eventData?.chapter && (
                <div className='detail'>
                  <label className='label'>Local Chapter Name:</label>
                  <p className='value'>{eventData?.chapter}</p>
                </div>
              )}
              {eventData?.organizers && (
                <div className='detail'>
                  <label className='label'>Organizations:</label>
                  <p className='value'>{eventData?.organizers}</p>
                </div>
              )}
              {/* {eventData?.payment_mode && (
                <div className='detail'>
                  <label className='label'>Mode of Refund :</label>
                  <p className='value'>{eventData?.payment_mode}</p>
                </div>
              )} */}
            </div>
            <div className='event-details'>
              {eventData?.location && (
                <div className='detail'>
                  <label className='label'>Location:</label>
                  <p className='value'>{eventData?.location}</p>
                </div>
              )}
              {/* <div className='detail'>
                <label className='label'>Sponsorship Available:</label>
                <p className='value'>{eventData.sponsorshipAvailable}</p>
              </div> */}
              {eventData?.date && (
                <div className='detail'>
                  <label className='label'>Date:</label>
                  <p className='value'>{formatDate(eventData?.date)}</p>
                </div>
              )}
              {/* {eventData?.refund_amount && (
                <div className='detail'>
                  <label className='label'>Refunded Amount:</label>
                  <p className='value'>{eventData?.refund_amount}</p>
                </div>
              )}
              {eventData?.credit_amount && (
                <div className='detail'>
                  <label className='label'>Credit Amount:</label>
                  <p className='value'>{eventData?.credit_amount}</p>
                </div>
              )} */}
            </div>
          </div>
          {eventRegistrationTypeReceiptData?.map((paymentGroup, index) => (
            <div key={paymentGroup.key}>
              <h4>{paymentGroup.label}</h4>
              <table className='sponsor-table'>
                <thead>
                  <tr>
                    <th className='cal-span-name'>Name</th>
                    <th className='cal-span-email'>Email</th>
                    <th>Company</th>
                    <th>Refunded Amount</th>
                    <th>Refund Type</th>
                    <th>Registration options</th>
                    <th>Food Options</th>
                    <th>Total Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentGroup?.attendees?.map((item) => {
                    const refund_mode = item?.refund_to_account;
                    const refund_status = item?.status == 0;
                    return (
                      <tr key={item?.id}>
                        <td className='col-cell-name'>
                          {item?.attendee_first_name
                            ? item?.attendee_first_name +
                              ' ' +
                              item?.attendee_last_name
                            : 'N/A'}
                        </td>
                        <td className='col-cell-email'>
                          {item?.attendee_email || 'N/A'}
                        </td>
                        <td>{item?.attendee_company || 'N/A'}</td>
                        <td>
                          {formatCurrency(item?.refunded_amount, 'USD') ||
                            'N/A'}
                        </td>
                        <td>{refundMode(refund_mode, refund_status)}</td>
                        <td>
                          {item?.event_registration_options?.registration
                            ?.name ||
                            item?.event_registration_options?.sponsorship
                              ?.name ||
                            'N/A'}
                        </td>
                        <td>
                          {item.event_registration_options.food?.name || 'N/A'}
                        </td>
                        <td>
                          {formatCurrency(item?.total_cost, 'USD') || 'N/A'}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ))}
          <div className='payment-details'>
            <h4>Payment Details</h4>
            <div className='total-price'>
              <p>Price </p>
              <label>
                {formatCurrencyByLocalChapter(totalPrice, localChapterData)}
              </label>
            </div>
            <div className='discount'>
              <p>Discount </p>
              <label>
                {formatCurrencyByLocalChapter(
                  totalDiscount_amount,
                  localChapterData,
                )}
              </label>
            </div>
            <Divider className='receipt-divider' />
            <div className='total-price'>
              <p>Total price </p>
              <label>
                {formatCurrencyByLocalChapter(totalCost, localChapterData)}
              </label>
            </div>
          </div>
        </div>
      </div>
      <button
        className='download-button'
        id={`myProfileMyEvents-download-button_${eventData?.id}`}
        onClick={handlePrint}>
        <div className='docs'>Download Receipt</div>
        <div className='download'>
          <svg
            className='css-i6dzq1'
            strokeLinejoin='round'
            strokeLinecap='round'
            fill='none'
            strokeWidth='2'
            stroke='currentColor'
            height='24'
            width='24'
            viewBox='0 0 24 24'>
            <path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4'></path>
            <polyline points='7 10 12 15 17 10'></polyline>
            <line y2='3' x2='12' y1='15' x1='12'></line>
          </svg>
        </div>
      </button>
      {/* <button onClick={handleDownloadPDF}>Download PDF</button> */}
    </React.Fragment>
  );
};

export default ReceiptComponent;

import React from 'react';
import {ApplicationString} from '../../../Localization/Locales/en';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import {Col, Row} from 'antd';
import PastEventsListing from '../../CommonComponents/PastEventsListing';
import Event2Listing from '../../CommonComponents/EventListing2.0';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';

const SymposiaComponent = () => {
  return (
    <Row gutter={[0, 26]}>
      <Col span={24}>
        <div className='eventTypesStaticPageCard'>
          <SponsorOptionPageHeroText
            title={
              <span className='eventTypesStaticPageCardHeading'>
                {ApplicationString['userSymposiaComponent.heading']}
              </span>
            }
          />
          <SectionLayout>
            <p>{ApplicationString['userSymposiaComponent.p1']}</p>
            <br />
            <p>
              {ApplicationString['userSymposiaComponent.p2']}{' '}
              <a
                href='/'
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => {
                  window.open('/');
                }}>
                click here
              </a>
            </p>
          </SectionLayout>
        </div>
      </Col>
    </Row>
  );
};

export default SymposiaComponent;

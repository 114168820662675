import React from 'react';
import WaitingListContainer from '../../../Containers/AdminContainer/WaitingList';

const WaitingListScreen = () => {
  return (
    <React.Fragment>
      <WaitingListContainer />
    </React.Fragment>
  );
};

export default WaitingListScreen;

import React, {useState} from 'react';
import './style.css';
import {CloseCircleFilled} from '@ant-design/icons';
import {Modal} from 'antd';
import {applicationConfig} from '../../../../Util/config';
import CustomizedTable from './../../CommonTable/index';

const CommiteeCard = ({data, allowReadMore = true}) => {
  const [readMoreClicked, setReadMoreClick] = useState(false);

  const handleCancel = () => {
    setReadMoreClick(false);
  };

  return (
    <React.Fragment>
      <div
        className={
          allowReadMore
            ? 'committee-card-container committee-card-container-pointer'
            : 'committee-card-container'
        }
        onClick={() => allowReadMore && setReadMoreClick(true)}>
        <div className='sub-committee-card-container'>
          <p>{data?.name}</p>
          {data?.designation && <label>{data?.designation}</label>}
        </div>
      </div>
      <Modal
        centered
        prefixCls='committee-read-more-modal'
        width={800}
        title=''
        open={readMoreClicked}
        footer={false}
        closeIcon={<CloseCircleFilled className='committee-close-icon' />}
        onCancel={handleCancel}>
        <div className='committee-main-container'>
          {/* Profile for dynamic data coming from admin side  */}
          {data?.profile_id?.name && (
            <div className='committee-profile-container'>
              <img
                src={`${applicationConfig.UPLOAD_URL}/${data?.profile_id?.name}`}
                className='committee-profile-image'
                crossOrigin='anonymous'></img>
            </div>
          )}
          {/* Profile for static data  */}
          {data?.profile && (
            <div className='committee-profile-container'>
              <img
                src={data.profile}
                className='committee-profile-image'
                crossOrigin='anonymous'></img>
            </div>
          )}
          <div className='committee-content-container'>
            <div className='committee-member-name-container'>
              <p className='committee-member-name'>{data?.name}</p>
            </div>

            <div className='committee-member-description-container'>
              <p className='committee-member-description'>
                <CustomizedTable TableData={data?.details} />
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default CommiteeCard;

import React from 'react';
import {Carousel} from 'antd';

import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import SectionLayout from '../../../CommonComponents/Layout/sectionLayout';
import ComponentTitle from '../../../CommonComponents/CommonComponentTitle';
import {ApplicationString} from '../../../../Localization/Locales/en';
import {applicationConfig} from '../../../../Util/config';

const StemHighlights = ({data}) => {
  const images =
    data?.stem_image_ids?.length > 0
      ? data?.stem_image_ids?.map((obj) => obj.name)
      : [];
  let carouselRef = null;
  const handlePrev = () => {
    carouselRef.prev();
  };

  const handleNext = () => {
    carouselRef.next();
  };
  return (
    <React.Fragment>
      {images?.length > 0 && (
        <SectionLayout>
          <div className='event-highlight-conatiner'>
            <ComponentTitle
              currentOpeningResponsive={true}
              textColor='black'
              title={
                ApplicationString['userStemHighlightsComponent.mainTitleText']
              }
              hideButton={true}
            />
            <div className='event-highlight-section2'>
              <button
                id={`event-highlight-left-action-button`}
                className='prev-button'
                onClick={handlePrev}>
                <LeftOutlined />
              </button>
              <Carousel
                dots={false}
                ref={(node) => (carouselRef = node)}
                prefixCls='event-highlight-carousel'>
                {images.map((image, index) => {
                  const imageSrc = `${applicationConfig.UPLOAD_URL}/${image}`;
                  return (
                    <img
                      key={index}
                      src={imageSrc}
                      crossOrigin='anonymous'
                      alt='carousel-image'
                      className='event-highlights-image-carousel'
                    />
                  );
                })}
              </Carousel>
              <button
                id={`event-highlight-right-action-button`}
                className='next-button'
                onClick={handleNext}>
                <RightOutlined />
              </button>
            </div>
          </div>
        </SectionLayout>
      )}
    </React.Fragment>
  );
};

export default StemHighlights;

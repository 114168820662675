import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Upload, message, Select, Radio, Tag} from 'antd';
import './add.css';
import {useParams, useNavigate} from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import {ApplicationString} from '../../../../Localization/Locales/en';
import {UploadOutlined} from '@ant-design/icons';
import {applicationConfig} from '../../../../Util/config';
import timeZones from '../../../../time_zones.json';
import countryWiseCurrency from '../../../../currency.json';
import {
  IsAvailableForMap,
  NoEmptySpaceInput,
  OnlyAlphabetInputValidator,
  UploadFileMaxSize,
  createSelectionForAdminOptionsUtilForTimeZones,
  formatCurrency,
  validateEmailInput,
  validateNormalizedName,
  validateNumberRange,
} from './../../../../Util/commonUtility';
import TimezoneDisplay from '../../../CommonComponents/CommonTimeZoneComponent';
import {FETCH_ERROR} from '../../../../Constant/ActionTypes';
import {useDispatch} from 'react-redux';

const AddChapterComponent = (props) => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const [submit, setSubmit] = useState(false);
  const [submitLabel, setSubmitLabel] = useState('Submit');
  const [validFileListLogo, setValidFileListLogo] = useState([]);
  const [validFileListBanner, setValidFileListBanner] = useState([]);
  const [validFileList, setValidFileList] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(false);
  const [validFileListDarkLogo, setValidFileListDarkLogo] = useState([]);

  const [form] = Form.useForm();
  let Navigate = useNavigate();
  const {postChapter, EditData, updateChapter, fetchChapter} = props;

  useEffect(() => {
    if (id) {
      fetchChapter(id);
      setSubmitLabel('Update');
    }
  }, []);

  useEffect(() => {
    if (id) {
      EditData &&
        form.setFieldsValue({
          ...EditData,
        });
      setSelectedTimeZone(EditData?.time_zone);
      setSelectedCurrency(EditData?.local_currency);
      EditData?.logo_ids?.length > 0
        ? setValidFileList((prev) => [
            {
              uid: EditData?.logo_ids[0]?.id,
              name: EditData?.logo_ids[0]?.name,
              status: 'done',
              url: `${applicationConfig.UPLOAD_URL}/${EditData?.logo_ids[0]?.name}`,
            },
          ])
        : setValidFileList((prev) => []);

      EditData?.dark_logo_ids?.length > 0
        ? setValidFileListDarkLogo((prev) => [
            {
              uid: EditData?.dark_logo_ids[0]?.id,
              name: EditData?.dark_logo_ids[0]?.name,
              status: 'done',
              url: `${applicationConfig.UPLOAD_URL}/${EditData?.dark_logo_ids[0]?.name}`,
            },
          ])
        : setValidFileListDarkLogo((prev) => []);

      EditData?.banner_ids?.length > 0
        ? setValidFileListBanner((prev) => [
            {
              uid: EditData?.banner_ids[0]?.id,
              name: EditData?.banner_ids[0]?.name,
              status: 'done',
              url: `${applicationConfig.UPLOAD_URL}/${EditData?.banner_ids[0]?.name}`,
            },
          ])
        : setValidFileListBanner((prev) => []);
    }
  }, [EditData]);

  const onSelectionChange = (value) => {
    setSelectedTimeZone(value);
  };

  const onCurrencyChange = (value) => {
    setSelectedCurrency(value);
  };

  const validateLatitude = (latitude) => {
    // Check if latitude is a number between -90 and 90
    if (!isNaN(latitude) && latitude >= -90 && latitude <= 90) {
      return true;
    }
    return false;
  };

  const validateLongitude = (longitude) => {
    // Check if longitude is a number between -180 and 180
    if (!isNaN(longitude) && longitude >= -180 && longitude <= 180) {
      return true;
    }
    return false;
  };

  const dummyRequestLogo = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const dummyRequestBanner = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const filesFormats = ['image/png', 'image/jpeg', 'image/jpg'];

  const uploadProps = {
    name: 'file',
    multiple: true,
    maxCount: 10,
    // listType: 'picture',
    beforeUpload: (file) => {
      const isRightFormat = filesFormats.includes(file.type);
      if (!isRightFormat) {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Accepted file formats: JPG and PNG only',
        });
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < UploadFileMaxSize;
      if (!isLt2M) {
        dispatch({
          type: FETCH_ERROR,
          payload: `File must smaller than ${UploadFileMaxSize}MB.`,
        });
        return false;
      }
      if (file?.type?.includes('image')) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            if (img.width >= 200 && img.height >= 200) {
              setValidFileList((prev) => [file]);
              resolve(isRightFormat && isLt2M);
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: 'Image size must be greater than 200x200 pixels.',
              });
              setValidFileList((prev) =>
                prev.filter((f) => f.uid !== file.uid),
              );
              reject();
            }
          };
          img.onerror = reject;
        });
      } else {
        setValidFileList((prev) => [file]);
        return isRightFormat && isLt2M;
      }
    },
  };

  const uploadPropsDarkLogos = {
    name: 'file',
    multiple: true,
    maxCount: 10,
    // listType: 'picture',
    beforeUpload: (file) => {
      const isRightFormat = filesFormats.includes(file.type);
      if (!isRightFormat) {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Accepted file formats: JPG and PNG only',
        });
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < UploadFileMaxSize;
      if (!isLt2M) {
        dispatch({
          type: FETCH_ERROR,
          payload: `File must smaller than ${UploadFileMaxSize}MB.`,
        });
        return false;
      }
      if (file?.type?.includes('image')) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            if (img.width >= 200 && img.height >= 200) {
              setValidFileListDarkLogo((prev) => [file]);
              resolve(isRightFormat && isLt2M);
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: 'Image size must be greater than 200x200 pixels.',
              });
              setValidFileListDarkLogo((prev) =>
                prev.filter((f) => f.uid !== file.uid),
              );
              reject();
            }
          };
          img.onerror = reject;
        });
      } else {
        setValidFileListDarkLogo((prev) => [file]);
        return isRightFormat && isLt2M;
      }
    },
  };

  const removeFileHandler = (file) => {
    setValidFileList((prevFileList) => {
      return prevFileList.filter((item) => item?.uid !== file?.uid);
    });
    setValidFileListBanner((prevFileList) => {
      return prevFileList.filter((item) => item?.uid !== file?.uid);
    });
    setValidFileListDarkLogo((prevFileList) => {
      return prevFileList.filter((item) => item?.uid !== file?.uid);
    });
  };

  const validateFiles = (fileList) => {
    const validFiles = fileList.filter((file) => {
      const isRightFormat = filesFormats.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 10;
      return isRightFormat && isLt2M;
    });

    validFiles.forEach((file) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.width < 50 || img.height < 50) {
          message.error('Image dimensions should more than 50x50 pixels');
          const index = validFiles.indexOf(file);
          if (index > -1) {
            validFiles.splice(index, 1);
          }
        }
      };
    });

    return validFiles;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const uploadPropsBanner = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      const isRightFormat = filesFormats.includes(file.type);
      if (!isRightFormat) {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Accepted file formats: JPG and PNG only',
        });
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < UploadFileMaxSize;
      if (!isLt2M) {
        dispatch({
          type: FETCH_ERROR,
          payload: `File must smaller than ${UploadFileMaxSize}MB.`,
        });
        return false;
      }
      if (file?.type?.includes('image')) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            if (img.width < 800 || img.height < 100) {
              dispatch({
                type: FETCH_ERROR,
                payload:
                  'Image dimensions should be greater than 800 width and 100 height pixels',
              });
              reject(false);
            } else {
              setValidFileListBanner((prev) => (prev = [file]));
              resolve(true);
            }
          };
          img.onerror = reject;
        });
      } else {
        setValidFileListBanner((prev) => [file]);
        return isRightFormat && isLt2M;
      }
    },
  };

  const onFinish = (values) => {
    const data = new FormData();
    data.append('name', values.name);
    // data.append('password', values.password);
    data.append('nvp_user', values.nvp_user);
    data.append('pp_email', values.pp_email);
    data.append('latitude', values.latitude);
    data.append('longitude', values.longitude);
    data.append('order_number', values.order_number);
    data.append('details', values.details);
    data.append('normalized_name', values.normalized_name);
    data.append('is_visible_on_map', values.is_visible_on_map);
    data.append('payment_gateway', values.payment_gateway);
    data.append('time_zone', values.time_zone);
    data.append('local_currency', values.local_currency);
    values?.special_announcement &&
      data.append('special_announcement', values?.special_announcement);
    data.append('sponsorship_info', values?.sponsorship_info);
    values?.about_chapter &&
      data.append('about_chapter', values?.about_chapter);
    values?.mission && data.append('mission', values?.mission);
    values?.contact_us && data.append('contact_us', values?.contact_us);

    if (EditData && EditData?.logo_ids?.length > 0) {
      EditData?.logo_ids[0]?.id === validFileList[0]?.uid
        ? data.append(
            'logo_ids',
            EditData?.logo_ids?.length > 0
              ? `[${EditData?.logo_ids[0]?.id}]`
              : '[]',
          )
        : data.append('logos', validFileList[0] || '[]');
    } else {
      data.append('logos', validFileList[0] || '[]');
    }
    if (EditData && EditData?.dark_logo_ids?.length > 0) {
      EditData?.dark_logo_ids[0]?.id === validFileListDarkLogo[0]?.uid
        ? data.append(
            'dark_logo_ids',
            EditData?.dark_logo_ids?.length > 0
              ? `[${EditData?.dark_logo_ids[0]?.id}]`
              : '[]',
          )
        : data.append('dark_logos', validFileListDarkLogo[0] || '[]');
    } else {
      data.append('dark_logos', validFileListDarkLogo[0] || '[]');
    }

    if (EditData && EditData?.banner_ids?.length > 0) {
      EditData?.banner_ids[0]?.id === validFileListBanner[0]?.uid
        ? data.append(
            'banner_ids',
            EditData?.banner_ids?.length > 0
              ? `[${EditData?.banner_ids[0]?.id}]`
              : '[]',
          )
        : data.append('banners', validFileListBanner[0] || '[]');
    } else {
      data.append('banners', validFileListBanner[0] || '[]');
    }

    if (id) {
      updateChapter(data, EditData.id);
    } else {
      postChapter(data);
    }
    setSubmit(true);
  };

  const backTOChapter = () => {
    Navigate('/admin/chapters');
  };

  const onFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };

  const onReset = () => {
    form.resetFields();
    setSelectedTimeZone(false);
    setSelectedCurrency(false);
    setValidFileListBanner([]);
    setValidFileListDarkLogo([]);
  };

  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='basic'
          layout='vertical'
          onFinish={onFinish}
          initialValues={''}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          className='two-column-form'>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item name='sponsorship_info' hidden initialValue={'[]'}>
                <Input />
              </Form.Item>
              <Form.Item name='special_announcement' hidden initialValue={null}>
                <Input />
              </Form.Item>
              <Form.Item name='about_chapter' hidden initialValue={null}>
                <Input />
              </Form.Item>
              <Form.Item name='mission' hidden initialValue={null}>
                <Input />
              </Form.Item>
              <Form.Item name='contact_us' hidden initialValue={null}>
                <Input />
              </Form.Item>

              <Form.Item
                label='Name'
                name='name'
                rules={[
                  {
                    required: true,
                    message: 'Chapter name should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      OnlyAlphabetInputValidator(value, 'Chapter name'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter the name of the chapter'
                />
              </Form.Item>
              <Form.Item
                label='User'
                name='nvp_user'
                rules={[
                  {
                    required: true,
                    message: 'User name should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'User name'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter your username'
                />
              </Form.Item>
              <Form.Item
                label='Display Name'
                name='normalized_name'
                rules={[
                  {
                    required: true,
                    message: 'Display name should not be empty',
                  },
                  {
                    validator: validateNormalizedName,
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter chapter display name'
                />
              </Form.Item>
              <Form.Item
                label='Latitude'
                name='latitude'
                rules={[
                  {
                    required: true,
                    message: 'Latitude should not be empty',
                  },
                  {
                    validator: (_, value) => {
                      const valueToString = value?.toString();
                      const spaceRegex = /\s/g;
                      const specialChar = /^-?[0-9]*\.?[0-9]*$/;
                      if (value && !validateLatitude(value)) {
                        return Promise.reject(
                          new Error('latitude is a number between -90 to 90'),
                        );
                      } else if (valueToString && spaceRegex.test(value)) {
                        return Promise.reject(
                          new Error(`Latitude cannot contain spaces!`),
                        );
                      } else if (
                        valueToString &&
                        !valueToString.match(specialChar)
                      ) {
                        return Promise.reject(
                          new Error(`Latitude should include only numbers!`),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter chapters latitude'
                />
              </Form.Item>
            </div>
            <div className='form-column'>
              <Form.Item
                label='Order Of Chapter'
                name='order_number'
                rules={[
                  {
                    required: true,
                    message: 'Order of chapter should not be empty',
                  },
                  {
                    validator: validateNumberRange,
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter order of chapter'
                />
              </Form.Item>
              <Form.Item
                label='Email'
                name='pp_email'
                rules={[
                  {
                    required: true,
                    message: 'Email should not be empty',
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter email'
                />
              </Form.Item>
              <Form.Item
                label='Payment gateway'
                name='payment_gateway'
                rules={[
                  {
                    required: true,
                    message: 'Payment gateway should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Payment gateway'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter payment gateway for this chapter'
                />
              </Form.Item>

              <Form.Item
                label='Longitude'
                name='longitude'
                rules={[
                  {
                    required: true,
                    message: 'Longitude should not be empty',
                  },
                  {
                    validator: (_, value) => {
                      const valueToString = value?.toString();
                      const spaceRegex = /\s/g;
                      const specialChar = /^-?[0-9]*\.?[0-9]*$/;
                      if (value && !validateLongitude(value)) {
                        return Promise.reject(
                          new Error(
                            'longitude is a number between -180 to 180',
                          ),
                        );
                      } else if (valueToString && spaceRegex.test(value)) {
                        return Promise.reject(
                          new Error(`Longitude cannot contain spaces!`),
                        );
                      } else if (
                        valueToString &&
                        !valueToString.match(specialChar)
                      ) {
                        return Promise.reject(
                          new Error(`Longitude should include only numbers!`),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter chapters latitude'
                />
              </Form.Item>
            </div>
          </div>

          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Details'
                name='details'
                rules={[
                  {
                    required: true,
                    message: 'Details should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Details'),
                  },
                ]}>
                <TextArea
                  prefixCls='common-input-user'
                  className='genral-form max-area-length'
                  placeholder='Enter details about the chapter'
                />
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Select Time Zone'
                name='time_zone'
                rules={[
                  {
                    required: true,
                    message: 'Time zone should not be empty',
                  },
                ]}>
                <Select
                  id='adminChapters-timeZone-select'
                  prefixCls='common-select-register'
                  showSearch
                  allowClear
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  onChange={(e) => onSelectionChange(e)}
                  className='space time-zone-selection'
                  placeholder={'Select time zone for this chapter'}
                  options={createSelectionForAdminOptionsUtilForTimeZones(
                    timeZones,
                    'value',
                    'label',
                  )}></Select>
              </Form.Item>
              {selectedTimeZone && (
                <div>
                  <TimezoneDisplay selectedTimeZone={selectedTimeZone} />
                </div>
              )}
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Select Currency'
                name='local_currency'
                rules={[
                  {
                    required: true,
                    message: 'Currency should not be empty',
                  },
                ]}>
                <Select
                  id='adminChapters-currency-select'
                  prefixCls='common-select-register'
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  onChange={(e) => onCurrencyChange(e)}
                  className='space'
                  placeholder={'Select currency for this chapter'}
                  options={createSelectionForAdminOptionsUtilForTimeZones(
                    countryWiseCurrency,
                    'currency_code',
                    'country',
                  )}></Select>
              </Form.Item>
              {selectedCurrency && (
                <div className='timezoneContainer'>
                  {selectedCurrency && (
                    <div className='timeZone-warper'>
                      <div className='timezoneLabel'>
                        Currency in {selectedCurrency} :
                      </div>
                      <Tag className='timeZone-tag'>
                        <div className='timezoneValue'>
                          {formatCurrency(100, selectedCurrency)}
                        </div>
                      </Tag>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <Form.Item
            label='Is this chapter will visible on map?'
            name='is_visible_on_map'
            rules={[
              {
                required: true,
                message:
                  'Please select chapter should be visible on map or not',
              },
            ]}
            valuePropName='value'>
            <Radio.Group
              prefixCls='common-radio-group'
              optionType='button'
              buttonStyle='solid'>
              {Object.entries(IsAvailableForMap).map(([key, value]) => (
                <Radio key={key} value={value}>
                  {key}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          <div className='input-container'>
            <div className='form-column'>
              <div className='upload-admin'>
                <Form.Item name='logo' getValueFromEvent={normFile}>
                  <Upload
                    className='common-input-user genral-form height-auto'
                    {...uploadProps}
                    accept='image/*'
                    customRequest={dummyRequest}
                    onRemove={removeFileHandler}
                    fileList={validFileList}
                    showUploadList={true}>
                    <div>
                      <UploadOutlined className='upload-logo-admin' />
                      <div>
                        <span className='upload-text-logos-admin'>
                          Upload light logo for header
                        </span>
                      </div>
                      <div className='upload-type-admin'>
                        <span className='upload-desc-admin'>
                          Accepts only image files (png, jpg, jpeg)
                        </span>
                        <br />
                        <span className='upload-desc-admin'>
                          {`Maximum file size is ${UploadFileMaxSize}MB.`}
                        </span>
                      </div>
                    </div>
                  </Upload>
                </Form.Item>
              </div>
            </div>
          </div>

          <div className='input-container'>
            <div className='form-column'>
              <div className='upload-admin'>
                <Form.Item name='dark_logo' getValueFromEvent={normFile}>
                  <Upload
                    className='common-input-user genral-form height-auto'
                    {...uploadPropsDarkLogos}
                    accept='image/*'
                    customRequest={dummyRequest}
                    onRemove={removeFileHandler}
                    fileList={validFileListDarkLogo}
                    showUploadList={true}>
                    <div>
                      <UploadOutlined className='upload-logo-admin' />
                      <div>
                        <span className='upload-text-logos-admin'>
                          Upload dark logo for footer
                        </span>
                      </div>
                      <div className='upload-type-admin'>
                        <span className='upload-desc-admin'>
                          Accepts only image files (png, jpg, jpeg)
                        </span>
                        <br />
                        <span className='upload-desc-admin'>
                          {`Maximum file size is ${UploadFileMaxSize}MB.`}
                        </span>
                      </div>
                    </div>
                  </Upload>
                </Form.Item>
              </div>
            </div>
          </div>

          <div className='input-container'>
            <div className='form-column'>
              <div className='upload-admin'>
                <Form.Item name='banner' getValueFromEvent={normFile}>
                  <Upload
                    className='common-input-user genral-form height-auto'
                    {...uploadPropsBanner}
                    accept='image/*'
                    customRequest={dummyRequestBanner}
                    onRemove={removeFileHandler}
                    fileList={validFileListBanner}
                    showUploadList={true}>
                    <div>
                      <UploadOutlined className='upload-logo-admin' />
                      <div>
                        <span className='upload-text-logos-admin'>
                          Upload Banner
                        </span>
                      </div>
                      <div className='upload-type-admin'>
                        <span className='upload-desc-admin'>
                          Accepts only image files (png, jpg, jpeg).{' '}
                          {`Maximum
                          file size is ${UploadFileMaxSize}MB.`}
                        </span>
                        <br />
                        <span className='upload-desc-admin'>
                          For best results, make sure the image is at least 800
                          pixels wide and 100 pixels tall.
                        </span>
                      </div>
                    </div>
                  </Upload>
                </Form.Item>
              </div>
            </div>
          </div>

          <Form.Item>
            <div className='button-container'>
              <Button
                id='adminChapters-backButton-button'
                className='common-submit-login common-form-submit common-form-cancel'
                onClick={() => {
                  backTOChapter();
                }}>
                {ApplicationString['common.button.backForm']}
              </Button>
              <div className='form-flex-button'>
                <Button
                  id='adminChapters-submitButton-button'
                  type='primary'
                  htmlType='submit'
                  //disabled={submit ? true : false}
                  className='common-submit-login common-form-submit'>
                  {submitLabel}
                </Button>
                <Button
                  id='adminChapters-resetButton-button'
                  className='common-submit-login common-form-submit common-form-cancel'
                  htmlType='button'
                  onClick={onReset}>
                  {ApplicationString['dynamicSearch.button.reset']}
                </Button>{' '}
              </div>
            </div>
          </Form.Item>
        </Form>
        {/* </Card> */}
      </div>{' '}
    </div>
  );
};

export default AddChapterComponent;

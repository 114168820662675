import React from 'react';
import './style.css';
import placeholder from '../../../../../Assets/logoIsNotAvailable.png';
import {Badge, Button, Image} from 'antd';
import {applicationConfig} from '../../../../../Util/config';
import {
  addHttpsToUrl,
  getStatusInfo,
  truncateHtmlToWords,
} from '../../../../../Util/commonUtility';
import draftSvg from '../../../../../Assets/draft.svg';
import inReviewSvg from '../../../../../Assets/in_review-v2.svg';
import rejectedSvg from '../../../../../Assets/rejected-v2.svg';
import approvedSvg from '../../../../../Assets/published-v2.svg';
import expiredSvg from '../../../../../Assets/expired-v2.svg';
import {useNavigate} from 'react-router-dom';
import AdsFormComponent from '../../AdsForm';
import {ApplicationString} from '../../../../../Localization/Locales/en';
import {EditOutlined} from '@ant-design/icons';
import view_more_svg from '../../../../../Assets/view-more-right-arrow.svg';

const CommonAdsListingCard = (props) => {
  const {
    adsLogo,
    adsHeading,
    adsDescription,
    adsStatus,
    url,
    id,
    adFeesOptions,
    ad_fee,
  } = props;
  const navigate = useNavigate();
  const [editModalOpen, setEditModalOpen] = React.useState(false);

  return (
    <React.Fragment>
      <div className='common-ads-listing-card-container'>
        <div className='common-ads-listing-card'>
          <div className='common-ads-listing-card-logo-container'>
            <Image.PreviewGroup className='common-ads-listing-card-image-preview'>
              <Image
                crossOrigin='anonymous'
                prefixCls='common-ads-listing-card-logo'
                // width={90}
                preview={adsLogo?.name ? true : false}
                height={70}
                src={
                  adsLogo
                    ? `${applicationConfig.UPLOAD_URL}/${adsLogo?.name}`
                    : placeholder
                }
              />
            </Image.PreviewGroup>
          </div>
          <div className='common-ads-listing-card-content-wrapper'>
            <div className='common-ads-listing-card-textAndButton'>
              <div className='common-ads-listing-card-textDetails'>
                <a
                  className='common-ads-listing-card-textDetails-heading hyperlink'
                  href={`${url}`}
                  target='_blank'
                  rel='noopener noreferrer'>
                  {adsHeading}
                </a>
                <span className='common-ads-listing-card-textDetails-description'>
                  {truncateHtmlToWords(adsDescription, 20)}
                </span>
              </div>
            </div>
            {
              <AdsFormComponent
                isModalOpen={editModalOpen}
                setIsModalOpen={setEditModalOpen}
                editData={props}
                adFeesOptions={adFeesOptions}
              />
            }
            {/* <div className='common-ads-listing-card-status-container'>
              <div className='common-ads-listing-card-status'>
                <img
                  src={getColorStatus(adsStatus).svg}
                  alt='ads-status-svg'
                  className='ads-listing-card-statusImg'
                />
                <span className={getColorStatus(adsStatus).className}>
                  {getColorStatus(adsStatus).normalizeName}
                </span>
              </div>
            </div> */}
            <div className='ads-listing-card-action-button-container'>
              <div className='ads-listing-card-action-button'>
                <div className='common-job-listing-card-action-container'>
                  {adsStatus === 'draft' && ad_fee > 0 && (
                    <Button
                      id={`myProfileAds-payButton-${id}`}
                      className='common-ads-listing-card-button'
                      onClick={() => navigate(`cart/${id}`)}>
                      <span className='link-no-style'>Pay</span>
                    </Button>
                  )}
                  {adsStatus === 'draft' && (
                    <Button
                      id={`myProfileAds-editButton-${id}`}
                      className='common-ads-listing-card-button'
                      onClick={() => setEditModalOpen(!editModalOpen)}>
                      Edit Ad
                    </Button>
                  )}
                </div>
                <Button
                  id={`myProfileAds-checkButton-${id}`}
                  className='view-more-svg-button'>
                  <a
                    className='link-no-style'
                    href={`${url}`}
                    target='_blank'
                    rel='noopener noreferrer'>
                    {
                      ApplicationString[
                        'userCommonAdsListingCard.button.checkItOut'
                      ]
                    }
                  </a>
                  <img
                    src={view_more_svg}
                    alt='view_more_svg'
                    className='view-more-svg'
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CommonAdsListingCard;

import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Button,
  Card,
  DatePicker,
  Empty,
  Form,
  Input,
  Select,
  Spin,
  Tag,
  Upload,
  message,
} from 'antd';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import CommonEditor from '../../CommonComponents/TextEditor';
import {ApplicationString} from '../../../Localization/Locales/en';
import {
  NoEmptySpaceInput,
  UploadFileMaxSize,
  commonSelectAll,
  mailToMemberVariables,
  maxTagPlaceholder,
  queryString,
  tagRender,
} from '../../../Util/commonUtility';
import {CloseOutlined, UploadOutlined} from '@ant-design/icons';
import {
  getAllChaptersByQueryString,
  getAllEmailCodes,
  getAllEmailCodesSearch,
  getAllUsersByQuery,
  getAllUsersByQuerySearch,
} from '../../../Store/Actions';
import {useDispatch} from 'react-redux';
import {FETCH_ERROR} from '../../../Constant/ActionTypes';

const EmailToMemberComponent = (props) => {
  const [submit, setSubmit] = useState(false);
  const [userValue, setUserValue] = useState([]);
  const [submitLabel, setSubmitLabel] = useState('Submit');
  const [selectdOption, setSelectdOption] = useState({});
  const [editorState, setEditorState] = useState(``);
  const [userEmail, setUserEmail] = useState([]);
  const [chapterIds, setChapterIds] = useState([]);
  const [chapterFieldActive, setChapterFieldActive] = useState(false);
  const [userFieldActive, setUserFieldActive] = useState(false);
  const [showUploadList, setShowUploadList] = useState(true);
  const [validFileList, setValidFileList] = useState([]);
  const {id} = useParams();

  const [form] = Form.useForm();
  let Navigate = useNavigate();
  let dispatch = useDispatch();

  const {
    emailCodeList,
    userEmailOptions,
    setUserEmailOptions,
    chapterOptions,
    sendEmailToMembersProp,
    fetching,
    setFetching,
    setChapterOptions,
    setEmailCodeList,
  } = props;

  useEffect(() => {
    if (id) {
      fetchEmailCodesById(id);
      setSubmitLabel('Update');
    }
  }, []);

  // const handleEmailTemplateSearch = (value) => {
  //   setEmailCodeList([]);
  //   setFetching(true);
  //   const Data = {
  //     details: value,
  //   };
  //   const searchQueryData = queryString(Data);
  //   dispatch(
  //     getAllEmailCodesSearch('', '', searchQueryData, {
  //       page: 1,
  //       pageCount: 10,
  //     }),
  //   );
  // };
  // const handleEmailTemplateAllowClear = () => {
  //   dispatch(
  //     getAllEmailCodesSearch('', '', '', {
  //       page: 1,
  //       pageCount: 10,
  //     }),
  //   );
  // };

  const handleUserSearch = (value) => {
    setUserEmailOptions([]);
    setFetching(true);
    const Data = {
      first_name: value,
    };
    const searchQueryData = queryString(Data);
    dispatch(
      getAllUsersByQuerySearch('', '', searchQueryData, {
        page: 1,
        pageCount: 10,
      }),
    );
  };

  const handleUserAllowClear = () => {
    dispatch(
      getAllUsersByQuerySearch('', '', '', {
        page: 1,
        pageCount: 10,
      }),
    );
  };
  const handleUserAllowClearForMouseLeave = () => {
    if (userEmailOptions?.length === 0) {
      dispatch(
        getAllUsersByQuerySearch('', '', '', {
          page: 1,
          pageCount: 10,
        }),
      );
    }
  };

  const onFinish = (values) => {
    const filteredFromVendorReps = values?.chapterIds?.filter((item) => {
      item != 'vendor_reps';
    });
    const isVendorRepsSelected =
      values?.chapterIds?.includes('vendor_reps') || false;
    const data = new FormData();
    data.append('userEmail', JSON.stringify(values?.userEmail || []));
    data.append('chapterIds', JSON.stringify(filteredFromVendorReps || []));
    data.append('vendorReps', isVendorRepsSelected);
    data.append('subject', values.subject);
    validFileList?.length > 0 &&
      validFileList?.forEach((file) => data.append(`attachments`, file));
    data.append('body', editorState);
    sendEmailToMembersProp(data, form, setValidFileList);
    setSubmit(true);
  };

  const onReset = () => {
    form.resetFields();
  };
  const backToEmailTemplate = () => {
    Navigate('/admin/emailTemplates');
  };

  const handalSelect = (value, option) => {
    setSelectdOption(option);
    const selectedEmailCode = option?.email_template;
    setEditorState(selectedEmailCode);
    form.setFieldsValue({
      body: selectedEmailCode, // Update the form field directly
    });
  };

  const userEmailChange = (value, option) => {
    if (value.length > 0) {
      setUserFieldActive(false);
      setChapterFieldActive(true);
    } else {
      setUserFieldActive(false);
      setChapterFieldActive(false);
    }
  };

  const chapterIdsChange = (value, option) => {
    if (value.length > 0) {
      setUserFieldActive(true);
      setChapterFieldActive(false);
    } else {
      setUserFieldActive(false);
      setChapterFieldActive(false);
    }
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const filesFormats = [
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // 'application/msword',
    'application/pdf',
    'image/png',
    'image/jpeg',
    'image/jpg',
  ];
  const uploadProps = {
    name: 'file',
    multiple: true,
    maxCount: 10,
    beforeUpload: (file) => {
      const isRightFormat = filesFormats.includes(file.type);
      if (!isRightFormat) {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Accepted file formats: PDF, JPG and PNG only',
        });
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < UploadFileMaxSize;
      if (!isLt2M) {
        dispatch({
          type: FETCH_ERROR,
          payload: `File must smaller than ${UploadFileMaxSize}MB.`,
        });
        return false;
      }

      setValidFileList((prev) => {
        if (prev.length >= 10) {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Maximum 10 files can be uploaded',
          });
          return prev;
        }
        return [...prev, file];
      });
      return isRightFormat && isLt2M;
    },
  };

  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const removeFileHandler = (file) => {
    setValidFileList((prevFileList) => {
      return prevFileList.filter((item) => item.uid !== file.uid);
    });
  };

  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='emailTemplateForm'
          onFinish={onFinish}
          layout='vertical'
          // initialValues={''}
          className='two-column-form'
          autoComplete='off'>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                name='userEmail'
                label='Select User'
                rules={[
                  {
                    required: !userFieldActive,
                    message: 'User should not be empty',
                  },
                ]}>
                <Select
                  prefixCls='common-select-register'
                  mode='multiple'
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  onSearch={handleUserSearch}
                  onClear={handleUserAllowClear}
                  onMouseLeave={handleUserAllowClearForMouseLeave}
                  notFoundContent={
                    fetching ? (
                      <Spin size='small' />
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onChange={(value, option) => userEmailChange(value, option)}
                  maxTagCount={'responsive'}
                  maxTagPlaceholder={maxTagPlaceholder}
                  tagRender={tagRender}
                  placeholder={'Select users'}
                  options={userEmailOptions}></Select>
              </Form.Item>
              <Form.Item
                name='chapterIds'
                label='Select Chapter'
                rules={[
                  {
                    required: !chapterFieldActive,
                    message: 'Chapter should not be empty',
                  },
                ]}>
                <Select
                  prefixCls='common-select-register'
                  mode='multiple'
                  showSearch
                  // disabled={chapterFieldActive}
                  allowClear
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  onChange={(value, option) => chapterIdsChange(value, option)}
                  // onSearch={handleChapterSearch}
                  // notFoundContent={fetching ? <Spin size='small' /> : null}
                  tagRender={tagRender}
                  placeholder={'Select chapters'}
                  options={chapterOptions}></Select>
              </Form.Item>
              <Form.Item
                label='Subject'
                name='subject'
                rules={[
                  {
                    required: true,
                    message: 'Subject should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Employer'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter subject for the email'
                />
              </Form.Item>
              <Form.Item
                label='Select Email Template'
                name='email_template_type'>
                <Select
                  id='adminEmailToMember-templateSelect-select'
                  prefixCls='common-select-register'
                  className='space'
                  placeholder='Select email template'
                  showSearch
                  allowClear
                  onChange={(value, option) => handalSelect(value, option)}
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={emailCodeList}
                />
              </Form.Item>
              <Form.Item name='body' label='Email Text Description'>
                <CommonEditor
                  // Variables={mailToMemberVariables}
                  value={editorState}
                  onChange={(value) => setEditorState(value)}
                  text={'email_to_member_description'}
                  placeholderValue={'email template description'}
                />
              </Form.Item>
              <div className='input-container'>
                <div className='form-column'>
                  <div className='upload-admin'>
                    <Form.Item name='attachment'>
                      <Upload
                        id='adminEmailToMember-uploadAttachButton-button'
                        prefixCls='upload-list-name-fix-class-admin'
                        className='common-input-user genral-form height-auto pd-y-20'
                        {...uploadProps}
                        onRemove={removeFileHandler}
                        accept='.pdf,.png,.jpg,.jpeg'
                        customRequest={dummyRequest}
                        fileList={validFileList}
                        showUploadList={showUploadList}>
                        <div>
                          <UploadOutlined className='upload-logo-admin' />
                          <div>
                            <span className='upload-text-logos-admin'>
                              Upload Attachment
                            </span>
                          </div>
                          <div className='upload-type-admin'>
                            <span className='upload-desc-admin'>
                              Accepts only pdf/image files (png, jpg, jpeg, pdf)
                            </span>
                            <br />
                            <span className='upload-desc-admin'>
                              Maximum number of files allowed is 10.
                            </span>
                            <br />
                            <span className='upload-desc-admin'>
                              {`Maximum file size is ${UploadFileMaxSize}MB.`}
                            </span>
                          </div>
                        </div>
                      </Upload>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Form.Item>
            <div className='button-container'>
              <Button
                className='common-submit-login common-form-submit common-form-cancel'
                id='adminEmailToMember-backButton-button'
                onClick={() => {
                  backToEmailTemplate();
                }}>
                {ApplicationString['common.button.backForm']}
              </Button>
              <div className='form-flex-button'>
                <Button
                  id='adminEmailToMember-submitButton-button'
                  type='primary'
                  htmlType='submit'
                  // //disabled={submit ? true : false}
                  className='common-submit-login common-form-submit'>
                  {submitLabel}
                </Button>
                <Button
                  className='common-submit-login common-form-submit common-form-cancel'
                  htmlType='button'
                  id='adminEmailToMember-resetButton-reset'
                  onClick={onReset}>
                  {ApplicationString['dynamicSearch.button.reset']}
                </Button>{' '}
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>{' '}
    </div>
  );
};

export default EmailToMemberComponent;

import React, {useEffect, useState, useContext, useMemo} from 'react';
import {HolderOutlined} from '@ant-design/icons';
import {DndContext} from '@dnd-kit/core';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {Table, Button, Divider, Col} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import {
  PaginationPosition,
  SponsorshipTypeMapping,
  formatCurrency,
  getSponsorshipTypeName,
} from '../../../Util/commonUtility';
import {ApplicationString} from '../../../Localization/Locales/en';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {DescriptionItem} from './../../../Util/commonUtility';
import CustomizedTable from './../../CommonComponents/CommonTable/index';
import AdminViewDetailsDrawer from '../../CommonComponents/AdminViewDetailsDrawer';
import {CSVLink} from 'react-csv';
import {useDispatch} from 'react-redux';
import {updateOptionSequence} from '../../../Store/Actions/eventOptions';
import HeaderSearchCard from '../../CommonComponents/HeaderSearchCard';
import {CREATE} from '../../../Constant/enums';
const EventOptionsComponent = (props) => {
  const {id} = useParams();
  const [expanded, setExpanded] = useState(false);
  let navigate = useNavigate();
  const {
    allEventOptionsData,
    fetchEventOptionsWithoutPagination,
    eventOptionsFetchingWithoutPagination,
    allEventOptionWithoutPaginationData,
    sortParams,
    handleTableChange,
    searchValues,
    fetchEventOptions,
    deleteEventOptions,
  } = props;
  const [eventNameHeading, setEventNameHeading] = useState('');
  const [open, setOpen] = useState(false);
  const [openDrawerDetails, setOpenDrawerDetails] = useState(false);
  const [dataSource, setDataSource] = React.useState();
  const [orderChanged, setOrderChanged] = useState(false); // Track if order has changed
  const [updatedSequence, setUpdatedSequence] = useState({}); // Store the updated sequence
  const dispatch = useDispatch();
  const RowContext = React.createContext({});
  const DragHandle = () => {
    const {setActivatorNodeRef, listeners} = useContext(RowContext);

    return (
      <Button
        type='text'
        size='small'
        icon={<HolderOutlined />}
        style={{
          cursor: 'grab',
        }}
        ref={setActivatorNodeRef}
        {...listeners}
      />
    );
  };

  useEffect(() => {
    if (allEventOptionsData) {
      setDataSource(allEventOptionsData);
    }
  }, [allEventOptionsData]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const handleSearch = (values) => {
    fetchEventOptions(values);
  };

  const handleEditClick = async (id) => {
    navigate(`/admin/eventOptions/update/${id}`);
  };

  useEffect(() => {
    if (allEventOptionsData) {
      setEventNameHeading(allEventOptionsData[0]?.event_name);
    }
  }, [allEventOptionsData]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  // Drawer functions ----------------------------------------------------
  const showDrawer = (record) => {
    setOpenDrawerDetails(record);
    setOpen(true);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const EventOptionsDrawerContent = () => {
    const {
      name: eventOptionName,
      event_option_group_name: eventOptionGroupName,
      event_option_type: optionType,
      sponsorship_type,
      price,
      currency_type,
      details,
      limit,
      qty,
      used_qty,
      eventSponsorshipPerk,
      is_vendor,
    } = openDrawerDetails || {};

    return (
      <>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Event Option Name'
              content={eventOptionName}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Event Option Group Name'
              content={eventOptionGroupName}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem title='Event Option Type' content={optionType} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Price'
              content={formatCurrency(price, 'USD')}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem
              title='Currency Type'
              content={currency_type || 'N/A'}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem title='Details' content={details || 'N/A'} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem title='Limit' content={limit} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem title='Quantity' content={qty || 'N/A'} />
          </Col>
          <Col span={12}>
            <DescriptionItem title='Used Quantity' content={used_qty || 0} />
          </Col>
        </Row>
        <Divider />
        {optionType === 'sponsorship' && (
          <>
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title='Sponsorship Type'
                  content={
                    sponsorship_type
                      ? getSponsorshipTypeName(Number(sponsorship_type))
                      : 'N/A'
                  }
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title='Attendee Price'
                  content={formatCurrency(
                    eventSponsorshipPerk?.attendees_price,
                    'USD',
                  )}
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title='Sponsorship Perks'
                  content={
                    eventSponsorshipPerk?.benefits ? (
                      <CustomizedTable
                        TableData={eventSponsorshipPerk?.benefits}
                      />
                    ) : (
                      'N/A'
                    )
                  }
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title='Lunch Limit'
                  content={eventSponsorshipPerk?.free_lunch || 'N/A'}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title='Meeting Presentation'
                  content={eventSponsorshipPerk?.workshop_attendees || 'N/A'}
                />
              </Col>
            </Row>

            <Divider />
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title='Dinner with Speaker'
                  content={eventSponsorshipPerk?.dinner_with_speaker || 'N/A'}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title='Dinner with Speaker Price'
                  content={
                    formatCurrency(
                      eventSponsorshipPerk?.dinner_with_speaker_price,
                      'USD',
                    ) || 'N/A'
                  }
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title='Sponsor Contact Content'
                  content={
                    eventSponsorshipPerk?.sponsor_contact_content ? (
                      <CustomizedTable
                        TableData={
                          eventSponsorshipPerk?.sponsor_contact_content
                        }
                      />
                    ) : (
                      'N/A'
                    )
                  }
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title='Sponsor Perk Content'
                  content={
                    eventSponsorshipPerk?.sponsor_perk_content ? (
                      <CustomizedTable
                        TableData={eventSponsorshipPerk?.sponsor_perk_content}
                      />
                    ) : (
                      'N/A'
                    )
                  }
                />
              </Col>
            </Row>
          </>
        )}

        {optionType === 'registration' && (
          <>
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title='Vendor'
                  content={is_vendor ? 'Yes' : 'No'}
                />{' '}
              </Col>
            </Row>
            <Divider />
          </>
        )}
      </>
    );
  };

  const columns = [
    {
      key: 'sort',
      align: 'center',
      width: '1%',
      render: () => <DragHandle />,
    },
    {
      title: 'Order No',
      key: 'order_number',
      dataIndex: 'order_number',
      align: 'center',
      width: '1%',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      width: '2%',
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '4%',
      sorter: true,
      render: (name) => {
        return <div>{name}</div>;
      },
    },
    {
      title: 'Event Option Type',
      dataIndex: 'event_option_type',
      sorter: true,
      width: '3%',
      render: (text) => {
        return <span>{text.toUpperCase()}</span>;
      },
    },
    {
      title: 'Sponsorship Type',
      dataIndex: 'sponsorship_type',
      key: 'sponsorship_type',
      ellipsis: true,
      width: '3%',
      render: (text) => {
        return (
          <span>
            {text ? getSponsorshipTypeName(Number(text)).toUpperCase() : 'NA'}
          </span>
        );
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      width: '3%',
      sorter: true,
      render: (text) => {
        return formatCurrency(text, 'USD');
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      width: '3%',
      sorter: true,
    },
    {
      title: 'Used Quantity',
      dataIndex: 'used_qty',
      width: '3%',
      sorter: true,
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      width: '1%',
      render: (_, record) => (
        <AdminActionsDropdown
          record={record}
          editBool={true}
          viewBool={true}
          handleView={showDrawer}
          deleteBool={true}
          handleEditClick={handleEditClick}
          handleDeleteClick={deleteEventOptions}
          text='Event Option'
          resource='events_options'
        />
      ),
    },
  ];
  columns.forEach((column) => {
    if (column.key !== 'action') {
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });

  const formateAccountData = () => {
    let newAccountData = [];

    if (allEventOptionWithoutPaginationData?.length > 0)
      for (const eventOption of allEventOptionWithoutPaginationData) {
        let newEventOptions = {
          id: eventOption?.id,
          name: eventOption?.name,
          price: eventOption?.price,
          currency_type: eventOption?.currency_type,
          details: eventOption?.details,
          qty: eventOption?.qty,
          used_qty: eventOption?.used_qty,
          orders_count: eventOption?.orders_count,
          is_vendor: eventOption?.is_vendor,
          event_option_type: eventOption?.event_option_type?.toUpperCase(),
          sponsorship_type:
            SponsorshipTypeMapping[parseInt(eventOption?.sponsorship_type)],
          event_name: eventOption?.event_name,
          free_lunch: eventOption?.eventSponsorshipPerk?.free_lunch,
          lunch_price: eventOption?.eventSponsorshipPerk?.lunch_price,
          workshop_attendees:
            eventOption?.eventSponsorshipPerk?.workshop_attendees,
          attendees_price: eventOption?.eventSponsorshipPerk?.attendees_price,
          dinner_with_speaker:
            eventOption?.eventSponsorshipPerk?.dinner_with_speaker,
          dinner_with_speaker_price:
            eventOption?.eventSponsorshipPerk?.dinner_with_speaker_price,
        };
        newAccountData.push(newEventOptions);
      }

    return newAccountData;
  };

  const headers = [
    {label: 'ID', key: 'id'},
    {label: 'Name', key: 'name'},
    {label: 'Price', key: 'price'},
    {label: 'Currency Type', key: 'currency_type'},
    {label: 'Details', key: 'details'},
    {label: 'Quantity', key: 'qty'},
    {label: 'Used Quantity', key: 'used_qty'},
    {label: 'Orders Count', key: 'orders_count'},
    {label: 'Is Vendor', key: 'is_vendor'},
    {label: 'Event Option Type', key: 'event_option_type'},
    {label: 'Sponsorship Type', key: 'sponsorship_type'},
    {label: 'Event Name', key: 'event_name'},
    {label: 'Free Lunch', key: 'free_lunch'},
    {label: 'Lunch Price', key: 'lunch_price'},
    {label: 'Workshop Attendees', key: 'workshop_attendees'},
    {label: 'Attendees Price', key: 'attendees_price'},
    {label: 'Dinner with Speaker', key: 'dinner_with_speaker'},
    {label: 'Dinner with Speaker Price', key: 'dinner_with_speaker_price'},
  ];

  const formattedEventOptionsAccountData = formateAccountData();
  const Row = ({dataRowKey, ...props}) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({id: dataRowKey});

    const style = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      ...(isDragging ? {position: 'relative', zIndex: 9999} : {}),
    };

    const contextValue = useMemo(
      () => ({
        setActivatorNodeRef,
        listeners,
      }),
      [setActivatorNodeRef, listeners],
    );

    return (
      <RowContext.Provider value={contextValue}>
        <tr {...props} ref={setNodeRef} style={style} {...attributes} />
      </RowContext.Provider>
    );
  };

  const onDragEnd = ({active, over}) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.id === active?.id,
        );
        const overIndex = prevState.findIndex(
          (record) => record.id === over?.id,
        );
        const reorderedData = arrayMove(prevState, activeIndex, overIndex);

        // Update sequence
        const sequence = reorderedData.reduce((acc, item, index) => {
          acc[item.id] = index + 1; // Map item ID to its new position
          return acc;
        }, {});

        setUpdatedSequence(sequence); // Save the updated sequence
        setOrderChanged(true); // Mark order as changed
        return reorderedData;
      });
    }
  };
  const handleSaveOrder = async () => {
    try {
      const Data = {
        sequence: updatedSequence,
      };
      dispatch(updateOptionSequence(Data));

      setOrderChanged(false); // Reset the order changed state
    } catch (error) {
      console.error('Failed to save order', error);
    }
  };

  const footer = () => {
    return (
      <div>
        {orderChanged && (
          <>
            <div className='refund-admin-container'>
              <Button
                type='primary'
                onClick={handleSaveOrder}
                className='common-submit-login common-form-submit'>
                Save Order Changes
              </Button>
            </div>
          </>
        )}
      </div>
    );
  };
  const extraButtons = () => {
    return (
      <>
        <Button onClick={() => navigate('/admin/events')} id='back-to-events'>
          {ApplicationString['common.button.backToEvents']}
        </Button>
        {eventOptionsFetchingWithoutPagination &&
          !allEventOptionWithoutPaginationData && (
            <Button>Fetching Event Options..</Button>
          )}
        {!eventOptionsFetchingWithoutPagination &&
          !allEventOptionWithoutPaginationData?.length && (
            <Button
              onClick={() => {
                fetchEventOptionsWithoutPagination(searchValues);
              }}>
              Load CSV Data
            </Button>
          )}
        {!eventOptionsFetchingWithoutPagination &&
          allEventOptionWithoutPaginationData?.length > 0 && (
            <Button id='EventOptions-button-downloadCsv'>
              <CSVLink
                data={formattedEventOptionsAccountData}
                header={headers}
                filename={'EventOption_Report.csv'}>
                Download CSV
              </CSVLink>
            </Button>
          )}
      </>
    );
  };
  return (
    <div className='main-container'>
      <AdminViewDetailsDrawer
        content={EventOptionsDrawerContent}
        visible={open}
        text={'Event Option'}
        onCloseDrawer={onCloseDrawer}
        editNavigate={`/admin/eventOptions/update/${openDrawerDetails?.id}`}
      />

      <HeaderSearchCard
        resource='events_options'
        buttonTitle={ApplicationString['eventOptionsComponent.button.addForm']}
        showFilter={false}
        showAddButton={true}
        addClick={() => navigate(`/admin/eventOptions/add/${id}`)}
        dataTestId='adminEventOptions-button-addEventOptions'
        permission={CREATE}
        expanded={expanded}
        extra={extraButtons()}
      />
      <div className='listing-container'>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            items={dataSource ? dataSource.map((item) => item.id) : []}
            strategy={verticalListSortingStrategy}>
            <Table
              className='admin-table'
              components={{
                body: {
                  row: (props) => (
                    <Row {...props} dataRowKey={props['data-row-key']} />
                  ),
                },
              }}
              columns={columns}
              dataSource={dataSource}
              rowKey='id'
              onChange={handleTableChange}
              sticky={true}
              pagination={{
                showTotal: (total, range) => `Total ${total} items`,
                position: [PaginationPosition],
              }}
              footer={footer}
            />
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};

export default EventOptionsComponent;

import React, {useEffect, useState} from 'react';
import {Button, Card, DatePicker, Form, Input, Select} from 'antd';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {ApplicationString} from '../../../../Localization/Locales/en';
import {
  NoEmptySpaceInput,
  OnlyAlphabetInputValidator,
  validateNumberRange,
} from '../../../../Util/commonUtility';
const EventTypesFormComponent = (props) => {
  const [submit, setSubmit] = useState(false);
  const [submitLabel, setSubmitLabel] = useState('Submit');
  const [form] = Form.useForm();
  let Navigate = useNavigate();
  const {
    postEventTypes,
    EditData,
    updateEventTypes,
    fetchAds,
    fetchEventTypes,
  } = props;

  useEffect(() => {
    if (id) {
      fetchEventTypes(id);
      setSubmitLabel('Update');
    }
  }, []);

  useEffect(() => {
    if (id) {
      EditData &&
        form.setFieldsValue({
          ...EditData,
        });
    }
  }, [EditData]);

  const onFinish = (values) => {
    if (id) {
      updateEventTypes(values, EditData.id);
      // form.resetFields();
      // Navigate('/admin/eventTypes');
    } else {
      postEventTypes(values);
      // form.resetFields();
      // Navigate('/admin/eventTypes');
    }
    setSubmit(true);
  };
  const {id} = useParams();
  const onFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };
  const onReset = () => {
    form.resetFields();
  };
  const backTOEventTypes = () => {
    Navigate('/admin/eventTypes');
  };

  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='basic'
          onFinish={onFinish}
          layout='vertical'
          initialValues={''}
          className='two-column-form'
          autoComplete='off'>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Name'
                name='name'
                rules={[
                  {
                    required: true,
                    message: 'Event type name should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      OnlyAlphabetInputValidator(value, 'Event type name'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter name event type'
                />
              </Form.Item>
              <Form.Item
                label='Details'
                name='details'
                rules={[
                  {
                    required: true,
                    message: 'Details should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Details'),
                  },
                ]}>
                <Input.TextArea
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter details for event type'
                />
              </Form.Item>
              <Form.Item
                label='Order Of Event Type'
                name='order_number'
                rules={[
                  {
                    required: true,
                    message: 'Order of event type should not be empty',
                  },
                  {
                    validator: validateNumberRange,
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter order of chapter'
                />
              </Form.Item>
            </div>
          </div>

          <Form.Item>
            <div className='button-container'>
              <Button
                id='adminEventTypes-backButton-button'
                className='common-submit-login common-form-submit common-form-cancel'
                onClick={() => {
                  backTOEventTypes();
                }}>
                {ApplicationString['common.button.backForm']}
              </Button>
              <div className='form-flex-button'>
                <Button
                  id='adminEventTypes-submitButton-button'
                  type='primary'
                  htmlType='submit'
                  //disabled={submit ? true : false}
                  className='common-submit-login common-form-submit'>
                  {submitLabel}
                </Button>
                <Button
                  id='adminEventTypes-resetButton-button'
                  className='common-submit-login common-form-submit common-form-cancel'
                  htmlType='button'
                  onClick={onReset}>
                  {ApplicationString['dynamicSearch.button.reset']}
                </Button>{' '}
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EventTypesFormComponent;

import React, {useEffect, useState} from 'react';
import UserUpdateProfileComponent from '../../../Components/UserComponents/UserUpdateProfile';
import {
  fetchUsersByIdForUpdateProfile,
  getAllChapters,
  getAllKeywordsByQuery,
  resetKeywordSearchData,
  updateUserById,
} from '../../../Store/Actions';
import {
  createSelectionOptionsUtil,
  queryString,
} from '../../../Util/commonUtility';
import {useDispatch, useSelector} from 'react-redux';
import {getLocalStorageUserInfo} from './../../../Util/commonUtility';

const UserUpdateProfileContainer = () => {
  const dispatch = useDispatch();
  const {fetchUserDataForUpdateProfile} = useSelector(({users}) => users);
  const {allChapters} = useSelector(({chapters}) => chapters);
  const {allKeywordsQueryData} = useSelector(({keywords}) => keywords);
  const [userData, setUserData] = useState([]);
  const [chapterList, setChapterList] = useState([]);
  const [keywordsOptions, SetKeywordsOptions] = useState([]);
  const userInfo = getLocalStorageUserInfo();
  const userId = userInfo?.id;

  useEffect(() => {
    dispatch(resetKeywordSearchData(false));
  }, []);

  useEffect(() => {
    if (fetchUserDataForUpdateProfile) {
      setUserData(fetchUserDataForUpdateProfile?.data);
    } else {
      dispatch(fetchUsersByIdForUpdateProfile(userId));
    }
  }, [fetchUserDataForUpdateProfile?.data]);

  useEffect(() => {
    if (allChapters) {
      const visibleChapters = allChapters?.data?.chapters?.filter((chapter) => {
        return chapter?.is_visible_on_map === true;
      });
      setChapterList(createSelectionOptionsUtil(visibleChapters, 'id', 'name'));
    } else {
      dispatch(getAllChapters());
    }
  }, [allChapters]);

  useEffect(() => {
    if (allKeywordsQueryData.forMaster) {
      SetKeywordsOptions(
        createSelectionOptionsUtil(
          allKeywordsQueryData.forMaster?.data?.keywords,
          'id',
          'name',
        ),
      );
    } else {
      dispatch(
        getAllKeywordsByQuery('name', 'ascend', queryString({status: 1}), {
          page: 1,
          pageCount: 1000,
        }),
      );
    }
  }, [allKeywordsQueryData.forMaster]);

  const updateUser = async (Data, id, Navigate, profileNameChange) => {
    dispatch(
      updateUserById(Data, id, Navigate, (profileNameChange = 'Profile')),
    );
  };

  return (
    <UserUpdateProfileComponent
      chaptersList={chapterList}
      userData={userData}
      updateUser={(id, Data, Navigate) => {
        updateUser(id, Data, Navigate);
      }}
      keywordsOptions={keywordsOptions}
    />
  );
};

export default UserUpdateProfileContainer;

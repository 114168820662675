import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createSelectionOptionsUtil} from '../../../Util/commonUtility';
import {useParams} from 'react-router-dom';
import AddAttendeeComponent from '../../../Components/UserComponents/AddAttendees';
import {getEventOptionsByEventId} from './../../../Store/Actions/eventOptions';
import {
  UpdateAttendee,
  deleteAttendee,
  getAttendesByUserAndEventId,
  getRegistrationUserWaitingListById,
  postAttendee,
} from './../../../Store/Actions/eventRegister';

const AddAttendeeContainer = () => {
  let dispatch = useDispatch();
  const {allAttendees} = useSelector(({eventRegister}) => eventRegister);
  const {getEventOptionByEventId} = useSelector(
    ({eventOptions}) => eventOptions,
  );
  const {allUserRegistrationWaitingList} = useSelector(
    ({eventRegister}) => eventRegister,
  );
  const [allEventOptionsFoodData, setAllEventOptionsFoodData] = useState([]);
  const [totalCartPrice, setTotalCartPrice] = useState(0);
  const [allEventOptionsRegistrationData, setAllEventOptionsRegistrationData] =
    useState([]);
  const [allRegistrationWaitingList, setAllRegistrationWaitingList] = useState(
    [],
  );

  const [allAttendeesData, setAllAttendeesData] = useState();
  const [attendeesData, setAttendeesData] = useState();
  const {id} = useParams();
  const [eventType, setEventType] = useState('');
  const [eventName, setEventName] = useState('');
  const [isSponsorAvailable, setIsSponsorAvailable] = useState(false);
  const userID = JSON.parse(localStorage.getItem('userInfo'))?.id;

  const fetchWaitingList = async (eventId) => {
    try {
      dispatch(getRegistrationUserWaitingListById(eventId, userID));
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (allUserRegistrationWaitingList) {
      setAllRegistrationWaitingList(
        allUserRegistrationWaitingList?.waitingList,
      );
    } else {
      fetchWaitingList(id);
    }
  }, [allUserRegistrationWaitingList?.waitingList, id]);
  useEffect(() => {
    fetchWaitingList(id);
  }, []);

  useEffect(() => {
    if (getEventOptionByEventId) {
      const filteredRegData =
        getEventOptionByEventId?.data?.registration?.filter(
          (reg) => reg?.is_vendor === false,
        );
      const data = getEventOptionByEventId?.data?.food
        ? createSelectionOptionsUtil(
            getEventOptionByEventId?.data?.food,
            'id',
            'name',
            'price',
          )
        : [];
      const noFoodData = [...data, {value: 'no-food', label: 'No Food'}];
      setAllEventOptionsFoodData(noFoodData);
      setAllEventOptionsRegistrationData(
        createSelectionOptionsUtil(filteredRegData, 'id', 'name', 'price'),
      );
    }
  }, [getEventOptionByEventId]);

  useEffect(() => {
    if (allAttendees) {
      setTotalCartPrice({
        total_price_per_cart: allAttendees?.data?.total_price,
      });
      const Data = allAttendees?.data?.event_registrations?.attendees?.filter(
        (obj) => obj.is_reps === false,
      );
      setAllAttendeesData(Data);
      setEventType(allAttendees?.data?.event_type_name);
      setEventName(allAttendees?.data?.event_name);
      setIsSponsorAvailable(allAttendees?.data?.sponsorship_available);
    } else {
      getAttendeeByEventId(id);
    }
  }, [allAttendees]);

  useEffect(() => {
    if (allAttendeesData) {
      const Data = Array.isArray(allAttendeesData)
        ? allAttendeesData.map((item) => {
            return {
              ...item,
              food_option: item?.event_registration_options?.food,
              registration_option:
                item?.event_registration_options?.registration,
            };
          })
        : [];
      setAttendeesData(Data);
    }
  }, [allAttendeesData, id]);

  useEffect(() => {
    if (id) {
      dispatch(getEventOptionsByEventId(id));
      getAttendeeByEventId(id);
    }
  }, [id]);

  const AddAttendee = (
    Data,
    setEditingKey,
    form,
    setIsAddAttendee,
    setDisableAttendeeClicks,
    setDisablePaymentClicks,
    setDisableEditClicks,
  ) => {
    dispatch(
      postAttendee(
        Data,
        setEditingKey,
        form,
        setIsAddAttendee,
        setDisableAttendeeClicks,
        setDisablePaymentClicks,
        setDisableEditClicks,
      ),
    );
  };
  const getAttendeeByEventId = (id) => {
    const attendeeType = 'normal';
    dispatch(getAttendesByUserAndEventId(id, attendeeType));
  };
  const editAttendeeById = (
    Data,
    id,
    setEditingKey,
    form,
    setDisableAttendeeClicks,
    setDisablePaymentClicks,
    setDisableEditClicks,
  ) => {
    dispatch(
      UpdateAttendee(
        Data,
        id,
        setEditingKey,
        form,
        setDisableAttendeeClicks,
        setDisablePaymentClicks,
        setDisableEditClicks,
      ),
    );
  };
  const deleteAttendeeById = (id) => {
    dispatch(deleteAttendee(id));
  };
  return (
    <div>
      <AddAttendeeComponent
        isSponsorAvailable={isSponsorAvailable}
        allEventOptionsFoodData={allEventOptionsFoodData}
        allEventOptionsRegistrationData={allEventOptionsRegistrationData}
        AddAttendee={(
          Data,
          setEditingKey,
          form,
          setIsAddAttendee,
          setDisableAttendeeClicks,
          setDisablePaymentClicks,
          setDisableEditClicks,
        ) => {
          AddAttendee(
            Data,
            setEditingKey,
            form,
            setIsAddAttendee,
            setDisableAttendeeClicks,
            setDisablePaymentClicks,
            setDisableEditClicks,
          );
        }}
        getAttendeeByEventId={getAttendeeByEventId}
        editAttendeeById={(
          Data,
          id,
          setEditingKey,
          form,
          setDisableAttendeeClicks,
          setDisablePaymentClicks,
          setDisableEditClicks,
        ) => {
          editAttendeeById(
            Data,
            id,
            setEditingKey,
            form,
            setDisableAttendeeClicks,
            setDisablePaymentClicks,
            setDisableEditClicks,
          );
        }}
        deleteAttendeeById={(id) => {
          deleteAttendeeById(id);
        }}
        attendeesData={attendeesData}
        setAttendeesData={setAttendeesData}
        totalCartPrice={totalCartPrice}
        eventType={eventType}
        eventName={eventName}
        allAttendeesData={allAttendeesData}
        allRegistrationWaitingList={allRegistrationWaitingList}
      />
    </div>
  );
};

export default AddAttendeeContainer;

import React, {useState, useEffect} from 'react';
import CommitteeMemberFormComponent from '../../../../Components/AdminComponents/CommitteeMember/CommitteeMemberForm';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  createCommitteeMembers,
  editCommitteeMembers,
  getCommitteeMemberById,
} from './../../../../Store/Actions/committeeMember';
import {createSelectionForAdminOptionsUtil} from '../../../../Util/commonUtility';
import {getAllChapters} from '../../../../Store/Actions';
import {applicationConfig} from '../../../../Util/config';

const CommitteeMemberFormContainer = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [editData, setEditData] = useState();
  const [chapterList, setChapterList] = useState([]);

  const {getCommitteeMemberData} = useSelector(
    ({committeeMembers}) => committeeMembers,
  );
  const {allChapters} = useSelector(({chapters}) => chapters);

  useEffect(() => {
    if (getCommitteeMemberData) {
      setEditData({
        ...getCommitteeMemberData?.data,
      });
    }
  }, [getCommitteeMemberData]);

  useEffect(() => {
    if (allChapters) {
      const visibleChapters = allChapters?.data?.chapters?.filter((chapter) => {
        return chapter?.is_visible_on_map === true;
      });
      setChapterList(
        createSelectionForAdminOptionsUtil(visibleChapters, 'id', 'name'),
      );
    } else {
      dispatch(getAllChapters());
    }
  }, [allChapters]);

  const postCommitteeMember = async (Data) => {
    dispatch(createCommitteeMembers(Data, navigate));
  };

  const updateCommitteeMember = async (Data, id) => {
    dispatch(editCommitteeMembers(Data, id, navigate));
  };

  const fetchCommitteeMemberById = async (id) => {
    dispatch(getCommitteeMemberById(id));
  };
  return (
    <div>
      <CommitteeMemberFormComponent
        postCommitteeMember={(Data) => {
          postCommitteeMember(Data);
        }}
        updateCommitteeMember={(id, Data) => {
          updateCommitteeMember(id, Data);
        }}
        fetchCommitteeMemberById={(id) => {
          fetchCommitteeMemberById(id);
        }}
        EditData={editData}
        chaptersList={chapterList}
      />
    </div>
  );
};

export default CommitteeMemberFormContainer;

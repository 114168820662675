import React, {useState, useEffect} from 'react';
import {
  createSelectionOptionsUtil,
  DefaultPaginationValues,
  queryString,
} from '../../../Util/commonUtility';
import {useDispatch, useSelector} from 'react-redux';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';
import {
  deleteCommitteeMembersById,
  getAllCommitteeMembers,
} from '../../../Store/Actions/committeeMember';
import CommitteeMemberComponent from './../../../Components/AdminComponents/CommitteeMember/index';
import {getAllChaptersByQueryStringForSearch} from '../../../Store/Actions';

const CommitteeMemberContainer = () => {
  const dispatch = useDispatch();
  const [chaptersOptions, setChaptersOptions] = useState([]);

  const {allCommitteeMembers} = useSelector(
    ({committeeMembers}) => committeeMembers,
  );
  const {allChaptersQueryData} = useSelector(({chapters}) => chapters);

  const [committeeMembersData, setCommitteeMembersData] = useState();
  const memorizedPageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.adFees,
  );
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.adFees,
  );

  const [pagination, setPagination] = useState({
    page: memorizedPageNumber || DefaultPaginationValues.PAGE,
    pagecount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [sortParams, setSortParams] = useState({
    field: 'id',
    order: 'descend',
  });
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);

  useEffect(() => {
    if (allCommitteeMembers) {
      setCommitteeMembersData(allCommitteeMembers?.data?.committeeMembers);
      setPagination({
        ...pagination,
        page: allCommitteeMembers?.data?.currentPage,
        totalPages: allCommitteeMembers?.data?.totalPage,
        totalCount: allCommitteeMembers?.data?.totalCount,
      });
    } else {
      fetchCommitteeMembers();
    }
  }, [allCommitteeMembers?.data?.committeeMembers]);

  useEffect(() => {
    if (allChaptersQueryData?.forSearch) {
      const visibleChapters =
        allChaptersQueryData.forSearch?.data?.chapters?.filter(
          (chapter) => chapter?.is_visible_on_map === true,
        );
      setChaptersOptions(
        createSelectionOptionsUtil(visibleChapters, 'id', 'name'),
      );
    } else {
      dispatch(
        getAllChaptersByQueryStringForSearch('', '', '', {
          page: 1,
          pageCount: 100,
        }),
      );
    }
  }, [allChaptersQueryData?.forSearch?.data?.chapters]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      fetchCommitteeMembers();
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged]);

  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('committeeMembers', pageSize));
    dispatch(setPageNumber('committeeMembers', current));
    setPagination({
      ...pagination,
      page: current,
      pagecount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
  };

  const fetchCommitteeMembers = async (searchValues) => {
    try {
      const sortField = sortParams.field || '';
      const sortOrder = sortParams.order || '';
      const searchQueryData = queryString(searchValues);
      dispatch(
        getAllCommitteeMembers(
          pagination,
          sortField,
          sortOrder,
          searchQueryData,
        ),
      );
    } catch (error) {
      console.error(error);
    }
  };
  const deleteCommitteeMember = async (id) => {
    try {
      dispatch(deleteCommitteeMembersById(id));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <CommitteeMemberComponent
        allCommitteeMembersData={committeeMembersData}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        deleteCommitteeMember={(id) => {
          deleteCommitteeMember(id);
        }}
        fetchCommitteeMembers={(searchValues) => {
          fetchCommitteeMembers(searchValues);
        }}
        handleTableChange={(pagination, filter, sorter) => {
          handleTableChange(pagination, filter, sorter);
        }}
        sortParams={sortParams}
        chaptersOptions={chaptersOptions}
      />
    </div>
  );
};

export default CommitteeMemberContainer;

import React, {useState, useEffect} from 'react';
import {Tabs} from 'antd';

const {TabPane} = Tabs;

const TabFilter = ({setTabKey, tabs}) => {
  const defaultTabKey = tabs?.[0]?.key || '';
  const [selectedTabKey, setSelectedTabKey] = useState(defaultTabKey);

  useEffect(() => {
    if (defaultTabKey) {
      setTabKey(defaultTabKey);
      setSelectedTabKey(defaultTabKey);
    }
  }, [defaultTabKey, setTabKey]);

  const handleTabChange = (key) => {
    setSelectedTabKey(key);
    setTabKey(key);
  };

  return (
    <Tabs
      className='mobile-view pbss-international-tab-list'
      prefixCls='user-event-card'
      activeKey={selectedTabKey}
      onChange={handleTabChange}>
      {tabs?.map((tab) => (
        <TabPane tab={tab.label} key={tab.key}>
          <div className='tablist-jobAds'>
            {tab.key === 'ads' ? (
              <p>{tab.content}</p>
            ) : (
              <div dangerouslySetInnerHTML={{__html: tab.content}} />
            )}
          </div>
        </TabPane>
      ))}
    </Tabs>
  );
};

export default TabFilter;

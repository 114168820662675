import React, {useEffect, useState} from 'react';
import {
  Space,
  Table,
  Popconfirm,
  Tooltip,
  Button,
  Divider,
  Row,
  Col,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FilterOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {
  PageSizeArray,
  PaginationPosition,
  ReturnNAOnEmpty,
  sponsorFilters,
} from '../../../Util/commonUtility';
import DynamicSearchForm from '../../CommonComponents/DynamicSearch';
import {ApplicationString} from '../../../Localization/Locales/en';
import editIcon from '../../../Assets/editIcon.svg';
import deleteIcon from '../../../Assets/deleteIcon.svg';
import plus from '../../../Assets/Plus.svg';
import UpdateHistoryTableInfo from '../HistoryView';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {useSelector} from 'react-redux';
import useDebouncedCallback from '../../../hooks/useDebounce';
import {DescriptionItem} from './../../../Util/commonUtility';
import CustomizedTable from './../../CommonComponents/CommonTable/index';
import AdminViewDetailsDrawer from './../../CommonComponents/AdminViewDetailsDrawer/index';
import HeaderSearchCard from './../../CommonComponents/HeaderSearchCard/index';
import {CREATE} from '../../../Constant/enums';
const SponsorsComponent = (props) => {
  let navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDrawerDetails, setOpenDrawerDetails] = useState(false);
  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const ps = useSelector(({pageSizes}) => pageSizes.pageSizes.sponsors);

  const {
    allSponsorData,
    fetchSponsor,
    paging,
    handlePaginationChange,
    deleteSponsor,
    sortParams,
    handleTableChange,
    searchValues,
  } = props;

  const handleSearch = (values) => {
    fetchSponsor(values);
  };

  const handleEditClick = async (id) => {
    navigate(`/admin/sponsors/update/${id}`);
  };

  useEffect(() => {
    searchValues && setExpanded(true);
  }, [searchValues]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  // Drawer functions ----------------------------------------------------
  const showDrawer = (record) => {
    setOpenDrawerDetails(record);
    setOpen(true);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const SponsorsDrawerContent = () => {
    const {
      name,
      phone_number,
      title,
      company_name,
      extension,
      email,
      comments,
    } = openDrawerDetails || {};

    return (
      <>
        <Row>
          <Col span={24}>
            <DescriptionItem title='Sponsor Name' content={name || 'N/A'} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem title='Sponsor Email' content={email || 'N/A'} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem title='Sponsor Title' content={title || 'N/A'} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Company Name'
              content={company_name || 'N/A'}
            />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Sponsor Phone Number'
              content={phone_number || 'N/A'}
            />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Sponsor Extension'
              content={extension || 'N/A'}
            />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Sponsor comments'
              content={<CustomizedTable TableData={comments} />}
            />
          </Col>
        </Row>
        <Divider />
      </>
    );
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '1.5%',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '4%',
      render: (text) => ReturnNAOnEmpty(text),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '6%',
      render: (text) => ReturnNAOnEmpty(text),
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      width: '5%',
      render: (text) => ReturnNAOnEmpty(text),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '5%',
    },
    {
      title: '',
      key: 'action',
      width: '4%',
      fixed: 'right',
      render: (_, record) => (
        <AdminActionsDropdown
          record={record}
          editBool={true}
          deleteBool={true}
          viewBool={true}
          handleView={showDrawer}
          handleDeleteClick={deleteSponsor}
          handleEditClick={handleEditClick}
          text='Sponsor'
          resource='sponsors'
        />
      ),
    },
  ];
  columns.forEach((column) => {
    if (column.key !== 'action' && column.key !== 'view_details') {
      column.sorter = true;
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
      // column.render = (text) => text;
    }
  });

  // debouncing the table & pagination changes to stop too many requests
  const debouncedHandlePaginationChange = useDebouncedCallback(
    handlePaginationChange,
    1000,
  );
  const debouncedTableChange = useDebouncedCallback(handleTableChange, 1000);

  return (
    <div className='main-container'>
      <AdminViewDetailsDrawer
        content={SponsorsDrawerContent}
        visible={open}
        text={'Sponsors'}
        onCloseDrawer={onCloseDrawer}
        editNavigate={`/admin/sponsors/update/${openDrawerDetails?.id}`}
      />

      <HeaderSearchCard
        resource='sponsors'
        toggleClick={toggleExpand}
        buttonTitle={ApplicationString['sponsorsComponent.button.addForm']}
        addClick={() => navigate('/admin/sponsors/add')}
        dataTestId='adminSponsors-button-addSponsors'
        permission={CREATE}
        expanded={expanded}
      />
      {expanded && (
        <DynamicSearchForm
          columns={sponsorFilters}
          onSearch={handleSearch}
          savedFilteredValues={searchValues}
          title={'sponsors'}
        />
      )}
      <div className='listing-container'>
        <Table
          bordered
          className='admin-table'
          columns={columns}
          onChange={debouncedTableChange}
          dataSource={allSponsorData}
          key={allSponsorData?.map((item) => {
            item.id;
          })}
          scroll={{x: columns?.length * 150}}
          // sticky={true}
          pagination={{
            onChange: debouncedHandlePaginationChange,
            current: paging.page,
            total: paging.totalCount,
            pageSize: ps || paging.pageCount,
            pageSizeOptions: PageSizeArray,
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            showTotal: (total, range) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>
    </div>
  );
};

export default SponsorsComponent;

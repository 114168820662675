import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {usePermissions} from '../../../hooks/usePermission';
import {allModuleKeys, urlModuleMapper} from '../../../permissions';
import {CREATE, UPDATE} from '../../../Constant/enums';

const IsAuthorized = ({isLoggedIn, allowedRoles, path, element}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const userInfo = localStorage.getItem('userInfo');
  const user = userInfo ? JSON.parse(userInfo) : null;
  const userRole = user ? user.role : null;
  const {checkPermission} = usePermissions(userRole);
  const locationPathNameArray = location.pathname.split('/');

  // Find the module name from the path
  const locationModuleName = locationPathNameArray.find((segment) =>
    allModuleKeys.includes(segment),
  );

  // Action variables
  const actionVariables = ['add', 'update'];

  // Identify an action present in the path that matches with the action variables
  const moduleLocationExtra =
    locationPathNameArray.find((segment) =>
      actionVariables.includes(segment),
    ) || null;

  // Map the module name to the resource
  const resource = urlModuleMapper[locationModuleName];

  // Permission mapping for actions
  const permissionMapper = {
    add: CREATE,
    update: UPDATE,
  };

  // Determine the final permission, defaulting to 'create' if no action is matched
  const permission = permissionMapper[moduleLocationExtra] || CREATE;

  // Check for modules without any permissions and map them to resources
  const allModulesWithNoPermissions = checkPermission();
  const mappedAllModulesWithNoPermissions =
    allModulesWithNoPermissions &&
    allModulesWithNoPermissions?.map((module) => urlModuleMapper[module]);

  const checkRole = () => {
    if (user && userRole && allowedRoles?.length > 0) {
      if (!allowedRoles.includes(userRole)) {
        navigate('/updateProfile');
        return;
      }

      if (
        mappedAllModulesWithNoPermissions &&
        mappedAllModulesWithNoPermissions.includes(locationPathNameArray[2])
      ) {
        navigate('/admin/forbidden');
        return;
      }

      if (moduleLocationExtra && !checkPermission(resource, permission)) {
        navigate('/admin/forbidden');
        return;
      }
    }
    setIsLoading(false); // Set loading to false only if all checks pass
  };

  useEffect(() => {
    checkRole();
  }, [isLoggedIn, allowedRoles, userInfo]);

  if (isLoading) {
    return null;
  }

  return <>{element}</>;
};

export default IsAuthorized;

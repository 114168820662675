import {Button, Result} from 'antd';
import {useNavigate} from 'react-router-dom';
const NotAuthorized = ({usedForAdmin = false}) => {
  let navigate = useNavigate();
  return (
    <Result
      status='403'
      title='403'
      subTitle='Sorry, you are not authorized to access this page.'
      extra={
        usedForAdmin ? (
          <Button
            type='primary'
            className='admin-buttons'
            onClick={() => navigate('/admin/events')}>
            Back Home
          </Button>
        ) : (
          <Button type='primary' onClick={() => navigate('/')}>
            Back Home
          </Button>
        )
      }
    />
  );
};

export default NotAuthorized;

import React from 'react';
import {Button, Checkbox, Form, Input} from 'antd';
import CommonPageHeroText from '../../CommonComponents/CommonPageHeroText';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import UpdatePasswordForm from '../../AdminComponents/Auth/ResetPassword/UpdatePassword/UpdatePassForm';
import {ApplicationString} from '../../../Localization/Locales/en';
import ChangePasswordForm from './changePasswordForm';

const ChangePassword = () => {
  return (
    <React.Fragment>
      <CommonPageHeroText
        heading={ApplicationString['userChangePasswordComponent.heading']}
        subheading={ApplicationString['userChangePasswordComponent.subheading']}
      />
      <SectionLayout>
        <ChangePasswordForm />
      </SectionLayout>
    </React.Fragment>
  );
};

export default ChangePassword;

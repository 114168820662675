import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {editStem, getStemById} from './../../../Store/Actions/stem';
import PbssInternationalComponent from '../../../Components/AdminComponents/PbssInternational';
import {
  editPbssInternational,
  getPbssInternationalById,
} from '../../../Store/Actions/pbssInternational';

const PbssInternationalContainer = () => {
  const dispatch = useDispatch();
  const [editData, setEditData] = useState();
  const {allPbssInternational} = useSelector(
    ({pbssInternational}) => pbssInternational,
  );

  useEffect(() => {
    if (allPbssInternational) {
      setEditData(allPbssInternational?.data?.pbssInternational[0]);
    }
  }, [allPbssInternational]);

  useEffect(() => {
    fetchPbssInternationalById();
  }, []);

  const updatePbssInternational = (Data, id) => {
    dispatch(editPbssInternational(Data, id));
  };

  const fetchPbssInternationalById = () => {
    const showMessage = true;
    dispatch(getPbssInternationalById(showMessage));
  };
  return (
    <PbssInternationalComponent
      updatePbssInternational={(id, Data) => {
        updatePbssInternational(id, Data);
      }}
      EditData={editData}
    />
  );
};

export default PbssInternationalContainer;

export const ApplicationString = {
  //adminPanel
  'eventScheduleAdd.button.add': 'Add Schedule',
  'stemComponent.button.add': 'Add Stem',
  'eventTemplateScheduler.button.addForm': 'Add New Schedule',
  'CommitteeMemberComponent.button.addForm': 'Add Committee Member',
  'signup.button.register': 'Register',
  'common.button.clearFeeForm': 'Clear',
  'common.button.submitForm': 'Submit',
  'common.button.updateForm': 'Update',
  'common.button.backToEvents': 'Back To Events',
  'common.button.editAction': 'Edit',
  'common.button.deleteAction': 'Delete',
  'common.button.statusAction': 'Status',
  'adFeesComponent.button.addForm': 'Add New AdFee',
  'jobFeesComponent.button.addForm': 'Add New JobFee',
  'jobsComponent.button.addForm': 'Add New Job',
  'speakersComponent.button.addForm': 'Add New Speaker',
  'adsComponent.button.addForm': 'Add New Ad',
  'adsApprovedListComponent.button': 'Click here to place ads',
  'jobsApprovedListComponent.button': 'Click here to post jobs',
  'chaptersComponent.button.addForm': 'Add New Chapter',
  'emailCodesComponent.button.addForm': 'Add New Email Template',
  'keywordsComponent.button.addForm': 'Add New Interest',
  'eventTypesComponent.button.addForm': 'Add New Event Type',
  'companiesComponent.button.addForm': 'Add New Company',
  'couponCodesComponent.button.addForm': 'Add New Coupon Code',
  'sponsorsComponent.button.addForm': 'Add New Sponsor',
  'usersComponent.button.addForm': 'Add New member attendee',
  'eventOptionGroupsComponent.button.addForm': 'Add New Event Option Group',
  'eventOptionsComponent.button.addForm': 'Add New Event Option',
  'eventsComponent.button.addForm': 'Add New Event',
  //Special Payment
  'specialPayment.button.addForm': 'Generate Special Payment',
  'adminSpecialPayment.button.submit': 'Submit',
  'adminSpecialPayment.button.reset': 'Reset',
  'specialPayment.alertMessage': 'Thank you for your payment',
  'dynamicSearch.button.update': 'Update',
  'dynamicSearch.button.search': 'Search',
  'dynamicSearch.button.reset': 'Reset',
  'dynamicSearch.button.submit-your-feedback': 'Submit your feedback',
  'dynamicSearch.button.heading': 'Filter Search',
  'common.button.backForm': 'Cancel',
  'couponCodesFormComponent.button.addFormHeading': 'Add Coupon Code Details',
  'couponCodesFormComponent.button.editFormHeading': 'Edit Coupon Code Details',
  'speakersFormComponent.button.addFormHeading': 'Add Speakers Details',
  'speakersFormComponent.button.editFormHeading': 'Edit Speakers Details',
  'jobFeesFormComponent.button.addFormHeading': 'Add JobFees Details',
  'jobFeesFormComponent.button.editFormHeading': 'Edit JobFees Details',
  'adFeesFormComponent.button.addFormHeading': 'Add AdFees Details',
  'adFeesFormComponent.button.editFormHeading': 'Edit AdFees Details',
  'jobsFormComponent.button.addFormHeading': 'Add Jobs Details',
  'jobsFormComponent.button.editFormHeading': 'edit Jobs Details',
  'companiesFormComponent.button.addFormHeading': 'Add Companies Details',
  'companiesFormComponent.button.editFormHeading': 'edit Companies Details',
  'eventOptionGroupsFormComponent.button.addFormHeading':
    'Add EventOptionGroups Details',
  'eventOptionsFormComponent.button.editFormHeading':
    'edit EventOptionGroups Details',
  'eventOptionsFormComponent.button.addFormHeading': 'Add EventOptions Details',
  'eventOptionsFormComponent.button.editFormHeading':
    'edit EventOptions Details',
  //userPanel
  'userJobsComponent.cardHeading': 'PBSS.org Jobs Link Service',
  'userJobsComponent.cardSubHeading': 'vacancy Details from around the world',
  'userJobsComponent.contentHeading':
    'Our jobs link service posts jobs relevant to the pharmaceutical and bioscience industry. The jobs are also delivered to the 4000+ members through our newsletters.',
  'userComponent.button.addListing': 'Add New',
  'userAdsComponent.cardHeading': 'PBSS.org Ads Publish Service',
  'userAdsComponent.cardSubHeading': 'vacancy Details from around the world',
  'userResetPasswordComponent.heading': 'Reset Password',
  'userChangePasswordComponent.heading': 'Change Your Password',
  'userResetPasswordComponent.subheading':
    'No need to worry. Tell us your email and we will send you reset Password link to email address',
  'userChangePasswordComponent.subheading':
    'To enhance your account security, please enter your old password and choose a new one.',
  'userText.done': 'Done',
  'userText.cancel': 'Cancel',
  'userText.save': 'Save',
  'userText.welcomeToPbss': 'Welcome to Pbss',
  'userText.welcomeToPbssDescription':
    'working in diverse organizations such as the biotechnology and pharmaceutical industries.',
  'userText.LearnMore': 'Learn More',

  //About Us
  'userAboutUsComponent.heading': 'Pharmaceutical & BioScience Society',
  'userAboutUsComponent.paragraph':
    'Pharmaceutical & BioScience Society International is a non-profit professional organization of scientists and other professionals in the life science sector, working in diverse organizations such as the biotechnology and pharmaceutical industries, contract research organizations, life science tools suppliers, academia, and government laboratories. It has 20,000+ participants and is active through six member organizations in the San Francisco Bay Area, San Diego, Boston, Seattle, Vancouver, and Korea, some of the largest life science clusters in the World.',
  'userAboutUsComponent.ourMission': 'Our Mission',
  'userAboutUsComponent.chapterSanFranciscoBay':
    'About PBSS - San Francisco Bay',
  'userAboutUsComponent.chapterSanFranciscoBayAbout':
    'About San Francisco Bay Phrmaceutical & BioScience Society...',
  'userAboutUsComponent.ourMissionParagraph':
    'The mission of PBSS is to promote the exchange of scientific ideas and provide growth opportunities in the fields of pharmaceutical / life science related disciplines. We foster interactions and disseminate information through seminar luncheons, focused workshops (short courses), symposia, and webinars, as well as career development programs.',
  'userAboutUsComponent.standingCommittee': 'PBSS Leadership',
  'userAboutUsComponent.standingCommittee.subText':
    'Click on member name for their bio',
  'userAboutUsComponent.standingCommittee.boston':
    'Boston Chapter Committee Members',
  'userAboutUsComponent.standingCommittee.korea':
    ' Korea Chapter Committee Members',
  'userAboutUsComponent.standingCommittee.sf-bay':
    'San Francisco Bay Chapter Committee Members',
  'userAboutUsComponent.standingCommittee.san-diego':
    'San Diego Chapter Committee Members',
  'userAboutUsComponent.standingCommittee.vancouver':
    'Vancouver Chapter Committee Members',
  'userAboutUsComponent.standingCommittee.advisors': 'Distinguished Advisors',
  'userAboutUsComponent.standingCommittee.operationTeam': 'Operations Team',
  'userAboutUsComponent.standingCommittee.programManagement':
    'Program Management',
  'userAboutUsComponent.standingCommitteeParagraph':
    'Logo Represents the branding material of respective brand of vendor or corporate Sponsor.',
  'userCommonAdsListingCard.button.checkItOut': 'Check it out',
  //sponsor details
  'userAddAttendeeComponent.sponsorDetail.label':
    "Sponsor's Contact Information",
  'userAddAttendeeComponent.sponsorDetail.description':
    "Please fill out the Sponsor Details section before entering the attendee details. If the contact person is attending the meeting's presentations, please also fill out their information in the next section",
  'userAddAttendeeComponent.sponsorDetail.perksLabel':
    'Register for Workshop Attendees',
  'userAddAttendeeComponent.sponsorDetail.perksLabel': 'Vendor Show Attendance',
  'userAddAttendeeComponent.sponsorDetail.perksDescription':
    'Please note that Meeting Presentations registration includes Vendor Show registration but registrants for Vendor Show only do not have access to the meeting presentations',
  'userAddAttendeeComponent.sponsorDetail.perksDescription.major':
    'Major sponsor can have up to 2 free workshop seats. If you have more than two, additional fees must be paid.Please note that Meeting Presentations registration includes Vendor Show registration but registrants for Vendor Show only do not have access to the meeting presentations',
  // Add Attendee
  'userAddAttendeeComponent.regularAttendee.label': 'Attendees',
  'userAddAttendeeComponent.registerAs.sponsorText':
    'Click here for Sponsor Registration',
  'userAddAttendeeComponent.registerAs.myEvents': 'My Events',
  'userAddAttendeeComponent.regularAttendeeForSponsorAttendee.label':
    ' Regular Sponsor Attendee',
  'userAddAttendeeComponent.regularAttendeeForVendorAttendee.label':
    'Regular Vendor Attendee',
  'userAddAttendeeComponent.popup': `You can modify your registration up to 14 days before the event start date. Any changes within 14 days of the event require PBSS approval. Requests for modifications must be submitted to PBSS at `,
  'userAddAttendeeComponent.popup1': 'no later than 4 days before the event.',
  'userAddAttendeeComponent.message':
    'if you have a question or run into a problem with your registration.',

  // Ads Cart
  'userAdsCartComponent.heading': 'Ads',
  'userAdsCartComponent.subheading':
    'Get Details about event  price, schedule & more..',

  // Ads Form
  'userAdsFormComponent.heading': 'Vendor Advertisement form',
  'userAdsFormComponent.subheading':
    ' Our advertisement posts relevant to the pharmaceutical and bioscience industry.The ads are also delivered to the 4000+ members through our newsletters.',

  // Approved Ads Listing
  'userApprovedAdsListingComponent.heading': 'PBSS - Approved Ads',
  'userApprovedAdsListingComponent.subheading':
    'Our advertisements are relevant to the pharmaceutical and bioscience industry. The ads are also delivered to the 4000+ members through our newsletters.',

  // My Ads
  'userAdsComponent.contentHeading':
    'Our advertisement posts relevant to the pharmaceutical and bioscience industry.The ads are also delivered to the 4000+ members through our newsletters.',
  'userAdsComponent.heading': 'PBSS.org Ads Publish Service',
  'userAdsComponent.subheading': 'Publish your ads',

  // Assets Upload Component
  'userAssetsUploadComponent.uploadConditionText':
    '9x12 inch Letter size , Size Below 3MB PDF',
  'userAssetsUploadComponent.uploadLogoText': 'Upload logo',
  'userAssetsUploadComponent.1080px1080px': '1080px x 1080px',

  // Attendee Cart Component
  'userAttendeeCartComponent.button.goBacktoAddAttendeeText':
    'Go Back to Add Attendees',
  'userAttendeeCartComponent.confirm&PayText': 'Confirm',
  'userAttendeeCartComponent.afterPayment': 'Edit Attendee',
  'userAttendeeCartComponent.subHeading': 'Review Cart & Confirm Details',
  'userAttendeeCartComponent.subHeading.afterPayment': 'Edit Attendee Details',
  'userAttendeeCartComponent.couponCodeTitle': 'Coupon code',
  'userAttendeeCartComponent.couponCodeButtonText': 'Redeem',
  'userAttendeeCartComponent.totalAmountToBePaid': 'Total amount to be paid',

  // Chapter Navigation Component
  'userChpaterNavigaitonComponent.heading': 'Welcome to',
  'userChpaterNavigationComponent.subheading':
    'Pharmaceutical & BioScience Society International',

  // Contact Us Component
  'userContactUsComponent.heading': 'Contact Us',
  'userContactUsComponent.ThankyouText':
    'We welcome and value your inquiries and feedback!',
  'userContactUsComponent.description': `As a grassroots organization, we thrive on ideas and contributions from our members. In fact, many of the topics and speakers for our meetings have come from members’ suggestions. If you have any suggestions, requests, or questions for PBSS, including potential future topics or your interest in organizing a meeting, please contact us using the following form. Alternately, you may also email us at info@pbss.org. `,
  'userContactUsComponent.captchaText': 'Complete CAPTCHA',

  // Contact Us Form Placeholder
  'userContactUsFormComponent.name.placeholder': 'Enter full name',
  'userContactUsFormComponent.email.placeholder': 'Enter email',
  'userContactUsFormComponent.emailConfirm.placeholder': 'Confirm email',
  'userContactUsFormComponent.inquiry.placeholder':
    'Enter topics or title of your inquiry',
  'userContactUsFormComponent.selectCity.placeholder': 'Enter city',
  'userContactUsFormComponent.selectCountry.placeholder': 'Enter country',
  'userContactUsFormComponent.phoneNumber.placeholder': 'Enter phone number',
  'userContactUsFormComponent.selectAffiliation.placeholder':
    'Select affiliation',
  'userContactUsFormComponent.selectEmailSubject.placeholder':
    'Select Email Subject',
  'userContactUsFormComponent.description.placeholder':
    'Add comments or description of matter',

  // Event Sponsor Component
  'userEventSponsorComponent.subheading':
    'Select option for sponsorship Registration',
  'userEventSponsorComponent.registerAs.regularAttendeeText':
    'Register as an Attendee',
  'userEventSponsorComponent.registerAs.myEvents': 'My Events',
  'userEventSponsorComponent.deleteText': 'attendee',
  'userEventSponsorComponent.backtoSponsorOption':
    'Go Back to Sponsorship Options',
  'userEventSponsorComponent.ProceedToPay': 'Proceed To Pay',

  //test heading
  'testHeading.heading':
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi. Proin porttitor, orci nec nonummy molestie, enim est eleifend mi, non fermentum diam nisl sit amet erat. Duis semper. Duis arcu massa, scelerisque vitae, consequat in, pretium a, enim. Pellentesque congue. Ut in risus volutpat libero pharetra tempor.',

  //test heading
  'testHeading.heading':
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi. Proin porttitor, orci nec nonummy molestie, enim est eleifend mi, non fermentum diam nisl sit amet erat. Duis semper. Duis arcu massa, scelerisque vitae, consequat in, pretium a, enim. Pellentesque congue. Ut in risus volutpat libero pharetra tempor.',

  // Event Sponsor Cart
  'userEventSponsorCartComponent.backToSponsorship': 'Back to Sponsorship',

  // Event Details modal
  'userEventDetailsModal.alertMsg.expired':
    'The deadline for refunds for this event has passed. Please contact the administrator for further assistance.',

  // Approved Jobs
  'userApprovedJobsListingComponent.heading':
    'Life Science Jobs Submitted by Employers',
  'userApprovedJobsListingComponent.subtitle':
    'Work in diverse organizations across the biotechnology and pharmaceutical industries',
  'userApprovedJobsListingComponent.subheading':
    'The jobs listings are also delivered to our members through our newsletters.',
  'userApprovedJobsListingComponent.button.viewDetails': 'View Details',

  // Jobs
  'userJobsComponent.button.viewMore': 'View More Jobs',
  'userJobsComponent.searchForJobsText': 'Search for jobs',
  'userJobsComponent.locationText': 'Locations',
  'userJobsComponent.statusPlaceHolder': 'Status',
  'userJobsComponent.heading': 'Add New Job Listing',
  'userJobsComponent.subheading.part1': ` Our Jobs link service posts jobs relevant to the
  pharmaceutical and bioscience industry. The jobs are also
  delivered to the 4000+ `,
  'userJobsComponent.subheading.part2': 'members through our newsletters.',
  'userJobsComponent.uploadLogoText': 'Upload Logo',
  'userJobsComponent.paymentText':
    'First, complete the payment to proceed with the listing process.',
  'userJobsComponent.submit&CheckoutText': 'Proceed & Confirm',

  // Jobs View Details Component
  'userJobViewDetailsComponent.heading': 'Job Details',
  'userJobViewDetailsComponent.subheading':
    'vacancy details from around the world',
  'userJobViewDetailsComponent.button.applyNow': 'Apply Now',
  'userJobViewDetailsComponent.qualify&skillReq':
    'Qualifications & Skills Required',
  'userJobViewDetailsComponent.jobDescriptionText': 'Description :',
  'userJobViewDetailsComponent.jobPositionText': 'Position :',
  'userJobViewDetailsComponent.newHeading': 'PBSS.org Jobs Link Service',
  'userJobViewDetailsComponent.newSubHeading':
    'Vacancy details from around the world',

  //spnsor perks
  'userEventSponsorComponent.sponsorPerks.uploadLogo':
    'Upload Company/Sponsor Logo',
  'userEventSponsorComponent.sponsorPerks.editUploadLogo':
    'Upload Company/Sponsor Logo',
  'userEventSponsorComponent.sponsorPerks.eventSponsorButton': 'Register',
  'userEventSponsorComponent.sponsorPerks.addWaitingListButton':
    'Join Waiting List',
  'userEventSponsorComponent.sponsorPerks.eventContinueSponsorButton':
    'Continue Registration',
  'userEventSponsorComponent.sponsorPerks.removeFromCart': 'Remove From Cart',

  // UserJobsComponent
  'userJobsComponentPage.heading': 'PBSS.org Jobs Link Service',
  'userJobsComponentPage.subheading': 'vacancy Details from around the world',

  // Login
  'userLoginComponent.heading': 'Login',
  'userLoginComponent.subheading':
    'Please log into your account | Login is required for event registration',
  // Reset Password
  'userResetPasswordComponent.heading': 'Reset Password',
  'userResetPasswordComponent.subheading':
    'Please update your password | Login required to register an event',

  // My Events
  'userMyEventsComponent.heading': 'PBSS - My Events',
  'userMyEventsComponent.subheading':
    'Please review the status of upcoming events that you have signed up for.',
  'userMyEventsComponent.button.registerForNewEvents':
    'Register for New Events',

  // My Events CART
  'userMyEventsCartComponent.heading': 'PBSS - My Cart',
  'userMyEventsCartComponent.subheading':
    ' Checkout status of upcoming to be paid events that you have signed up for.',

  // PBSS Chapter Component
  'userPbssChapterComponent.heading':
    'Welcome to the Pharmaceutical & BioScience Society',

  // Workshop Agenda
  'userWorkShopAgendaComponent.heading.Bio': 'Event Description',
  'userWorkShopAgendaComponent.heading.Info': 'Event Agenda',
  'userWorkShopAgendaComponent.heading.Speaker': "Speakers' Bios",
  'userWorkShopAgendaComponent.subheading':
    'Clinical pharmacology is an integral component of drug development that plays a vital role in establishing the right target, right dose, and right population throughout the development and the life cycle management of an investigational product. ',

  // Event Form Admin Leave Popup
  'userEventFormAdminLeavePopupComponent.heading':
    'Do you want to leave the page?',
  'userEventFormAdminLeavePopupComponent.subheading':
    'The changes you made will be lost. Click on the Continue Editing button to stay on the same page, else click on Exit to leave the page',
  'userEventFormAdminLeavePopupComponent.button.continueEditing':
    'Continue Editing',
  'userEventFormAdminLeavePopupComponent.button.exit': 'Exit',
  //STEM Highlights
  'userStemHighlightsComponent.mainTitleText': 'STEM Highlights',
  // Event Info Card
  'userEventInfoCardComponent.mainTitleText': 'Event Highlights',
  'userEventInfoCardComponent.props.date': 'Sept 12 | Tuesday',
  'userEventInfoCardComponent.props.eventName':
    'Clinical Pharmacology Event | 2023',
  'userEventInfoCardComponent.props.description':
    'Clinical pharmacology is an integral component of drug development...',
  'userEventInfoCardComponent.props.location': 'Conference at SF- Bay',

  'userEventInfoCardComponent.card.title-image1':
    ' Clinical Pharmacology Across the Drug Development Continuum | 2022',
  'userEventInfoCardComponent.card.date-image1': 'Sept 06 | Tuesday',
  'userEventInfoCardComponent.card.description-image1':
    'Clinical pharmacology is an integral component of drug development that plays a vital role in establishing the right target, right dose, and right population throughout the development and the life cycle management of an investigational product. This workshop will provide an overview of how clinical pharmacology contributes to key decision‑making milestones throughout the drug development process, from establishing a first-in-human dose to crafting the package insert for filing. The fundamental principles and strategy for conducting clinical pharmacology studies as well as modeling and simulation approaches to accelerate the drug development process will be covered. Real-world applications will illustrate contemporary approaches to developing and executing a clinical pharmacology program. This workshop is suitable for novice drug developers, experts from other disciplines interested in learning more about the field, and clinical pharmacologists looking to refresh their knowledge and discuss emerging topics.',
  'userEventInfoCardComponent.card.location-image1': 'Conference at San-Diego',

  'userEventInfoCardComponent.card.title-image2':
    'Taking the leap: Everything you want to know about starting a Biotech | 2023',
  'userEventInfoCardComponent.card.date-image2': 'May 25 | Thursday',
  'userEventInfoCardComponent.card.description-image2':
    'Have you ever wondered what it takes to start a biotech?  Are you planning your next career move to a small startup or stealth company and want to understand the benefits/risks?  Or are you curious to get into the mind of a passionate entrepreneur?  Join us for an interactive hour long “nourishing greatness” session where you can learn more about this fascinating topic and hear from accomplished experts.  ',
  'userEventInfoCardComponent.card.location-image2': 'Conference at Korea',

  'userEventInfoCardComponent.card.title-image3':
    'Oncology Clinical Dose Optimization in Light of FDA Project Optimus | 2024',
  'userEventInfoCardComponent.card.date-image3': 'Feb 12 | Monday',
  'userEventInfoCardComponent.card.description-image3':
    'Historically, dose selection in oncology was driven by the MTD paradigm. This traditional approach was partly driven by the few available therapies in oncology, and the need to bring effective drugs to the market as soon as possible. Now that the field of oncology drug development has matured and cancer patients are living longer than ever on chronic therapies, we as drug developers need to meet these new challenges by shifting our approach to oncology dose selection to a new paradigm. This one-day symposium will discuss the new regulatory expectations to oncology dose selection, various approaches to optimize oncology dose selection from a study design as well as statistical perspective, different clinical pharmacology considerations and related analysis to support dose selection, and evaluations using patient-reported outcomes.',
  'userEventInfoCardComponent.card.location-image3':
    'Conference at  PBSS SF-Bay',

  // Register
  'userRegisterComponent.heading': 'Join PBSS',
  'userRegisterComponent.subheading':
    'Pharmaceutical & BioScience Society International',
  'userRegisterComponent.description':
    'To join PBSS, please fill out the form below',
  'userRegisterComponent.disclaimer':
    'To protect the privacy of our members, membership information is strictly used for PBSS operations only, and is not shared outside of PBSS.',
  'userRegisterComponent.disclaimer2':
    '  By becoming a member of PBSS, you will be subscribed to receive our newsletter via email.',

  // Sponsor Policy
  'userSponsorPolicyComponent.p1':
    'At least 1 day before the event, you MUST email your slides to the organizer. Keep in mind that your slot has only 10 minutes and you need to plan accordingly.',
  'userSponsorPolicyComponent.p2':
    'Complimentary lunch is provided to two representatives of the major sponsor during a workshop / symposium. If it is a seminar luncheon, the number of lunch is unlimited. Online registration is require',
  'userSponsorPolicyComponent.membershipListSharing':
    'Policy on Membership List Sharing:',
  'userSponsorPolicyComponent.membershipListSharing.description':
    'In order to protect the privacy of our members, our membership information, such as membership list and meeting attendees list, is strictly used for our internal business only and can not be shared with any party externally.',
  'userSponsorPolicyComponent.vendorShowCard.title':
    'Policy on Vendor Speakers for "Non-commercial" Presentations:',
  'userSponsorPolicyComponent.vendorShowCard.description': `We often receive requests from vendors for providing a speaker for one of our scientific meetings. Unfortunately, in order to avoid any impression of commercial promotion or vendor favoritism, our policy generally prohibits use of vendor speakers, except in rare cases where a vendor holds a unique technology that we are interested in hearing about. One compromise solution is that the vendor can recommend a client scientist who is an expert on the vendor's technology.`,
  'userSponsorPolicyComponent.vendorShowCard.title2':
    'Policies on Vendor Reps attending the vendor show and workshop:',
  'userSponsorPolicyComponent.vendorShowCard.li1': `Each vendor show sponsor can send unlimited number of reps for attending the booth (but not for attending the workshop). If the additional reps needs lunch, the lunch can be purchased online in several ways: 1) the rep who is registering for the vendor show can add more reps either at the point of paying for the vendor show or at a later point by re-logging in; 2) the additional rep can log in on his/her own to purchase the lunch..`,
  'userSponsorPolicyComponent.vendorShowCard.li2': `One rep per vendor can register for the workshop at just the cost of the handout when registering for the vendor show.`,
  'userSponsorPolicyComponent.vendorShowCard.li3': `If an additional rep / employee wants to attend the workshop, he/she needs to register as a regular attendee (workshop attendance + handout +/- lunch). If this rep does not want to pay for the workshop, he/she may sit in without registration, space permitting. However, that rep will not be able to purchase just a copy of the handout separately online or on-site. He/she can register on-site at the regular online workshop rate (workshop attendance + handout +/- lunch), as opposed to the much higher on-site rate for the regular walk-in attendees..`,
  'userSponsorPolicyComponent.vendorShowCard.li4': `The Major Sponsor gets 2 free workshop seats + 2 copies of handout + 2 lunches; No separate registration other than the Major Sponsorship payment is required.`,
  'userSponsorPolicyPage.title': 'Sponsorship Policy',
  'userSponsorPolicyPage.subtitle':
    'Information here for our sponsorship Policies',

  // Event Major Sponsor page
  'userEventMajorSponsorPage.heading': 'Major Sponsorship',
  'userEventMajorSponsorPage.subheading':
    'You can become a sponsor by clicking on the ‘Register as a Sponsor’ button from the event details.',
  'userEventMajorSponsorPage.description':
    'For a seminar luncheon or a half-day session of a full-day event, we allow only one Major Sponsorship slot.',
  'userEventMajorSponsorCard.heading': 'Major Sponsorship',
  'userEventMajorSponsorCard.regularPriceText': 'Regular Price',
  'userEventMajorSponsorCard.benefitsText': 'Benefits:',
  'userEventMajorSponsorCard.bodyText': `As the sole Major Sponsor of an event or session, you will get great exposure through:`,
  'userEventMajorSponsorCard.li1': `Distributing your product brochures to the attendee's seats`,
  'userEventMajorSponsorCard.li2': `A 10-minute podium presentation opportunity immediately before the
  keynote seminar`,
  'userEventMajorSponsorCard.li3': `A 6 display table at the most prominent location`,
  'userEventMajorSponsorCard.afterListText':
    ' All sponsorship payments MUST be made through our website. We do not issue purchasing orders.',
  'userEventMajorSponsorCard.buttonText.signupforsponsorship':
    'Register for Sponsorship',
  'userEventMajorSponsorCard.buttonText.contactourvendorliaison':
    'Contact Our Vendor Liaison',
  'userEventMajorSponsorCard.buttonText.makeaspecialpayment':
    'Make a Special Payment',
  'userEventMajorSponsorCard.instructions.heading':
    'Major Sponsorship Instructions:',
  'userEventMajorSponsorCard.instructions.l1':
    'At least 1 day before the event, you MUST email your slides to the organizer. Keep in mind that your slot has only 10 minutes and you need to plan accordingly.',
  'userEventMajorSponsorCard.instructions.l2':
    'Complimentary lunch is provided to two representatives of the major sponsor during a workshop/symposium. If it is a seminar luncheon, the number of lunches is unlimited. Online registration is required.',

  // Sponsorship option
  'userSponsorshipOptionComponent.heading': 'Select Sponsorship Type',
  'userSponsorshipOptionComponent.subheading': '',
  'userSponsorshipOption.alertMessage':
    'There are no sponsorships slots available for this event! Please navigate back to the Attendee Registration page to register as a regular attendee.',
  'userNormalAttendee.alertMessage':
    ' The sponsorship registrations are now open, click on the above button to proceed. The slots for regular registrations will open soon, keep an eye on this page!',
  'userRegistrationOption.alertMessage':
    'The sponsorship registrations are now open, click on the above button to proceed.The slots for regular registrations will open soon, keep an eye on this page!',

  // Vendor Show
  'userVendorShowComponent.heading': 'Vendor Sponsorship',
  'userVendorShowComponent.subheading':
    'You can become a sponsor by clicking on the ‘Register as a Sponsor’ button from the event details',

  // Vendor Info Card
  'userVendorInfoCardComponent.p1':
    'At least 1 day before the event, you MUST email your slides to the organizer. Keep in mind that your slot has only 10 minutes and you need to plan accordingly.',
  'userVendorInfoCardComponent.p2':
    'Complimentary lunch is provided to two representatives of the major sponsor during a workshop / symposium. If it is a seminar luncheon, the number of lunch is unlimited. Online registration is require',
  'userVendorInfoCardComponent.title':
    'Lunch Option for Vendor Show Representatives:',
  'userVendorInfoCardComponent.body1.label': 'Seminar luncheon vendor show:',
  'userVendorInfoCardComponent.body1.p':
    'lunches are complimentary , but online registration is required.',
  'userVendorInfoCardComponent.body2.label': 'Workshop / symposium vendor show',
  'userVendorInfoCardComponent.body2.p':
    'lunches can be purchased during the online registration process.',
  'userVendorInfoCardComponent.vendorShowCard1.title':
    'Attending a Vendor Show:',
  'userVendorInfoCardComponent.vendorShowCard1.li1':
    'Each vendor will get a 6 display table. You can display anything appropriate on the table , as well as a poster on the wall behind the table using painters tape',
  'userVendorInfoCardComponent.vendorShowCard1.li2':
    'The Table next to the meeting room entrance is reserved for the Major Sponsor.',
  'userVendorInfoCardComponent.vendorShowCard1.li3':
    'For seminar luncheons and afternoon workshops, arrive by 11:00 AM to set up . For full-day workshops, arrive by 8:00am to set up.',
  'userVendorInfoCardComponent.vendorShowCard1.li4': `You are encouraged to bring a raffle prize to attract attendees
  (we limit one prize per vendor). If it is a seminar luncheon, the
  raffle drawing takes place at the end of the seminar, 1:15 PM | If
  it is a workshop, the drawing takes place normally right before
  the final workshop session, ~4 PM.`,
  'userVendorInfoCardComponent.vendorShowCard2.title': 'Vendor show time',
  'userVendorInfoCardComponent.vendorShowCard2.time-p1':
    'Seminar luncheon vendor shows:',
  'userVendorInfoCardComponent.vendorShowCard2.time-label1':
    '11:00 AM - 12:45 PM',
  'userVendorInfoCardComponent.vendorShowCard2.time-p2':
    'Afternoon workshop vendor shows:',
  'userVendorInfoCardComponent.vendorShowCard2.time-label2':
    '11:00 AM - 12:15 PM',
  'userVendorInfoCardComponent.vendorShowCard2.time-p3':
    'Morning vendor shows:',
  'userVendorInfoCardComponent.vendorShowCard2.time-label3':
    '8:00 - 8:45 AM, 10:30 - 10:45 AM',
  'userVendorInfoCardComponent.vendorShowbtext1-p':
    'Indicate your name, company name, our organization name and meeting date before the workshop',
  'userVendorInfoCardComponent.vendorShowbtext1-label':
    'During the workshop breaks (typically ~2:30 - 2:45, ~3:45 - 4:00 PM).',
  'userVendorInfoCardComponent.vendorShowbtext2-span':
    'If you plan to ship your vendor show display materials to the meeting in advance:',
  'userVendorInfoCardComponent.vendorShowbtext2-p':
    'You can ship to the Banquet Office of the meeting venue hotel (Indicate your name, company name, our organization name and meeting date.).',
  //payment history
  'userPaymentHistoryComponent.subheading':
    'Checkout all Transection &  Refund Details from here',
  'userPaymentHistoryComponent.heading': 'Payment History',

  // Special Payment
  'specialPaymentFormComponent.button.proceedToPayment': 'Proceed to Payment',
  'specialPaymentFormComponent.returnButton.returnToNormalForm': 'Back',

  // Seminar Luncheons
  'userSeminarLuncheonsComponent.heading': 'Seminar Luncheons',
  'userSeminarLuncheonsComponent.p1':
    'Our seminar luncheons (typically free) are for networking and learning about selected advances in drug discovery and development.',
  'userSeminarLuncheonsComponent.p2':
    'Vendor shows during seminar luncheons allow attendees to interact with vendors and win raffle prizes. Be sure to bring your business cards to enter the raffles!',
  'userSeminarLuncheonsComponent.p3':
    'For information on upcoming seminar luncheons,',

  // Workshops
  'userWorkshopsComponent.heading': 'Workshops',
  'userWorkshopsComponent.p1':
    'Our workshops cover foundational topics in drug discovery and development, at minimal cost for participants (for example, registration fees for academic attendees and those who pay out of pocket are heavily subsidized). Each workshop is essentially a short course covering fundamentals, advances, applications, and case studies for a given topic. The speakers present a well coordinated curriculum, much like teaching different chapters of textbook. Workshops are typically full-day events. Course materials (PDF slides) are provided to attendees as a handy reference.',
  'userWorkshopsComponent.p2':
    'Workshops are typically held in person, but may be held virtually via Zoom. In-person workshops may also be webcast for attendees who are not local to the event.',
  'userWorkshopsComponent.p3': 'For information on upcoming workshops,',

  // Symposia
  'userSymposiaComponent.heading': 'Symposia',
  'userSymposiaComponent.p1':
    'Our symposia are like a conference session, with individual seminars covering the latest advances in an area of drug discovery and development. They are either held in-person or virtually via Zoom. The in-person symposia may also be webcast to attendees who are not local.',
  'userSymposiaComponent.p2':
    'Each symposium is a half-day (afternoon) or a full day.',
  'userSymposiaComponent.p2': 'For information on the upcoming symposia,',

  //Webinar
  'userWebinarsComponent.heading': 'Webinars',
  'userWebinarsComponent.p1':
    'Webinars typically involve one or two online presentations on a given topic and typically last 1-2 hours. ',
  'userWebinarsComponent.p2': `For information on the upcoming webinars,`,

  // Dinner Seminar
  'userDinnerSeminarComponent.heading': 'Dinner Seminars',
  'userDinnerSeminarComponent.p1':
    'Our dinner seminars are held on a less frequent basis and may include several seminars with related topics. Vendor shows are also held during the dinner seminars.',
  'userDinnerSeminarComponent.p2':
    'For information on the upcoming dinner seminars,',

  //Validation
  //only Alphabet Allow-
  'common.validation.onlyAlphabet': `#{name} only allows alphabets and it should be less than #{maxLength} characters!`,

  // Sponsorship Info
  // Vendor Show Exhibition
  'userSponsorshipInfo.heading': '#{text} Sponsorship Info',
  'userSponsorshipInfo.content.vendorShow.h1': 'Vendor Show Exhibition:',
  'userSponsorshipInfo.content.p1':
    'Participation in an in-person PBSS event as #{text}.',
  'userSponsorshipInfo.content.p2': 'Your registration fee includes:',
  'userSponsorshipInfo.content.vendorShow.li1':
    'Vendor show tabletop space where you may display your company materials through the event.',
  'userSponsorshipInfo.content.vendorShow.li2':
    '2 attendees for the workshop at a subsidized price.',
  'userSponsorshipInfo.content.vendorShow.li3':
    'PDF Slide Deck of the presentations if the event is a workshop.',
  'userSponsorshipInfo.content.vendorShow.li4':
    'Participation in a Raffle (you collect business cards from our attendees and participate in a drawing along with other vendors to giveaway any prize of your choice to the attendee).',
  'userSponsorshipInfo.content.contactUs': 'For further details please contact',
  // Happy Hour Sponsorship
  'userSponsorshipInfo.content.happyHour.h1': 'Happy Hour Sponsorship:',
  'userSponsorshipInfo.content.happyHour.li1':
    'Vendor show tabletop space where you may display your company materials through the event.',
  'userSponsorshipInfo.content.happyHour.li2':
    'PDF Slide Deck of the presentations if the event is a workshop.',
  'userSponsorshipInfo.content.happyHour.li3':
    '2 attendees for the workshop at a subsidized price.',
  'userSponsorshipInfo.content.happyHour.li4':
    'Participation in a Raffle (you collect business cards from our attendees and participate in a drawing along with other vendors to giveaway any prize of your choice to the attendee).',
  'userSponsorshipInfo.content.happyHour.li5':
    'Your sponsorship is acknowledged in the meeting agenda.',
  'userSponsorshipInfo.content.happyHour.li6':
    'A two-minute informal oral advert/blurb about your company and its offerings to our attendees during the Happy Hour.',
  // Lunch Sponsorship
  'userSponsorshipInfo.content.lunchSponsorship.h1': 'Lunch Sponsorship:',
  'userSponsorshipInfo.content.lunchSponsorship.li1':
    'Vendor show tabletop space where you may display your company materials through the event.',
  'userSponsorshipInfo.content.lunchSponsorship.li2':
    'PDF Slide Deck of the presentations if the event is a workshop.',
  'userSponsorshipInfo.content.lunchSponsorship.li3':
    '2 attendees for the workshop at a subsidized price.',
  'userSponsorshipInfo.content.lunchSponsorship.li4':
    'Participation in a Raffle (you collect business cards from our attendees and participate in a drawing along with other vendors to giveaway any prize of your choice to the attendee).',
  'userSponsorshipInfo.content.lunchSponsorship.li5':
    'Two free lunches for your representatives.',
  'userSponsorshipInfo.content.lunchSponsorship.li6':
    'Your sponsorship is acknowledged in the meeting agenda.',
  'userSponsorshipInfo.content.lunchSponsorship.li7':
    'A two-minute informal oral advert/blurb about your company and its offerings to our attendees during lunch.',
  'userSponsorshipInfo.content.lunchSponsorship.li8':
    'Flyer distribution to advertise your company’s offerings on the lunch tables before attendees come in for lunch.',
  // Major Sponsorship
  'userSponsorshipInfo.content.major.h1': 'Major Sponsorship:',
  'userSponsorshipInfo.content.major.li1':
    'Exclusive vendor show tabletop space next to the workshop/symposium entrance where you may display your company materials through the event.',
  'userSponsorshipInfo.content.major.li2':
    '2 free workshop or symposium seats.',
  'userSponsorshipInfo.content.major.li3':
    'PDF Slide Deck of the presentations if the event is a workshop.',
  'userSponsorshipInfo.content.major.li4':
    'Participation in a Raffle (you collect business cards from our attendees and participate in a drawing along with other vendors to give away any prize of your choice to the attendee).',
  'userSponsorshipInfo.content.major.li5':
    'Two free lunches for your representatives.',
  'userSponsorshipInfo.content.major.li6':
    'A ten-minute oral podium slide presentation about your company and its offerings during the workshop/symposium.',
  'userSponsorshipInfo.content.major.li7':
    'Company flyer that advertises your company’s offerings will be emailed to the meeting attendees and will also be included in the workshop slide deck for all attendees.',
  'userSponsorshipInfo.content.major.li8':
    'Dinner invitation for 2 representatives to the post-meeting dinner for the organizers/speakers/sponsors.',
  'userSponsorshipInfo.content.major.li9':
    'You will receive the meeting attendees’ names and affiliations (their emails may be provided if they opted in during the registration process).',
  // Raffle Rules
  'userSponsorshipInfo.content.raffleRules.h1': 'Raffle Rules:',
  'userSponsorshipInfo.content.raffleRules.li1':
    'The raffle typically takes place at the end of the last break/vendor show session and before the final presentation session.',
  'userSponsorshipInfo.content.raffleRules.li2':
    'To minimize the raffle time, each participating vendor must pre-draw 3 winners’ names (one as the winner and the other two as backups), so that the winner’s name can be quickly announced.',
  'userSponsorshipInfo.content.raffleRules.li3':
    'Each vendor rep has 30 seconds to announce their name, company name and offerings.',
  'userSponsorshipInfo.content.raffleRules.li4':
    'Each vendor must give the prize(s) to one single winner.',
  'attendee-screen-extra-message':
    'Attendee details saved to My Cart, you can either add additional attendees or proceed to next step to complete the registration.',
  'attendee-cart-screen-extra-message':
    'Confirm your registration to secure your seat in the event.',
  'userSponsorshipInfo.content.raffleRules.li5':
    'A person must be present to win.',
  'userSponsorshipInfo.content.raffleRules.contactUs': `For further details, please visit the individual PBSS chapters' pages
    via the PBSS home page, or email info@pbss.org.`,
};

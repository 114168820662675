import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Row} from 'antd';
import {getAllApprovedAds, getAllApprovedJobs} from '../../../Store/Actions';
import {
  DEFAULT_CONTAINER_HEIGHT,
  JobsAndAdsTabs,
  cssClassText,
  getLocalStorageUserInfo,
  truncateTextToWords,
} from '../../../Util/commonUtility';
import view_more_svg from '../../../Assets/view-more-right-arrow.svg';
import {ApplicationString} from '../../../Localization/Locales/en';
import './style.css';
import TabFilter from '../UserTabFilter';

const CommonWantToSeeAdsListing = ({
  usedForChapPage,
  containerHeight = DEFAULT_CONTAINER_HEIGHT,
  usedForRegister,
  isVisible = true,
  tabKey = 'ads',
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tabCommonKey, setTabCommonKey] = useState();

  const adsData = useSelector(({ads}) => ads?.getApprovedAds?.data?.ads);
  const jobsData = useSelector(({jobs}) => jobs?.getApprovedJobs?.data?.jobs);

  const [shouldRender, setShouldRender] = useState(false);
  const [allAdsData, setAllAdsData] = useState([]);
  const [allJobsData, setAllJobsData] = useState([]);
  const [isHovered, setIsHovered] = useState(true);

  const isOverflowing = (data) => data?.length > 6;
  const isAdsVisible = (!shouldRender ? tabCommonKey : tabKey) === 'ads';

  const handleWindowResize = () => {
    setShouldRender(window.innerWidth > 1000);
  };

  const handleMouseOver = () => setIsHovered(false);
  const handleMouseOut = () => setIsHovered(true);

  const submitAdHandler = () => {
    const userInfo = getLocalStorageUserInfo();
    navigate(userInfo ? '/ads' : '/login');
  };

  const submitJobHandler = () => {
    const userInfo = getLocalStorageUserInfo();
    navigate(userInfo ? '/jobs' : '/login');
  };

  const renderCardList = (data) =>
    data.map((item, i) => {
      const isLastItem =
        i === selectedData.length - 1 && !shouldRender && isAdsVisible;
      const attachmentUrl = item?.attachment_url || '';
      const title = isAdsVisible ? item?.vendor : item?.employer || '';
      return (
        <div
          key={i}
          className={`${
            isLastItem && 'margin20'
          } want-to-see-your-ads-listing-card`}>
          <span>
            <a
              id={`ads-link-${i}`}
              href={attachmentUrl}
              target='_blank'
              rel='noopener noreferrer'>
              {title}
            </a>{' '}
            {tabCommonKey === 'ads' && tabKey === 'ads' ? (
              <span>{truncateTextToWords(item?.description || '', 20)}</span>
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: truncateTextToWords(item?.description || '', 20),
                }}
              />
            )}
          </span>
        </div>
      );
    });

  useEffect(() => {
    if (adsData || jobsData) {
      setAllAdsData(adsData || []);
      setAllJobsData(jobsData || []);
    } else {
      dispatch(getAllApprovedJobs());
      dispatch(getAllApprovedAds());
    }
  }, [adsData, jobsData, dispatch]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const selectedData = isAdsVisible ? allAdsData : allJobsData;
  const isOverflowingContent = isOverflowing(selectedData);

  const containerClassName = [
    usedForRegister
      ? 'want-to-see-your-ads-container for-register'
      : 'want-to-see-your-ads-container',
    usedForChapPage && 'want-to-see-your-ads-container-margin-for-chapPage',
    !shouldRender && 'tab-view-mobile',
  ]
    .filter(Boolean)
    .join(' ');

  const listingContainerClassName = [
    usedForRegister
      ? 'want-to-see-your-ads-listing-container register'
      : 'want-to-see-your-ads-listing-container',
    !isOverflowingContent && 'scrolling-none',
    isAdsVisible ? '' : 'margin-none',
    !shouldRender && 'tab-view-mobile',
  ]
    .filter(Boolean)
    .join(' ');

  const shouldShowScrollingContent =
    isOverflowingContent && shouldRender && isHovered && isVisible;
  const containerPosition =
    isOverflowingContent && isVisible ? 'absolute' : 'relative';

  return (
    <>
      {!shouldRender && (
        <div className='align-right  padding-top20'>
          <TabFilter setTabKey={setTabCommonKey} tabs={JobsAndAdsTabs} />{' '}
        </div>
      )}
      <div
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        className={containerClassName}>
        <div
          className={listingContainerClassName}
          style={{
            height: isOverflowingContent ? containerHeight : cssClassText.auto,
            maxHeight: containerHeight,
            marginBottom:
              isOverflowingContent && isVisible
                ? cssClassText.pixel32
                : cssClassText.pixel0,
          }}>
          {shouldShowScrollingContent ? (
            <div
              className={`marquee--inner ${
                usedForRegister && 'register-width'
              }`}>
              <span>{renderCardList(selectedData)}</span>
              <span>{renderCardList(selectedData)}</span>
            </div>
          ) : (
            renderCardList(selectedData)
          )}
        </div>
        {isAdsVisible && (
          <Row
            className='want-to-see-your-ads-buttons-container'
            style={{
              position: containerPosition,
            }}>
            <button
              id='submit-ads-button'
              className='view-more-svg-button'
              onClick={submitAdHandler}>
              {ApplicationString['adsApprovedListComponent.button']}
              <img
                src={view_more_svg}
                alt='view_more_svg'
                className='view-more-svg'
              />
            </button>
          </Row>
        )}
        {!isAdsVisible && (
          <Row
            className='want-to-see-your-ads-buttons-container'
            style={{
              position: containerPosition,
            }}>
            <button
              id='submit-ads-button'
              className='view-more-svg-button'
              onClick={submitJobHandler}>
              {ApplicationString['jobsApprovedListComponent.button']}
              <img
                src={view_more_svg}
                alt='view_more_svg'
                className='view-more-svg'
              />
            </button>
          </Row>
        )}
      </div>
    </>
  );
};

export default CommonWantToSeeAdsListing;

import {
  DELETE_COMMITTEE_MEMBERS_BY_ID,
  GET_ALL_COMMITTEE_MEMBERS,
  GET_COMMITTEE_MEMBERS_BY_ID,
  POST_COMMITTEE_MEMBERS,
  UPDATE_COMMITTEE_MEMBERS_BY_ID,
} from '../../Constant/ActionTypes';

const initialState = {
  allCommitteeMembers: false,
  getCommitteeMemberData: false,
  isCreateCommitteeMember: false,
  isUpdateCommitteeMember: false,
};

const CommitteeMemberReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COMMITTEE_MEMBERS:
      return {
        ...state,
        allCommitteeMembers: action.payload,
        isCreateCommitteeMember: false,
        isUpdateCommitteeMember: false,
      };
    case UPDATE_COMMITTEE_MEMBERS_BY_ID:
      return {
        ...state,
        allCommitteeMembers: false,
        isUpdateCommitteeMember: true,
      };
    case GET_COMMITTEE_MEMBERS_BY_ID:
      return {
        ...state,
        getCommitteeMemberData: action.payload,
      };
    case POST_COMMITTEE_MEMBERS:
      return {
        ...state,
        allCommitteeMembers: false,
        isCreateCommitteeMember: true,
      };
    case DELETE_COMMITTEE_MEMBERS_BY_ID:
      return {
        ...state,
        allCommitteeMembers: false,
      };
    default:
      return state;
  }
};

export default CommitteeMemberReducer;

import React, {useEffect, useState} from 'react';

import {Layout} from 'antd';
import './adminLayout.scss';
import SideBarMenu from '../../CommonComponents/SideBar/sidebar';
import AppInfoView from '../AppInfoView/appInfoView';
import TableHeading from '../CommonHeadingTable';
import {useLocation} from 'react-router-dom';
import {capitalizeWords, checkUrl} from '../../../Util/commonUtility';
import {useSelector} from 'react-redux';
const {Content} = Layout;

const AdminLayout = (props) => {
  const location = useLocation();
  const {sideBarState} = useSelector(({chapters}) => chapters);
  const {defaultEventNameState} = useSelector(({chapters}) => chapters);
  const {emailTemplateTypeValue} = useSelector(({emailCodes}) => emailCodes);
  const [isEventTableHeading, setIsEventTableHeading] = useState('');

  useEffect(() => {
    const url = location.pathname.replace('/admin/', '');
    setIsEventTableHeading(
      checkUrl(url, defaultEventNameState, emailTemplateTypeValue),
    );
  }, [location.pathname, defaultEventNameState, emailTemplateTypeValue]);

  return (
    <React.Fragment>
      <Layout prefixCls='side-bar-layout' className='layout'>
        <SideBarMenu />
        <Layout style={{background: 'none'}}>
          <Content
            prefixCls='admin-layout'
            className={`site-layout-content ${
              sideBarState ? 'small-side-bar' : ''
            }`}>
            <TableHeading
              title={capitalizeWords(location.pathname.replace('/admin/', ''))}
              subText={isEventTableHeading ?? ''}
            />
            {props.children}
            <AppInfoView />
          </Content>
        </Layout>
      </Layout>
    </React.Fragment>
  );
};
export default AdminLayout;

import React, {useEffect, useState} from 'react';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  InputNumber,
  Upload,
  Tooltip,
  message,
} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import {
  NoEmptySpaceInput,
  disablePrevDatesHandler,
  NumberWithDecimalValidation,
  urlInputValidator,
  validateMonthsInput,
  removeHttpsFromUrl,
  UploadFileMaxSize,
} from '../../../../Util/commonUtility';
import {ApplicationString} from '../../../../Localization/Locales/en';
import dayjs from 'dayjs';
import {DeleteOutlined, UploadOutlined} from '@ant-design/icons';
import CommonEditor from '../../../CommonComponents/TextEditor';
import {applicationConfig} from './../../../../Util/config';
import {FETCH_ERROR} from '../../../../Constant/ActionTypes';
import {useDispatch} from 'react-redux';

const JobsFormComponent = (props) => {
  const {postJobs, EditData, updateJobs, fetchJobsById, jobFeesOptions} = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submit, setSubmit] = useState(false);
  const [editorDescState, setEditorDescState] = useState(`<p></p>`);
  const [editorReqState, setEditorReqState] = useState(`<p></p>`);
  const [validFileList, setValidFileList] = useState([]);
  const [allLogoIds, setAllLogoIds] = useState([]);
  const [logoIds, setLogoIds] = useState([]);
  const [submitLabel, setSubmitLabel] = useState(
    ApplicationString['common.button.submitForm'],
  );

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const uploadPropsLogos = {
    name: 'file',
    accept: 'image/*, application/pdf',
    multiple: true,
    maxCount: 1,
  };

  useEffect(() => {
    if (id) {
      fetchJobsById(id);
      setSubmitLabel(ApplicationString['common.button.updateForm']);
    }
  }, []);

  const filesFormats = ['image/png', 'image/jpeg', 'image/jpg'];

  const uploadProps = {
    name: 'file',
    multiple: true,
    maxCount: 10,
    // listType: 'picture',
    beforeUpload: (file) => {
      const isRightFormat = filesFormats.includes(file.type);
      if (!isRightFormat) {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Accepted file formats: JPG and PNG only',
        });
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < UploadFileMaxSize;
      if (!isLt2M) {
        dispatch({
          type: FETCH_ERROR,
          payload: `File must smaller than ${UploadFileMaxSize}MB.`,
        });
        return false;
      }
      if (file?.type?.includes('image')) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            if (img.width >= 200 && img.height >= 200) {
              setValidFileList((prev) => [file]);
              resolve(isRightFormat && isLt2M);
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: 'Image size must be greater than 200x200 pixels.',
              });
              setValidFileList((prev) =>
                prev.filter((f) => f.uid !== file.uid),
              );
              reject();
            }
          };
          img.onerror = reject;
        });
      } else {
        setValidFileList((prev) => [file]);
        return isRightFormat && isLt2M;
      }
    },
  };

  const removeFileHandler = (file) => {
    setValidFileList((prevFileList) => {
      return prevFileList.filter((item) => item?.uid !== file?.uid);
    });
  };
  useEffect(() => {
    if (id) {
      const findJobFeesId = jobFeesOptions?.find(
        (obj) => obj?.details === EditData?.job_fee_note,
      )?.id;

      if (EditData) {
        form.setFieldsValue({
          ...EditData,
          start_date: EditData.start_date ? dayjs(EditData.start_date) : null,
          end_date: EditData.end_date ? dayjs(EditData.end_date) : null,
          job_fee_id: findJobFeesId,
          attachment_url: EditData?.attachment_url,
        });
        setEditorDescState(EditData.description);
        setEditorReqState(EditData.requirement);
      }
    }
  }, [EditData]);

  useEffect(() => {
    EditData?.logo_ids?.length > 0
      ? setValidFileList((prev) => [
          {
            uid: EditData?.logo_ids[0]?.id,
            name: EditData?.logo_ids[0]?.name,
            status: 'done',
            url: `${applicationConfig.UPLOAD_URL}/${EditData?.logo_ids[0]?.name}`,
          },
        ])
      : setValidFileList([]);
  }, [EditData]);

  // useEffect(() => {
  //   if (id) {
  //     if (EditData) {
  //       form.setFieldsValue({
  //         ...EditData,
  //         start_date: EditData.start_date ? dayjs(EditData.start_date) : null,
  //         end_date: EditData.end_date ? dayjs(EditData.end_date) : null,
  //         logos: EditData.logo_ids
  //           ? [
  //               EditData.logo_ids.map((item) => ({
  //                 uid: item.id,
  //                 name: item.name,
  //                 status: 'done',
  //                 url: `${applicationConfig.UPLOAD_URL}/${item.name}`,
  //               })),
  //             ]
  //           : [],
  //       });
  //       setEditorDescState(EditData.description);
  //       setEditorReqState(EditData.requirement);
  //     }
  //   }
  // }, [EditData]);

  // useEffect(() => {
  //   const allLogoIds =
  //     EditData?.logo_ids?.length > 0
  //       ? JSON.stringify(EditData.logo_ids.map((item) => item.id))
  //       : [];
  //   setLogoIds(allLogoIds);
  //   setAllLogoIds(EditData?.logo_ids ?? []);
  // }, [EditData]);

  const onFinish = (values) => {
    const addHttpsToAttachmentUrl = `${values?.attachment_url}`;
    // const data = new FormData();
    // const file_logo = values.logos
    //   ? values.logos.map((item) => {
    //       data.append('logos', item.originFileObj);
    //     })
    //   : null;
    // data.append('position', values.position ? values.position : '');
    // data.append('start_date', values.start_date ? values.start_date : '');
    // data.append('employer', values.employer ? values.employer : '');
    // data.append('description', editorDescState);
    // data.append('location', values.location ? values.location : '');
    // data.append('requirement', editorReqState);
    // data.append('contact_info', values.contact_info ? values.contact_info : '');
    // data.append('end_date', values.end_date ? values.end_date : '');
    // data.append(
    //   'attachment_url',
    //   values.attachment_url ? values.attachment_url : '',
    // );
    // values?.job_fee && data.append('job_fee', parseInt(values.job_fee));
    // data.append('job_fee_note', values.job_fee_note ? values.job_fee_note : '');
    const jobsData = {
      ...values,
      id: EditData?.id,
      attachment_url: addHttpsToAttachmentUrl,
      // logo_ids: logoIds,

      start_date: dayjs(values?.start_date).format('YYYY-MM-DD'),
      end_date: dayjs(values?.end_date).format('YYYY-MM-DD'),
    };
    const data = new FormData();
    Object.entries(jobsData).forEach(([key, value, index]) => {
      if (key === 'logos') {
        // value?.length
        //   ? value.map((item) => {
        //       data.append(key, item.originFileObj ? item.originFileObj : null);
        //     })
        //   : [];
        if (EditData) {
          EditData?.logo_ids?.length > 0
            ? EditData?.logo_ids[0]?.id === validFileList[0]?.uid
              ? data.append('logo_ids', `[${EditData?.logo_ids[0]?.id}]`)
              : data.append('logos', validFileList[0])
            : data.append(
                'logos',
                validFileList[0]?.originFileObj || validFileList[0],
              );
        } else {
          data.append(
            'logos',
            validFileList[0]?.originFileObj || validFileList[0],
          );
        }
      } else {
        data.append(key, value ? value : '');
      }
    });
    if (id) {
      data.set('job_fee_id', `${data.get('job_fee_id')}`);
      updateJobs(data, EditData.id, form);
      // form.resetFields();
    } else {
      postJobs(data, form);
      // form.resetFields();
    }
    setSubmit(true);
  };

  const {id} = useParams();
  const onFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };
  const onReset = () => {
    form.resetFields();
    setEditorDescState(`<p></p>`);
    setEditorReqState(`<p></p>`);
    setSubmit(false);
  };

  const backToJobs = () => {
    navigate('/admin/jobs');
    setValidFileList([]);
  };
  const beforeUploadLogos = (file) => {
    const reader = new FileReader();
    const fileSize = file.size / 1024 / 1024;
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        if (width !== 250 && height !== 250) {
          message.error('Image must be 250px x 250px!');
          return Upload.LIST_IGNORE;
        }
      };
      if (fileSize > 2) {
        message.error('File size must not exceed 2MB!');
        return Upload.LIST_IGNORE;
      }
      return true;
    };

    reader.readAsDataURL(file);
  };
  const beforeUploadBanner = (file) => {
    const reader = new FileReader();
    const fileSize = file.size / 1024;

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        if (width !== 1080 && height !== 1080) {
          message.error('Image must be 1080px x 1080px!');
          return Upload.LIST_IGNORE;
        }
      };
      if (fileSize > 2) {
        message.error('File size must not exceed 2MB!');
        return Upload.LIST_IGNORE;
      }
      return true;
    };

    reader.readAsDataURL(file);
  };

  const handleDeleteLogo = (logoId) => {
    const updatedLogoIds = allLogoIds?.filter((logo) => logo.id !== logoId);
    const idsArray = updatedLogoIds.map((item) => item.id);
    setLogoIds(JSON.stringify(idsArray));
    setAllLogoIds(updatedLogoIds);
  };

  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='basic'
          onFinish={onFinish}
          layout='vertical'
          className='two-column-form'
          initialValues={''}
          onFinishFailed={onFinishFailed}
          autoComplete='off'>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Employer'
                name='employer'
                rules={[
                  {
                    required: true,
                    message: 'Employer should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Employer'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter the employer name'
                />
              </Form.Item>
              <Form.Item
                label='Position'
                name='position'
                rules={[
                  {
                    required: true,
                    message: 'Position should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Position'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter job position'
                />
              </Form.Item>
              <Form.Item
                label='Job Type'
                rules={[
                  {required: true, message: 'Jobs type should not be empty'},
                ]}
                name='job_fee_id'>
                <Select
                  prefixCls='common-select-register'
                  placeholder={`Select job type`}
                  options={jobFeesOptions}></Select>
              </Form.Item>
              <Form.Item
                label='Start Date'
                name='start_date'
                rules={[
                  {
                    required: true,
                    message: 'Start date should not be empty',
                  },
                ]}>
                <DatePicker
                  disabledDate={(current) =>
                    disablePrevDatesHandler(current, true)
                  }
                  prefixCls='common-input-user'
                  className='genral-form width-100'
                  placeholder='Select jobs start date'
                />
              </Form.Item>

              {/* <Form.Item
                label='Job Fee'
                name='job_fee'
                rules={[{validator: NumberWithDecimalValidation}]}>
                <Input
 prefixCls='common-input-user'
                  className='genral-form'                  min={0}
                  controls={false}
                  placeholder='Enter the job fee'
                />
              </Form.Item> */}
            </div>
            <div className='form-column'>
              <Form.Item
                label='Contact Info'
                name='contact_info'
                rules={[
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Contact info'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter the contact info'
                />
              </Form.Item>
              {/* <Form.Item label='Job Fee Note' name='job_fee_note'>
                <Input
 prefixCls='common-input-user'
                  className='genral-form'                  placeholder='Enter notes about the job fee'
                />
              </Form.Item> */}
              <Form.Item
                label='Attachment URL'
                name='attachment_url'
                rules={[
                  {
                    required: true,
                    message: 'Url should not be empty',
                  },
                  {
                    type: 'url',
                    message: 'Invalid URL',
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  // addonBefore='https://'
                  className='genral-form'
                  placeholder={`Enter the attachment url for job`}
                />
              </Form.Item>
              <Form.Item
                label='Location'
                name='location'
                rules={[
                  {required: true, message: 'Location should not be empty'},
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Location'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder={`Enter the jobs location`}
                />
              </Form.Item>
              <Form.Item
                label='Months'
                name={'months'}
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                  {
                    validator: (_, value) =>
                      validateMonthsInput(value, 'Months'),
                  },
                ]}>
                <Input
                  placeholder='Enter job duration in months'
                  prefixCls='common-input-user'
                  className='genral-form'
                />
              </Form.Item>
            </div>
          </div>

          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Description'
                name='description'
                rules={[
                  {
                    required: true,
                    message: 'Description should not be empty',
                  },
                ]}>
                <CommonEditor
                  value={editorDescState}
                  onChange={setEditorDescState}
                  text={'jobs_description'}
                  placeholderValue={'job description'}
                />
              </Form.Item>
              <Form.Item
                label='Requirement'
                name='requirement'
                rules={[
                  {
                    required: true,
                    message: 'Requirement should not be empty',
                  },
                ]}>
                <CommonEditor
                  value={editorReqState}
                  onChange={setEditorReqState}
                  text={'jobs_requirements'}
                  placeholderValue={'job requirement'}
                />
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <div className='upload-admin'>
                <Form.Item
                  name='logos'
                  // valuePropName='fileList'
                  getValueFromEvent={normFile}>
                  <Upload
                    className='common-input-user genral-form height-auto'
                    {...uploadProps}
                    accept='image/*'
                    customRequest={dummyRequest}
                    onRemove={removeFileHandler}
                    fileList={validFileList}
                    showUploadList={true}>
                    <div>
                      <UploadOutlined className='upload-logo-admin' />
                      <div>
                        <span className='upload-text-logos-admin'>
                          Upload Logo
                        </span>
                      </div>
                      <div className='upload-type-admin'>
                        <span className='upload-desc-admin'>
                          Accepts only image files (png, jpg, jpeg)
                        </span>
                        <br />
                        <span className='upload-desc-admin'>
                          {`Maximum file size is ${UploadFileMaxSize}MB.`}
                        </span>
                      </div>
                    </div>
                  </Upload>
                </Form.Item>
                {/* {allLogoIds?.length ? (
                  <Form.Item label='Uploaded Logos'>
                    <div className='logo-list-container'>
                      {allLogoIds?.map((logo, index) => (
                        <div
                          key={index}
                          className='logo-container extend-logo-container'>
                          <Image
                            crossOrigin='anonymous'
                            preview={true}
                            className='sponsor-icon-email-attach'
                            src={
                              logo.name
                                ? `${applicationConfig.UPLOAD_URL}/${logo.name}`
                                : placeholder
                            }
                          />
                          <Tooltip prefixCls = 'common-pbss-tooltip' title='Click to delete this logo'>
                            <Button
                              // type='button'
                              className='delete-logo-button'
                              onClick={() => handleDeleteLogo(logo.id)}>
                              <DeleteOutlined />
                            </Button>
                          </Tooltip>
                        </div>
                      ))}
                    </div>
                  </Form.Item>
                ) : (
                  ''
                )} */}
              </div>
            </div>
          </div>
          <Form.Item>
            <div className='button-container'>
              <Button
                className='common-submit-login common-form-submit common-form-cancel'
                id='jobsComponent-button-back'
                onClick={() => {
                  backToJobs();
                }}>
                {ApplicationString['common.button.backForm']}
              </Button>
              <div className='form-flex-button'>
                <Button
                  type='primary'
                  htmlType='submit'
                  id='jobsComponent-button-submit'
                  //disabled={submit ? true : false}
                  className='common-submit-login common-form-submit'>
                  {submitLabel}
                </Button>
                <Button
                  className='common-submit-login common-form-submit common-form-cancel'
                  htmlType='button'
                  id='JobsComponent-button-reset'
                  onClick={onReset}>
                  {ApplicationString['dynamicSearch.button.reset']}
                </Button>{' '}
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>{' '}
    </div>
  );
};
export default JobsFormComponent;

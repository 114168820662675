// import ImgCrop from 'antd-img-crop';
import React, {useEffect, useState} from 'react';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Upload,
  Tooltip,
  Select,
  message,
} from 'antd';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import {DeleteOutlined, UploadOutlined} from '@ant-design/icons';
import {applicationConfig} from './../../../../Util/config';
import {
  disablePrevDatesHandler,
  NumberWithDecimalValidation,
  NoEmptySpaceInput,
  validateEmailInput,
  urlInputValidator,
  validateAdsDescriptionInput,
  validateMonthsInput,
  removeHttpsFromUrl,
  UploadFileMaxSize,
} from '../../../../Util/commonUtility';
import {ApplicationString} from '../../../../Localization/Locales/en';
import {FETCH_ERROR} from '../../../../Constant/ActionTypes';
import {useDispatch} from 'react-redux';
const AdsFormComponent = (props) => {
  const [submit, setSubmit] = useState(false);
  const [submitLabel, setSubmitLabel] = useState('Submit');
  const [form] = Form.useForm();
  let Navigate = useNavigate();
  const {postAds, EditData, updateAds, adFeesOptions, fetchAdsById} = props;
  const [logosData, setLogosData] = useState([]);
  const [logoIds, setLogoIds] = useState([]);
  const [allLogoIds, setAllLogoIds] = useState([]);
  const [validFileList, setValidFileList] = useState([]);
  const {id} = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      fetchAdsById(id);
      setSubmitLabel('Update');
    }
  }, []);

  useEffect(() => {
    if (id) {
      const findAdFeesId = adFeesOptions?.find(
        (obj) => obj?.details === EditData?.ad_fee_note,
      )?.id;
      if (EditData) {
        form.setFieldsValue({
          ...EditData,
          start_date: EditData.start_date ? dayjs(EditData.start_date) : null,
          end_date: EditData.end_date ? dayjs(EditData.end_date) : null,
          attachment_url: EditData?.attachment_url,
          // logos:
          //   EditData.logo_ids.length > 0
          //     ? [
          //         EditData.logo_ids.map((item) => ({
          //           uid: item.id,
          //           name: item.name,
          //           status: 'done',
          //           url: `${applicationConfig.UPLOAD_URL}/${item.name}`,
          //         })),
          //       ]
          //     : null,
          ad_fee_id: findAdFeesId,
          // logo_ids:
          //   EditData.logo_ids.length > 0
          //     ? [
          //         EditData.logo_ids.map((item) => (item.id)),
          //       ]
          //     : null,
          // banners:
          //   EditData.banner_ids.length > 0
          //     ? [
          //         EditData.banner_ids.map((item) => ({
          //           uid: item.id,
          //           name: item.name,
          //           status: 'done',
          //           url: `${applicationConfig.UPLOAD_URL}/${item.name}`,
          //         })),
          //       ]
          //     : null,
        });
      }
    }
  }, [EditData]);

  // const uploadPropsLogos = {
  //   name: 'fileLogos',
  //   // listType: 'picture',
  //   accept: 'image/*, application/pdf',
  //   multiple: false,
  //   maxCount: 1,
  // };
  // const uploadPropsBanners = {
  //   name: 'fileBanners',
  //   accept: 'image/*, application/pdf',
  //   multiple: true,
  // };

  const filesFormats = ['image/png', 'image/jpeg', 'image/jpg'];

  const uploadProps = {
    name: 'file',
    multiple: true,
    maxCount: 10,
    // listType: 'picture',
    beforeUpload: (file) => {
      const isRightFormat = filesFormats.includes(file.type);
      if (!isRightFormat) {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Accepted file formats: JPG and PNG only',
        });
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < UploadFileMaxSize;
      if (!isLt2M) {
        dispatch({
          type: FETCH_ERROR,
          payload: `File must smaller than ${UploadFileMaxSize}MB.`,
        });
        return false;
      }
      if (file?.type?.includes('image')) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            if (img.width >= 200 && img.height >= 200) {
              setValidFileList((prev) => [file]);
              resolve(isRightFormat && isLt2M);
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: 'Image size must be greater than 200x200 pixels.',
              });
              setValidFileList((prev) =>
                prev.filter((f) => f.uid !== file.uid),
              );
              reject();
            }
          };
          img.onerror = reject;
        });
      } else {
        setValidFileList((prev) => [file]);
        return isRightFormat && isLt2M;
      }
    },
  };

  const removeFileHandler = (file) => {
    setValidFileList((prevFileList) => {
      return prevFileList.filter((item) => item?.uid !== file?.uid);
    });
  };

  useEffect(() => {
    EditData?.logo_ids?.length > 0
      ? setValidFileList((prev) => [
          {
            uid: EditData?.logo_ids[0]?.id,
            name: EditData?.logo_ids[0]?.name,
            status: 'done',
            url: `${applicationConfig.UPLOAD_URL}/${EditData?.logo_ids[0]?.name}`,
          },
        ])
      : setValidFileList([]);
  }, [EditData]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };
  const beforeUploadLogos = (file) => {
    const reader = new FileReader();
    const fileSize = file.size / 1024;

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalWidth;
        if (width !== 250 && height !== 250) {
          message.error('Image must be 250px x 250px!');
          return Upload.LIST_IGNORE;
        }
      };
      if (fileSize > 2) {
        message.error('File size must not exceed 2MB!');
        return Upload.LIST_IGNORE;
      }
      return true;
    };

    reader.readAsDataURL(file);
  };
  // const beforeUploadBanner = (file) => {
  //   const reader = new FileReader();
  //   const fileSize = file.size / 1024;

  //   reader.onload = (e) => {
  //     const img = new Image();
  //     img.src = e.target.result;

  //     img.onload = () => {
  //       const width = img.naturalWidth;
  //       const height = img.naturalHeight;
  //       if (width !== 1080 && height !== 1080) {
  //         message.error('Image must be 1080px x 1080px!');
  //         return Upload.LIST_IGNORE;
  //       }
  //     };
  //     if (fileSize > 2) {
  //       message.error('File size must not exceed 2MB!');
  //       return Upload.LIST_IGNORE;
  //     }
  //     return true;
  //   };

  //   reader.readAsDataURL(file);
  // };

  // useEffect(() => {
  //   const allLogos =
  //     EditData?.logo_ids?.length > 0
  //       ? JSON.stringify(
  //           EditData.logo_ids.map((item) => ({
  //             uid: item.id,
  //             name: item.name,
  //             status: 'done',
  //             url: `${applicationConfig.UPLOAD_URL}/${item.name}`,
  //           })),
  //         )
  //       : [];
  //   setLogosData(allLogos);
  //   const allLogoIds =
  //     EditData?.logo_ids?.length > 0
  //       ? JSON.stringify(EditData.logo_ids.map((item) => item.id))
  //       : [];
  //   setLogoIds(allLogoIds);
  //   setAllLogoIds(EditData?.logo_ids ?? []);
  // }, [EditData]);

  const onFinish = (values) => {
    const addHttpsToAttachmentUrl = `${values?.attachment_url}`;
    const adsData = {
      ...values,
      id: EditData?.id,
      // logo_ids: logoIds,
      attachment_url: addHttpsToAttachmentUrl,
      start_date: dayjs(values?.start_date).format('YYYY-MM-DD'),
      end_date: dayjs(values?.end_date).format('YYYY-MM-DD'),
    };
    const data = new FormData();
    Object.entries(adsData).forEach(([key, value, index]) => {
      if (key === 'logos') {
        // value?.length
        //   ? value?.map((item) => {
        //       data.append(key, item.originFileObj ? item.originFileObj : null);
        //     })
        //   : [];
        if (EditData) {
          EditData?.logo_ids?.length > 0
            ? EditData?.logo_ids[0]?.id === validFileList[0]?.uid
              ? data.append('logo_ids', `[${EditData?.logo_ids[0]?.id}]`)
              : data.append('logos', validFileList[0])
            : data.append(
                'logos',
                validFileList[0]?.originFileObj || validFileList[0],
              );
        } else {
          data.append(
            'logos',
            validFileList[0]?.originFileObj || validFileList[0],
          );
        }
      } else {
        data.append(key, value ? value : '');
      }
    });
    // const file_logo = values.logos
    //   ? values.logos.map((item) => {
    //       data.append('logos', item.originFileObj);
    //     })
    //   : null;
    // // const file_banner = values.banners
    // //   ? values.banners.map((item) => {
    // //       data.append('banners', item.originFileObj);
    // //     })
    // //   : null;
    // data.append('vendor', values.vendor ? values.vendor : '');
    // data.append(
    //   'attachment_url',
    //   values.attachment_url ? values.attachment_url : '',
    // );
    // data.append('email', values.email ? values.email : '');
    // data.append('start_date', values.start_date ? values.start_date : '');
    // data.append('end_date', values.end_date ? values.end_date : '');
    // data.append('description', values.description ? values.description : '');
    // data.append('ad_fee_id', values.ad_fee_id ? parseInt(values.ad_fee_id) : undefined);
    // values?.ad_fee && data.append('ad_fee', parseInt(values.ad_fee));
    // data.append('ad_fee_note', values.ad_fee_note ? values.ad_fee_note : '');
    // data.append('logo_ids', values.logo_ids ? JSON.stringify(values.logo_ids) : '');

    if (id) {
      data.set('ad_fee_id', `${data.get('ad_fee_id')}`);
      updateAds(data, EditData.id, form);
      // form.resetFields();
    } else {
      postAds(data, form);
      // form.resetFields();
    }
    setSubmit(true);
  };
  const onFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };
  const onReset = () => {
    form.resetFields();
  };
  const backTOads = () => {
    Navigate('/admin/ads');
    setValidFileList([]);
  };

  const handleDeleteLogo = (logoId) => {
    const updatedLogoIds = allLogoIds?.filter((logo) => logo.id !== logoId);
    const idsArray = updatedLogoIds.map((item) => item.id);
    setLogoIds(JSON.stringify(idsArray));
    setAllLogoIds(updatedLogoIds);
  };

  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='basic'
          initialValues={''}
          onFinish={onFinish}
          layout='vertical'
          className='two-column-form'
          autoComplete='off'>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Vendor'
                name='vendor'
                rules={[
                  {
                    required: true,
                    message: 'Vendor should not be empty',
                  },
                  {
                    validator: (_, value) => NoEmptySpaceInput(value, 'Vendor'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter vendor name'
                />
              </Form.Item>
              <Form.Item
                label='Ad Type'
                rules={[
                  {required: true, message: 'Ad type should not be empty'},
                ]}
                name='ad_fee_id'>
                <Select
                  prefixCls='common-select-register'
                  placeholder={`Select ad type`}
                  options={adFeesOptions}></Select>
              </Form.Item>
              <Form.Item
                label='Start Date'
                name='start_date'
                rules={[
                  {
                    required: true,
                    message: 'Start date should not be empty',
                  },
                ]}>
                <DatePicker
                  disabledDate={(current) =>
                    disablePrevDatesHandler(current, true)
                  }
                  placeholder='Select ad start date'
                  prefixCls='common-input-user'
                  className='genral-form'
                />
              </Form.Item>
              {/* <Form.Item
                label='Ad Fee'
                name='ad_fee'
                rules={[{validator: NumberWithDecimalValidation}]}>
                <Input
 prefixCls='common-input-user'
                  className='genral-form'                  placeholder='Enter the ad fee'
                />
              </Form.Item>
              <Form.Item label='Ad Fee Note' name='ad_fee_note'>
                <Input
 prefixCls='common-input-user'
                  className='genral-form'                  placeholder='Enter the ad fee note'
                />
              </Form.Item> */}
            </div>
            <div className='form-column'>
              {/* <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Quantity  should not be empty!',
                  },
                  {validator: NumberWithDecimalValidation},
                ]}
                label='Quantity'
                name='qty'>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter ads quantity'
                />
              </Form.Item>
              <Form.Item
                label='Poster/Email'
                name='email'
                rules={[
                  {
                    type: 'email',
                    message: `Enter Valid Email.`,
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter Poster or email'
                />
              </Form.Item> */}

              <Form.Item
                label='Poster/Email'
                name='email'
                rules={[
                  {
                    required: true,
                    message: 'Poster/Email should not be empty',
                  },
                  {
                    validator: (_, value) => validateEmailInput(value, 'Email'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter poster or email'
                />
              </Form.Item>
              <Form.Item
                label='Attachment URL'
                name='attachment_url'
                rules={[
                  {
                    required: true,
                    message: 'Attachment url should not be empty',
                  },
                  {
                    type: 'url',
                    message: 'Invalid URL',
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  // addonBefore='https://'
                  className='genral-form'
                  placeholder={`Enter the attachment url for ad`}
                />
              </Form.Item>

              <Form.Item
                label='Months'
                name='months'
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                  {
                    validator: (_, value) =>
                      validateMonthsInput(value, 'Months'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder={`Enter ad duration in months`}
                />
              </Form.Item>

              {/* <div className='upload-banner-admin'>
              <Form.Item
                name='banners'
                valuePropName='fileList'
                getValueFromEvent={normFile}>
                <Upload
                  className='common-input-user genral-form height-auto'
                  {...uploadPropsBanners}
                  beforeUpload={beforeUploadBanner}
                  customRequest={dummyRequest}>
                  <Button
                    icon={
                      <UploadOutlined className='upload-logo-banner-admin' />
                    }
                    className='upload-button-admin'>
                    <span className='upload-text-logos-admin'>Upload Banner</span>
                  </Button>
                  <div className='upload-banner-content-admin'>
                    <span className='upload-desc-banner-admin'> 1080px</span>
                    <span className='upload-cross-banner-admin'>x</span>
                    <span className='upload-desc-banner-admin'> 1080px</span>
                  </div>
                </Upload>
              </Form.Item>
            </div> */}
            </div>
          </div>

          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Description'
                name='description'
                rules={[
                  {
                    validator: (_, value) =>
                      validateAdsDescriptionInput(value, 'Descrption'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter the description for ad'
                />
              </Form.Item>
            </div>{' '}
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <div className='upload-admin'>
                <Form.Item
                  name='logos'
                  // valuePropName='fileList'
                  getValueFromEvent={normFile}>
                  {/* <ImgCrop rotationSlider> */}
                  <Upload
                    className='common-input-user genral-form height-auto'
                    {...uploadProps}
                    accept='image/*'
                    customRequest={dummyRequest}
                    onRemove={removeFileHandler}
                    fileList={validFileList}
                    showUploadList={true}>
                    <div>
                      <UploadOutlined className='upload-logo-admin' />
                      <div>
                        <span className='upload-text-logos-admin'>
                          Upload Logo
                        </span>
                      </div>
                      <div className='upload-type-admin'>
                        <span className='upload-desc-admin'>
                          Accepts only image files (png, jpg, jpeg)
                        </span>
                        <br />
                        <span className='upload-desc-admin'>
                          {`Maximum file size is ${UploadFileMaxSize}MB.`}
                        </span>
                      </div>
                    </div>
                  </Upload>
                  {/* </ImgCrop> */}
                </Form.Item>
                {/* {allLogoIds?.length && id ? (
                  <Form.Item label='Uploaded Logos'>
                    <div className='logo-list-container'>
                      {allLogoIds?.map((logo, index) => (
                        <div
                          key={index}
                          className='logo-container extend-logo-container'>
                          <Image
                            crossOrigin='anonymous'
                            preview={true}
                            className='sponsor-icon-email-attach'
                            src={
                              logo.name
                                ? `${applicationConfig.UPLOAD_URL}/${logo.name}`
                                : placeholder
                            }
                          />
                          <Tooltip prefixCls = 'common-pbss-tooltip' title='Click to delete this logo'>
                            <Button
                              // type='button'
                              className='delete-logo-button'
                              onClick={() => handleDeleteLogo(logo.id)}>
                              <DeleteOutlined />
                            </Button>
                          </Tooltip>
                        </div>
                      ))}
                    </div>
                  </Form.Item>
                ) : (
                  ''
                )} */}
              </div>
            </div>{' '}
          </div>
          <Form.Item>
            <div className='button-container'>
              <Button
                className='common-submit-login common-form-submit common-form-cancel'
                id='adsComponent-button-back'
                onClick={() => {
                  backTOads();
                }}>
                {ApplicationString['common.button.backForm']}
              </Button>
              <div className='form-flex-button'>
                <Button
                  type='primary'
                  htmlType='submit'
                  id='adsComponent-button-submit'
                  //disabled={submit ? true : false}
                  className='common-submit-login common-form-submit'>
                  {submitLabel}
                </Button>
                <Button
                  className='common-submit-login common-form-submit common-form-cancel'
                  htmlType='button'
                  id='adsComponent-button-reset'
                  onClick={onReset}>
                  {ApplicationString['dynamicSearch.button.reset']}
                </Button>{' '}
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>{' '}
    </div>
  );
};

export default AdsFormComponent;

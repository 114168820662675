import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CouponUsersTrackComponent from '../../../Components/AdminComponents/CouponUsersTrack';
import {useParams} from 'react-router-dom';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';
import {
  DefaultPaginationValues,
  queryString,
} from '../../../Util/commonUtility';
import {getAllCouponCodeUsers} from '../../../Store/Actions';

const CouponUsersTrackContainer = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {allCouponCodeUsers} = useSelector(({couponCodes}) => couponCodes);
  const searchValues = useSelector(
    ({dynamicSearch}) => dynamicSearch.searchValues.couponUsers,
  );
  
  const [couponUsersData, setCouponUsersData] = useState();
  const [pagination, setPagination] = useState({
    page: DefaultPaginationValues.PAGE,
    pagecount: DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [sortParams, setSortParams] = useState({
    field: 'id',
    order: 'descend',
  });
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);

  useEffect(() => {
    if (allCouponCodeUsers) {
      setCouponUsersData(allCouponCodeUsers?.data?.eventRegistrations);
    } else {
      fetchCouponUsers();
    }
  }, [allCouponCodeUsers?.data?.eventRegistrations]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      fetchCouponUsers(searchValues);
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged, searchValues]);

  useEffect(() => {
    if (searchValues) {
      dispatch(setPageNumber('couponUsers', 1));
      setPagination({
        ...pagination,
        page: 1,
        pagecount: 10,
      });
      setPaginationAndSortChanged(true);
    }
  }, [searchValues]);

  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('couponUsers', pageSize));
    dispatch(setPageNumber('couponUsers', current));
    setPagination({
      ...pagination,
      page: current,
      pageCount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
  };

  const fetchCouponUsers = (searchValues) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(
      getAllCouponCodeUsers(
        pagination,
        sortField,
        sortOrder,
        searchQueryData,
        id,
      ),
    );
  };

  useEffect(() => {
    if (id) {
      fetchCouponUsers();
    }
  }, [id]);

  return (
    <div>
      <CouponUsersTrackComponent
        allCouponCodeUsers={couponUsersData}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        handleTableChange={handleTableChange}
        sortParams={sortParams}
        fetchCouponUsers={(searchValues) => {
          fetchCouponUsers(searchValues);
        }}
        searchValues={searchValues}
      />
    </div>
  );
};

export default CouponUsersTrackContainer;

import React, {useEffect, useState} from 'react';
import {
  Space,
  Table,
  Popconfirm,
  Row,
  Button,
  Tooltip,
  Divider,
  Col,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FilterOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import DynamicSearchForm from '../../CommonComponents/DynamicSearch';
import {useNavigate} from 'react-router-dom';
import {
  DescriptionItem,
  PageSizeArray,
  PaginationPosition,
  ReturnNAOnEmpty,
  companyFilters,
} from '../../../Util/commonUtility';
import {ApplicationString} from '../../../Localization/Locales/en';
import editIcon from '../../../Assets/editIcon.svg';
import deleteIcon from '../../../Assets/deleteIcon.svg';
import plus from '../../../Assets/Plus.svg';
import UpdateHistoryTableInfo from '../HistoryView';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {useSelector} from 'react-redux';
import AdminViewDetailsDrawer from '../../CommonComponents/AdminViewDetailsDrawer';
import HeaderSearchCard from '../../CommonComponents/HeaderSearchCard';
import {CREATE} from '../../../Constant/enums';

const CompaniesComponent = (props) => {
  const [expanded, setExpanded] = useState(false);
  let navigate = useNavigate();
  const {
    allCompaniesData,
    handlePaginationChange,
    paging,
    sortParams,
    handleTableChange,
    deleteCompanies,
    fetchCompanies,
    searchValues,
  } = props;
  const ps = useSelector(({pageSizes}) => pageSizes.pageSizes.companies);
  const [open, setOpen] = useState(false);
  const [openDrawerDetails, setOpenDrawerDetails] = useState(false);

  useEffect(() => {
    searchValues && setExpanded(true);
  }, [searchValues]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleSearch = (values) => {
    fetchCompanies(values);
  };

  const handleEditClick = async (id) => {
    navigate(`/admin/companies/update/${id}`);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  // Drawer functions ----------------------------------------------------
  const showDrawer = (record) => {
    setOpenDrawerDetails(record);
    setOpen(true);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const CompaniesDrawerContent = () => {
    const {
      name,
      phone_number,
      city,
      state,
      country,
      address,
      sales_tax_rate,
      postal_code,
      fax_number,
      payment_terms,
      invoice_description,
    } = openDrawerDetails || {};

    const renderList = (items) =>
      items?.length > 0
        ? items?.map((item) => <span key={item}>{`${item?.name}, `}</span>)
        : 'N/A';

    return (
      <>
        <Row>
          <Col span={12}>
            <DescriptionItem title='Name' content={name} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={12}>
            <DescriptionItem title='Phone Number' content={phone_number} />
          </Col>
          <Col span={12}>
            <DescriptionItem title='Fax Number' content={fax_number || 'N/A'} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem title='Address' content={address} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={12}>
            <DescriptionItem title='City' content={city} />
          </Col>
          <Col span={12}>
            <DescriptionItem title='State' content={state} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={12}>
            <DescriptionItem title='Country' content={country} />
          </Col>
          <Col span={12}>
            <DescriptionItem title='Postal Code' content={postal_code} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={12}>
            <DescriptionItem title='Sales Tax Rate' content={sales_tax_rate} />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title='Invoice Description'
              content={invoice_description || 'N/A'}
            />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Payment Terms'
              content={payment_terms || 'N/A'}
            />
          </Col>
        </Row>
        <Divider />
      </>
    );
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '2%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '4%',
    },
    {
      title: '',
      key: 'action',
      width: '4%',
      fixed: 'right',
      render: (_, record) => (
        <AdminActionsDropdown
          record={record}
          editBool={true}
          deleteBool={true}
          viewBool={true}
          handleView={showDrawer}
          handleDeleteClick={deleteCompanies}
          handleEditClick={handleEditClick}
          text='Company'
          resource='companies'
        />
      ),
    },
  ];
  columns.forEach((column) => {
    if (column.key !== 'action' && column.key !== 'view_details') {
      column.sorter = true;
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });
  return (
    <div className='main-container'>
      <AdminViewDetailsDrawer
        content={CompaniesDrawerContent}
        visible={open}
        text={'Companies'}
        onCloseDrawer={onCloseDrawer}
        editNavigate={`/admin/companies/update/${openDrawerDetails?.id}`}
      />
      <HeaderSearchCard
        resource='companies'
        buttonTitle={ApplicationString['companiesComponent.button.addForm']}
        toggleClick={toggleExpand}
        addClick={() => navigate('/admin/companies/add')}
        dataTestId='adminCompanies-button-addCompanies'
        permission={CREATE}
        expanded={expanded}
      />

      {expanded && (
        <DynamicSearchForm
          columns={companyFilters}
          onSearch={handleSearch}
          savedFilteredValues={searchValues}
          title={'companies'}
        />
      )}
      <div className='listing-container'>
        <Table
          bordered
          className='admin-table'
          columns={columns}
          dataSource={allCompaniesData}
          rowKey={'id'}
          scroll={{x: columns?.length * 140}}
          onChange={handleTableChange}
          // sticky={true}
          pagination={{
            onChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            current: paging.page,
            total: paging.totalCount,
            pageSize: ps || paging.pagecount,
            pageSizeOptions: PageSizeArray,
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            showTotal: (total, range) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>
    </div>
  );
};

export default CompaniesComponent;

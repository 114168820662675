import {UserOutlined} from '@ant-design/icons';
import {Button, Card, Result, Col, Form, Image, Input, Row, Space} from 'antd';
import {logo} from '../../../../Resource/images';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {resetSendMail, signIn} from '../../../../Store/Actions';
import AppInfoView from '../../../CommonComponents/AppInfoView/appInfoView';
import Link from 'antd/es/typography/Link';
import SectionLayout from '../../../CommonComponents/Layout/sectionLayout';
import './style.css';
import CommonHeroText from '../../../CommonComponents/CommonHeroText';
import {validateEmailInput} from '../../../../Util/commonUtility';
import {SEND_MAil_SUCCESS} from '../../../../Constant/ActionTypes';
const ResetSendEmailComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resetSendEmailForm] = Form.useForm();
  const {sendMailSuccess} = useSelector(({auth}) => auth);
  const onResetSendMail = (values) => {
    dispatch(resetSendMail(values));
  };
  // const redirection = (url) => {
  //   if (url === '/resetPassword') {
  //     sendMailSuccess = false;
  //   }
  //   navigate(url);
  // };
  return (
    <>
      <CommonHeroText
        heading={'Reset Password'}
        subheading='Enter your email address below and we will email you the link for resetting the password.'
      />
      <Row justify='center' align='middle' style={{minHeight: '60vh'}}>
        <Col span={24}>
          <SectionLayout>
            {!sendMailSuccess && (
              <div className='reset-pass-container'>
                <div className='reset-pass-sub-container'>
                  <Form
                    name='reset_email_form'
                    initialValues={''}
                    onFinish={onResetSendMail}
                    form={resetSendEmailForm}>
                    <div className='reset-text-container'>
                      <p className='reset-page-heading'>
                        Did you forget your password?
                      </p>
                      <p className='sub-page-heading'>
                        {` Enter email address you're using for PBSS account
                        below and we will send you a password reset link`}
                      </p>
                    </div>
                    <label className='reset-form-label-text'>
                      Email Address
                    </label>
                    <Form.Item
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: 'Email should not be empty',
                        },
                        {
                          validator: (_, value) =>
                            validateEmailInput(value, 'Email'),
                        },
                      ]}>
                      <Input
                        prefixCls='common-input-user-login w-100'
                        className='reset-input'
                        placeholder='myemail@address.com'
                      />
                    </Form.Item>
                    <Form.Item className='text-center'>
                      <Button
                        type='primary'
                        htmlType='submit'
                        prefixCls='submit-login-page'
                        className='login-button extend-login-button'>
                        Reset Password
                      </Button>
                    </Form.Item>
                    <Button onClick={() => navigate('/login')} type='text'>
                      Back to Sign In
                    </Button>
                  </Form>
                </div>
              </div>
            )}
            {sendMailSuccess && (
              <div className='send-email-success'>
                <Result
                  status='success'
                  title='Success! Password Reset Email Sent!'
                  subTitle='We have successfully sent a password reset email to the address associated with your account. Please check your inbox and follow the instructions provided to reset your password. If you do not receive the email within a few minutes, please check your spam or junk folder. If you still encounter any issues, feel free to reach out to our support team for further assistance. Thank you for choosing our services.'
                  extra={[
                    <Button
                      type='primary'
                      key='goto_signin'
                      className='goto_signin_button'
                      onClick={() => navigate('/login')}>
                      Go to Login
                    </Button>,
                    <Button
                      key='resend_mail'
                      onClick={() => {
                        dispatch({type: SEND_MAil_SUCCESS, payload: false});
                      }}
                      prefixCls='reset_back_button'>
                      Resend Email
                    </Button>,
                  ]}
                />
              </div>
            )}
          </SectionLayout>
        </Col>
      </Row>
    </>
  );
};

export default ResetSendEmailComponent;

import React, {useEffect} from 'react';
import {Button, Table} from 'antd';
import {useNavigate} from 'react-router-dom';
import {
  ReturnNAOnEmpty,
} from '../../../Util/commonUtility';
import {useSelector} from 'react-redux';
import useDebouncedCallback from '../../../hooks/useDebounce';

const CouponUsersTrackComponent = (props) => {
  let navigate = useNavigate();
  const {
    allCouponCodeUsers,
    paging,
    handleTableChange,
    handlePaginationChange,
    sortParams,
    searchValues,
  } = props;
  const pageSize = useSelector(({pageSizes}) => pageSizes.pageSizes.coupons);

  useEffect(() => {
    searchValues && setExpanded(true);
  }, [searchValues]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (_, record) => {
        const firstName = ReturnNAOnEmpty(record.attendee_first_name);
        const lastName = ReturnNAOnEmpty(record.attendee_last_name);
        return `${firstName} ${lastName}`;
      },
    },
    {
      title: 'Email',
      dataIndex: 'attendee_email',
      render: (text) => ReturnNAOnEmpty(text),
    },
  ];

  columns.forEach((column) => {
    if (column.key !== 'action') {
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });

  const debouncedHandlePaginationChange = useDebouncedCallback(
    handlePaginationChange,
    1000,
  );
  const debouncedTableChange = useDebouncedCallback(handleTableChange, 1000);

  return (
    <div className='main-container'>
      <div className='search-container' style={{gap: '10px'}}>
        <Button
          onClick={() => navigate('/admin/couponCodes')}
          id='EventTemplateSchedulerComponent-button-filterSearch'>
          Back To Coupon Codes
        </Button>
      </div>
      <div className='listing-container'>
        <Table
          columns={columns}
          dataSource={allCouponCodeUsers}
          pagination={{
            pageSize: pageSize,
            total: paging?.total,
            current: paging?.current,
            onChange: debouncedHandlePaginationChange,
          }}
          onChange={debouncedTableChange}
          rowKey='id'
          scroll={{x: true}}
        />
      </div>
    </div>
  );
};

export default CouponUsersTrackComponent;

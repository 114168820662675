import React from 'react';
import CommiteeCard from './CommiteeCard';
import './style.css';
import {ApplicationString} from '../../../Localization/Locales/en';

const StandingCommiteeListing = ({
  data,
  usedForLeadership = false,
  heading,
}) => {
  return (
    <React.Fragment>
      <div className='Commitee-listing-main'>
        {heading && (
          <div
            className={
              usedForLeadership
                ? 'Commitee-listing-heading'
                : 'Commitee-listing-sub-heading'
            }>
            <p>{heading}</p>
            <span className='sub-heading-standing-committee'>
              {
                ApplicationString[
                  'userAboutUsComponent.standingCommittee.subText'
                ]
              }
            </span>
          </div>
        )}
        <div className='Commitee-listing-container-advisors'>
          {data?.length > 0 &&
            data?.map((CommiteeItem) => {
              return (
                <CommiteeCard
                  key={CommiteeItem.name}
                  allowReadMore={
                    CommiteeItem?.details === undefined ? false : true
                  }
                  data={CommiteeItem}
                />
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default StandingCommiteeListing;

import React, {useEffect} from 'react';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import CustomCollapse from '../../CommonComponents/CommonAccordian';
import './style.css';
import {Col, Row} from 'antd';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';
import StandingCommiteeListing from '../../CommonComponents/StandingCommiteeListing';
import {ApplicationString} from '../../../Localization/Locales/en';
import {
  CommitteeDataForPBSSORGAdvisors,
  CommitteeDataForPBSSORGOperationTeam,
  CommitteeDataForPBSSORGProgramManagerTeam,
  CommitteeDataFromPBSSORG,
} from '../../CommonComponents/StandingCommiteeListing/committe';
import aboutImage from '../../../Assets/AboutUs.jpg';

const AboutUsComponent = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const LeaderShipHeading = () => {
    return (
      <>
        <p>{ApplicationString['userAboutUsComponent.standingCommittee']}</p>
      </>
    );
  };
  const DistinguishAdvisorSubHeading = () => {
    return (
      <>
        <p>
          {ApplicationString['userAboutUsComponent.standingCommittee.advisors']}
        </p>
      </>
    );
  };
  const OperationTeamSubHeading = () => {
    return (
      <>
        <p>
          {
            ApplicationString[
              'userAboutUsComponent.standingCommittee.operationTeam'
            ]
          }
        </p>
      </>
    );
  };
  const ProgramManagementSubHeading = () => {
    return (
      <>
        <p>
          {
            ApplicationString[
              'userAboutUsComponent.standingCommittee.programManagement'
            ]
          }
        </p>
      </>
    );
  };

  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRow'>
        {/* <Col span={24}>
          <SponsorOptionPageHeroText title={'About PBSS'} />
        </Col> */}
        <Col span={24}>
          <SectionLayout>
            <div className='about-us-container'>
              <label>{ApplicationString['userAboutUsComponent.heading']}</label>
              <p>{ApplicationString['userAboutUsComponent.paragraph']}</p>
            </div>
          </SectionLayout>
        </Col>
        <Col span={24}>
          <div className='commite-sub-container'>
            <SectionLayout>
              <div className='commite-sub-wapper'>
                <div className='commitee-left-section'>
                  <label>
                    {ApplicationString['userAboutUsComponent.ourMission']}
                  </label>
                  <p>
                    {
                      ApplicationString[
                        'userAboutUsComponent.ourMissionParagraph'
                      ]
                    }
                  </p>
                </div>
                <img src={aboutImage} />
              </div>{' '}
            </SectionLayout>
          </div>
        </Col>
        <Col span={24}>
          <SectionLayout>
            <div className='responsiveness-about-us'>
              <StandingCommiteeListing
                data={CommitteeDataFromPBSSORG}
                usedForLeadership={true}
                heading={LeaderShipHeading()}
              />
              <StandingCommiteeListing
                data={CommitteeDataForPBSSORGAdvisors}
                heading={DistinguishAdvisorSubHeading()}
              />

              <StandingCommiteeListing
                data={CommitteeDataForPBSSORGOperationTeam}
                heading={OperationTeamSubHeading()}
              />
              <StandingCommiteeListing
                data={CommitteeDataForPBSSORGProgramManagerTeam}
                heading={ProgramManagementSubHeading()}
              />
            </div>
          </SectionLayout>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AboutUsComponent;

import {useCallback} from 'react';
import {rolePermissions} from '../permissions';
import {CREATE, DELETE, READ, UPDATE} from '../Constant/enums';

export function usePermissions(userRole) {
  const allPermissions = [CREATE, READ, UPDATE, DELETE];

  const checkPermission = useCallback(
    (resource, permission) => {
      const role = rolePermissions[userRole];
      if (!role) return false;

      const resourcePermissions = role.permissions[resource];
      if (!resourcePermissions) return getAllModulesWithNoPermissions();

      // If a specific permission was checked
      if (permission) {
        return resourcePermissions[permission];
      } else {
        // If all permissions are false, return module name
        return getAllModulesWithNoPermissions();
      }
    },
    [userRole],
  );

  const getAllModulesWithNoPermissions = useCallback(() => {
    const role = rolePermissions[userRole];
    if (!role) return false;

    const resources = Object.keys(role.permissions);
    const modulesWithoutPermissions = resources.filter((resource) => {
      const resourcePermissions = role.permissions[resource];
      return allPermissions.every(
        (perm) => resourcePermissions[perm] === false,
      );
    });

    return modulesWithoutPermissions;
  }, [userRole]);

  return {checkPermission, getAllModulesWithNoPermissions};
}

import React, {useEffect, useState} from 'react';
import Event2Listing from '../../CommonComponents/EventListing2.0';
import PbssInMap from '../../CommonComponents/PbssInCommonMap';
import ChapterNavigation from '../ChaptersNavigation';
import SponsorListing from '../../CommonComponents/SponsorListing';
import PastEventsListing from '../../CommonComponents/PastEventsListing';
import {Col, Divider, Form, Row} from 'antd';
import EventInfoCard from '../../CommonComponents/EventHighlights';
import RecentOpeningsJobs from '../../CommonComponents/RecentOpenings/jobs';
import {sponsorDataMarquee} from '../../../Util/commonUtility';
import {ApplicationString} from '../../../Localization/Locales/en';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import {getPbssInternationalById} from '../../../Store/Actions/pbssInternational';
import {useDispatch, useSelector} from 'react-redux';

const PbssInternationalComponent = ({
  allEventSponsorLogos,
  allApprovedJobsData,
  eventData,
  handleNext,
  handlePrev,
  handleYearFilter,
  pagination,
  totalEventCount,
  handleLoadMore,
  pastEventData,
  handleChapterFilter,
  handleEventTypeFilter,
  chaptersList,
  eventTypeList,
  countLoadMoreData,
  handleChapterClick,
  selectedEventTypeValue,
  selectedChapterValue,
  selectedDateValue,
  selectedYearValue,
  dateToggle,
  dateOrderHandler,
  handlePaginationChange,
  totalPastEventsPages,
}) => {
  const visibleChapters = chaptersList?.filter((chapter) => {
    return chapter?.is_visible_on_map === true;
  });
  const {allPbssInternational} = useSelector(
    ({pbssInternational}) => pbssInternational,
  );
  const [form] = Form.useForm();
  const [editData, setEditData] = useState();
  const dispatch = useDispatch();

  const newChapterList = [
    {value: '', label: 'Clear Filter'},
    ...visibleChapters,
  ];
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (allPbssInternational) {
      setEditData(allPbssInternational?.data?.pbssInternational[0]);
    }
  }, [allPbssInternational]);

  useEffect(() => {
    fetchPbssInternationalById();
  }, []);

  const fetchPbssInternationalById = () => {
    dispatch(getPbssInternationalById());
  };

  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRow'>
        <Col span={24}>
          <ChapterNavigation
            chaptersList={chaptersList}
            handleChapterClick={handleChapterClick}
          />
        </Col>
        <Col span={24}>
          <PbssInMap
            mapSize={300}
            chaptersList={chaptersList}
            specialAnnouncementHeading={editData?.special_announcement}
          />
        </Col>
        <Col span={24}>
          <SectionLayout>
            <Divider prefixCls='pbss-divider' />
          </SectionLayout>
        </Col>
        <Col span={24}>
          <Event2Listing
            filteredEvents={eventData}
            isChapSelect={false}
            handleChapterFilter={handleChapterFilter}
            handleEventTypeFilter={handleEventTypeFilter}
            chaptersList={newChapterList}
            eventTypeList={eventTypeList}
            countLoadMoreData={countLoadMoreData}
            totalEventCount={totalEventCount}
            handleLoadMore={handleLoadMore}
            selectedChapterValue={selectedChapterValue}
            selectedEventTypeValue={selectedEventTypeValue}
            selectedDateValue={selectedDateValue}
            selectedYearValue={selectedYearValue}
            dateToggle={dateToggle}
            dateOrderHandler={dateOrderHandler}
          />
        </Col>
        <Col span={24}>
          <PastEventsListing
            handlePaginationChange={(page, pageSize) => {
              handlePaginationChange(page, pageSize);
            }}
            pastEventData={pastEventData}
            pagination={pagination}
            handleYearFilter={handleYearFilter}
            totalPastEventsPages={totalPastEventsPages}
            selectedYearValue={selectedYearValue}
            usedForBio={true}
            chaptersList={newChapterList}
          />
        </Col>
        {allEventSponsorLogos?.length > 0 && (
          <Col span={24}>
            <SponsorListing
              sponsorData={allEventSponsorLogos}
              addScroll={true}
            />
          </Col>
        )}

        <Col span={24}>
          <EventInfoCard
            date={ApplicationString['userEventInfoCardComponent.props.date']}
            eventName={
              ApplicationString['userEventInfoCardComponent.props.eventName']
            }
            description={
              ApplicationString['userEventInfoCardComponent.props.description']
            }
            location={
              ApplicationString['userEventInfoCardComponent.props.location']
            }
            editData={editData}
            imageSrc='/rectangle-107@2x.png'
          />
        </Col>
        {/* <Col span={24}>
          <RecentOpeningsJobs
            title='Current Job Openings'
            buttonName='View Details'
            data={allApprovedJobsData}
            flex={true}
            handleNext={handleNext}
            handlePrev={handlePrev}
            home={true}
          />
        </Col> */}
      </Row>
    </React.Fragment>
  );
};

export default PbssInternationalComponent;

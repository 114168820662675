import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import EventDetailsComponent from '../../../../Components/UserComponents/PbssInternational/EventDetails';
import {useNavigate, useParams} from 'react-router-dom';
import moment from 'moment';
import {
  getAllUserEvents,
  getEventsById,
} from '../../../../Store/Actions/events';
import {
  getAllApprovedAds,
  getAllApprovedJobs,
  getAllChapters,
  getAllEventTypesByQuery,
  getAllSponsors,
} from '../../../../Store/Actions';
import {
  EventRegistrationStatus,
  EventStatusEnum,
  LoadMoreSize,
  createSelectionOptionsUtil,
} from '../../../../Util/commonUtility';
import { getAllEventSponsorLogos } from '../../../../Store/Actions/eventRegister';
const EventDetailsContainer = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const {getEventsData} = useSelector(({events}) => events);
  const {allEvents, allUserEvents} = useSelector(({events}) => events);
  const [eventsData, setEventsData] = useState([]);
  const {fetchUserData} = useSelector(({users}) => users);
  const {getApprovedJobs} = useSelector(({jobs}) => jobs);
  const {getApprovedAds} = useSelector(({ads}) => ads);
  const {allChapters} = useSelector(({chapters}) => chapters);
  const { getAllEventRegistrationLogos } = useSelector(({ eventRegister }) => eventRegister)
  const [allApprovedJobsData, setAllApprovedJobsData] = useState([]);
  const [allApprovedAdsData, setAllApprovedAdsData] = useState([]);
  const [sponsorsFilteredData, setSponsorsFilteredData] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(2);
  const [startIndexAds, setStartIndexAds] = useState(0);
  const [endIndexAds, setEndIndexAds] = useState(3);
  const [endIndexSponsor, setEndIndexSponsor] = useState(0);
  const [startIndexSponsor, setStartIndexSponsor] = useState(3);
  const [totalJobs, setTotalJobs] = useState(0);
  const [totalAds, setTotalAds] = useState(0);
  const {id} = useParams();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const userRole = userInfo ? userInfo.role : null;
  const [eventData, setEventData] = useState([]);
  const [getAllEventData, setGetAllEventData] = useState();
  const {allEventTypesQueryData} = useSelector(({eventTypes}) => eventTypes);
  const [chaptersList, setChaptersList] = useState([]);
  const [eventTypesList, setEventTypesList] = useState([]);
  const [countLoadMoreData, setCountLoadMoreData] = useState(2);
  const [totalEventCount, setTotalEventCount] = useState(0);
  const [countData, setCountData] = useState(LoadMoreSize);
  const [selectedChapterValue, setSelectedChapterValue] = useState('');
  const [selectedEventTypeValue, setSelectedEventTypeValue] = useState('');
  const [selectedDateValue, setSelectedDateValue] = useState('');
  const [dateToggle, setdateToggle] = useState(false);
  const [allEventSponsorLogos, setEventSponsorLogos] = useState()

  const dateOrderHandler = () => {
    if (dateToggle) {
      dispatch(
        getAllUserEvents(selectedEventTypeValue, selectedChapterValue, 'desc'),
      );
    } else {
      dispatch(
        getAllUserEvents(selectedEventTypeValue, selectedChapterValue, 'asc'),
      );
    }
    setdateToggle(!dateToggle);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [id]);

  const handleNext = () => {
    if (endIndex + 1 < totalJobs) {
      setStartIndex(endIndex + 1);
      setEndIndex(endIndex + 3);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 3);
      setEndIndex(endIndex - 3);
    }
  };
  const handleNextAds = () => {
    if (endIndexAds + 1 < totalAds) {
      setStartIndexAds(endIndexAds + 1);
      setEndIndexAds(endIndexAds + 4);
    }
  };

  const handlePrevAds = () => {
    if (startIndexAds > 0) {
      setStartIndexAds(startIndexAds - 4);
      setEndIndexAds(endIndexAds - 4);
    }
  };

  const handleNextMajorSponsor = () => {
    if (endIndexSponsor + 1 < totalAds) {
      setStartIndexSponsor(endIndexSponsor + 1);
      setEndIndexSponsor(endIndexSponsor + 4);
    }
  };

  const handlePrevMajorSponsor = () => {
    if (startIndexSponsor > 0) {
      setStartIndexSponsor(startIndexSponsor - 4);
      setEndIndexSponsor(endIndexSponsor - 4);
    }
  };

  useEffect(() => {
    if (allChapters) {
      let filteredChaptersForMap = allChapters?.data?.chapters?.filter(
        (chapter) => chapter.is_visible_on_map,
      );
      const chapters = createSelectionOptionsUtil(
        filteredChaptersForMap,
        'id',
        'name',
      );
      setChaptersList([...chapters]);
    } else {
      dispatch(getAllChapters());
    }
  }, [allChapters, dispatch]);

  useEffect(() => {
    dispatch(getEventsById(id));
  }, [id]);

   useEffect(() => {
      dispatch(
        getAllEventSponsorLogos()
      )
    }, []);

  useEffect(() => {
    if (getEventsData && getEventsData?.data?.id === parseInt(id)) {
      {
        const updatedEventData = {
          ...getEventsData?.data,
          key: getEventsData?.data?.key,
          id: getEventsData?.data?.id,
          flyer_url: getEventsData?.data?.flyer_url,
          flyer_text: getEventsData?.data?.flyer_text,
          flyer_images: getEventsData?.data?.flyer_image_ids,
          sponsorshipAvailable: `${
            Array.isArray(getEventsData?.data?.sponsors)
              ? getEventsData?.data?.sponsors.length
              : 0
          } Sponsorship Available`,
          // speakers: Array.isArray(getEventsData?.data?.speakers)
          //   ? getEventsData?.data?.speakers.map((speaker) => {
          //       return {
          //         name: `${speaker?.first_name} ${
          //           speaker?.last_name ? speaker.last_name : ''
          //         }`.trim(),
          //         title: speaker?.title || 'N/A',
          //         company: speaker?.company_name || 'N/A',
          //         details: speaker?.details || 'N/A',
          //       };
          //     })
          //   : '',
          speaker_name: getEventsData?.data?.speaker_name,
          registrationPrice: getEventsData?.data?.registration_price,
          registerText: 'Register Now',
        };
        const updatedSponsorsData = getEventsData?.data?.sponsors?.filter(
          (obj) =>
            parseInt(obj?.sponsorship_type) === EventRegistrationStatus.PAID,
        );
        setSponsorsFilteredData(updatedSponsorsData);
        setEventsData(updatedEventData);
      }
    }
  }, [getEventsData]);

  useEffect(() => {
    if (getApprovedJobs) {
      const Data = getApprovedJobs?.data?.jobs?.slice(startIndex, endIndex + 1);
      setAllApprovedJobsData(Data);
      setTotalJobs(getApprovedJobs?.data?.totalCount);
    } else {
      dispatch(getAllApprovedJobs());
    }
  }, [getApprovedJobs, startIndex, endIndex]);

  useEffect(() => {
    if (getApprovedAds) {
      const Data = getApprovedAds?.data?.ads?.slice(
        startIndexAds,
        endIndexAds + 1,
      );
      setAllApprovedAdsData(Data);
      setTotalAds(getApprovedAds?.data?.totalCount);
    } else {
      dispatch(getAllApprovedAds());
    }
  }, [getApprovedAds, startIndexAds, endIndexAds]);

  useEffect(() => {
    if (getAllEventData) {
      const updatedEventData = getAllEventData.map((item) => {
        return {
          key: item.id,
          status: item.status,
          sponsorshipAvailable: `${
            Array.isArray(item.sponsors) ? item.sponsors.length : 0
          } Sponsorship Available`,
          speakers: Array.isArray(item.speakers)
            ? item.speakers.map((speakers) => {
                return speakers.first_name + ' ' + speakers.last_name + ',';
              })
            : '',
          date: moment(item.date).utcOffset(0).format('MMM DD'),
          dateObject: item.formattedDateTime,
          eventName: item.name,
          eventDetails: item.details,
          location: item.place,
          registerText:
            Number(item.status) === 2 ? 'Registration Closed' : 'Register Now',
          price: '295',
          category: 'PBSS International Boston - PBSS',
          eventType: item.event_type_name,
          eventChapter: item?.local_chapter_name,
        };
      });
      setEventData(updatedEventData);
    }
  }, [getAllEventData]);

  useEffect(() => {
    if (allEventTypesQueryData?.forMaster) {
      const eventTypes = createSelectionOptionsUtil(
        allEventTypesQueryData?.forMaster?.data?.eventTypes,
        'id',
        'name',
        null,
        'eventType',
      );
      setEventTypesList([{value: '', label: 'Clear Filter'}, ...eventTypes]);
    } else {
      dispatch(
        getAllEventTypesByQuery('', '', '', {
          page: 1,
          pageCount: 100,
        }),
      );
    }
  }, [allEventTypesQueryData?.forMaster, dispatch]);

  useEffect(() => {
      if (getAllEventRegistrationLogos) {
        setEventSponsorLogos(getAllEventRegistrationLogos)
      }
    }, [getAllEventRegistrationLogos])

  useEffect(() => {
    if (allUserEvents) {
      const Data = allUserEvents?.data?.events;
      setGetAllEventData(Data);
      setTotalEventCount(allUserEvents?.data?.totalCount);
    } else {
      dispatch(
        getAllUserEvents(
          selectedEventTypeValue,
          selectedChapterValue,
          selectedDateValue,
        ),
      );
    }
  }, [allUserEvents, countData]);

  const handleChapterFilter = (value) => {
    if (value === 'clear') {
      dispatch(getAllUserEvents(selectedEventTypeValue, '', selectedDateValue));
      setSelectedChapterValue('');
    } else {
      dispatch(
        getAllUserEvents(selectedEventTypeValue, value, selectedDateValue),
        setSelectedChapterValue(value),
      );
    }
    setCountLoadMoreData(2);
  };
  const handleEventTypeFilter = (value) => {
    if (value === 'clear') {
      dispatch(getAllUserEvents('', selectedChapterValue, selectedDateValue));
      setSelectedEventTypeValue('');
    } else {
      dispatch(
        getAllUserEvents(value, selectedChapterValue, selectedDateValue),
        setSelectedEventTypeValue(value),
      );
    }
    setCountLoadMoreData(2);
  };

  const handleLoadMore = () => {
    setCountData(countData * 2);
    setCountLoadMoreData(countLoadMoreData * 2);
  };

  return (
    <div>
      <EventDetailsComponent
        allEventSponsorLogos={allEventSponsorLogos}
        eventsData={eventsData}
        eventData={eventData}
        chaptersList={chaptersList}
        eventTypeList={eventTypesList}
        handleLoadMore={handleLoadMore}
        handleChapterFilter={handleChapterFilter}
        handleEventTypeFilter={handleEventTypeFilter}
        countLoadMoreData={countLoadMoreData}
        allApprovedJobsData={allApprovedJobsData}
        allApprovedAdsData={allApprovedAdsData}
        sponsorsFilteredData={sponsorsFilteredData}
        selectedEventTypeValue={selectedEventTypeValue}
        selectedChapterValue={selectedChapterValue}
        selectedDateValue={selectedDateValue}
        dateToggle={dateToggle}
        dateOrderHandler={dateOrderHandler}
        handleNextMajorSponsor={handleNextMajorSponsor}
        handlePrevMajorSponsor={handlePrevMajorSponsor}
        handleNext={handleNext}
        handlePrev={handlePrev}
        handleNextAds={handleNextAds}
        handlePrevAds={handlePrevAds}
      />
    </div>
  );
};

export default EventDetailsContainer;

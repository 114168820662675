import React, {useState} from 'react';
import {Input, Form, Select} from 'antd';
import {CaretDownOutlined} from '@ant-design/icons';
import './style.css';
import {
  OnlyAlphabetInputValidator,
  OnlyAlphabetInputValidatorWithSpecialCharacters,
  validatePlaceForEvents,
} from '../../../Util/commonUtility';
import {validateCompanyName} from '../../../Util/commonUtility';

const {Option} = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  eventType,
  isMeal,
  record,
  index,
  children,
  allEventOptionsFoodData,
  allEventOptionsRegistrationData,
  attendeesData = [],
  usedForSponsorDetails = false,
  usedForSponsorPerks = false,
  freeLunch,
  workshopAttendee,
  transactionId,
  ...restProps
}) => {
  let inputElement;

  const filterEmptyAttendeeData = attendeesData?.filter(
    (obj) => obj.id !== '1',
  );
  const [isActive, setActive] = useState(true);
  const toggleClass = () => {
    setActive(!isActive);
  };
  const uniqueEmailValidator = (value, record) => {
    const trimmedAttendeeEmail = record?.attendee_email?.toLowerCase()?.trim();
    const trimmedValue = value?.toLowerCase()?.trim();
    if (trimmedAttendeeEmail !== trimmedValue && attendeesData?.length > 0) {
      const sameEmail = attendeesData?.find(
        (obj) => obj?.attendee_email?.toLowerCase()?.trim() === trimmedValue,
      );
      if (sameEmail) {
        return Promise.reject(
          new Error(`${value} already exists as attendee email address!`),
        );
      }
    }
    return Promise.resolve();
  };
  const disableLunchOptions = (foodOption) => {
    let disabledValue;
    const totalRemainingQuantity = foodOption?.qty - foodOption?.used_qty;

    const allAttendeeFilterData = attendeesData?.filter(
      (obj) =>
        obj?.event_registration_options?.food?.event_option_id ===
        foodOption.id,
    );
    disabledValue =
      totalRemainingQuantity === 0 ||
      allAttendeeFilterData?.length >= totalRemainingQuantity;

    return disabledValue;
  };
  const disableRegisterOptions = (regOption) => {
    let disabledValue;
    const totalRemainingQuantity = regOption?.qty - regOption?.used_qty;

    const allAttendeeFilterData = attendeesData?.filter(
      (obj) =>
        obj?.event_registration_options?.registration?.event_option_id ===
        regOption.id,
    );
    disabledValue =
      totalRemainingQuantity === 0 ||
      allAttendeeFilterData?.length >= totalRemainingQuantity;

    return disabledValue;
  };

  switch (dataIndex) {
    case 'name':
      inputElement = (
        <div className='field-separation'>
          <Form.Item
            name='attendee_first_name'
            className='no-margin'
            rules={[
              {
                required: true,
                message: 'Please fill out first name',
              },
              {
                validator: (_, value) =>
                  OnlyAlphabetInputValidatorWithSpecialCharacters(
                    value,
                    'First name',
                  ),
              },
            ]}>
            <Input placeholder='Enter attendee first name' />
          </Form.Item>
          <Form.Item
            name='attendee_last_name'
            className='no-margin'
            rules={[
              {
                required: true,
                message: 'Please fill out last name',
              },
              {
                validator: (_, value) =>
                  OnlyAlphabetInputValidatorWithSpecialCharacters(
                    value,
                    'Last name',
                  ),
              },
            ]}>
            <Input placeholder='Enter attendee last name' />
          </Form.Item>
        </div>
      );
      break;
    case 'attendee_email':
      inputElement = (
        <div className='field-separation'>
          <Form.Item
            name={dataIndex}
            className='no-margin'
            rules={[
              {
                required: true,
                message: `Please fill out ${title?.toLowerCase()}`,
              },
              {
                type: 'email',
                message: 'Please enter a valid email address',
              },
              {
                validator: (_, value) => uniqueEmailValidator(value, record),
              },
            ]}>
            <Input
              onCopy={(e) => e.preventDefault()} // Disable copy
              onPaste={(e) => e.preventDefault()} // Disable paste
              placeholder={`${
                usedForSponsorDetails
                  ? `Enter ${title?.toLowerCase()}`
                  : `Enter attendee ${title?.toLowerCase()}`
              }`}
            />
          </Form.Item>
          <Form.Item
            name={`${dataIndex}_confirm`}
            className='no-margin'
            rules={[
              {
                required: true,
                message: `Please confirm attendee ${title?.toLowerCase()}`,
              },
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (
                    !value ||
                    getFieldValue(dataIndex)?.toLowerCase() ===
                      value?.toLowerCase()
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Email IDs must match'));
                },
              }),
            ]}>
            <Input
              onCopy={(e) => e.preventDefault()} // Disable copy
              onPaste={(e) => e.preventDefault()} // Disable paste
              placeholder={`${
                usedForSponsorDetails
                  ? `Confirm ${title?.toLowerCase()}`
                  : `Confirm attendee ${title?.toLowerCase()}`
              }`}
            />
          </Form.Item>
        </div>
      );
      break;
    case 'attendee_company':
      inputElement = (
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `Please fill out ${title?.toLowerCase()}`,
            },
            {
              validator: (_, value) => validateCompanyName(value, title),
            },
          ]}
          style={{margin: 0}}>
          <Input placeholder={`Enter attendee ${title?.toLowerCase()}`} />
        </Form.Item>
      );
      break;
    case 'option':
      inputElement = (
        <div className='field-separation'>
          <Form.Item
            name='registration_option'
            className='no-margin'
            allowClear
            rules={[
              {
                required: true,
                message: `Please select attendee registration option`,
              },
            ]}>
            <Select
              showSearch
              allowClear
              placeholder='Select attendee registration option'
              className='editable-options'
              suffixIcon={
                <CaretDownOutlined
                  className={isActive ? 'ant-select-suffix' : ''}
                  onClick={toggleClass}
                />
              }>
              {allEventOptionsRegistrationData?.map((regOption) => (
                <Option
                  key={regOption.value}
                  value={regOption.value}
                  disabled={
                    disableRegisterOptions(regOption) &&
                    filterEmptyAttendeeData?.length > 0
                  }>
                  {disableRegisterOptions(regOption) ? (
                    <>
                      {regOption.label}
                      <br />
                      (This option limit has been reached, please make a new
                      registration to join our waiting list )
                    </>
                  ) : (
                    <>{regOption.label}</>
                  )}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {
            (inputElement = eventType !== 'Webcast' && (
              <Form.Item
                name='food_option'
                className='no-margin'
                rules={[
                  {
                    required: true,
                    message: `Please select attendee food option`,
                  },
                ]}>
                <Select
                  showSearch
                  allowClear
                  placeholder='Select attendee food option'
                  disabled={
                    transactionId ? (record.food_option ? false : true) : false
                  }
                  className='editable-options'
                  suffixIcon={
                    <CaretDownOutlined
                      className={isActive ? 'ant-select-suffix' : ''}
                      onClick={toggleClass}
                    />
                  }>
                  {allEventOptionsFoodData?.map((foodOption) => (
                    <Option
                      key={foodOption.value}
                      value={foodOption.value}
                      disabled={disableLunchOptions(foodOption)}>
                      {foodOption.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ))
          }
        </div>
      );
      break;
    default:
      inputElement = (
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `Please fill out ${title?.toLowerCase()}`,
            },
            {
              validator: (_, value) =>
                OnlyAlphabetInputValidatorWithSpecialCharacters(value, title),
            },
          ]}
          style={{margin: 0}}>
          <Input
            placeholder={`${
              usedForSponsorDetails
                ? `Enter ${title?.toLowerCase()}`
                : `Enter attendee ${title?.toLowerCase()}`
            }`}
          />
        </Form.Item>
      );
  }

  return (
    <td {...restProps}>{editing ? <div>{inputElement}</div> : children}</td>
  );
};

export default EditableCell;

import './style.css';
import React from 'react';
import {Link} from 'react-router-dom';

const PastEventsListingCard = ({
  name,
  date,
  event_type,
  chapter_name,
  event_id,
}) => {
  return (
    <Link to={`/eventDetails/${event_id}`}>
      <div className='cardForListing'>
        <div className='past-event-card-title'>{name}</div>
        <div className='row2'>
          <div className='past-event-card-date'>
            <span>{date}</span>
          </div>
          <div className='past-event-card-extraDetails'>
            <span>{event_type}</span>
          </div>
          <div className='past-event-card-location'>
            {chapter_name && <span>{chapter_name}</span>}
          </div>
        </div>
        {/* <div className='row3'></div> */}
      </div>
    </Link>
  );
};

export default PastEventsListingCard;

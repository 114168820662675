import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import moment from 'moment/moment';
import {applicationConfig} from './config';
import {loadStripe} from '@stripe/stripe-js';
import {Tag, Tooltip, List, Divider} from 'antd';
import {message} from 'antd';
import {useSelector} from 'react-redux';
import i1 from '../Assets/sponsors/Allucent.png';
import i2 from '../Assets/sponsors/abzena.png';
import i3 from '../Assets/sponsors/alturas.png';
import i4 from '../Assets/sponsors/appliedbiomath.png';
import i5 from '../Assets/sponsors/BioIVT.png';
import i6 from '../Assets/sponsors/ITR.jpeg';
import i7 from '../Assets/sponsors/Olink.png';
import i8 from '../Assets/sponsors/Orchestra.png';
import i9 from '../Assets/sponsors/Pharmaron.png';
import i10 from '../Assets/sponsors/Veloxity.png';
import i11 from '../Assets/sponsors/WuXi.png';
import i12 from '../Assets/sponsors/WuXiBiologics.jpeg';
import i13 from '../Assets/sponsors/emerypharma.png';
import i14 from '../Assets/sponsors/fortrea.png';
import i15 from '../Assets/sponsors/mycenax.png';
import i16 from '../Assets/sponsors/patelkwan.png';
import i17 from '../Assets/sponsors/q2solutions.png';
import i18 from '../Assets/sponsors/quotient.png';
import i19 from '../Assets/sponsors/resolian.png';
import {fetchError} from '../Store/Actions';
import {ApplicationString} from '../Localization/Locales/en';
import {HttpStatusCode} from 'axios';
import {HttpCode} from '../Constant/HttpCode';
import {
  AUTH_TOKEN_NOT_PROVIDED,
  INVALID_REFRESH_TOKEN_PROVIDE,
  JWT_EXPIRED,
  RETRY_LOGIN,
} from '../Constant/ActionTypes';
import {useState} from 'react';
import countryState from '../country_state.json';

export const DefaultPaginationValues = {
  PAGE_SIZE: 10,
  PAGE: 1,
  TOTAL_PAGES: 1,
};

export const DefaultPaginationValuesForPastEvents = {
  PAGE_SIZE: 5,
  PAGE: 1,
  TOTAL_PAGES: 1,
};
export const DEFAULT_CONTAINER_HEIGHT = 550;

export const cssClassText = {
  auto: 'auto',
  pixel32: '32px',
  pixel0: '0px',
};

export const DefaultListingPageCount = {
  adsListingPageCount: 5,
  jobsListingPageCount: 5,
  eventsListingPageCount: 3,
};

export const DefaultListingViewMoreCount = {
  adsListingViewMoreCount: 3,
  jobsListingViewMoreCount: 3,
  eventsListingViewMoreCount: 3,
};
// This will change file max size validation and error message text for all upload
export const UploadFileMaxSize = 10; // in MB
export const UploadFileMaxSizeForImage = 3; // in MB
export const UploadFileMaxSizeForProfile = 5; // in MB
export const UploadFileMaxSizeForImages = 5; // in MB

export const DescriptionItem = ({title, content, usedForCommittee = false}) => (
  <>
    <div
      className={
        usedForCommittee
          ? 'view-description-item image-item'
          : 'view-description-item'
      }>
      <span className='view-description-item-title'>{title}: </span>
      <span className='view-description-item-content'>{content}</span>
    </div>
  </>
);
/**
 * Array of page sizes for pagination.
 * @type {Array<number>}
 */
export const PageSizeArray = [5, 10, 50, 100];

/**
 * Number of items to load when "Load More" is triggered.
 * @type {number}
 */
export const LoadMoreSize = 3;

/**
 * Position of pagination controls.
 * @type {string}
 */
export const PaginationPosition = 'bottomLeft';

/**
 * Maps Ant Design table component sort orders to common sort orders.
 * @param {string} sortOrder - Ant Design table component sort order ('descend', 'ascend', or other).
 * @returns {string} Common sort order ('desc', 'asc', or empty string).
 */
export const commonSortOrder = (sortOrder) => {
  if (sortOrder === 'descend') {
    sortOrder = 'desc';
    return sortOrder;
  } else if (sortOrder === 'ascend') {
    sortOrder = 'asc';
    return sortOrder;
  } else {
    sortOrder = '';
    return sortOrder;
  }
};

/**
 * Enum representing user roles.
 * @type {Object}
 * @property {string} ADMIN - Admin user role.
 * @property {string} SUPER_ADMIN - Super admin user role.
 * @property {string} USER - Regular user role.
 */
export const UserRoleEnums = {
  ADMIN: 'admin',
  SUPER_ADMIN: 'super_admin',
  USER: 'user',
};

export const EventRegistrationType = {
  regular: 'regular',
  sponsorship: 'sponsorship',
  waitingList: 'waitingList',
};

// /**
//  * Validates a string input.
//  * @param {object} _ - Not used (usually used for form item props).
//  * @param {string} value - The value to be validated.
//  * @param {function} callback - The callback function to handle validation results.
//  */
// export const validateString = (_, value, callback) => {
//   // Check if the value is empty
//   if (!value) {
//     callback();
//   } else if (!isNaN(value)) {
//     callback(`This field should be a string!`);
//   } else {
//     callback();
//   }
// };

/**
 * Converts a numeric keyword value to a corresponding status string.
 * @param {number} value - The numeric keyword value (0 or 1).
 * @returns {string} The corresponding status string ('DISABLED' or 'ACTIVE').
 */
export const getKeywordValue = (value) => {
  if (value === 0) {
    return 'DISABLED';
  } else {
    return 'ACTIVE';
  }
};
/**
 * Adds a "Select All" option to a list of data for use in a select dropdown.
 * @param {Array} Data - The array of data to which the "Select All" option will be added.
 * @returns {Array} A new array containing the original data and a "Select All" option.
 */
export const commonSelectAll = (Data) => {
  return [...Data, {label: 'Select All', value: 'all'}];
};

/**
 * Constructs a query string from an object of search values.
 * @param {object} searchValues - The object containing the search values.
 * @returns {string} - The constructed query string.
 */
export const queryString = (searchValues) => {
  const searchQuery = Object.entries(
    searchValues || searchValues?.name ? searchValues : '',
  )
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  return searchQuery;
};
// /**
//  * @param itemList - The `itemList` parameter is an array of objects that contains the items from which
//  * you want to create selection options. Each object in the `itemList` array should have properties
//  * corresponding to the `value` and `label` parameters.
//  * @param value - The `value` parameter is used to specify the property name of each item in the
//  * `itemList` that should be used as the value for the option.
//  * @param label - The `label` parameter is used to specify the property name of each item in the
//  * `itemList` that will be used as the label for the selection options.
//  * @returns an array of objects with `value` and `label` properties.
//  */
// export const createSelectionOptionsUtil = (itemList, value, label, label2) => {
//   const options = itemList?.map((item) => ({
//     ...item,
//     value: item[`${value}`],
//     label: label2
//       ? item[`${label}`] + ' - ' + formatCurrency(item[`${label2}`], 'CAD')
//       : item[`${label}`],
//   }));
//   return options;
// };

// export const createSelectionOptionsUtilForEmailToMembers = (
//   itemList,
//   value,
//   label,
//   label2,
// ) => {
//   const options = itemList?.map((item) => ({
//     ...item,
//     value: item[`${value}`],
//     // label: label2
//     //   ? item[`${label}`] + ' - ' + item[`${label2}`]
//     //   : item[`${label}`],
//     label: `${item[`${label}`]} ${item[`${label2}`]}`,
//   }));
//   return options;
// };

// export const createSelectionOptionsUtilForEmailCode = (
//   itemList,
//   value,
//   label,
//   label2,
// ) => {
//   const options = itemList?.map((item) => ({
//     ...item,
//     value: item[`${value}`],
//     label: label2
//       ? item[`${label}`] + ' - ' + formatCurrency(item[`${label2}`], 'CAD')
//       : item[`${label}`],
//   }));
//   return options;
// };

// export const createSelectionForAdminOptionsUtil = (
//   itemList,
//   value,
//   label,
//   label2,
// ) => {
//   const options = itemList?.map((item) => ({
//     value: item[`${value}`],
//     label: label2
//       ? item[`${label}`] + ' ' + item[`${label2}`]
//       : item[`${label}`],
//   }));
//   return options;
// };

// export const isParsable = (parsingString) => {
//   try {
//     JSON.parse(parsingString);
//     return true;
//   } catch (error) {
//     return false;
//   }
// };

// export const getLocalStorageUserInfo = () => {
//   let userInfo = localStorage.getItem('userInfo');
//   if (isParsable(userInfo)) {
//     return JSON.parse(userInfo);
//   } else {
//     return undefined;
//   }
// };
/**
 * Creates selection options for a dropdown menu.
 * @param {Array} itemList - The list of items to create options from.
 * @param {string} value - The property name for the option value.
 * @param {string} label - The property name for the option label.
 * @param {string} label2 - The property name for an additional label (optional).
 * @returns {Array} An array of objects representing the selection options.
 */
export const createSelectionOptionsUtil = (
  itemList,
  value,
  label,
  label2,
  optiontype,
) => {
  const options = itemList?.map((item) => ({
    ...item,
    value: item[`${value}`],
    label: label2
      ? item[`${label}`] + ' - ' + formatCurrency(item[`${label2}`], 'USD')
      : item[`${label}`],
  }));
  return options;
};

const renderPriceAsPerSponsorType = (
  item,
  label2,
  label,
  optionLimit,
  optionPrice,
) => {
  if (label2) {
    const labelValue =
      item[`${label}`] +
      ' - ' +
      formatCurrency(optionPrice, 'USD') +
      ' ' +
      `(limit of ${optionLimit})`;
    return labelValue;
  } else {
    return item[`${label}`] + ' - ' + formatCurrency(item[`${label2}`], 'USD');
  }
};
export const uniqueSponsorPerksValueEnum = 'Sponsor_Perks';

/**
 * Creates selection options for a dropdown menu.
 * @param {Array} itemList - The list of items to create options from.
 * @param {string} value - The property name for the option value.
 * @param {string} label - The property name for the option label.
 * @param {string} label2 - The property name for an additional label (optional).
 * @returns {Array} An array of objects representing the selection options.
 */
export const createSelectionOptionsUtilForPerks = (
  itemList,
  optionLimit,
  optionPrice,
  value,
  label,
  label2,
) => {
  const options = itemList?.map((item) => ({
    ...item,
    value: item[`${value}`] + '-' + uniqueSponsorPerksValueEnum,
    label: renderPriceAsPerSponsorType(
      item,
      label2,
      label,
      optionLimit,
      optionPrice,
    ),
    discounted_price: optionPrice,
  }));
  return options;
};

export const isNullUndefinedEmpty = (selectedEventTypeValue) => {
  return selectedEventTypeValue === null ||
    selectedEventTypeValue === undefined ||
    selectedEventTypeValue === ''
    ? false
    : true;
};

/**
 * Creates selection options for a dropdown menu with a specific format for email to members.
 * @param {Array} itemList - The list of items to create options from.
 * @param {string} value - The property name for the option value.
 * @param {string} label - The property name for the option label.
 * @param {string} label2 - The property name for an additional label (optional).
 * @returns {Array} An array of objects representing the selection options.
 */
export const createSelectionOptionsUtilForEmailToMembers = (
  itemList,
  value,
  label,
  label2,
) => {
  const options = itemList?.map((item) => ({
    ...item,
    value: item[`${value}`],
    label: `${item[`${label}`]} ${item[`${label2}`]}`,
  }));
  return options;
};

/**
 * Creates selection options for a dropdown menu with a specific format for email code.
 * @param {Array} itemList - The list of items to create options from.
 * @param {string} value - The property name for the option value.
 * @param {string} label - The property name for the option label.
 * @param {string} label2 - The property name for an additional label (optional).
 * @returns {Array} An array of objects representing the selection options.
 */
export const createSelectionOptionsUtilForEmailCode = (
  itemList,
  value,
  label,
  label2,
) => {
  const options = itemList?.map((item) => ({
    ...item,
    value: item[`${value}`],
    label: label2
      ? item[`${label}`] + ' - ' + formatCurrency(item[`${label2}`], 'USD')
      : item[`${label}`],
  }));
  return options;
};

/**
 * Creates selection options for a dropdown menu with a specific format for admin options.
 * @param {Array} itemList - The list of items to create options from.
 * @param {string} value - The property name for the option value.
 * @param {string} label - The property name for the option label.
 * @param {string} label2 - The property name for an additional label (optional).
 * @returns {Array} An array of objects representing the selection options.
 */
export const createSelectionForAdminOptionsUtil = (
  itemList,
  value,
  label,
  label2,
) => {
  const options = itemList?.map((item) => ({
    value: item[`${value}`],
    label: label2
      ? item[`${label}`] + ' ' + (item[`${label2}`] || '')
      : item[`${label}`],
  }));
  return options;
};

/**
 * Creates selection options for a dropdown menu with a specific format for admin options.
 * @param {Array} itemList - The list of items to create options from.
 * @param {string} value - The property name for the option value.
 * @param {string} label - The property name for the option label.
 * @param {string} label2 - The property name for an additional label (optional).
 * @returns {Array} An array of objects representing the selection options.
 */
export const createSelectionForAdminOptionsUtilForTimeZones = (
  itemList,
  value,
  label,
  label2,
) => {
  const options = itemList?.map((item) => ({
    value: item[`${value}`],
    label: `(${item[`${value}`]}) ${item[`${label}`]}`,
  }));
  return options;
};

/**
 * Creates selection menu options from a list of items.
 *
 * @param {Array} itemList - The list of items.
 * @param {string} value - The property name for the value of each item.
 * @param {string} label - The property name for the main label of each item.
 * @param {string} label2 - Optional property name for a secondary label of each item.
 * @returns {Array} An array of objects representing the menu options.
 */
export const createSelectionMenuOptionsUtil = (
  itemList,
  value,
  label,
  label2,
) => {
  /**
   * Maps each item in the itemList to a menu option object.
   *
   * @param {Object} item - The individual item in the list.
   * @returns {Object} An object representing a menu option.
   */
  const mapToMenuOption = (item) => ({
    key: `chapter/` + item[`${value}`],
    label: label2
      ? item[`${label}`] + ' ' + item[`${label2}`]
      : item[`${label}`],
  });

  // Generate options by mapping each item to a menu option
  const options = itemList?.map(mapToMenuOption);

  return options;
};

/**
 * Generates an array of table rows based on sponsor information.
 *
 * @param {Object} sponsorInfo - Information about the sponsor.
 * @param {number} numberOfSponsor - The number of sponsors.
 * @param {number} attendees_price - The price for each attendee.
 * @returns {Array} An array of table rows.
 */
export const generateTableRow = (
  sponsorId,
  sponsorInfo,
  free_lunch,
  numberOfSponsor,
  attendees_price,
) => {
  /**
   * Represents a table row.
   *
   * @typedef {Object} TableRow
   * @property {number} id - The unique identifier for the row.
   * @property {string} attendee_fname - First name of the attendee.
   * @property {string} attendee_lname - Last name of the attendee.
   * @property {string} attendee_company - Company name of the attendee.
   * @property {string} attendee_email - Email of the attendee.
   * @property {string} Registraton_option - Registration option for the attendee.
   * @property {string} food_option - Food option for the attendee.
   * @property {number} price - The price for the attendee.
   */

  /**
   * The template for a table row.
   * @type {TableRow}
   */
  const tableRow = {
    id: '',
    attendee_fname: sponsorInfo?.first_name, // Replace with actual first name
    attendee_lname: sponsorInfo?.last_name, // Replace with actual last name
    attendee_company: '', // Replace with actual company name
    attendee_email: sponsorInfo?.email, // Replace with actual email
    Registraton_option: '',
    food_option: '',
    price: attendees_price,
  };
  const data = Array.from({length: numberOfSponsor}, () => ({
    ...tableRow,
    id: crypto.randomUUID(),
    price: attendees_price,
    // free_lunch:free_lunch?true:false
  }));
  return data;
};

/**
 * Checks if the provided URL contains any of the specified keywords.
 * @param {string} url - The URL to be checked.
 * @returns {boolean} Returns true if any keyword is found in the URL, otherwise false.
 */
export const checkUrl = (url, eventNameValue, emailTemplateTypeValue) => {
  // List of keywords to check for in the URL
  const options = [
    'eventOptions',
    'eventRegistration',
    'eventRefund',
    'couponCodes/users',
    'eventEmailTemplatesScheduler',
  ];
  const templateUrl = `${url?.split('/')[0]}/${url?.split('/')[1]}`;
  const templateOptions = ['emailTemplates/update'];

  const eventOptionBool = options.some((option) => url.includes(option));
  const templateOptionsBool = templateOptions.some((option) =>
    templateUrl.includes(option),
  );
  const returnValue = eventOptionBool
    ? eventNameValue
    : templateOptionsBool
    ? emailTemplateTypeValue
    : '';

  // Using Array.some() to check if any keyword is present in the URL
  return returnValue;
};

/**
 * Checks if a string is parsable as JSON.
 * @param {string} parsingString - The string to check for JSON parseability.
 * @returns {boolean} True if the string is parsable as JSON, false otherwise.
 */
export const isParsable = (parsingString) => {
  try {
    JSON.parse(parsingString);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Retrieves user information from local storage.
 * @returns {Object|undefined} The user information if available, otherwise undefined.
 */
export const getLocalStorageUserInfo = () => {
  let userInfo = localStorage.getItem('userInfo');
  if (isParsable(userInfo)) {
    return JSON.parse(userInfo);
  } else {
    return undefined;
  }
};

/**
 * Returns the color corresponding to the given status.
 *
 * @param {number} status - The status code.
 * @returns {string} The color associated with the status.
 */
export const getStatusColor = (status) => {
  switch (status) {
    case 'pending':
      return 'red'; // Pending status
    case 'paid':
      return 'green'; // Success status
    // Add more cases for other statuses if needed
    default:
      return 'black'; // Default color if status is not recognized
  }
};

export const mailToMemberVariables = [
  `{{user_name}}`,
  `{{user_email}}`,
  `{{subject}}`,
  `{{chapter_ids}}`,
];
export const eventSchedulerVariables = [
  `{{user_name}}`,
  `{{user_email}}`,
  `{{subject}}`,
  `{{schedule_date}}`,
  `{{code_id}}`,
  `{{email_template_body}}`,
];
export const NotificationTypes = {
  SPONSORSHIP_ANNOUNCEMENT: 'Sponsorship Announcement',
  SPONSORSHIP_REGISTRATION_CONFIRMATION:
    'Sponsorship Registration Confirmation',
  EVENT_INITIAL_ANNOUNCEMENT: 'Event Initial Announcement',
  EVENT_REGISTRATION_CONFIRMATION: 'Event Registration Confirmation',
  EVENT_REMINDER: 'Event Reminder',
  EVENT_PAYMENT_REMINDER: 'Event Payment Reminder',
  EVENT_FINAL_CALL: 'Event Final Call',
  EVENT_ATTENDANCE_REMINDER: 'Event Attendance Reminder',
  PROFILE_CREATION: 'Profile Creation',
  PROFILE_UPDATE: 'Profile Update',
  PASSWORD_REQUEST: 'Password Reset Request',
  PASSWORD_RESET_CONFIRMATION: 'Password Reset Confirmation',
  SPECIAL_PAYMENT_CONFIRMATION: 'Special Payment Confirmation',
  AD_PLACEMENT_CONFIRMATION: 'Ad Placement Confirmation',
};

export const emailTemplatesTypeCode = [
  {
    label: 'Ad Placement Confirmation',
    value: 'Ad Placement Confirmation',
    dynamicValues: [
      '{{chapter_name}}',
      '{{user_name}}',
      '{{ad_fee}}',
      '{{ad_url}}',
      '{{date}}',
      '{{end_date}}',
    ],
  },
  {
    label: 'Job Placement Confirmation',
    value: 'Job Placement Confirmation',
    dynamicValues: [
      '{{chapter_name}}',
      '{{user_name}}',
      '{{job_fee}}',
      '{{job_url}}',
      '{{date}}',
      '{{end_date}}',
    ],
  },
  {
    label: 'Password Reset Confirmation',
    value: 'Password Reset Confirmation',
    dynamicValues: ['{{user_name}}', '{{chapter_name}}'],
  },
  {
    label: 'Password Reset Request',
    value: 'Password Reset Request',
    dynamicValues: [
      '{{user_name}}',
      '{{chapter_name}}',
      '{{reset_password_url}}',
    ],
  },
  {
    label: 'Profile Creation',
    value: 'Profile Creation',
    dynamicValues: [
      '{{user_name}}',
      '{{first_name}}',
      '{{last_name}}',
      '{{company_name}}',
      '{{email}}',
      '{{update_profile_url}}',
      '{{date}}',
      '{{chapter_name}}',
    ],
  },
  {
    label: 'Profile Update',
    value: 'Profile Update',
    dynamicValues: [
      '{{user_name}}',
      '{{first_name}}',
      '{{last_name}}',
      '{{company_name}}',
      '{{email}}',
      '{{update_profile_url}}',
      '{{date}}',
      '{{chapter_name}}',
    ],
  },
  {
    label: 'Sponsorship Registration Confirmation',
    value: 'Sponsorship Registration Confirmation',
    isEventEmailTemplate: true,
    dynamicValues: [
      '{{chapter_name}}',
      '{{sponsor_name}}',
      '{{attendee_name}}',
      '{{total_cost}}',
      '{{date}}',
      '{{time}}',
      '{{location}}',
      '{{event_type}}',
      '{{event_name}}',
      '{{speakers}}',
      '{{organizers}}',
      '{{major_sponsorship_display}}',
      '{{major_sponsorship_registered}}',
      '{{lunch_sponsorship_display}}',
      '{{lunch_sponsorship_registered}}',
      '{{post_meeting_reception_display}}',
      '{{post_meeting_reception_registered}}',
      '{{vendor_show_display}}',
      '{{vendor_show_registered}}',
      '{{event_url}}',
      '{{event_description}}',
      '{{speaker_details}}',
    ],
  },
  {
    label: 'Sponsorship Announcement',
    value: 'Sponsorship Announcement',
    isEventEmailTemplate: true,
    dynamicValues: [
      '{{chapter_name}}',
      '{{date}}',
      '{{time}}',
      '{{location}}',
      '{{event_type}}',
      '{{event_name}}',
      '{{major_sponsorship_display}}',
      '{{major_sponsorship_registered}}',
      '{{major_sponsorship_price}}',
      '{{lunch_sponsorship_display}}',
      '{{lunch_sponsorship_registered}}',
      '{{lunch_sponsorship_price}}',
      '{{post_meeting_reception_display}}',
      '{{post_meeting_reception_registered}}',
      '{{post_meeting_reception_price}}',
      '{{vendor_show_display}}',
      '{{vendor_show_registered}}',
      '{{vendor_show_price}}',
    ],
  },
  {
    label: 'Event Registration Confirmation',
    value: 'Event Registration Confirmation',
    isEventEmailTemplate: true,
    dynamicValues: [
      '{{chapter_name}}',
      '{{attendee_name}}',
      '{{total_cost}}',
      '{{date}}',
      '{{time}}',
      '{{location}}',
      '{{event_type}}',
      '{{event_name}}',
      '{{speakers}}',
      '{{organizers}}',
      '{{major_sponsorship_display}}',
      '{{major_sponsorship_registered}}',
      '{{lunch_sponsorship_display}}',
      '{{lunch_sponsorship_registered}}',
      '{{post_meeting_reception_display}}',
      '{{post_meeting_reception_registered}}',
      '{{vendor_show_display}}',
      '{{vendor_show_registered}}',
      '{{event_url}}',
      '{{event_description}}',
      '{{speaker_details}}',
    ],
  },
  {
    label: 'Event Initial Announcement',
    value: 'Event Initial Announcement',
    isEventEmailTemplate: true,
    dynamicValues: [
      '{{chapter_name}}',
      '{{date}}',
      '{{time}}',
      '{{location}}',
      '{{event_type}}',
      '{{event_name}}',
      '{{speakers}}',
      '{{organizers}}',
      '{{major_sponsorship_display}}',
      '{{major_sponsorship_registered}}',
      '{{lunch_sponsorship_display}}',
      '{{lunch_sponsorship_registered}}',
      '{{post_meeting_reception_display}}',
      '{{post_meeting_reception_registered}}',
      '{{vendor_show_display}}',
      '{{vendor_show_registered}}',
      '{{event_url}}',
      '{{event_description}}',
      '{{speaker_details}}',
    ],
  },
  {
    label: 'Event Payment Reminder',
    value: 'Event Payment Reminder',
    isEventEmailTemplate: true,
    dynamicValues: [
      '{{chapter_name}}',
      '{{attendee_name}}',
      '{{total_cost}}',
      '{{date}}',
      '{{time}}',
      '{{location}}',
      '{{event_type}}',
      '{{event_name}}',
      '{{speakers}}',
      '{{organizers}}',
      '{{major_sponsorship_display}}',
      '{{major_sponsorship_registered}}',
      '{{lunch_sponsorship_display}}',
      '{{lunch_sponsorship_registered}}',
      '{{post_meeting_reception_display}}',
      '{{post_meeting_reception_registered}}',
      '{{vendor_show_display}}',
      '{{vendor_show_registered}}',
      '{{event_url}}',
      '{{event_description}}',
      '{{speaker_details}}',
    ],
  },
  {
    label: 'Event Attendance Reminder',
    value: 'Event Attendance Reminder',
    isEventEmailTemplate: true,
    dynamicValues: [
      '{{chapter_name}}',
      '{{attendee_name}}',
      '{{total_cost}}',
      '{{date}}',
      '{{time}}',
      '{{location}}',
      '{{event_type}}',
      '{{event_name}}',
      '{{speakers}}',
      '{{organizers}}',
      '{{major_sponsorship_display}}',
      '{{major_sponsorship_registered}}',
      '{{lunch_sponsorship_display}}',
      '{{lunch_sponsorship_registered}}',
      '{{post_meeting_reception_display}}',
      '{{post_meeting_reception_registered}}',
      '{{vendor_show_display}}',
      '{{vendor_show_registered}}',
      '{{event_url}}',
      '{{event_description}}',
      '{{speaker_details}}',
    ],
  },
  {
    label: 'Event Final Call',
    value: 'Event Final Call',
    isEventEmailTemplate: true,
    dynamicValues: [
      '{{chapter_name}}',
      '{{date}}',
      '{{time}}',
      '{{location}}',
      '{{event_type}}',
      '{{event_name}}',
      '{{speakers}}',
      '{{organizers}}',
      '{{major_sponsorship_display}}',
      '{{major_sponsorship_registered}}',
      '{{lunch_sponsorship_display}}',
      '{{lunch_sponsorship_registered}}',
      '{{post_meeting_reception_display}}',
      '{{post_meeting_reception_registered}}',
      '{{vendor_show_display}}',
      '{{vendor_show_registered}}',
      '{{event_url}}',
      '{{event_description}}',
      '{{speaker_details}}',
    ],
  },
  {
    label: 'Event Reminder',
    value: 'Event Reminder',
    isEventEmailTemplate: true,
    dynamicValues: [
      '{{chapter_name}}',
      '{{date}}',
      '{{time}}',
      '{{location}}',
      '{{event_type}}',
      '{{event_name}}',
      '{{speakers}}',
      '{{organizers}}',
      '{{major_sponsorship_display}}',
      '{{major_sponsorship_registered}}',
      '{{lunch_sponsorship_display}}',
      '{{lunch_sponsorship_registered}}',
      '{{post_meeting_reception_display}}',
      '{{post_meeting_reception_registered}}',
      '{{vendor_show_display}}',
      '{{vendor_show_registered}}',
      '{{event_url}}',
      '{{event_description}}',
      '{{speaker_details}}',
    ],
  },
  {
    label: 'Special Payment Confirmation',
    value: 'Special Payment Confirmation',
    dynamicValues: ['{{chapter_name}}', '{{amount}}', '{{notes}}'],
  },
  {
    label: 'EverydayMail',
    value: 'EverydayMail',
    dynamicValues: false,
  },
];

export function isEventEmailTemplate(value) {
  // Find the object with the given value in the array
  const template = emailTemplatesTypeCode.find(
    (template) => template.value === value,
  );

  // Check if the template exists and has the property isEventEmailTemplate
  if (template && template.isEventEmailTemplate) {
    return true;
  } else {
    return false;
  }
}

export const WordSplite = {
  'couponCodes/users': 'Coupon Code Users',
  chapters: 'Chapters',
  waitingList: 'Waiting List',
  'waitingList/': 'Waiting List',
  emailCodes: 'Email Templates',
  emailTemplates: 'Email Templates',
  eventTypes: 'Event Types',
  couponCodes: 'Coupon Codes',
  jobfees: 'Job Fees',
  adfees: 'Ad Fees',
  eventOptionGroups: 'Event Option Groups',
  eventEmailTemplatesScheduler: 'Event Email Templates Scheduler',
  'eventEmailTemplatesScheduler/': 'Event Email Templates Scheduler',
  eventOptions: 'Event Options',
  'eventOptions/': 'Event Options',
  memberAttendees: 'Member Attendees',
  jobs: 'Jobs',
  eventRegistration: 'Event Registration',
  'eventRegistration/': 'Event Registration',
  ads: 'Ads',
  stem: 'STEM',
  committeeMember: 'Committee Member',
  sponsors: 'Sponsors',
  speakers: 'Speakers',
  events: 'Events',
  companies: 'Companies',
  keywords: 'Keywords',
  interests: 'Interests',
  emailToMembers: 'Email To Members',
  sponsorshipInfo: 'Sponsorship Info',
  specialPayment: 'Special Payment',
  eventRefund: 'Event Refund',
  'eventRefund/': 'Event Refund',
  forbidden: '403',
  pbssInternational: 'PBSS International',
};

export const event_registration_type = [
  {value: 'in_person', label: 'In-Person'},
  {value: 'webcast', label: 'Webcast'},
];

/**
 * Capitalizes the first letter of each word in a given input string and performs
 * specific transformations based on certain keywords. Removes 's' from 'Add' and 'Update'.
 *
 * @param {string} inputString - The input string to be processed.
 * @returns {string} - A modified string with each word capitalized and potential
 *                    transformations based on keywords like 'add' and 'update'.
 */
export const capitalizeWords = (inputString) => {
  // Split the input string into an array of words
  const words = inputString.split(' ');

  // Capitalize the first letter of each word
  let capitalizedWords = words;

  // Join the capitalized words back into a single string
  const resultString = capitalizedWords.join(' ');

  // Check if the inputString is a keyword and perform specific transformations
  const word = WordSplite[inputString];
  if (word === undefined) {
    // Check for the presence of 'add' and 'update' in the resultString
    const addStr = resultString.includes('add');
    const addUpdate = resultString.includes('update');

    if (addStr) {
      // If 'add' is found, split the resultString and capitalize the first part
      const parts = resultString.split('/');
      const result = parts[0];
      const word = WordSplite[result];
      const finalStr = 'Add' + ` ${word ? word : result}`.replace(/s$/, ''); // Remove 's' if it's at the end
      return finalStr === 'Add Companie' ? 'Add Company' : finalStr;
    } else if (addUpdate) {
      // If 'update' is found, split the resultString and capitalize the first part
      const parts = resultString.split('/');
      const result = parts[0];
      const word = WordSplite[result];
      const finalStr = 'Update' + ` ${word ? word : result}`.replace(/s$/, ''); // Remove 's' if it's at the end
      return finalStr === 'Update Companie' ? 'Update Company' : finalStr;
    } else {
      if (
        resultString.includes('eventEmailTemplatesScheduler/') ||
        resultString.includes('eventRegistration/') ||
        resultString.includes('eventOptions/') ||
        resultString.includes('eventRefund/') ||
        resultString.includes('waitingList/') ||
        resultString.includes('couponCodes/users') ||
        resultString.includes('sponsorshipInfo/')
      ) {
        // Extract the part of the string before the last '/'
        const partBeforeLastSlash = resultString.substring(
          0,
          resultString.lastIndexOf('/'),
        );
        // Retrieve a specific word using WordSplite object
        const word = WordSplite[partBeforeLastSlash];
        // Return the extracted word
        return word;
      }
      // Return the original resultString if no specific conditions are met
      return resultString;
    }
  } else {
    // If inputString is a predefined word, return its corresponding value
    return word;
  }
};

/**
 * Checks if an object is empty by iterating through its properties.
 *
 * @param {Object} obj - The object to be checked for emptiness.
 * @returns {boolean} - `true` if the object is empty, `false` otherwise.
 */
export const isEmptyObject = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== '') {
      return false;
    }
  }
  return true;
};

/**
 * Get the name of the sponsorship type based on its value.
 *
 * @param {number} value - The value of the sponsorship type.
 * @returns {string} - The name of the sponsorship type.
 */
export const getSponsorshipTypeName = (value) => {
  switch (value) {
    case SponsorshipType.VENDOR_SHOW:
      return 'Vendor Show';
    case SponsorshipType.HAPPY_HOUR:
      return 'Happy Hour';
    case SponsorshipType.LUNCH:
      return 'Lunch';
    case SponsorshipType.MAJOR:
      return 'Major';
    default:
      return 'Unknown';
  }
};

/**
 * Formats a numeric value as currency.
 * @param {number} value - The numeric value to be formatted.
 * @param {string} currencyCode - The currency code (optional, default is 'CAD').
 * @returns {string} The formatted currency string.
 */
export const formatCurrency = (value, currencyCode) => {
  const options = {
    style: 'currency',
    currency: currencyCode ? currencyCode : 'USD',
    minimumFractionDigits: 0, // Ensure no decimal digits are displayed
    maximumFractionDigits: 0, // Ensure no decimal digits are displayed
  };
  const formatter = new Intl.NumberFormat('en-US', options);
  return formatter.format(value ?? 0);
};

export const formatCurrencyByLocalChapter = (
  value,
  loggedUserLocalChapterData,
) => {
  const userLocalCurrency = loggedUserLocalChapterData?.local_currency;
  const localCurrencyConversionRate =
    loggedUserLocalChapterData?.conversion_rate;
  const localCurrencyValue = value * localCurrencyConversionRate;
  if (userLocalCurrency === 'USD') {
    return formatCurrency(value);
  }
  // placeholder until both values are available
  if (!userLocalCurrency || !localCurrencyValue) return '$0.00';
  return `${formatCurrency(value)} (${formatCurrency(
    localCurrencyValue,
    userLocalCurrency,
  )})`;
};

/**
 * Formats a date using moment.js to a specific format.
 * @param {Date|string} date - The date to be formatted.
 * @returns {string} The formatted date string.
 */
export const formatDate = (date) => {
  return moment(date).utcOffset(0).format('MMM DD YYYY,h:mm A');
};

export const formatDataForScheculer = (date) => {
  return moment(date).utcOffset(0).format('MMM DD YYYY');
};

// /**
//  * Formats a date using moment.js to a specific format (DD/MM/YYYY).
//  * @param {Date|string} date - The date to be formatted.
//  * @returns {string} The formatted date string.
//  */
// export const formatDateRange = (date) => {
//   return moment(date).utcOffset(0).format('DD/MM/YYYY');
// };

/**
 * Renders a checkbox icon based on the provided flag.
 * @param {boolean} flag - The flag indicating whether to render a check or close icon.
 * @returns {JSX.Element} The checkbox icon element.
 */
export const renderCheckBox = (flag) => {
  if (flag) {
    return <CheckOutlined style={{color: 'green'}} />;
  } else {
    return <CloseOutlined style={{color: 'red'}} />;
  }
};

const EventType = {
  SPONSORSHIP: 'sponsorship',
  FOOD: 'food',
  REGISTRATION: 'registration',
};

/**
 * Get the color based on the event type.
 *
 * @param {string} eventType - The type of the event (SPONSORSHIP, FOOD, REGISTRATION).
 * @returns {string} - The color associated with the event type.
 */
export const getEventColor = (eventType) => {
  switch (eventType) {
    case EventType.SPONSORSHIP:
      return '#EC9192'; // Gold color for sponsorship
    case EventType.FOOD:
      return '#32CD32'; // Lime green color for food
    case EventType.REGISTRATION:
      return '#1E90FF'; // Dodger blue color for registration
    default:
      return '#808080'; // Gray color for unknown types
  }
};

export const exportToCSV = (data, filename) => {
  const csvContent =
    'data:text/csv;charset=utf-8,' +
    data.map((row) => Object.values(row).join(',')).join('\n');
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${filename}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const AdsColumnValues = [
  'vendor',
  'description',
  'status',
  'ad_fee_note',
  'attachment_url',
];
export const EventOptionTypes = {
  FOOD: 'food',
  REGISTRATION: 'registration',
  SPONSORSHIP: 'sponsorship',
};
export const EventTypesColumnValues = ['name', 'details'];

export const ChapterColumnValues = [
  'name',
  'details',
  'pp_email',
  'nvp_user',
  'secret_key',
  'payment_gateway',
];
export const emailCodeColumnValues = ['details', 'email_template'];
export const AdStatus = {
  DRAFT: 'draft',
  IN_REVIEW: 'in_review',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};
export const EventStatus = {
  PRIVATE: -2,
  TENTATIVE: -1,
  ACTIVE: 0,
  DRAFT: 1,
  REG_CLOSED: 2,
};
export const CronJobStatus = {
  RUN_SUCCESSFULLY: 1,
  FAILED: 0,
  NOT_STARTED_YET: 2,
};

export const EventRegistrationStatus = {
  TO_BE_DELETED: -1,
  PAYMENT_REFUNDED: 0,
  TO_BE_PAID: 1,
  REFUND_PENDING: 2,
  PAID: 3,
  PAYMENT_CONFIRMED: 4,
  NO_CHARGE_EVENT: 5,
  FEE_WAIVER: 6,
};

export const JobsAndAdsTabs = [
  {key: 'ads', label: 'Paid Advertisements'},
  {
    key: 'jobs',
    label: 'Job Postings',
  },
];

export const EventOptions = [
  {
    label: 'Private',
    value: -2,
  },
  {
    label: 'Tentative',
    value: -1,
  },
  {
    label: 'Active',
    value: 0,
  },
  {
    label: 'Draft',
    value: 1,
  },
  {
    label: 'Registration Closed',
    value: 2,
  },
];
export const EventWebCast = {
  TRUE: true,
  FALSE: false,
};
export const JobStatus = {
  DRAFT: 'draft',
  IN_REVIEW: 'in_review',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};
export const SponsorshipType = {
  VENDOR_SHOW: 0,
  HAPPY_HOUR: 1,
  LUNCH: 2,
  MAJOR: 3,
};

export const SponsorshipTypeMapping = {
  0: 'Vendor Show',
  1: 'Happy Hour',
  2: 'Lunch',
  3: 'Major',
  null: 'NA',
};
export const OnlineEvents = {
  YES: true,
  NO: false,
};

export const IsAvailableForMap = {
  YES: true,
  NO: false,
};

export const IsScheduleAvailable = {
  YES: true,
  NO: false,
};

export const eventStatusOptions = [
  {
    label: 'Private',
    value: '-2',
  },
  {
    label: 'Tentative',
    value: '-1',
  },
  {
    label: 'Active',
    value: '0',
  },
  {
    label: 'Draft',
    value: '1',
  },
  {
    label: 'Registation Closed',
    value: '2',
  },
];

export const AdsAndJobsOptionsForAdmin = [
  // {
  //   label: 'Clear Filter',
  //   value: '',
  // },
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'In Review',
    value: 'in_review',
  },
  {
    label: 'Approved',
    value: 'approved',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
  {
    label: 'Expired',
    value: 'expired',
  },
];

export const AdsAndJobsOptionsForUser = [
  {
    label: 'Clear Filter',
    value: '',
  },
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'In Review',
    value: 'in_review',
  },
  {
    label: 'Approved',
    value: 'approved',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
  {
    label: 'Expired',
    value: 'expired',
  },
];
export const UserStatusOptions = [
  {
    label: 'In-Active',
    value: 'inactive',
  },
  {
    label: 'Active',
    value: 'active',
  },
];

export const UserStatusDropdownOptions = [
  {
    label: 'Active',
    value: 1,
  },
  {
    label: 'In-Active',
    value: 0,
  },
];

export const UserRoleOptions = [
  {label: 'Admin', value: 'admin'},
  {label: 'User', value: 'user'},
  {label: 'Super Admin', value: 'super_admin'},
  {label: 'Event Manager Admin', value: 'event_manager_admin'},
  {label: 'Event Registration Admin', value: 'event_registration_admin'},
];

export const myEventsCategoryOptions = [
  {
    label: 'Clear Filter',
    value: null,
  },
  {
    label: 'Attended',
    value: 'attended',
  },
  {
    label: 'Upcoming',
    value: 'upcoming',
  },
];

export const eventStatusRegistrationClosed = 2;

export const statusOptions = [
  {
    label: 'Clear Filter',
    value: '',
  },
  {
    label: 'Paid',
    value: 'Paid',
  },
  {
    label: 'To Be Paid',
    value: 'To Be Paid',
  },
  {
    label: 'Registration Closed',
    value: 'Registration Closed',
  },
];
export const statusEnums = {
  PAID: 'Paid',
  TO_BE_PAID: 'To Be Paid',
  REGISTRATION_CLOSED: 'Registration Closed',
};
export const ToBePaidStatusFilterValue = 'To Be Paid';
export const formatTimeZoneString = (timeZoneString) => {
  if (!timeZoneString) {
    return '';
  }
  const firstLetter = timeZoneString[0];
  const lastLetter = timeZoneString[timeZoneString.length - 1];
  return firstLetter + lastLetter;
};

export const registrationFilterOptions = [
  {
    label: 'Paid',
    value: 3,
  },
  {
    label: 'To be Paid',
    value: 1,
  },
  {
    label: 'Refund Pending',
    value: 2,
  },
  {
    label: 'Fee Waiver',
    value: 6,
  },
  {
    label: 'Payment Refunded',
    value: 0,
  },
  {
    label: 'No Charge Event',
    value: 5,
  },
  {
    label: 'Payment Confirmed',
    value: 4,
  },
  {
    label: 'Registration Cancelled',
    value: -1,
  },
];
export const registrationAdminOptions = [
  {
    label: 'Paid',
    value: 3,
  },
  {
    label: 'To be Paid',
    value: 1,
  },
  {
    label: 'Refund Pending',
    value: 2,
  },
  {
    label: 'Fee Waiver',
    value: 6,
  },
  {
    label: 'No Charge Event',
    value: 5,
  },
  {
    label: 'Payment Confirmed',
    value: 4,
  },
];

export const KeywordColumnValues = ['name'];

export const keyWordRadioOptions = [
  {label: 'Active', value: 1},
  {label: 'Disabled', value: 0},
];

export const CouponCodeRaioOptions = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

export const KeyWordStatus = [
  {label: 'ACTIVE', value: 1},
  {label: 'DISABLED', value: 0},
];

export const defaultDropdownValue = [];

// admin table filter arrays
export const userFilters = [
  {label: 'First Name', name: 'first_name', initialValue: ''},
  {label: 'Last Name', name: 'last_name', initialValue: ''},
  {label: 'Email', name: 'email', initialValue: ''},
  {label: 'Company Name', name: 'company', initialValue: ''},
  {
    label: 'Phone Number',
    name: 'phone_number',
    type: 'number',
    initialValue: '',
  },
  {label: 'City', name: 'city', initialValue: ''},
  {label: 'State', name: 'state', initialValue: ''},
  {label: 'Country', name: 'country', initialValue: ''},
  {label: 'Postal Code', name: 'postal_code', initialValue: ''},
  {label: 'Fax Number', name: 'fax_number', initialValue: ''},
  {
    label: 'Role',
    name: 'role',
    type: 'dropdown',
    options: UserRoleOptions,
    initialValue: defaultDropdownValue,
  },
  {
    label: 'Chapter Name',
    name: 'local_chapter_id',
    type: 'dropdown',
    initialValue: defaultDropdownValue,
  },
  {
    label: 'Status',
    name: 'status',
    type: 'dropdown',
    options: UserStatusDropdownOptions,
    initialValue: defaultDropdownValue,
  },
];

export const speakerFilters = [
  {label: 'First Name', name: 'first_name', initialValue: ''},
  {label: 'Last Name', name: 'last_name', initialValue: ''},
  {label: 'Email', name: 'email', initialValue: ''},
  {label: 'Company Name', name: 'company_name', initialValue: ''},
  {
    label: 'Phone Number',
    name: 'phone_number',
    type: 'number',
    initialValue: '',
  },
];

export const sponsorFilters = [
  {label: 'Name', name: 'name', initialValue: ''},
  {label: 'Company Name', name: 'company_name', initialValue: ''},
  {label: 'Email', name: 'email', initialValue: ''},
  {
    label: 'Phone Number',
    name: 'phone_number',
    type: 'number',
    initialValue: '',
  },
];

export const adsFilters = [
  {label: 'Vendor', name: 'vendor', initialValue: ''},
  {label: 'Email', name: 'email', initialValue: ''},
  // { label: 'Quantity', name: 'qty', initialValue: '' },
  {
    label: 'Status',
    name: 'status',
    type: 'dropdown',
    options: AdsAndJobsOptionsForAdmin,
    initialValue: defaultDropdownValue,
  },
  {
    label: 'Date Range',
    name: 'date',
    type: 'dateRange',
    initialValue: '',
  },
];

export const jobsFilters = [
  {label: 'Employer name', name: 'employer', initialValue: ''},
  {label: 'Position', name: 'position', initialValue: ''},
  {label: 'Location', name: 'location', initialValue: ''},
  {
    label: 'Status',
    name: 'status',
    type: 'dropdown',
    options: AdsAndJobsOptionsForAdmin,
    initialValue: defaultDropdownValue,
  },
  {
    label: 'Date Range',
    name: 'date',
    type: 'dateRange',
    initialValue: '',
  },
];

export const eventRegistrationsFilters = [
  {label: 'First Name', name: 'attendee_first_name', initialValue: ''},
  {label: 'Last Name', name: 'attendee_last_name', initialValue: ''},
  {label: 'Email', name: 'attendee_email', initialValue: ''},
  {label: 'Company Name', name: 'attendee_company', initialValue: ''},
  {
    label: 'Status',
    name: 'status',
    type: 'dropdown',
    options: registrationFilterOptions,
    initialValue: defaultDropdownValue,
  },
];
export const eventRefundsFilters = [
  {label: 'First Name', name: 'attendee_first_name', initialValue: ''},
  {label: 'Last Name', name: 'attendee_last_name', initialValue: ''},
  {label: 'Email', name: 'attendee_email', initialValue: ''},
  {label: 'Company Name', name: 'attendee_company', initialValue: ''},
];

export const chapterFilters = [
  {label: 'Name', name: 'name', initialValue: ''},
  {label: 'User', name: 'nvp_user', initialValue: ''},
  {label: 'Email', name: 'pp_email', initialValue: ''},
  {label: 'Payment Gateway', name: 'payment_gateway', initialValue: ''},
];
// Job Fees
export const jobFeesFilters = [];

// Ad Fees
export const adFeesFilters = [];

export const keywordFilters = [
  {label: 'Name', name: 'name', initialValue: ''},
  {
    label: 'Status',
    name: 'status',
    type: 'radio',
    options: keyWordRadioOptions,
    initialValue: '',
  },
];

export const couponCodesUsersFilter = [
  {label: 'Name', name: 'attendee_first_name', initialValue: ''},
  {
    label: 'Email',
    name: 'attendee_email',
    initialValue: '',
  },
];

export const emailCodesFilters = [
  {label: 'Details', name: 'details', initialValue: ''},
];

export const companyFilters = [
  {label: 'Name', name: 'name', initialValue: ''},
  {
    label: 'Phone number',
    name: 'phone_number',
    type: 'number',
    initialValue: '',
  },
  {label: 'City', name: 'city', initialValue: ''},
  {label: 'State', name: 'state', initialValue: ''},
  {label: 'Country', name: 'country', initialValue: ''},
  {label: 'Postal code', name: 'postal_code', initialValue: ''},
  {label: 'Fax number', name: 'fax_number', initialValue: ''},
  {
    label: 'Sales tax rate',
    name: 'sales_tax_rate',
    type: 'number',
    initialValue: '',
  },
  {label: 'Payment terms', name: 'payment_terms', initialValue: ''},
];

export const eventOptionGroupFilters = [
  {label: 'Name', name: 'name', initialValue: ''},
  {label: 'Price', name: 'price', initialValue: ''},
];

export const specialPaymentFilters = [
  {label: 'Name', name: 'name', initialValue: ''},
];

export const eventTypesFilters = [
  {label: 'Name', name: 'name', initialValue: ''},
];

export const commonSelectAllInitialValue = (data) => {
  const selectData = data.map((item) => {
    return item?.value;
  });
  return selectData;
};
// export const commonSelectAllInitialValueFunction = (
//   handleChapterWithoutLocal,
// ) => {
//   console.log(handleChapterWithoutLocal);
//   const dataSelect = commonSelectAllInitialValue(
//     handleChapterWithoutLocal?.filter((data) => data?.value !== 'all'),
//   );
//   return dataSelect;
// };

/**
 * Truncates HTML content to a specified number of words.
 *
 * @param {string} html - The HTML content to truncate.
 * @param {number} limit - The maximum number of words to keep.
 * @returns {string} - The truncated content.
 */

export function truncateHtmlToWords(html, limit) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  let text = doc.body.innerHTML;

  // Remove HTML comments
  text = text.replace(/<!--[\s\S]*?-->/g, '');

  const div = document.createElement('div');
  div.innerHTML = text;
  text = div.textContent || div.innerText || '';

  const words = text.split(/\s+/);
  return words.length > limit ? words.slice(0, limit).join(' ') + '...' : text;
}

/**
 * Truncates text to a specified number of words.
 *
 * @param {string} text - The text to truncate.
 * @param {number} limit - The maximum number of words to keep.
 * @returns {string} - The truncated text.
 */
export function truncateTextToWords(text, limit) {
  const words = text.split(/\s+/);
  return words.length > limit ? words.slice(0, limit).join(' ') + '...' : text;
}

/**
 * Calculates the number of sponsors to show based on the width of the container.
 *
 * @param {number} width - The width of the container.
 * @param {number} totalSponsors - The total number of sponsors.
 * @returns {number} - The number of sponsors to show.
 */
export const calculateSponsorsToShow = (width, totalSponsors, addScroll) => {
  if (width >= 1900) {
    return 10;
  } else if (width >= 1715) {
    return 9;
  } else if (width >= 1515) {
    return 8;
  } else if (width >= 1330) {
    return 7;
  } else if (width >= 1130) {
    return 6;
  } else if (width >= 950) {
    return 5;
  } else if (width >= 750) {
    return 4;
  } else if (width >= 550) {
    return addScroll ? 3 : 6;
  } else {
    return addScroll ? totalSponsors : 6;
  }
};
/**
 * Calculates the number of sponsors to show based on the width of the container.
 *
 * @param {number} width - The width of the container.
 * @param {number} totalSponsors - The total number of sponsors.
 * @returns {number} - The number of sponsors to show.
 */
export const calculateMajorSponsorsToShow = (
  width,
  totalSponsors,
  addScroll,
) => {
  if (width >= 1900) {
    return 10;
  } else if (width >= 1715) {
    return 7;
  } else if (width >= 1515) {
    return 6;
  } else if (width >= 1330) {
    return 5;
  } else if (width >= 1130) {
    return 8;
  } else if (width >= 950) {
    return 8;
  } else if (width >= 750) {
    return 6;
  } else if (width >= 550) {
    return addScroll ? 4 : 6;
  } else {
    return addScroll ? totalSponsors : 6;
  }
};

export const checkValuesForPerks = (value) =>
  value ? value : <CloseOutlined className='sponsor-option-cross' />;

/**
 * Formats the event data into a more readable format.
 *
 * @param {Array} eventData - The event data to be formatted. Each item in the array should be an object with properties like event_id, sponsor, date, name, place, total_price, event_type_name, local_chapter_normalized_name, local_chapter_name, registration_status, and status.
 * @returns {Array} The formatted event data. Each item in the array is an object with properties like key, id, sponsorshipAvailable, date, eventName, location, price, eventType, chapter, and status.
 */
export const formatMyEventsData = (eventData) => {
  const data = eventData?.map((item) => {
    return {
      key: item?.event_id,
      id: item?.event_id,
      sponsorshipAvailable: `${
        Array.isArray(item?.sponsor) ? item?.sponsors?.length : 0
      } Sponsorship Available`,
      date: item?.event_start_date_time,
      eventName: item?.name,
      speakers: Array.isArray(item?.speakers)
        ? item?.speakers.map((speakers) => {
            return (
              <div key={speakers}>
                {speakers?.first_name + ' ' + speakers?.last_name}
              </div>
            );
          })
        : '',
      sponsors: item?.sponsors || [],
      attendees: item?.normal || [],
      discount: item?.discount,
      discounted_price: item?.discounted_price,
      location: item?.place,
      price: item?.total_price,
      eventInfo: item?.event_info || 'No Info',
      eventType: item?.event_type_name,
      chapter: item?.local_chapter_name,
      registration_status: item?.registration_status,
      status: item?.status,
      refund_last_date: item?.refund_last_date,
      event_start_date: item?.event_start_date_time,
      waitingList: item?.waitingList,
    };
  });

  return data;
};

export const stripePromise = loadStripe(applicationConfig.STRIPE_PROMISE_KEY);
export const createStipePromise = (key) => loadStripe(key);

//common function for saelect tagRender
export function tagRender(props) {
  const {label, closable, onClose} = props;
  return (
    <div>
      {closable && (
        <span onClick={onClose} style={{marginLeft: 8, cursor: 'pointer'}}>
          <Tag color={'geekblue'}>
            {label} <CloseOutlined />
          </Tag>
        </span>
      )}
    </div>
  );
}

//common function for max tagRender
export const maxTagPlaceholder = (oversizedTagList) => {
  const exceededCount = oversizedTagList.length;
  return (
    <Tooltip
      prefixCls='common-pbss-tooltip'
      title={oversizedTagList.map(({label}) => label).join(', ')}>
      <Tag key='exceeded' color='geekblue'>
        +{exceededCount} more
      </Tag>
    </Tooltip>
  );
};
export const maxTagPlaceholderKeyword = (oversizedTagList) => {
  const exceededCount = oversizedTagList.length;
  return (
    <Tooltip
      prefixCls='common-pbss-keyword-tooltip'
      title={oversizedTagList.map(({label}) => label).join(', ')}>
      <Tag key='exceeded' color='geekblue'>
        +{exceededCount} more
      </Tag>
    </Tooltip>
  );
};

//CUSTOM VALIDATION FUNCTIONS
const lengthRegex = /^.{1,255}$/;
const beginRegex = /^\S/;
const regexSpace = /\s{4,}/g;

/**
 * Disables the selection of previous dates in a date picker.
 * If currentDayActive is true, it checks if the current date is less than today's date.
 * If currentDayActive is false, it checks if the current date is less than the end of today's date.
 *
 * @param {moment} current - The current date to check.
 * @param {boolean} currentDayActive - Whether to check against the start or end of today's date.(true: current day is active, false: current day is disabled)
 * @returns {boolean} - Returns true if the current date is less than today's date (or the end of today's date), otherwise false.
 */

export const disablePrevDatesHandler = (current, currentDayActive) => {
  let currentDate = moment().format('YYYY-MM-DD');
  return currentDayActive
    ? current && current < moment(currentDate)
    : current && current < moment(currentDate).endOf('day');
};

// when we select date from date picker and we want time by default to be 00:00:00 if user does not select time then pass this to showTime={showTimeDefaultValue}
export const showTimeDefaultValue = {
  defaultValue: moment('00:00', 'HH:mm'),
};

/**
 * Validates a date-time value.
 * If the value is not provided or the value is a future date-time, it resolves the promise.
 * If the value is a past date-time, it rejects the promise with an error message.
 *
 * @param {moment} value - The date-time value to validate.
 * @param {string} text - The error message to display if the value is in the past.(Input field name or label name)
 * @returns {Promise} - Returns a resolved promise if the value is valid, otherwise a rejected promise with an error message.
 */

export const dateTimeValidator = (value, text) => {
  if (!value || (value && value.isAfter(moment()))) {
    return Promise.resolve();
  }
  return Promise.reject(new Error(`${text} time cannot be in the past`));
};

/**
 * This function validates the input value to ensure it's not empty or doesn't contain four or more consecutive spaces.
 *
 * @param {_} _ - This parameter is not used in the function.
 * @param {string} value - The input value to be validated.
 * @returns {Promise} - Returns a Promise that either resolves if the input is valid, or rejects with an Error if the input is invalid.
 * @throws {Error} - Throws an error if the input is empty or contains four or more consecutive spaces.
 */
export const NoEmptySpaceInput = (value, name, maxLength = 255) => {
  const regex = new RegExp(`^(?!\\s).{1,${maxLength}}$`);

  if (value && !regex.test(value)) {
    return Promise.reject(
      new Error(
        `${name} cannot include space at the beginning and should be less than or equal to ${maxLength} characters!`,
      ),
    );
  } else {
    return Promise.resolve();
  }
};

/**
 * Validates a string input for empty spaces and word limit.
 *
 * @param {string} value - The string to be validated.
 * @param {string} name - The name to be used in the error message.
 * @param {number} [maxWords=240] - The maximum number of words allowed.
 *
 * @returns {Promise} - Resolves if the string is valid, rejects with an error message if not.
 *
 * @throws {Error} If the string contains more than 10 consecutive spaces.
 * @throws {Error} If the string contains more than the specified maximum number of words.
 */
export const NoEmptySpaceWithLimitedWordsInput = (
  value,
  name,
  maxWords = 240,
) => {
  const regex = /( {11,})/;
  const totalWords = value && value?.trim().split(/\s+/).length;

  if (value && regex.test(value.trim())) {
    return Promise.reject(
      new Error(`${name} cannot include more then 10  consecutive space.`),
    );
  } else if (value && totalWords >= maxWords) {
    return Promise.reject(
      new Error(`${name} should not exceed ${maxWords} words!`),
    );
  } else {
    return Promise.resolve();
  }
};

/**
 * Validates the contact details.
 *
 * @param {string} contactDetails - The contact details to be validated.
 * @param {string} contactName - The name of the contact details.
 * @return {Promise} A promise that resolves if the validation passes, or rejects with an error if the validation fails.
 */
export const validateContactDetails = (
  contactDetails,
  contactName,
  minLength = 10,
) => {
  const regex = new RegExp(`^(?!\\s).{${minLength},}$`);

  if (contactDetails && !regex.test(contactDetails)) {
    const errorMessage = `${contactName} should have at least ${minLength} characters.`;
    return Promise.reject(new Error(errorMessage));
  }

  return Promise.resolve();
};
/**
 * This function validates the input value is email or not and conatins four or  more consecutive spaces.
 *
 * @param {_} _ - This parameter is not used in the function.
 * @param {string} value - The input value to be validated.
 * @returns {Promise} - Returns a Promise that either resolves if the input is valid, or rejects with an Error if the input is invalid.
 * @throws {Error} - Throws an error if the input is empty or contains four or more consecutive spaces.
 */
export const validateEmailInput = (value, name) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (value && !emailRegex.test(value)) {
    return Promise.reject(new Error(`Email should be a valid email address!`));
  } else {
    return Promise.resolve();
  }
};

/**
 * This function validates the input value to ensure the description is 150 character long or not and doesn't contain four or more consecutive spaces.
 *
 * @param {_} _ - This parameter is not used in the function.
 * @param {string} value - The input value to be validated.
 * @returns {Promise} - Returns a Promise that either resolves if the input is valid, or rejects with an Error if the input is invalid.
 * @throws {Error} - Throws an error if the input is empty or contains four or more consecutive spaces.
 */
export const validateAdsDescriptionInput = (value, name) => {
  const adsLengthRegex = /^.{1,150}$/;

  if (value && !beginRegex.test(value)) {
    return Promise.reject(new Error(`${name} cannot include space!`));
  } else if (value && !adsLengthRegex.test(value)) {
    return Promise.reject(
      new Error(`${name} should be less than 150 characters!`),
    );
  } else if (value && regexSpace.test(value)) {
    return Promise.reject(
      new Error(`${name} should not have consecutive spaces!`),
    );
  } else {
    return Promise.resolve();
  }
};

/**
 * This function validates the input value to ensure it's not empty, doesn't contain four or more consecutive spaces, and is a valid URL.
 *
 * @param {_} _ - This parameter is not used in the function.
 * @param {string} value - The input value to be validated.
 * @returns {Promise} - Returns a Promise that either resolves if the input is valid, or rejects with an Error if the input is invalid.
 * @throws {Error} - Throws an error if the input is empty, contains four or more consecutive spaces, or is not a valid URL.
 */
export const urlInputValidator = (_, value) => {
  const regexUrl = /^(?:www\.)?[a-zA-Z]+\.[a-zA-Z]+$/i;
  if (value && !beginRegex.test(value)) {
    return Promise.reject(new Error('Url cannot include space!'));
  } else if (value && !regexUrl.test(value)) {
    return Promise.reject(new Error('Invalid URL!'));
  } else if (value && !lengthRegex.test(value)) {
    return Promise.reject(new Error(`Url should be less than 255 characters!`));
  } else {
    return Promise.resolve();
  }
};

/**
 * Validates input for no empty spaces and only alphabets allowed.
 * @param {any} _ - Placeholder for a parameter that isn't used.
 * @param {string} value - The input value to validate.
 * @returns {Promise} - A promise that resolves if the input is valid, and rejects with an error message if the input is invalid.
 */
export const OnlyAlphabetInputValidator = (
  value,
  name,
  maxLengthForInput = 254,
) => {
  const alphabetRegex = new RegExp(`^[a-zA-Z ]{0,${maxLengthForInput}}$`);
  if (value && !alphabetRegex?.test(value)) {
    return Promise.reject(
      new Error(
        ApplicationString['common.validation.onlyAlphabet']
          .replace('#{name}', name)
          .replace('#{maxLength}', maxLengthForInput + 1),
      ),
    );
  } else {
    return Promise.resolve();
  }
};

/**
 * Validates company name input.
 *
 * @param {string} inputValue - the input value to validate
 * @param {string} inputName - the name of the input field
 * @param {number} [maxLength=255] - the maximum length allowed for the input
 * @returns {Promise} - resolves if the input value is valid, rejects with an error message otherwise
 */
export const validateCompanyName = (value, name, maxLengthForInput = 255) => {
  if (value && value.length >= maxLengthForInput) {
    return Promise.reject(
      new Error(`${name} should be less than ${maxLengthForInput} characters!`),
    );
  } else {
    return Promise.resolve();
  }
};
/**
 * Validates input for no empty spaces and only alphabets allowed with special characters.
 * @param {any} _ - Placeholder for a parameter that isn't used.
 * @param {string} value - The input value to validate.
 * @returns {Promise} - A promise that resolves if the input is valid, and rejects with an error message if the input is invalid.
 */
export const OnlyAlphabetInputValidatorWithSpecialCharacters = (
  value,
  name,
  maxLengthForInput = 254,
) => {
  const alphabetRegex = new RegExp(`^[^0-9]{0,${maxLengthForInput}}$`);

  if (value && !alphabetRegex.test(value)) {
    return Promise.reject(
      new Error(
        `${name} does not allow numbers and it should be less than ${
          maxLengthForInput + 1
        } characters!`,
      ),
    );
  } else {
    return Promise.resolve();
  }
};

/**
 * Validates a place input value.
 *
 * @param {string} value - The input value to validate.
 * @param {string} name - The name of the input field.
 * @param {number} [maxLengthForInput] - The maximum length for the input value.
 * @return {Promise<void>} - A promise that resolves if the input is valid, and rejects with an error message if the input is invalid.
 */
export const validatePlaceForEvents = (value, name) => {
  const alphabetRegex = new RegExp(`^[^0-9]`);

  if (value && !alphabetRegex.test(value)) {
    return Promise.reject(
      new Error(`${name} should include alphabets and special characters!`),
    );
  } else {
    return Promise.resolve();
  }
};

/**
 * Validates password for no empty spaces and minimum length of 8.
 * @param {any} _ - Placeholder for a parameter that isn't used.
 * @param {string} value - The password to validate.
 * @returns {Promise} - A promise that resolves if the password is valid, and rejects with an error message if the password is invalid.
 */
export const UserPasswordValidator = (_, value) => {
  if (value && !beginRegex.test(value)) {
    return Promise.reject(new Error('Password should not be empty!'));
  } else if (value && regexSpace.test(value)) {
    return Promise.reject(new Error('Password cannot contain spaces!'));
  } else if (value && value.length < 8) {
    return Promise.reject(
      new Error('Password must be at least 8 characters long!'),
    );
  } else {
    return Promise.resolve();
  }
};

export const validateEventOptionNumberInput = (value, name, max = 10) => {
  const numberRegex = /^[0-9]+$/;
  const valueToString = value?.toString();
  const num = Number(value);
  if ((valueToString && !numberRegex.test(value)) || num < 0 || num > max) {
    return Promise.reject(
      new Error(
        `Please enter a number for the ${name}, but make sure it's ${max} or less.`,
      ),
    );
  }
  return Promise.resolve();
};

/**
 * This function validates the input value is number or not and contains four or  more consecutive spaces , without including decimal numbers.
 *
 * @param {_} _ - This parameter is not used in the function.
 * @param {string} value - The input value to be validated.
 * @returns {Promise} - Returns a Promise that either resolves if the input is valid, or rejects with an Error if the input is invalid.
 * @throws {Error} - Throws an error if the input is empty or contains four or more consecutive spaces.
 */
export const NumberWithoutDecimalValidation = (
  value,
  name,
  max = 999999999,
) => {
  const valueToString = value?.toString();
  const numberValidateRegex = new RegExp(
    `^(0|[1-9]\\d{0,${Math.floor(Math.log10(max))}})$`,
  );

  if (valueToString && !numberValidateRegex.test(valueToString)) {
    return Promise.reject(
      new Error(`${name} should be a number less than ${max}`),
    );
  } else {
    return Promise.resolve();
  }
};

// export const PriceLimitationValidation = (value, name, max = 9999999) => {
//   const valueToString = value?.toString();
//   const priceValidationWithDecimal = /^\d{0,7}\.?\d{0,2}$/;
//   if (value && !priceValidationWithDecimal.test(valueToString)) {
//     return Promise.reject( new Error(`${name} should be a number less than ${max}`));
//   } else {
//     return Promise.resolve();
//   }
// };

// /**
//  * This function validates the input value for place amd contains only alphabets and conatins four or  more consecutive spaces.
//  *
//  * @param {_} _ - This parameter is not used in the function.
//  * @param {string} value - The input value to be validated.
//  * @returns {Promise} - Returns a Promise that either resolves if the input is valid, or rejects with an Error if the input is invalid.
//  * @throws {Error} - Throws an error if the input is empty or contains four or more consecutive spaces.
//  */

// export const validatePlace = (value, name) => {
//   const regexForPlace = /^[a-zA-Z ]*$/;
//   const emptySpaceRegex = /\s{4,}/g;
//   if (!value) {
//     return Promise.reject(`${name} should not be empty!`);
//   } else if (!value.match(regexForPlace)) {
//     return Promise.reject(`${name} should include only alphabets!`);
//   } else if (value.trim() === '') {
//     return Promise.reject(`${name} should not be empty!`);
//   } else if (emptySpaceRegex.test(value)) {
//     return Promise.reject('Too many consecutive spaces!');
//   }
//   return Promise.resolve();
// };

// /**
//  * This function validates the input value is number or not and conatins four or  more consecutive spaces , with the decimal number including.
//  *
//  * @param {_} _ - This parameter is not used in the function.
//  * @param {string} value - The input value to be validated.
//  * @returns {Promise} - Returns a Promise that either resolves if the input is valid, or rejects with an Error if the input is invalid.
//  * @throws {Error} - Throws an error if the input is empty or contains four or more consecutive spaces.
//  */
// export const NumberWithDecimalValidation = (value, name) => {
//   const valueToString = value?.toString();
//   if (value && !beginRegex.test(value)) {
//     return Promise.reject(new Error(`${name} cannot include space!`));
//   } else if (value && regexSpace.test(value)) {
//     return Promise.reject(
//       new Error(`${name} should not include consecutive spaces!`),
//     );
//   } else if (value && isNaN(value)) {
//     return Promise.reject(new Error(`${name} should be number only!`));
//   } else if (value && !lengthRegex.test(value)) {
//     return Promise.reject(
//       new Error(`${name} should be less than 255 characters!`),
//     );
//   } else {
//     return Promise.resolve();
//   }
// };

/*
 * This function validates the input value for event name with the custom validations to not include consecutive special characters , only include four special character in whole string and  conatins four or  more consecutive spaces.
 *
 * @param {_} _ - This parameter is not used in the function.
 * @param {string} value - The input value to be validated.
 * @returns {Promise} - Returns a Promise that either resolves if the input is valid, or rejects with an Error if the input is invalid.
 * @throws {Error} - Throws an error if the input is empty or contains four or more consecutive spaces.
 */
export const validateEventString = (value, name) => {
  const regexSpecialCharacterConsecutive =
    /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{4,}/;
  const hasAlphabetCharacter = /[a-zA-Z]/;
  const specialCharactersRegex = /[!@#$%^&*(),.?":{}|<>]/g;

  // Check if string contains more than 4 consecutive spaces
  if (value && regexSpace.test(value)) {
    return Promise.reject(
      new Error(`${name} should not include consecutive spaces!`),
    );
  }

  // // Check if string contains more than 3 consecutive special characters
  // if (value && regexSpecialCharacterConsecutive.test(value)) {
  //   return Promise.reject(
  //     new Error(
  //       `${name} should not include more than three consecutive special characters!`,
  //     ),
  //   );
  // }
  // Check if string contains only special characters or not
  if (value && !hasAlphabetCharacter.test(value)) {
    return Promise.reject(
      new Error(`${name} should contain at least one alphabet character.`),
    );
  }

  if (value && !beginRegex.test(value)) {
    return Promise.reject(new Error(`${name} cannot include space!`));
  }
  // If all checks pass, return true
  return Promise.resolve();
};

/**
 * This function validates the input value for discount that it should be number and if dscount is selected as price then value must be greater than 0 and if discount is percentage then it shoub be between 1 to 100.
 *
 * @param {_} _ - This parameter is not used in the function.
 * @param {string} value - The input value to be validated.
 * @returns {Promise} - Returns a Promise that either resolves if the input is valid, or rejects with an Error if the input is invalid.
 * @throws {Error} - Throws an error if the input is empty or contains four or more consecutive spaces.
 */
export const validateDiscount = (percentage_discount, _, value) => {
  let validValue = Number(value);
  if (value && isNaN(value)) {
    return Promise.reject(new Error('Discount should be a number!'));
  } else if (value && !beginRegex.test(value)) {
    return Promise.reject(new Error(`Discount cannot include space!`));
  } else if (value && percentage_discount === 'price' && validValue <= 0) {
    return Promise.reject(new Error('Price must be greater than 0!'));
  } else if (
    value &&
    percentage_discount === 'percentage' &&
    (validValue < 1 || validValue > 100)
  ) {
    return Promise.reject(new Error('Percentage must be between 1 and 100!'));
  } else {
    return Promise.resolve();
  }
};

/**
 * This function validates the input value for phone number and conatins four or  more consecutive spaces.
 *
 * @param {_} _ - This parameter is not used in the function.
 * @param {string} value - The input value to be validated.
 * @returns {Promise} - Returns a Promise that either resolves if the input is valid, or rejects with an Error if the input is invalid.
 * @throws {Error} - Throws an error if the input is empty or contains four or more consecutive spaces.
 */

export const validatePhoneNumber = (value, name) => {
  const phoneNumberRegex = /^(\+\d{1,3})\d{9,10}$/;

  if (value && !phoneNumberRegex.test(value)) {
    return Promise.reject(
      new Error(
        `Please enter a valid ${name}, including the country code, with a minimum of 10 digits and a maximum of 13 digits.`,
      ),
    );
  }

  return Promise.resolve();
};

export const validateNormalizedName = (_, value) => {
  const regex = /^[a-zA-Z-]+$/; // Regex for allowing only letters and hyphens

  if (!value || regex.test(value)) {
    return Promise.resolve();
  }

  return Promise.reject(
    'Display name can only contain letters and hyphens without spaces in between',
  );
};

export const validateNumberInput = (numberInput) => {
  // Regular expression to match only whole numbers
  const numberRegex = /^\d+$/;
  return numberRegex.test(numberInput);
};

export const validateNumberRange = (_, value) => {
  const regex = /^(?:1000|[1-9]\d{0,2})$/;

  if (!value || regex.test(value)) {
    return Promise.resolve();
  }

  return Promise.reject('Please enter a valid number from 1 and 1000');
};

export const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    {length: currentYear - 2007 + 1},
    (_, index) => currentYear - index,
  );
  const options = [
    {label: 'Clear Filter', value: null},
    ...years.map((year) => ({
      value: year,
      label: year.toString(),
    })),
  ];

  return options;
};

export const filterFoodOptionColumnsForAttendee = (
  columns,
  eventType,
  isEditing,
) => {
  let filteredColumns;
  if (eventType?.toLowerCase() === 'webcast') {
    filteredColumns = columns
      .filter((column) => column.dataIndex !== 'food_option')
      .map((col) => {
        return {
          ...col,
          onCell: (record) => ({
            record,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record) && col.editable,
          }),
        };
      });
  } else {
    filteredColumns = columns.map((col) => {
      return {
        ...col,
        onCell: (record) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record) && col.editable,
        }),
      };
    });
  }

  return filteredColumns;
};
export const filterFoodOptionColumnsForSponsorAttendee = (
  columns,
  eventType,
  isEditing,
) => {
  let filteredColumns;
  if (eventType?.toLowerCase() === 'webcast') {
    filteredColumns = columns
      .filter((column) => column.dataIndex !== 'food_option')
      .map((col) => {
        if (!col.editable) {
          return col;
        }

        return {
          ...col,
          onCell: (record, rowIndex) => {
            return {
              record,
              dataIndex: col.dataIndex,
              title: col.title,
              editing: isEditing(record),
              index: rowIndex,
            };
          },
        };
      });
  } else {
    filteredColumns = columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record, rowIndex) => {
          return {
            record,
            dataIndex: col.dataIndex,
            inputType: 'text',
            title: col.title,
            editing: isEditing(record),
            index: rowIndex,
          };
        },
      };
    });
  }

  return filteredColumns;
};

export const ReturnNAOnEmpty = (data) => {
  return data ? data : 'N/A';
};

export const EventStatusEnum = {
  PRIVATE: -2,
  TENTATIVE: -1,
  ACTIVE: 0,
  DRAFT: 1,
  REG_CLOSED: 2,
};

/**
 * Handles JWT expiration error messages.
 *
 * @param {Object} error - The error object that may contain response data.
 * @param {string} originalMessage - The original error message.
 * @returns {string} - Returns an empty string based on conditions, otherwise returns the original message.
 */

export const handleJwtExpireMsg = (error, errorMessage) => {
  if (
    error?.response?.status === HttpCode.Unauthorized ||
    error?.response?.data?.errorMessage === JWT_EXPIRED ||
    AUTH_TOKEN_NOT_PROVIDED ||
    RETRY_LOGIN ||
    INVALID_REFRESH_TOKEN_PROVIDE
  ) {
    return '';
  } else {
    return errorMessage;
  }
};

export const sponsorDataMarquee = [
  {
    id: 1,
    icon: i1,
    name: 'PBSS ',
  },
  {
    id: 2,
    icon: i2,
    name: 'PBSS ',
  },
  {
    id: 3,
    icon: i3,
    name: 'PBSS ',
  },
  {
    id: 4,
    icon: i4,
    name: 'PBSS ',
  },
  {
    id: 5,
    icon: i5,
    name: 'PBSS ',
  },
  {
    id: 6,
    icon: i6,
    name: 'PBSS ',
  },
  {
    id: 7,
    icon: i7,
    name: 'PBSS ',
  },
  {
    id: 8,
    icon: i8,
    name: 'PBSS ',
  },
  {
    id: 9,
    icon: i9,
    name: 'PBSS ',
  },
  {
    id: 10,
    icon: i10,
    name: 'PBSS ',
  },
  {
    id: 11,
    icon: i11,
    name: 'PBSS ',
  },
  {
    id: 12,
    icon: i12,
    name: 'PBSS ',
  },
  {
    id: 13,
    icon: i13,
    name: 'PBSS ',
  },
  {
    id: 14,
    icon: i14,
    name: 'PBSS ',
  },
  {
    id: 15,
    icon: i15,
    name: 'PBSS ',
  },
  {
    id: 16,
    icon: i16,
    name: 'PBSS ',
  },
  {
    id: 17,
    icon: i17,
    name: 'PBSS ',
  },
  {
    id: 18,
    icon: i18,
    name: 'PBSS ',
  },
  {
    id: 19,
    icon: i19,
    name: 'PBSS ',
  },
];

export const getStatusInfo = (status) => {
  let color, normalizeName;

  switch (status) {
    case 'draft':
      color = 'orange';
      normalizeName = 'Draft';
      break;
    case 'in_review':
      color = 'blue';
      normalizeName = 'In Review';
      break;
    case 'rejected':
      color = 'red';
      normalizeName = 'Rejected';
      break;
    case 'approved':
      color = 'green';
      normalizeName = 'Approved';
      break;
    case 'expired':
      color = 'gray';
      normalizeName = 'Expired';
      break;
    default:
      color = 'default';
      normalizeName = 'Unknown';
  }

  return {color, normalizeName};
};

export const getStatusInfoMyEvent = (status) => {
  let color, nameText;
  switch (status) {
    case 'Paid':
      color = 'green';
      break;
    case 'To Be Paid':
      color = 'blue';
      break;
    case 'Registration Closed':
      color = 'red';
      break;
    default:
      color = 'orange';
      break;
  }

  nameText = status;

  return {color, nameText};
};

export const getStatusInfoOfEventRegistration = (status) => {
  const updatedStatus = +status;
  let color;
  let label;
  switch (updatedStatus) {
    case EventRegistrationStatus.PAID:
      color = 'green';
      label = 'Paid';
      break;
    case EventRegistrationStatus.PAYMENT_CONFIRMED:
      color = 'blue';
      label = 'Payment Confirmed';
      break;
    case EventRegistrationStatus.FEE_WAIVER:
      color = 'purple';
      label = 'Fee Waiver';
      break;
    case EventRegistrationStatus.TO_BE_DELETED:
      color = 'red';
      label = 'Registration Cancelled';
      break;
    case EventRegistrationStatus.PAYMENT_REFUNDED:
      color = 'purple';
      label = 'Payment Refunded';
      break;
    case EventRegistrationStatus.REFUND_PENDING:
      color = 'red';
      label = 'Refund Pending';
      break;
    case EventRegistrationStatus.NO_CHARGE_EVENT:
      color = 'blue';
      label = 'No Charge Event';
      break;
    default:
      color = 'red';
      label = 'To Be Paid';
  }
  return {color, label};
};

// validating the input for the number of months and showing proper message for respective errors
export const validateMonthsInput = (value, name) => {
  const num = Number(value);
  const integerRegex = /^\d+$/;
  if (isNaN(num)) {
    return Promise.reject(new Error(`${name} should be a number`));
  } else if (!value) {
    return Promise.reject(new Error(`Please fill out months!`));
  } else if (num < 1) {
    return Promise.reject(new Error(`${name} should be greater than 0`));
  } else if (num > 24) {
    return Promise.reject(
      new Error(`${name} should be a number less than or equal to 24`),
    );
  } else if (!integerRegex.test(value)) {
    return Promise.reject(new Error(`${name} should be a whole number`));
  } else {
    return Promise.resolve();
  }
};

export const addHttpsToUrl = (url) => {
  if (!url?.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
};
export const removeHttpsFromUrl = (url) => {
  if (url?.startsWith('https://')) {
    return url.slice(8);
  }
  return url;
};
// Showing proper msg if price value exceeds 1 million
export const validatePaymentPrice = (price, dispatch) => {
  if (price > 999999) {
    dispatch(
      fetchError(
        "Can't create payment intent of more than 1 million at a time.",
      ),
    );
    return true;
  }
  return false;
};

// Function to validate the form values on value change
// used for event attendee & sponsor registration email fields
export const formValueChangeHandler = (
  form,
  changedValues,
  conditionString = '',
) => {
  // list out field names that need to be validated on value change
  if (conditionString === 'contactUs') {
    (Object.keys(changedValues).includes('email') ||
      Object.keys(changedValues).includes('email_confirm')) &&
      form.validateFields(['email_confirm']);
  } else {
    (Object.keys(changedValues).includes('attendee_email_confirm') ||
      Object.keys(changedValues).includes('attendee_email')) &&
      form.validateFields(['attendee_email_confirm']);
  }
};
/**
 * Truncates an input string to a specified number of words.
 *
 * @param {string} inputString - The input string to be truncated.
 * @param {number} maxWords - The maximum number of words to keep.
 * @return {string} The truncated string.
 */
export const truncateStringToWords = (inputString, maxWords = 10) => {
  const words = inputString.split(/\s+/);
  const truncatedString = words.slice(0, maxWords).join(' ');
  return (
    <Tooltip title={inputString}>
      <span>{truncatedString.trim()}</span>
    </Tooltip>
  );
};

export const getEmailDetails = (template) => {
  switch (template) {
    case 'Sponsorship Announcement':
      return {
        description:
          'Sent to all vendor representatives based on their email preference for in-person events.',
        userGroup: 'Vendor Representatives',
      };
    case 'Event Reminder':
      return {
        description:
          'Sent to members who have not registered for the event, based on their email preference.',
        userGroup: 'Members',
      };
    case 'Sponsorship Registration Confirmation':
      return {
        description:
          'Sent to vendors who have registered for an event’s sponsorship.',
        userGroup: 'Vendor Representatives',
      };
    case 'Event Initial Announcement':
      return {
        description:
          'Sent to members based on their email preference for all events.',
        userGroup: 'Members',
      };
    case 'Event Registration Confirmation':
      return {
        description: 'Sent to members who have registered for an event.',
        userGroup: 'Members',
      };
    case 'Password Request':
      return {
        description:
          'Sent to the user when they click on the Forgot Password button.',
        userGroup: 'All Users',
      };
    case 'Password Reset Confirmation':
      return {
        description:
          'Sent to members after they reset their PBSS profile password.',
        userGroup: 'Members',
      };
    case 'Profile Update':
      return {
        description: 'Sent to members who updated their profile on PBSS.',
        userGroup: 'Members',
      };
    case 'Event Attendance Reminder':
      return {
        description:
          'Sent to members who have registered for an in-person event.',
        userGroup: 'Members',
      };
    case 'Event Payment Reminder':
      return {
        description:
          'Sent to members who added an event in the cart but have not completed the payment.',
        userGroup: 'Members',
      };
    case 'Event Final Call':
      return {
        description:
          'Sent to members who have not registered for the event, based on their email preference.',
        userGroup: 'Members',
      };
    case 'Profile Creation':
      return {
        description: 'Sent to members who created a new profile on PBSS.',
        userGroup: 'Members',
      };
    case 'Special Payment Confirmation':
      return {
        description:
          'Sent as confirmation when a Special Payment has been made.',
        userGroup: 'Members',
      };
    case 'Ad Placement Confirmation':
      return {
        description: 'Sent after payment for an Ad placement.',
        userGroup: 'Members',
      };
    default:
      return {
        description: '',
        userGroup: '',
      };
  }
};

export const useCountryState = (editData, form) => {
  const [stateOptions, setStateOptions] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const availableStates = countryState.find((state) =>
    selectedCountry
      ? state.name === selectedCountry
      : state.name === editData?.country,
  );

  const selectedStates = availableStates
    ? availableStates.states.map((state) => ({
        label: state.name,
        value: state.name,
      }))
    : null;

  const handleStateChange = (stateName) => {
    form.setFieldsValue({
      state: stateName,
    });
    setStateOptions(stateName);
  };

  const countriesData = createSelectionOptionsUtil(
    countryState,
    'name',
    'name',
  );

  const handleCountryChange = (countryName) => {
    form.setFieldsValue({
      country: countryName,
      state: null,
    });
    setSelectedCountry(countryName);
    setStateOptions(null);
  };

  return {
    stateOptions,
    selectedCountry,
    selectedStates,
    countriesData,
    handleStateChange,
    handleCountryChange,
    setSelectedCountry,
  };
};

import React from 'react';
import EditSponsorshipInfoContainer from '../../../../Containers/AdminContainer/Chapters/EditSponsorshipInfo';

const EditSponsorshipInfoScreen = () => {
  return (
    <div>
      <EditSponsorshipInfoContainer />
    </div>
  );
};

export default EditSponsorshipInfoScreen;

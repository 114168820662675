import {usePermissions} from '../../../hooks/usePermission';

export function ProtectedResource({
  resource,
  permission,
  children,
  fallback = null,
}) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const userRole = userInfo?.role;
  const {checkPermission} = usePermissions(userRole);

  if (!checkPermission(resource, permission)) {
    return <>{fallback}</>;
  }

  return <>{children}</>;
}

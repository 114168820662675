import React, {useEffect, useState} from 'react';
import {Space, Table, Popconfirm, Button, Tooltip} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {PageSizeArray, PaginationPosition} from '../../../Util/commonUtility';
import {ApplicationString} from '../../../Localization/Locales/en';
import DynamicSearchForm from '../../CommonComponents/DynamicSearch';
import editIcon from '../../../Assets/editIcon.svg';
import deleteIcon from '../../../Assets/deleteIcon.svg';
import plus from '../../../Assets/Plus.svg';
import UpdateHistoryTableInfo from '../HistoryView';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {useSelector} from 'react-redux';
import HeaderSearchCard from '../../CommonComponents/HeaderSearchCard';
import {CREATE} from '../../../Constant/enums';

const JobFeeComponent = (props) => {
  const [expanded, setExpanded] = useState(false);
  let navigate = useNavigate();
  const {
    allJobFeeData,
    paging,
    handlePaginationChange,
    deleteJobFee,
    fetchJobFee,
    sortParams,
    handleTableChange,
    allUserDetails,
  } = props;
  const ps = useSelector(({pageSizes}) => pageSizes.pageSizes.jobFees);
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const handleSearch = (values) => {
    fetchJobFee(values);
  };
  const handleEditClick = async (id) => {
    navigate(`/admin/jobfees/update/${id}`);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 60,
    },
    {
      title: 'Fee',
      dataIndex: 'fee',
    },
    {
      title: 'Details',
      dataIndex: 'details',
    },
    {
      title: '',
      key: 'action',
      width: '6%',
      render: (_, record) => (
        <AdminActionsDropdown
          record={record}
          editBool={true}
          deleteBool={true}
          handleEditClick={handleEditClick}
          handleDeleteClick={deleteJobFee}
          text='Job Fee'
          resource='job_fees'
        />
      ),
    },
  ];
  columns.forEach((column) => {
    if (column.key !== 'action') {
      column.sorter = true;
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });
  return (
    <div className='main-container'>
      <HeaderSearchCard
        resource='job_fees'
        showFilter={false}
        buttonTitle={ApplicationString['jobFeesComponent.button.addForm']}
        addClick={() => navigate('/admin/jobfees/add')}
        dataTestId='JobFeeComponent-button-addJobFees'
        permission={CREATE}
        expanded={expanded}
      />

      <div className='listing-container'>
        <Table
          bordered
          className='admin-table'
          columns={columns}
          dataSource={allJobFeeData}
          rowKey={'id'}
          scroll={{x: 786}}
          onChange={handleTableChange}
          // sticky={true}
          pagination={{
            onChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            current: paging.page,
            total: paging.totalCount,
            pageSize: ps || paging.pagecount,
            pageSizeOptions: PageSizeArray,
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            showTotal: (total, range) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>
    </div>
  );
};

export default JobFeeComponent;

import React, { useEffect, useState } from 'react';
import ComponentTitle from '../CommonComponentTitle';
import './style.css';
import SponsorCard from './SponsorCards';
import { calculateSponsorsToShow } from '../../../Util/commonUtility';

const SponsorListing = ({ sponsorData, addScroll = false }) => {
  const [shouldRender, setShouldRender] = useState(false);
  const [allSponsor, setAllSponsorData] = useState(sponsorData);
  const [endIndexSponsor, setEndIndexSponsor] = useState(10);
  const [startIndexSponsor, setStartIndexSponsor] = useState(0);
  const [numSponsors, setNumSponsors] = useState(
    calculateSponsorsToShow(window.outerWidth, sponsorData?.length, addScroll),
  );
  const [sponsorSingleColumn, setSponsorSingleColumn] = useState(false);
  const isLogoScrolling = sponsorData?.length > 8 ? '' :'no-logo-scroll'
  const isLogoScroll = sponsorData?.length > 8 

  const handleNextSponsor = () => {
    if (endIndexSponsor < sponsorData.length) {
      setStartIndexSponsor(endIndexSponsor);
      setEndIndexSponsor(endIndexSponsor + numSponsors);
    }
  };

  const handlePrevSponsor = () => {
    if (startIndexSponsor - numSponsors >= 0) {
      setEndIndexSponsor(startIndexSponsor);
      setStartIndexSponsor(startIndexSponsor - numSponsors);
    }
  };

  useEffect(() => {
    if (sponsorData) {
      setAllSponsorData(sponsorData);
    }
  }, [sponsorData]);

  useEffect(() => {
    const data = sponsorData?.slice(
      startIndexSponsor,
      startIndexSponsor + numSponsors,
    );
    setAllSponsorData(data);
  }, [startIndexSponsor, numSponsors, sponsorData]);

  useEffect(() => {
    setStartIndexSponsor(0);
    setEndIndexSponsor(numSponsors);
  }, [numSponsors]);

  useEffect(() => {
    const handleWindowResize = () => {
      const sponsorsToShow = calculateSponsorsToShow(
        window.outerWidth,
        sponsorData?.length,
        addScroll,
      );
      setNumSponsors(sponsorsToShow);
      setShouldRender(window.outerWidth < 550);
      setSponsorSingleColumn(window.outerWidth <= 350);
    };
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [sponsorData, addScroll]);

  const renderCardElement = (data) => {
    return (
      <span>
        <div
          className={`${isLogoScrolling} ${ addScroll ? 'sponsor-scroll' : 'sponsor-grid'} ${sponsorSingleColumn
              ? addScroll
                ? ''
                : 'sponsor-single-column'
              : ''
            }`}>
          {sponsorData
            ? data?.map((item, index) => {
              return <SponsorCard key={index} icon={item?.name} />;
            })
            : ''}
        </div>
      </span>
    );
  };

  if (shouldRender) {
    return (
      <React.Fragment>
        <div className='sr-main-conatiner'>
          <ComponentTitle
            hideButton={shouldRender}
            textColor='white'
            title='Event Sponsors'
            OnNext={handleNextSponsor}
            OnPrev={handlePrevSponsor}
          />
          {renderCardElement(sponsorData)}
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className='sr-main-conatiner'>
          <ComponentTitle
            hideButton={true}
            textColor='white'
            title='Event Sponsors'
            OnNext={handleNextSponsor}
            OnPrev={handlePrevSponsor}
          />
          {
            isLogoScroll ?
              <div className='sponsor-marquee'>
                <div className='sponsor-marquee-inner'>
                  {renderCardElement(sponsorData?.slice(0, 10))}
                  {renderCardElement(sponsorData?.slice(10, 19))}
                </div>
              </div> :
              renderCardElement(sponsorData)
          }
        </div>
      </React.Fragment>
    );
  }
};

export default SponsorListing;

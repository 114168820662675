import {
  Chandra_Prakash,
  Cyrus_Khojasteh,
  Kevin_Grimes,
  Lilia_Koriazova,
  Michael_Varney,
  Peter_Korytko,
  Robyn_Rourick,
  Shichang_Miao,
  Young_Shin,
} from '../../../Resource/images';

export const CommitteeDataFromPBSSORG = [
  {
    designation: 'President',
    profile: Shichang_Miao,
    name: 'Shichang Miao, PhD',

    details: `
    <div class="committee-member-sub-heading-container" style="font-family: inter; color: rgba(0, 0, 0, 0.88); font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
      <p class="committee-member-sub-heading" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz16); font-weight: var(--desktop-fw600);">President, PBSS International</p>
      <p class="committee-member-sub-heading" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz16); font-weight: var(--desktop-fw600);">Scientific Program Co-Chair</p>
      <p class="committee-member-sub-heading" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz16); font-weight: var(--desktop-fw600);">San Francisco Bay Chapter Committee Member</p>
      <p class="committee-member-sub-heading" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz16); font-weight: var(--desktop-fw600);"><br></p>
   
    </div>
    <div class="committee-member-description-container" style="font-family: inter; color: rgba(0, 0, 0, 0.88); font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
      <p class="committee-member-description" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz15); font-weight: var(--desktop-fw400); text-align: left;">
        Dr. Shichang Miao is a seasoned biotech drug developer, investor (partner, Rivermount Fund), advisor/consultant and scientific society leader. He worked at several biotech companies in the San Francisco Bay Area (Tularik, ChemoCentryx and Amgen). As Vice President - Preclinical Development & Clinical Pharmacology at ChemoCentryx (acquired by Amgen in 2022), he led a team to conduct the preclinical assessment and clinical pharmacology studies for the development of Tavneos® (Avacopan), which was approved in 2021 by the FDA. He is the founder and president of Pharmaceutical & BioScience Society International (PBSS). Dr. Miao received his PhD training in organic chemistry and post-doctoral training in biochemistry at the University of British Columbia.
      </p>
    </div>
  `,
  },
  {
    designation: 'Chair, PBSS - San Francisco Bay Chapter',
    name: 'Peter Staehr, MD',
    details: `
    <div class="committee-member-sub-heading-container" style="font-family: inter; color: rgba(0, 0, 0, 0.88); font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
      <p class="committee-member-sub-heading" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz16); font-weight: var(--desktop-fw600);">Chair, PBSS - San Francisco Bay Chapter</p>
        <p class="committee-member-sub-heading" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz16); font-weight: var(--desktop-fw600);"><br></p>
    </div>
    <div class="committee-member-description-container" style="font-family: inter; color: rgba(0, 0, 0, 0.88); font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
      <p class="committee-member-description" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz15); font-weight: var(--desktop-fw400); text-align: left;">
        Dr. Peter Staehr is a senior executive medical doctor with 20+ years of global clinical development experience and executive management in Pharmaceutical/Biotech as well as Medical Drug-Device combination. He was trained as internist and cardiologist at Johannes-Gutenberg University Mainz/Germany and completed his postdoc fellowship at Stanford University. Dr. Staehr held demanding leadership roles in early- and late-stage (Phase 1-3) clinical development with increasing responsibilities at small to large-size organizations (J&J, Theravance, CV Therapeutics, Gilead, Abbott Vascular, ChemoCentryx). As the medical lead he oversaw numerous IND drug development programs in various therapeutic areas and was directly involved in the registration of Regadenoson (Lexiscan®) and Abbott’s Absorb™ bioresorbable stent. Dr. Staehr serves as consulting CMO of several companies and is the chairman of the San Francisco Bay Area chapter of the Pharmaceutical & BioScience Society (PBSS).
      </p>
    </div>
  `,
  },
  {
    designation: 'Co-Chair, PBSS - San Diego Chapter',
    profile: Robyn_Rourick,
    name: 'Robyn Rourick, MSc',
    details: `
    <div class="committee-member-sub-heading-container" style="font-family: inter; color: rgba(0, 0, 0, 0.88); font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
      <p class="committee-member-sub-heading" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz16); font-weight: var(--desktop-fw600);">Co-Chair, PBSS - San Diego Chapter</p>
        <p class="committee-member-sub-heading" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz16); font-weight: var(--desktop-fw600);"><br></p>
    </div>
    <div class="committee-member-description-container" style="font-family: inter; color: rgba(0, 0, 0, 0.88); font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
      <p class="committee-member-description" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz15); font-weight: var(--desktop-fw400); text-align: left;">
        Ms. Robyn Rourick, Senior Director at Genentech, Inc., leads bioanalytical outsourcing strategies for non-clinical and clinical PK, ADA, and biomarkers. With 4 years as Sr. Manager in Nonclinical Study Operations, she managed outsourced studies, serving as a liaison and developing operational strategies. Previously, at Kalypsys, Inc., she was Director of Pharmaceutical Sciences, focusing on analytical and pharmaceutical development for early phase clinical programs. Robyn also held key roles at DuPont Pharmaceuticals Research Labs, CombiChem, Inc., and Bristol-Myers Squibb, with a decade-long tenure. She holds a B.S. in Chemistry from Fairfield University and an MS in Forensic Chemistry from the University of New Haven. Additionally, Robyn is a Board Member of PBSS, chairs the San Diego Chapter, and is involved in the CPSA Charitable Foundation and CPSA USA.
      </p>
    </div>
  `,
  },
  {
    designation: 'Co-Chair, PBSS - San Diego Chapter',
    profile: Lilia_Koriazova,
    name: 'Lilia Koriazova, PhD',
    details: `
    <div class="committee-member-sub-heading-container" style="font-family: inter; color: rgba(0, 0, 0, 0.88); font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
      <p class="committee-member-sub-heading" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz16); font-weight: var(--desktop-fw600);">Co-Chair, PBSS - San Diego Chapter</p>
      <p class="committee-member-sub-heading" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz16); font-weight: var(--desktop-fw600);"><br></p>
    </div>
    <div class="committee-member-description-container" style="font-family: inter; color: rgba(0, 0, 0, 0.88); font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
      <p class="committee-member-description" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz15); font-weight: var(--desktop-fw400); text-align: left;">
        Dr. Lilia Koriazova is a Sr. Director of Biologics Pharmaceutical Sciences at Erasca, leading discovery of EGFR-targeting bispecific antibody for the treatment of solid tumors. She has more than 20 years of experience in R&D of therapeutic monoclonal antibodies, biologics process development, and CMC leadership at IDEC Pharmaceuticals, Kyowa Kirin, and Thermo Fisher Scientific. She received her postdoctoral training at UCSD and a PhD from Moscow Institute of Biotechnology.
      </p>
    </div>
  `,
  },
  {
    designation: 'Chair, Boston PBSS',
    profile: Chandra_Prakash,
    name: 'Chandra Prakash, PhD',
    details: `
    <div class="committee-member-sub-heading-container" style="font-family: inter; color: rgba(0, 0, 0, 0.88); font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
      <p class="committee-member-sub-heading" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz16); font-weight: var(--desktop-fw600);">Chair, Boston PBSS</p>
      <p class="committee-member-sub-heading" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz16); font-weight: var(--desktop-fw600);"><br></p>
    </div>
    <div class="committee-member-description-container" style="font-family: inter; color: rgba(0, 0, 0, 0.88); font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
      <p class="committee-member-description" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz15); font-weight: var(--desktop-fw400); text-align: left;">
        Dr. Chandra Prakash is an accomplished DMPK/Clinical Pharmacology/bioanalytical scientist/leader/manager with proven success in drug discovery and development spanning CNS, cardiovascular and metabolic disease, rare genetic disease, antibacterial, oncology and immunology. He held several academic appointments prior to joining the pharmaceutical industry. Currently, he is a Senior Research Fellow in Drug Metabolism, Pharmacokinetics and Clinical Pharmacology department at Agios. He worked for sixteen years at Pfizer Global Research and Development and six years at Biogen prior to joining Agios. For the last 30 years, he has been involved in drug metabolism and clinical pharmacology studies. He has authored more than 280 manuscripts, book chapters, presentations, and patents. He also coedited five volumes of Handbook of Metabolic Pathways of Xenobiotics. He obtained his Ph.D. in Medicinal Chemistry and Post-doctoral training in Biochemistry.
      </p>
    </div>
  `,
  },
  {
    designation: 'Chair, Seattle PBSS',
    profile: Peter_Korytko,
    name: 'Peter Korytko, PhD',
    details: `
    <div class="committee-member-sub-heading-container" style="font-family: inter; color: rgba(0, 0, 0, 0.88); font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
      <p class="committee-member-sub-heading" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz16); font-weight: var(--desktop-fw600);">Chair, Seattle PBSS</p>
      <p class="committee-member-sub-heading" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz16); font-weight: var(--desktop-fw600);"><br></p>
    </div>
    <div class="committee-member-description-container" style="font-family: inter; color: rgba(0, 0, 0, 0.88); font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
      <p class="committee-member-description" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz15); font-weight: var(--desktop-fw400); text-align: left;">
        Peter Korytko is a toxicologist and drug development professional with over 25 years of pharmaceutical/biotech industry experience (Pfizer, Amgen, and Preclinical GPS). Peter has been the toxicology or nonclinical leader supporting 55 Phase 1 starts, 30 Phase 2 starts, and 2 NDAs with expertise in development of peptides, oligos, small molecules, monoclonal antibodies, and proteins. Peter’s experience includes designing and implementing ICH compliant programs including over 350 GLP toxicology studies and over 1400 preclinical studies. Peter is Founder of Preclinical GPS, a consulting company with expertise in strategy and execution of nonclinical programs, plus prior experience at Pfizer and Amgen. Peter is a past president of the Roundtable of Toxicology Consultants, Regional Chapter of Society of Toxicology, and Board Member of the Boulder Peptide Symposium. He has been a symposium chair and/or presenter at international conferences including the American College of Toxicology, Society of Toxicology, TIDES, and Boulder Peptide Symposium. Peter has a PhD and MS in comparative toxicology from Cornell University and MBA from DePaul University.
      </p>
    </div>
  `,
  },
  {
    designation: 'Chair, Vancouver PBSS',
    name: 'Harvey Wong, PhD',
    details: `
    <div class="committee-member-sub-heading-container" style="font-family: inter; color: rgba(0, 0, 0, 0.88); font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
      <p class="committee-member-sub-heading" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz16); font-weight: var(--desktop-fw600);">Chair, Vancouver PBSS</p>
      <p class="committee-member-sub-heading" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz16); font-weight: var(--desktop-fw600);"><br></p>
    </div>
    <div class="committee-member-description-container" style="font-family: inter; color: rgba(0, 0, 0, 0.88); font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
      <p class="committee-member-description" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz15); font-weight: var(--desktop-fw400); text-align: left;">
        Dr. Harvey Wong is an Associate Professor of Pharmacokinetics in the Faculty of Pharmaceutical Sciences at the University of British Columbia. Previously, he worked at several pharmaceutical companies (DuPont Pharmaceuticals, BMS, Genentech) where he served as a global research project team leader, a non-clinical development team leader, and DMPK project representative, and was involved with nonclinical drug development strategy and nonclinical sections of INDs, NDAs, and other regulatory filings. He was also involved in the filing of the NDA and European MMA of vismodegib (Erivedge). Dr. Wong's expertise is in drug discovery and development strategies with a specialization in ADME, pharmacokinetics, and modeling and simulation (translational PK/PD analysis, systems pharmacology, and PBPK modeling). He currently chairs the Vancouver-PBSS, and serves on the editorial boards of Biopharmaceutics and Drug Disposition, Xenobiotica, and Clinical and Translational Sciences. Dr. Wong is the author/co-author of approximately 105 manuscripts, 5 book/book chapters and has given numerous presentations at national/international meetings.
      </p>
    </div>
  `,
  },
  {
    designation: 'Chair, Korea PBSS',
    profile: Young_Shin,
    name: 'Young G. Shin, PhD',
    details: `
    <div class="committee-member-name-container" style="font-family: inter; color: rgba(0, 0, 0, 0.88); font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
      <p class="committee-member-name" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; color: var(--pbss-p1); font-size: var(--desktop-fz22); font-weight: var(--desktop-fw600); line-height: 22px;">
        <span style="font-family: var(--font-inter); font-size: var(--desktop-fz16); font-weight: var(--desktop-fw600); color: rgba(0, 0, 0, 0.88);">Chair, Korea PBSS</span>
      </p>
    </div>
    <div class="committee-member-sub-heading-container" style="font-family: inter; color: rgba(0, 0, 0, 0.88); font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
      <p class="committee-member-sub-heading" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz16); font-weight: var(--desktop-fw600);"><br></p>
    </div>
    <div class="committee-member-description-container" style="font-family: inter; color: rgba(0, 0, 0, 0.88); font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
      <p class="committee-member-description" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz15); font-weight: var(--desktop-fw400); text-align: left;">
        Dr. Young Shin is currently a Professor at the College of Pharmacy, Chungnam National University, South Korea. Dr. Shin began his career as an Investigator/Principal Scientist in the Drug Metabolism & Pharmacokinetics department at GlaxoSmithKline (Collegeville, PA, USA) before transitioning to the Drug Metabolism & Pharmacokinetics department at Genentech (South San Francisco, CA, USA). Dr. Shin’s main research areas include: 1). Development of fit-for-purpose bioanalytical/drug metabolism/pharmacokinetics platforms for peptides, monoclonal antibodies, antibody-drug conjugates (ADCs), ASOs, siRNAs, and small molecules for lead optimization stage and beyond. 2) Development of in silico ADME lead optimization processes and human PK/metabolism prediction platforms using QSAR and PBPK modeling etc. Dr. Shin is also the founder of Korea-PBSS. Dr. Shin received BSc, MSc, and Ph.D. from the College of Pharmacy at Seoul National University in Seoul, South Korea, and completed his post-doctoral training at the College of Pharmacy, University of Illinois, Chicago.
      </p>
    </div>
  `,
  },
];

//distinguished advisor committee json data
export const CommitteeDataForPBSSORGAdvisors = [
  {
    name: 'Mike Varney',
    location: 'PBSS International',
    profile: Michael_Varney,
    name: 'Mike Varney, PhD',
    details: `
    <p><span style="color: rgba(0, 0, 0, 0.88); font-family: inter; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: left; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;">
      Dr. Mike Varney is a pioneer drug discoverer and biotech leader. As one of the original fifteen employees at Agouron, a biotech based in San Diego, he built a team that developed protein-structure based design, a novel approach to drug discovery that is utilized globally by drug discovery teams today. In 1997, the Agouron team launched Viracept, an HIV protease inhibitor that achieved the highest first year launch sales of any biotech product at the time. Dr. Varney’s leadership at Agouron resulted in the discovery of a number of currently marketed anti-cancer agents including Xalkori and Inlyta, a drug that won the American Chemical Society’s Heroes of Chemistry Award in 2018.
      
      In 2005, Dr. Varney was recruited to Genentech, the US based R&D organization in Roche, to expand the organization’s drug discovery capabilities to include small molecules. Focused on agility and pioneering science, Varney built a team-based organization that today produces more that 40% of Genentech’s development portfolio including the marketed anticancer agents Erivedge and Cotellic. In 2015, Dr. Varney was appointed Executive Vice President and Head of Genentech’s Research and Early Development (gRED) and a member of the Roche Corporate Executive Committee in Basel. In this role, he was responsible for all aspects of gRED innovation, drug discovery and drug development. Varney also focused on building a team-based culture empowered to clear the path to patients. Under his leadership, gRED teams discovered and developed successful medicines that include Venclexta with AbbVie, the first bcl-2 inhibitor, and Polivy, an antibody drug conjugate for the treatment of DLBCL. Pioneering molecules in the clinic include Mosunetuzumab, a bispecific antibody targeting CD20, and GDC-9545, a next-generation estrogen receptor degrader.

      Dr. Varney retired from Genentech in July 2020, leaving a diversity of drug platform types that includes personalized therapeutic vaccines and cellular therapies. Dr. Varney holds a B.S. in Chemistry from the University of California, Los Angeles, and a Ph.D. in synthetic organic chemistry from the California Institute of Technology, and was an American Cancer Society postdoctoral fellow at Columbia University. Dr. Varney is currently an advisor to the Biotech and Pharma world and is a Board member of both public and private companies.
    </span></p>
  `,
  },
  {
    name: 'Kevin Grimes',
    location: 'PBSS International',
    profile: Kevin_Grimes,
    name: 'Kevin Grimes, MD',
    details: `
    <p><span style="color: rgba(0, 0, 0, 0.88); font-family: inter; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: left; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;">
      Kevin Grimes, MD, MBA is a Professor of Chemical and Systems Biology at Stanford University and Co-Director of SPARK at Stanford. He received his MD from Brown University. Dr. Grimes began his career as a Clinical Assistant Professor of Medicine at Stanford, where his primary duties included the teaching and practice of internal medicine. Dr. Grimes received a Hartford Foundation Fellowship to study health economics and obtained an MBA at the Stanford Graduate School of Business. He was subsequently selected as a White House Fellow and assigned to the Department of Defense, where he served as Special Assistant to the Secretary. He spent fifteen years in industry, working in the medical device, life science consulting, and biotechnology sectors prior to returning to Stanford to co-direct SPARK. Dr. Grimes also teaches graduate student courses on drug discovery and development and continues to teach and practice internal medicine. He has received the David Rytand Award for Excellence in Clinical Teaching and the Faculty Award for Excellence in Graduate Teaching.
    </span></p>
  `,
  },

  {
    name: 'Cyrus Khojasteh',
    location: 'PBSS International',
    profile: Cyrus_Khojasteh,
    name: 'Cyrus Khojasteh, PhD',
    details: `
    <p><span style="color: rgba(0, 0, 0, 0.88); font-family: inter; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: left; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;">
      Dr. Cyrus Khojasteh leads the Drug Metabolism Group at Genentech, Inc., supporting both discovery and development efforts. He received his Bachelor’s degree from the University of California, Berkeley and his PhD in Medicinal Chemistry from the University of Washington under the direction of Professor Sidney Nelson. After graduating, he worked in the Biotransformation Group at Pfizer, Groton, Connecticut. Since joining Genentech in 2000, he has supported both small molecule and antibody-drug conjugate projects. Cyrus Khojasteh’s research focuses on mechanisms of biotransformation and the formation of reactive metabolites. He has collaborated with a number of distinguished researchers in the field of drug metabolism.
    </span></p>
  `,
  },
];
// operation team committee json data
export const CommitteeDataForPBSSORGOperationTeam = [
  {
    name: 'Enas Mahmoud, PhD, Director of Operations',
  },
  {
    name: 'Sharmili Roy, MSc, Operations & Sponsorship Manager',
  },
  {
    name: 'Megan Yuan, Assistant of Operations',
  },
  {
    name: 'Moamen Mohamed, Assistant of Operations',
  },
];

// program manager team committee json data
export const CommitteeDataForPBSSORGProgramManagerTeam = [
  {
    name: 'Dean Wilson, PhD, Program Director',
    details: `
    <div class="committee-member-sub-heading-container" style="font-family: inter; color: rgba(0, 0, 0, 0.88); font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
     
      <p class="committee-member-sub-heading" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz16); font-weight: var(--desktop-fw600);"><br></p>
    </div>
    <div class="committee-member-description-container" style="font-family: inter; color: rgba(0, 0, 0, 0.88); font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
      <p class="committee-member-description" style="font-family: var(--font-inter); margin: 0px !important; padding: 0px !important; font-size: var(--desktop-fz15); font-weight: var(--desktop-fw400); text-align: left;">
        Dr. Dean Wilson is Program Director at PBSS and has more than 25 years of experience in the pharmaceutical and life sciences industries, including 15 years at Vertex. His career has spanned drug discovery research, development, and commercialization, including medicinal chemistry, CMC project management, product launches and marketed products, health economics and outcomes research (HEOR), and medical and field sales training and development. He has led and managed successful cross-functional and global efforts in early research, neurology, oncology, hematology, cystic fibrosis, and infectious diseases. He received his PhD in synthetic organic chemistry from Cornell University and completed his postdoctoral training at UCSD.
      </p>
    </div>
  `,
  },
  {
    name: 'Adrienne Li, PhD, Program and Partnership Manager',
  },
  {
    name: 'Lu Lu, MSc, Program Coordinator',
  },
];

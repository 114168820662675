import React from 'react';
import './style.css';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import {Link} from 'react-router-dom';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';
import {ApplicationString} from '../../../Localization/Locales/en';
import CustomCollapse from '../../CommonComponents/CommonAccordian';

const SponsorInfoDetailsComponent = () => {
  const collapseData = [
    {
      title: 'Vendor Show Exhibition',
      content: `<p class='sponsorshipInfo'>Participation in an in-person PBSS event as an exhibitor.</p>
              <p class='sponsorshipInfo'>Your registration fee includes:</p>
              <ol class='sponsorshipInfoOrderedList'>
                <li class='sponsorshipInfoList'>
                  Vendor show tabletop space where you may display your company
                  materials through the event.
                </li>
                <li class='sponsorshipInfoList'>2 attendees for the workshop at a subsidized price.</li>
                <li class='sponsorshipInfoList'>
                  PDF Slide Deck of the presentations if the event is a workshop.
                </li>
                <li class='sponsorshipInfoList'>
                  Participation in a Raffle (you collect business cards from our
                  attendees and participate in a drawing along with other vendors
                  to giveaway any prize of your choice to the attendee).
                </li>
              </ol>
              <p class='sponsorshipInfo'>
                For further details please contact
                <a href="mailto:info@pbss.org">info@pbss.org</a>
              </p>`,
    },
    {
      title: 'Happy Hour Sponsorship',
      content: `
            <p class='sponsorshipInfo'>
              Participation in an in-person PBSS event as a Happy Hour Sponsor.
            </p>
            <p class='sponsorshipInfo'>Your registration includes:</p>
            <ol class='sponsorshipInfoOrderedList'>
              <li class='sponsorshipInfoList'>
                Vendors show tabletop space where you may display your company
                materials through the event.
              </li>
              <li class='sponsorshipInfoList'>
                PDF Slide Deck of the presentations if the event is a workshop.
              </li>
              <li class='sponsorshipInfoList'>2 attendees for the workshop at a subsidized price.</li>
              <li class='sponsorshipInfoList'>
                Participation in a Raffle (you collect business cards from our
                attendees and participate in a drawing along with other vendors
                to give away any prize of your choice to the attendee).
              </li>
              <li class='sponsorshipInfoList'>Your sponsorship is acknowledged in the meeting agenda.</li>
              <li class='sponsorshipInfoList'>
                A two-minute informal oral advert/blurb about your company and
                its offerings to our attendees during the Happy Hour.
              </li>
            </ol>
            <p class='sponsorshipInfo'>
                For further details please contact
                <a href="mailto:info@pbss.org">info@pbss.org</a>
              </p>
          `,
    },
    {
      title: 'Lunch Sponsorship',
      content: `<p class='sponsorshipInfo'>Participation in an in-person PBSS event as a Lunch Sponsor.</p>
            <p class='sponsorshipInfo'>Your registration fee includes:</p>
            <ol class='sponsorshipInfoOrderedList'>
              <li class='sponsorshipInfoList'>
                Vendor show tabletop space where you may display your company
                materials throughout the event.
              </li>
              <li class='sponsorshipInfoList'>
                PDF Slide Deck of the presentations if the event is a workshop.
              </li>
              <li class='sponsorshipInfoList'>2 attendees for the workshop at a subsidized price.</li>
              <li class='sponsorshipInfoList'>
                Participation in a Raffle (you collect business cards from our
                attendees and participate in a drawing along with other vendors
                to give away any prize of your choice to the attendee).
              </li>
              <li class='sponsorshipInfoList'>Two free lunches for your representatives.</li>
              <li class='sponsorshipInfoList'>Your sponsorship is acknowledged in the meeting agenda.</li>
              <li class='sponsorshipInfoList'>
                A two-minute informal oral advert/blurb about your company and
                its offerings to our attendees during lunch.
              </li>
              <li class='sponsorshipInfoList'>
                Flyer distribution to advertise your company’s offerings on the
                lunch tables before attendees come in for lunch.
              </li>
            </ol>
            <p class='sponsorshipInfo'>
                For further details please contact
                <a href="mailto:info@pbss.org">info@pbss.org</a>
              </p>`,
    },
    {
      title: 'Major Sponsorship',
      content: `<p class='sponsorshipInfo'>Participation in an in-person PBSS event as a Major Sponsor.</p>
            <p class='sponsorshipInfo'>Your registration fee includes:</p>
            <ol class='sponsorshipInfoOrderedList'>
              <li class='sponsorshipInfoList'>
                Exclusive vendor show tabletop space next to the
                workshop/symposium entrance where you may display your company
                materials through the event.
              </li>
              <li class='sponsorshipInfoList'>2 free workshop or symposium seats.</li>
              <li class='sponsorshipInfoList'>
                PDF Slide Deck of the presentations if the event is a workshop.
              </li>
              <li class='sponsorshipInfoList'>
                Participation in a Raffle (you collect business cards from our
                attendees and participate in a drawing along with other vendors
                to give away any prize of your choice to the attendee).
              </li>
              <li class='sponsorshipInfoList'>Two free lunches for your representatives.</li>
              <li class='sponsorshipInfoList'>
                A ten-minute oral podium slide presentation about your company
                and its offerings during the workshop/symposium.
              </li>
              <li class='sponsorshipInfoList'>
                Company flyer that advertises your company’s offerings will be
                emailed to the meeting attendees and will also be included in
                the workshop slide deck for all attendees.
              </li>
              <li class='sponsorshipInfoList'>
                Dinner invitation for 2 representatives to the post-meeting
                dinner for the organizers/speakers/sponsors.
              </li>
              <li class='sponsorshipInfoList'>
                You will receive the meeting attendees’ names and affiliations
                (their emails may be provided if they opted in during the
                registration process).
              </li>
            </ol>
            <p class='sponsorshipInfo'>
                For further details please contact
                <a href="mailto:info@pbss.org">info@pbss.org</a>
              </p>`,
    },
    {
      title: 'Raffle Rules',
      content: `<ul><li class='sponsorshipInfoList'>
              The raffle typically takes place at the end of the last
              break/vendor show session and before the final presentation
              session.
            </li>
            <li class='sponsorshipInfoList'>
              To minimize the raffle time, each participating vendor must
              pre-draw 3 winners’ names (one as the winner and the other two as
              backups), so that the winner’s name can be quickly announced.
            </li>
            <li class='sponsorshipInfoList'>
              Each vendor rep has 30 seconds to announce their name, company
              name and offerings.
            </li>
            <li class='sponsorshipInfoList'>Each vendor must give the prize(s) to one single winner.</li>
            <li class='sponsorshipInfoList'>A person must be present to win.</li></ul>`,
    },
  ];
  return (
    <>
      <SponsorOptionPageHeroText title={'Sponsorship Info'} />
      <SectionLayout>
        <div className='sponsorInfoDetails-cards-wrapper'>
          <CustomCollapse data={collapseData} titleHide />
          <div>
            {ApplicationString[
              `userSponsorshipInfo.content.raffleRules.contactUs`
            ].includes('info@pbss.org')
              ? ApplicationString[
                  `userSponsorshipInfo.content.raffleRules.contactUs`
                ]
                  .split('info@pbss.org')
                  .map((part, index, array) => (
                    <>
                      {part}
                      {index < array.length - 1 && (
                        <a href='mailto:info@pbss.org'>info@pbss.org</a>
                      )}
                    </>
                  ))
              : ''}
          </div>
        </div>
      </SectionLayout>
    </>
  );
};

export default SponsorInfoDetailsComponent;

import {HttpCode} from '../../Constant/HttpCode';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ALL_USERS,
  SOMETHING_WENT_WRONG,
  FETCH_USER_BY_ID,
  UPDATE_USER_BY_ID,
  DELETE_USER_BY_ID,
  GET_USER_DETAILS,
  GET_ALL_USERS_BY_QUERY,
  ADD_USERS,
  GET_ALL_USERS_WITHOUT_PAGINATION,
  SHOW_MESSAGE,
  UPDATE_USER_STATUS,
  UPDATE_USERS_STATUS,
  UPDATING_CONTENT,
  GET_ALL_USERS_BY_QUERY_SEARCH,
  UPDATE_USER_PROFILE_SUCCESS,
  POST_USER_SUCCESS,
  UPDATE_USER_STATUS_SUCCESS,
  DELETE_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  FETCH_USER_BY_ID_FOR_UPDATE_PROFILE,
} from '../../Constant/ActionTypes';

import Urls from '../../Constant/Urls';
import {httpClient} from '../../Api/client';
import {commonSortOrder, handleJwtExpireMsg} from '../../Util/commonUtility';

export const getAllUsers = () => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(Urls.USER_GET_ALL_USERS);
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_USERS, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAllUsersWithoutPagination = (
  sortField,
  sortOrder,
  queryString,
  totalUserCount,
  setCSVDataLoading,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    setCSVDataLoading(true);
    try {
      let data = await httpClient.get(
        `${
          Urls.USER_GET_ALL_USERS
        }?page=1&pageCount=${totalUserCount}&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${queryString}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_USERS_WITHOUT_PAGINATION, payload: data?.data});
        setCSVDataLoading && setCSVDataLoading(false);
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
      setCSVDataLoading(false);
    }
  };
};
export const resetUserCsvDownloadState = () => {
  return {
    type: GET_ALL_USERS_WITHOUT_PAGINATION,
    payload: [],
  };
};

export const getAllUsersByQuery = (
  sortField,
  sortOrder,
  queryString,
  pagination,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.USER_GET_ALL_USERS}?page=${pagination.page}&pageCount=${
          pagination.pageCount
        }&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${queryString}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_USERS_BY_QUERY, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const getAllUsersByQuerySearch = (
  sortField,
  sortOrder,
  queryString,
  pagination,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.USER_GET_ALL_USERS}?page=${pagination.page}&pageCount=${
          pagination.pageCount
        }&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${queryString}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_USERS_BY_QUERY_SEARCH, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const fetchUsersById = (userId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.get(
        Urls.USER_FETCH_BY_ID.replace('#{userId}', userId),
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_USER_BY_ID, payload: response?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const fetchUsersByIdForUpdateProfile = (userId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.get(
        Urls.USER_FETCH_BY_ID.replace('#{userId}', userId),
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: FETCH_USER_BY_ID_FOR_UPDATE_PROFILE,
          payload: response?.data,
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addUsers = (Data, navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.post(Urls.USER_ADD, Data);
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message,
        });
        dispatch({type: ADD_USERS, payload: response?.data});
        navigate('/admin/memberAttendees');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateUserById = (
  updatedUserData,
  userId,
  Navigate,
  profileNameChange,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.patch(
        Urls.USER_UPDATE_BY_ID.replace('#{userId}', userId),
        updatedUserData,
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message.replace(
            'Member Attendee',
            profileNameChange,
          ),
        });
        dispatch({type: UPDATE_USER_BY_ID, payload: response?.data});
        localStorage.setItem('userInfo', JSON.stringify(response?.data?.data));
        Navigate && Navigate('/');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateUserByAdminId = (updatedUserData, userId, Navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.patch(
        Urls.USER_UPDATE_BY_ADMIN_ID.replace('#{userId}', userId),
        updatedUserData,
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message,
        });
        dispatch({type: UPDATE_USER_BY_ID, payload: response?.data});
        Navigate && Navigate('/admin/memberAttendees');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteUserById = (userId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.delete(
        Urls.USER_DELETE_BY_ID.replace('#{userId}', userId),
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message,
        });
        dispatch({type: DELETE_USER_BY_ID, payload: userId});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getUserDetails = () => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(Urls.USER_DETAILS_BY_ID);
      if (data?.status === HttpCode?.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_USER_DETAILS, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const editUserStatusById = (id, status) => {
  return async (dispatch) => {
    dispatch({type: UPDATING_CONTENT});
    try {
      let data = await httpClient.patch(
        `${Urls.USER_UPDATE_STATUS_BY_ID.replace('#{userId}', id).replace(
          '#{userStatus}',
          status,
        )}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: UPDATE_USERS_STATUS});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

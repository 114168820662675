import React from 'react';
import EditableCell from '../../CommonComponents/EditableCell';
import {Alert, Form, Skeleton, Table, Tag} from 'antd';
import {ApplicationString} from '../../../Localization/Locales/en';

const WaitingList = ({data}) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return (
          <Tag prefixCls='regularAttendeesTag'>
            {record.first_name} {record.last_name}
          </Tag>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
      },
    },
    {
      title: 'Registration Type',
      dataIndex: 'registration_type',
      key: 'registration_type',
      render: (text) => {
        const capitalizedLetter = text.charAt(0).toUpperCase() + text.slice(1);
        return <Tag prefixCls='regularAttendeesTag'>{capitalizedLetter}</Tag>;
      },
    },
  ];
  return (
    <Form>
      <div className='attendees-content-container'>
        <div className='attendees-label'>Waiting List Attendees</div>
      </div>

      <Table
        prefixCls='attendees-table-container'
        components={{
          body: {
            cell: (props) => <EditableCell {...props} usedForSponsorDetails />,
          },
        }}
        scroll={{x: columns?.length * 150}}
        rowClassName={() => 'editable-row'}
        dataSource={data}
        columns={columns}
        pagination={false}
      />
    </Form>
  );
};

export default WaitingList;

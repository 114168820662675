import {Modal, Button, Table, Tag, Divider, Alert, Tabs, message} from 'antd';
import {
  EventRegistrationStatus,
  EventRegistrationType,
  formatCurrency,
  getStatusInfoOfEventRegistration,
} from '../../../../../Util/commonUtility';
import dayjs from 'dayjs';
import {ApplicationString} from '../../../../../Localization/Locales/en';
import {useNavigate} from 'react-router-dom';
import {useCallback, useEffect, useState} from 'react';

const categorizeAndGroupByTransitionId = (attendees) => {
  // Filter attendees to include only those with a status other than To be Paid
  const filteredAttendees = attendees?.filter(
    (attendee) => attendee?.status !== EventRegistrationStatus.TO_BE_PAID,
  );

  const grouped = filteredAttendees?.reduce((acc, attendee) => {
    const transactionId = attendee?.transaction_id;
    if (!acc[transactionId]) {
      acc[transactionId] = [];
    }
    acc[transactionId].push(attendee);
    return acc;
  }, {});

  return Object.keys(grouped)?.map((key, index) => ({
    key: key,
    label: `Payment ${index + 1}`,
    attendees: grouped[key],
  }));
};

const filterAttendeesByStatus = (sponsors, status = 1) => {
  return sponsors?.filter((attendee) => attendee?.status === status);
};

const findSponsorshipEventOptionId = (registrations) =>
  registrations
    ?.map(
      (registration) =>
        registration?.event_registration_options?.sponsorship?.event_option_id,
    )
    .find((eventOptionId) => eventOptionId !== undefined);

const EventDetailsModal = ({
  paidEvent,
  attendees,
  sponsors,
  paymentStatus,
  eventRegistrationType,
  handleRefund,
  filteredAttendees,
  eventStartDate,
  eventId,
  eventType,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState('');
  const [transactionId, setTransactionId] = useState(null);
  const [activeTabOptionId, setActiveTabOptionId] = useState([]);
  const [activeTabCosts, setActiveTabCosts] = useState({
    payableAmount: 0,
    discountAmount: 0,
    totalCost: 0,
  });
  const [toBePaidEventRegistration, setToBePaidRegistration] = useState([]);
  const [eventRegistrationTypeData, setEventRegistrationTypeData] = useState(
    [],
  );

  const refundMode = (isBankRefund, refund_status) => {
    if (refund_status) {
      return isBankRefund ? 'PBSS Credit' : 'Refund to Bank';
    } else {
      return 'N/A';
    }
  };

  const handleEditAttendees = () => {
    if (eventRegistrationType === 'sponsorship') {
      navigate(
        `/eventSponsorCart/${eventId}/${activeTabOptionId}/${transactionId}`,
      );
    } else {
      navigate(`/attendeesCart/${eventId}/${transactionId}`);
    }
  };

  const handleTabKey = (key) => {
    setActiveTabKey(key);
  };

  // event cta for refund and edit registration
  const renderButtons = () => {
    const isEventExpired = eventStartDate
      ? dayjs(eventStartDate).isBefore(dayjs(), 'minute')
      : false;
    const isPaymentStatusToBePaid = paymentStatus === 'To Be Paid';

    const isEventStartDateWithin14Days = eventStartDate
      ? dayjs().diff(dayjs(eventStartDate), 'day') <= -14
      : false;

    const activeTabData = eventRegistrationTypeData?.find(
      (group) => group?.key === activeTabKey,
    );
    const payment_id_null = activeTabData?.key === 'null' ? false : true;

    // hide the event registration if event expired and show message of event expired
    if (
      isEventExpired &&
      eventRegistrationType !== EventRegistrationType.waitingList
    ) {
      return (
        <Alert
          prefixCls='attendees-description-alert'
          message={ApplicationString['userEventDetailsModal.alertMsg.expired']}
          type='info'
          showIcon
        />
      );
    } else {
      const buttons = [];

      if (
        (payment_id_null &&
          isEventStartDateWithin14Days &&
          eventRegistrationType !== EventRegistrationType.waitingList) ||
        isPaymentStatusToBePaid
      ) {
        buttons.push(
          <Button
            type='primary'
            className='common-button-refund'
            key='cancel'
            onClick={handleEditAttendees}>
            Edit Registration
          </Button>,
        );
      }
      return buttons;
    }
  };

  // show the data based on event registration type
  useEffect(() => {
    let categorizedData;
    let dataWithWaitingList;

    if (eventRegistrationType === 'sponsorship') {
      categorizedData = categorizeAndGroupByTransitionId(sponsors);
      dataWithWaitingList = categorizedData.reverse();
    } else {
      categorizedData = categorizeAndGroupByTransitionId(attendees);
      dataWithWaitingList = categorizedData.reverse();
    }

    setEventRegistrationTypeData(dataWithWaitingList);
  }, [eventRegistrationType, attendees, sponsors]);

  useEffect(() => {
    if (
      activeTabKey !== 'waitingList' &&
      eventRegistrationTypeData?.length > 0
    ) {
      setActiveTabKey(eventRegistrationTypeData[0]?.key);
    }
  }, [eventRegistrationTypeData, eventRegistrationType]);

  // set initial data for active tab
  useEffect(() => {
    if (eventRegistrationTypeData?.length > 0 && activeTabKey) {
      const activeTabData = eventRegistrationTypeData?.find(
        (group) => group?.key === activeTabKey,
      );
      if (activeTabData) {
        const totalCost = activeTabData?.attendees?.reduce((sum, attendee) => {
          return sum + parseFloat(attendee?.total_cost);
        }, 0);
        const discountAmount = activeTabData?.attendees?.reduce(
          (sum, attendee) => {
            return sum + parseFloat(attendee?.discount_amount);
          },
          0,
        );
        const payableAmount = totalCost - discountAmount;
        const transactionId = activeTabData?.key || null;
        setData(activeTabData ? activeTabData?.attendees : []);
        setActiveTabOptionId(
          findSponsorshipEventOptionId(
            activeTabData ? activeTabData?.attendees : [],
          ),
        );
        setTransactionId(transactionId);
        setActiveTabCosts({
          payableAmount: payableAmount,
          discountAmount: discountAmount,
          totalCost: totalCost,
        });
      }
    }
  }, [activeTabKey, eventRegistrationType, attendees, sponsors]);

  // set to_be_paid data based on event registration type
  useEffect(() => {
    if (eventRegistrationType === 'sponsorship') {
      setToBePaidRegistration(filterAttendeesByStatus(sponsors));
    } else if (eventRegistrationType === EventRegistrationType.waitingList) {
      setToBePaidRegistration(attendees);
    } else {
      setToBePaidRegistration(filterAttendeesByStatus(attendees));
    }
  }, [sponsors, attendees, eventRegistrationType]);

  const columns =
    eventRegistrationType === EventRegistrationType.waitingList
      ? [
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
              return (
                <Tag prefixCls='regularAttendeesTag'>
                  {record.attendee_first_name} {record.attendee_last_name}
                </Tag>
              );
            },
          },
          {
            title: 'Email',
            dataIndex: 'attendee_email',
            key: 'email',
            render: (text) => {
              return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
            },
          },
          {
            title: 'Registration Type',
            dataIndex: 'registration_type',
            key: 'registration_type',
            render: (text) => {
              const capitalizedLetter =
                text.charAt(0).toUpperCase() + text.slice(1);
              return (
                <Tag prefixCls='regularAttendeesTag'>{capitalizedLetter}</Tag>
              );
            },
          },
        ]
      : [
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            editable: true,
            width: '8%',
            render: (text, record) => {
              return (
                <Tag prefixCls='regularAttendeesTag'>
                  {record.attendee_first_name} {record.attendee_last_name}
                </Tag>
              );
            },
          },
          {
            title: 'Email',
            dataIndex: 'attendee_email',
            key: 'email',
            width: '15%',
            editable: true,
            render: (text) => {
              return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
            },
          },
          {
            title: 'Company/Affiliation',
            dataIndex: 'attendee_company',
            key: 'company',
            width: '12%',
            editable: true,
            render: (text) => {
              return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
            },
          },
          {
            title: 'Refunded Amount',
            dataIndex: 'refunded_amount',
            width: '8%',
            render: (text) => {
              return (
                <Tag prefixCls='regularAttendeesTag'>
                  {formatCurrency(text)}
                </Tag>
              );
            },
          },
          {
            title: 'Refund Type',
            dataIndex: 'refund_to_account',
            width: '8%',
            render: (_, record) => {
              const refund_mode = record?.refund_to_account;
              const refund_status = record?.status == 0;
              return (
                <Tag prefixCls='regularAttendeesTag'>
                  {refundMode(refund_mode, refund_status)}
                </Tag>
              );
            },
          },
          {
            title: 'Registration Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            render: (status) => {
              const {label} = getStatusInfoOfEventRegistration(status);
              return <Tag prefixCls='regularAttendeesTag'>{label}</Tag>;
            },
          },
          {
            title: 'Cost',
            dataIndex: 'total_cost',
            width: '5%',
            render: (text) => {
              return (
                <h4 className='regularAttendees-price'>
                  {formatCurrency(text ? text : 0)}
                </h4>
              );
            },
          },
        ];

  const titleWithButton = (
    <div className='modal-title-wrapper'>
      <div className='modal-title-buttons'>{renderButtons()}</div>
    </div>
  );

  const noFilteredAttendees = filteredAttendees?.length < 0;

  const tabItems = eventRegistrationTypeData?.map((group) => ({
    key: group.key,
    label: group.label,
    children: (
      <>
        {eventType !== EventRegistrationType.waitingList && (
          <div className='table-edit-container'>
            <div className='table-cost-amount'>
              <p className='table-total-cost'>
                Total Cost:{' '}
                {formatCurrency(
                  activeTabCosts?.payableAmount > 0
                    ? activeTabCosts?.payableAmount
                    : 0,
                )}
              </p>
            </div>
            {titleWithButton && titleWithButton}
          </div>
        )}
        <Table
          prefixCls='event-details-table'
          dataSource={data}
          scroll={{x: 1000}}
          columns={columns}
          size='small'
          pagination={false}
        />
      </>
    ),
  }));

  return (
    <div className='modal-container'>
      <div className='event-details-modal-container'>
        {noFilteredAttendees && (
          <div key='cancel' className='update-footer'>
            <Button
              type='primary'
              className={`${'common-button-refund'}`}
              key='cancel'
              onClick={handleRefund}>
              Ask for Refund
            </Button>
          </div>
        )}
        <div className='evente-details-modal-wapper'>
          {/* <div>
            <p>
              <strong>Event Name:</strong> {eventName}
            </p>
            <p>
              <strong>Status of Payment:</strong> {paymentStatus}
            </p>
            {payment_mode && (
              <p>
                <strong>Mode of Refund :</strong> {payment_mode}
              </p>
            )}
          </div> */}
          {/* <div>
            {refund_amount && (
              <p>
                <strong>Refunded Amount:</strong> {refund_amount}
              </p>
            )}
            {credit_amount && (
              <p>
                <strong>Credit Amount:</strong> {credit_amount}
              </p>
            )}
          </div> */}
        </div>
        {!paidEvent ||
        eventRegistrationType === EventRegistrationType.waitingList ? (
          <Table
            prefixCls='event-details-table'
            dataSource={toBePaidEventRegistration}
            scroll={{x: 1000}}
            columns={columns}
            size='small'
            pagination={false}
          />
        ) : (
          <Tabs
            prefixCls='user-event-card'
            activeKey={activeTabKey}
            onChange={(key) => handleTabKey(key)}
            items={tabItems}
          />
        )}
      </div>
    </div>
  );
};

export default EventDetailsModal;

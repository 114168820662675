import {Col, Row} from 'antd';
import React from 'react';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';
import StemComponent from './Stem';
import StemHighlights from './StemHighlights';

const StemOutReachComponent = ({stemData}) => {
  const StemComponentHTML = stemData?.stem;
  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRow'>
        <Col span={24}>
          <SponsorOptionPageHeroText title={'STEM Outreach'} />
        </Col>
        <Col span={24}>
          <SectionLayout>
            <div dangerouslySetInnerHTML={{__html: StemComponentHTML}}></div>
          </SectionLayout>
        </Col>
        <Col span={24}>
          <StemHighlights data={stemData} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default StemOutReachComponent;

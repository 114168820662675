import React, {useEffect, useState} from 'react';
import {
  Table,
  Button,
  Tooltip,
  Tag,
  Popover,
  Row,
  Col,
  Divider,
  Image,
} from 'antd';
import {EyeOutlined, FilterOutlined} from '@ant-design/icons';
import DynamicSearchForm from '../../CommonComponents/DynamicSearch';
import {useNavigate} from 'react-router-dom';
import {
  CouponCodeRaioOptions,
  DescriptionItem,
  EventOptions,
  EventStatus,
  PageSizeArray,
  PaginationPosition,
  ReturnNAOnEmpty,
  defaultDropdownValue,
  eventStatusOptions,
  formatDate,
  truncateStringToWords,
} from '../../../Util/commonUtility';
import {ApplicationString} from '../../../Localization/Locales/en';
import plus from '../../../Assets/Plus.svg';
import StatusUpdateModal from '../../CommonComponents/CommonModal';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {useSelector} from 'react-redux';
import CommonTableColumnTextView from '../../CommonComponents/CommonTableColumnTextView';
import useDebouncedCallback from '../../../hooks/useDebounce';
import AdminViewDetailsDrawer from '../../CommonComponents/AdminViewDetailsDrawer';
import CustomizedTable from './../../CommonComponents/CommonTable/index';
import {applicationConfig} from '../../../Util/config';
import HeaderSearchCard from '../../CommonComponents/HeaderSearchCard';
import {CREATE} from '../../../Constant/enums';

const EventsComponent = (props) => {
  const [expanded, setExpanded] = useState(false);
  let navigate = useNavigate();
  const {
    eventTypesOptions,
    allEventsData,
    handlePaginationChange,
    paging,
    chaptersOptions,
    sortParams,
    speakerOptions,
    handleTableChange,
    fetchEvents,
    deleteEvents,
    duplicateEvents,
    updateStatusById,
    searchValues,
    fetching,
    setFetching,
    setSpeakerOptions,
    SetEventTypesOption,
  } = props;
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const ps = useSelector(({pageSizes}) => pageSizes.pageSizes.events);
  const pn = useSelector(({pageSizes}) => pageSizes.pageNumbers.events);
  const [recordData, setRecordData] = useState();
  const [open, setOpen] = useState(false);
  const [openDrawerDetails, setOpenDrawerDetails] = useState(false);

  useEffect(() => {
    searchValues && setExpanded(true);
  }, [searchValues]);

  const eventsFilters = [
    {label: 'Event Name', name: 'name', initialValue: ''},
    // { label: 'Company Name', name: 'company_name', initialValue: '' },
    {
      label: 'Chapter Name',
      name: 'local_chapter_id',
      type: 'dropdown',
      // options: eventStatusOptions,
      initialValue: defaultDropdownValue,
    },
    {
      label: 'Event Type',
      name: 'event_type_id',
      type: 'event_type_id',
      options: eventTypesOptions,
      initialValue: defaultDropdownValue,
    },
    {
      label: 'Status',
      name: 'status',
      type: 'dropdown',
      options: eventStatusOptions,
      initialValue: defaultDropdownValue,
    },
    {
      label: 'Date Range',
      name: 'date',
      type: 'dateRange',
      initialValue: '',
    },
    {
      label: 'Organizers',
      name: 'organizers',
      initialValue: '',
    },
    // {
    //   label: 'Is Online',
    //   name: 'is_online',
    //   type: 'checkbox',
    //   valueProps: 'checked',
    //   initialValue: '',
    // },
    {
      label: 'Online Event',
      name: 'is_online',
      type: 'radio',
      options: CouponCodeRaioOptions,
      initialValue: '',
    },
  ];

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const handleSearch = (values) => {
    fetchEvents(values);
  };

  const handleEditClick = async (id) => {
    navigate(`/admin/events/update/${id}`);
  };
  const handleUpdateStatusClick = async (record) => {
    setShowUpdateStatus(+record.status);
    setRecordData(record);
  };
  const handleUpdateStatus = () => {
    updateStatusById(recordData, showUpdateStatus);
    setShowUpdateStatus(false);
  };

  const hideModal = () => {
    setShowUpdateStatus(false);
  };
  const handleChange = (value) => {
    setShowUpdateStatus(+value);
  };

  // debouncing the table & pagination changes to stop too many requests
  const debouncedHandlePaginationChange = useDebouncedCallback(
    handlePaginationChange,
    1000,
  );
  const debouncedTableChange = useDebouncedCallback(handleTableChange, 1000);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  // Drawer functions ----------------------------------------------------
  const showDrawer = (record) => {
    setOpenDrawerDetails(record);
    setOpen(true);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const EventDrawerContent = () => {
    const {
      name: eventName,
      event_type_name: eventType,
      chapter_name: chapter,
      place,
      is_online: isOnline,
      is_available_for_webcast: isWebcastAvailable,
      online_for_chapters: onlineForChapters,
      webcast_available_for_chapters: webcastAvailableForChapters,
      details,
      cost_note: costNote,
      speaker_name,
      organizers,
      seats,
      flyer_image_ids,
      vendors,
      occupied_seats: occupiedSeats,
      sponsorship,
      formattedDateTime,
      event_bio: eventBio,
      event_info: eventInfo,
      flyer_url: flyerUrl,
      flyer_text: flyerText,
      speaker_bio: speakerBio,
      registration_price: registrationPrice,
      status,
    } = openDrawerDetails || {};

    const renderList = (items) =>
      items?.length > 0
        ? items?.map((item) => <span key={item}>{`${item}, `}</span>)
        : 'N/A';

    return (
      <>
        <Row>
          <Col span={24}>
            <DescriptionItem title='Event Name' content={eventName} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem title='Event Type' content={eventType} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem title='Local Chapter' content={chapter} />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title='Event Status'
              content={
                {
                  0: 'Active',
                  '-2': 'Private',
                  '-1': 'Tentative',
                  1: 'Draft',
                  2: 'Registration Closed',
                }[status] || status
              }
            />{' '}
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem title='Event Location' content={place} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem
              title='Is this an online only event?'
              content={isOnline ? 'Yes' : 'No'}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title='Webcast for Non-local Attendees'
              content={isWebcastAvailable ? 'Yes' : 'No'}
            />{' '}
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Online Only'
              content={renderList(onlineForChapters)}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Webcast Available For Chapters'
              content={renderList(webcastAvailableForChapters)}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem title='Event Details' content={details} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem title='Event Speakers' content={speaker_name} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Event Organizers'
              content={organizers || 'N/A'}
            />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Event Registration Fees'
              content={registrationPrice || 'N/A'}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem title='Total Seats' content={seats} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem
              title='Sponsorships'
              content={sponsorship?.available_qty || 0}
            />{' '}
          </Col>
          <Col span={12}>
            <DescriptionItem title='Vendors' content={vendors} />{' '}
          </Col>
        </Row>
        <Divider />
        <Row gutter={20}>
          <Col span={12}>
            <DescriptionItem
              title='Event Flyer'
              content={
                <>
                  {flyerUrl ? (
                    <a
                      href={`${applicationConfig.UPLOAD_URL}/${flyerUrl}`}
                      target='_blank'
                      rel='noreferrer'>
                      {flyerUrl}
                    </a>
                  ) : (
                    'N/A'
                  )}
                </>
              }
            />
          </Col>
          <Col span={12}>
            <DescriptionItem title='Flyer text' content={flyerText || 'N/A'} />
          </Col>
        </Row>
        <Divider />
        <Row gutter={20}>
          <Col span={24}>
            <DescriptionItem
              title='Event Flyer Image'
              content={
                <>
                  <Image.PreviewGroup>
                    {flyer_image_ids?.map((image) => (
                      <Image
                        prefixCls='event-flyer-image'
                        crossOrigin='anonymous'
                        key={image?.id}
                        width={'50%'}
                        height={'400px'}
                        src={`${applicationConfig.UPLOAD_URL}/${image?.name}`}
                      />
                    ))}
                  </Image.PreviewGroup>
                </>
              }
            />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Registration Start Date'
              content={`${formattedDateTime?.reg_start_date} ${formattedDateTime?.reg_start_time}`}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Registration End Date'
              content={`${formattedDateTime?.reg_end_date} ${formattedDateTime?.reg_end_time}`}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Event Start Date'
              content={`${formattedDateTime?.event_start_date} ${formattedDateTime?.event_start_time}`}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Event End Date'
              content={`${formattedDateTime?.event_end_date} ${formattedDateTime?.event_end_time}`}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Event Description'
              content={<CustomizedTable TableData={eventBio} />}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Event Agenda'
              content={<CustomizedTable TableData={eventInfo} />}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Speakers' Bio"
              content={<CustomizedTable TableData={speakerBio} />}
            />
          </Col>
        </Row>
        <Divider />
      </>
    );
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '3%',
      sorter: true,
    },
    {
      title: 'Event Start Date',
      dataIndex: 'event_start_date_time',
      width: '8%',
      sorter: true,
      render: (event_start_date_time, record) => {
        return (
          <div>
            {`${record?.formattedDateTime?.event_start_date} ${record?.formattedDateTime?.event_start_time}`}
          </div>
        );
      },
    },
    {
      title: 'Event End Date',
      dataIndex: 'formattedDateTime',
      width: '8%',
      render: (formattedDateTime) => {
        return (
          <div>
            {`${formattedDateTime?.event_end_date} ${formattedDateTime?.event_end_time}`}
          </div>
        );
      },
    },
    {
      title: 'Reg Start Date',
      dataIndex: 'formattedDateTime',
      width: '8%',
      render: (formattedDateTime) => {
        return (
          <div>
            {`${formattedDateTime?.reg_start_date} ${formattedDateTime?.reg_start_time}`}
          </div>
        );
      },
    },
    {
      title: 'Reg End Date',
      dataIndex: 'formattedDateTime',
      width: '8%',
      render: (formattedDateTime) => {
        return (
          <div>
            {`${formattedDateTime?.reg_end_date} ${formattedDateTime?.reg_end_time}`}
          </div>
        );
      },
    },

    {
      title: 'Event Name',
      dataIndex: 'name',
      width: '40%',
      sorter: true,
      render: (text) => truncateStringToWords(text),
    },
    {
      title: 'Local Chapter',
      dataIndex: 'chapter_name',
      width: '6%',
      sorter: false,
    },
    {
      title: 'Location',
      dataIndex: 'place',
      width: '6%',
      sorter: true,
    },
    {
      title: 'Speakers',
      dataIndex: 'speaker_name',
      width: '42%',
      render: (text) => {
        const NAText = ReturnNAOnEmpty(text);
        return truncateStringToWords(NAText);
      },
    },
    // {
    //   title: 'Event Type',
    //   dataIndex: 'event_type_name',
    //   width: '4%',
    //   sorter: false,
    // },
    // {
    //   title: 'Local Chapter',
    //   width: '5%',
    //   dataIndex: 'chapter_name',
    // },
    // {
    //   title: 'Place',
    //   dataIndex: 'place',
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '6%',
      render: (status, record) => {
        const updatedStatus = +status;
        let color;
        let label;
        switch (updatedStatus) {
          case EventStatus.ACTIVE:
            color = 'green';
            label = 'Active';
            break;
          case EventStatus.DRAFT:
            color = 'purple';
            label = 'Draft';
            break;
          case EventStatus.REG_CLOSED:
            color = 'red';
            label = 'Registration Closed';
            break;
          case EventStatus.PRIVATE:
            color = 'magenta';
            label = 'Private';
            break;
          case EventStatus.TENTATIVE:
            color = 'blue';
            label = 'Tentative';
            break;
          default:
            color = 'purple';
            label = 'Draft';
        }
        return <span style={{color: `${color}`}}>{label}</span>;
      },
    },
    {
      title: 'Regular Registrations',
      dataIndex: 'seats',
      width: '1%',
      render: (_, record) => {
        return (
          <div className='fixed-size-text'>
            {record?.seats_registered}/{record?.seats || 0}
          </div>
        );
      },
    },
    {
      title: 'Sponsors(Major + HH + LS)',
      dataIndex: 'sponsorship',
      width: '45%',
      render: (_, record) => {
        return (
          <div className='fixed-size-text'>
            {record?.sponsorship_registered}/{record?.sponsorship || 0}
          </div>
        );
      },
    },
    {
      title: 'Exhibitors(Vendors)',
      dataIndex: 'vendors',
      width: '1%',
      render: (_, record) => {
        return (
          <div className='fixed-size-text'>
            {record?.vendors_registered}/{record?.vendors || 0}
          </div>
        );
      },
    },
    {
      title: 'Workshop Attendance (Vendor Reps)',
      dataIndex: 'workshop_attendees_registered',
      width: '1%',
      render: (_, record) => {
        return (
          <div className='fixed-size-text'>
            {record?.workshop_attendees_registered || 0}/
            {record?.workshop_attendees || 0}
          </div>
        );
      },
    },
    {
      title: 'Organizers',
      dataIndex: 'organizers',
      width: '6%',
      render: (text) => {
        const NAText = ReturnNAOnEmpty(text);
        return truncateStringToWords(NAText);
      },
    },

    {
      title: '',
      key: 'action',
      width: '3%',
      render: (_, record) => (
        <AdminActionsDropdown
          record={record}
          handleEditClick={handleEditClick}
          handleUpdateStatusClick={handleUpdateStatusClick}
          handleDeleteClick={deleteEvents}
          handleCopyClick={duplicateEvents}
          deleteBool={true}
          editBool={true}
          statusBool={true}
          copyBool={true}
          viewBool={true}
          handleView={showDrawer}
          text='Event'
          eventNavigate={true}
          resource='events'
        />
      ),
    },
  ];
  columns.forEach((column) => {
    if (column.key !== 'action') {
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });
  return (
    <div className='main-container'>
      <AdminViewDetailsDrawer
        content={EventDrawerContent}
        visible={open}
        text={'Event'}
        onCloseDrawer={onCloseDrawer}
        isEditable={openDrawerDetails?.status != EventStatus.ACTIVE}
        editNavigate={`/admin/events/update/${openDrawerDetails?.id}`}
      />
      <StatusUpdateModal
        data={recordData}
        visible={showUpdateStatus === false ? false : true}
        onCancel={hideModal}
        showUpdateStatus={showUpdateStatus}
        handleChange={handleChange}
        handleUpdateJobStatus={handleUpdateStatus}
        selectionOptions={EventOptions}
      />

      <HeaderSearchCard
        resource='events'
        toggleClick={toggleExpand}
        buttonTitle={ApplicationString['eventsComponent.button.addForm']}
        addClick={() => navigate('/admin/events/add')}
        dataTestId='adminEvents-button-addEvents'
        permission={CREATE}
        expanded={expanded}
      />

      {expanded && (
        <DynamicSearchForm
          columns={eventsFilters}
          eventTypesOptions={eventTypesOptions}
          chaptersOptions={chaptersOptions}
          onSearch={handleSearch}
          savedFilteredValues={searchValues}
          title={'events'}
          fetching={fetching}
          setFetching={setFetching}
          speakerOptions={speakerOptions}
          setSpeakerOptions={setSpeakerOptions}
          SetEventTypesOption={SetEventTypesOption}
        />
      )}
      <div className='listing-container'>
        <Table
          bordered
          className='admin-table'
          columns={columns}
          dataSource={allEventsData}
          rowKey={'id'}
          scroll={{x: 1000}}
          onChange={debouncedTableChange}
          pagination={{
            onChange: debouncedHandlePaginationChange,
            current: pn || paging.page,
            total: paging.totalCount,
            pageSize: ps || paging.pageCount,
            pageSizeOptions: PageSizeArray,
            showQuickJumper: true,
            showSizeChanger: true,
            onShowSizeChange: debouncedHandlePaginationChange,
            showTotal: (total, range) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>
    </div>
  );
};

export default EventsComponent;

import {Button, Col, Divider, Result, Row} from 'antd';
import React, {useEffect, useState} from 'react';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import './style.css';
import RegisterForm from '../../CommonComponents/RegisterForm';
import CommonWantToSeeAdsListing from '../../CommonComponents/CommonWantToSeeAdsListing';
import CommonPageHeroText from '../../CommonComponents/CommonPageHeroText';
import {ApplicationString} from '../../../Localization/Locales/en';
import TabFilter from '../../CommonComponents/UserTabFilter';
import {JobsAndAdsTabs} from '../../../Util/commonUtility';
import {useNavigate} from 'react-router-dom';

const RegisterComponent = ({
  chaptersList,
  keywordsOptions,
  chaptersListForAnnouncements,
}) => {
  const navigate = useNavigate();
  const [tabKey, setTabKey] = useState('ads');
  const [shouldRender, setShouldRender] = useState(false);
  const handleWindowResize = () => {
    setShouldRender(window.innerWidth > 1000);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <React.Fragment>
      <div className='register-container'>
        <>
          <CommonPageHeroText
            heading={ApplicationString['userRegisterComponent.heading']}
            subheading={ApplicationString['userRegisterComponent.subheading']}
          />
          {shouldRender && (
            <SectionLayout>
              <div className='align-right'>
                <div className='hero-subheading contact-us_register'>
                  Contact us at <a href='mailto:info@pbss.org'>info@pbss.org</a>{' '}
                  {ApplicationString['userAddAttendeeComponent.message']}
                </div>
                <TabFilter setTabKey={setTabKey} tabs={JobsAndAdsTabs} />{' '}
              </div>
            </SectionLayout>
          )}
          <SectionLayout>
            <div className='registration-content-container'>
              <Row
                gutter={{
                  xs: 4,
                  sm: 4,
                  md: 40,
                  lg: 100,
                }}>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <div className='registration-heading-container'>
                    <div className='registration-description'>
                      {ApplicationString['userRegisterComponent.description']}
                    </div>
                    <div className='registration-disclaimer'>
                      {ApplicationString['userRegisterComponent.disclaimer']}
                    </div>
                  </div>
                  <RegisterForm
                    chaptersList={chaptersList}
                    chaptersListForAnnouncements={chaptersListForAnnouncements}
                    keywordsOptions={keywordsOptions}
                  />

                  <Row>
                    <div className='registration-disclaimer2 '>
                      {ApplicationString['userRegisterComponent.disclaimer2']}
                    </div>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} prefixCls='ads-column'>
                  <CommonWantToSeeAdsListing
                    isVisible={false}
                    tabKey={tabKey}
                    usedForChapPage={true}
                    usedForRegister={true}
                  />
                </Col>
              </Row>
            </div>
          </SectionLayout>
        </>
      </div>
    </React.Fragment>
  );
};

export default RegisterComponent;

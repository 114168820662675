import React, {useEffect, useState} from 'react';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  Checkbox,
  Radio,
  Spin,
  Empty,
} from 'antd';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {ApplicationString} from '../../../../Localization/Locales/en';
import {
  disablePrevDatesHandler,
  NumberWithoutDecimalValidation,
  queryString,
  validateDiscount,
} from '../../../../Util/commonUtility';
import dayjs from 'dayjs';
import {useDispatch} from 'react-redux';
import {
  getAllEventsByQueryString,
  getAllEventsByQueryStringSearch,
} from '../../../../Store/Actions/events';
import {InfoCircleOutlined} from '@ant-design/icons';

const CouponCodesFormComponent = (props) => {
  const [submit, setSubmit] = useState(false);
  const [submitLabel, setSubmitLabel] = useState(
    ApplicationString['common.button.submitForm'],
  );
  const [form] = Form.useForm();
  const {id} = useParams();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const {
    postCouponCodes,
    EditData,
    eventsList,
    updateCouponCodes,
    fetchCouponCodesById,
    setFetching,
    fetching,
    setEventsList,
  } = props;

  const handleEventSearch = (value) => {
    setEventsList([]);
    setFetching(true);
    const Data = {
      name: value,
    };
    const searchQueryData = queryString(Data);
    dispatch(
      getAllEventsByQueryStringSearch(
        {
          page: 1,
          pagecount: 10,
        },
        '',
        '',
        searchQueryData,
      ),
    );
  };

  useEffect(() => {
    if (id) {
      fetchCouponCodesById(id);
      setSubmitLabel(ApplicationString['common.button.updateForm']);
    }
  }, []);

  useEffect(() => {
    if (id) {
      EditData &&
        form.setFieldsValue({
          ...EditData,
          start_date: EditData?.start_date ? dayjs(EditData?.start_date) : null,
          end_date: EditData?.end_date ? dayjs(EditData?.end_date) : null,
          qty: parseInt(EditData?.qty),
          discount: parseInt(EditData?.discount),
          percentage_discount: EditData?.percentage_discount
            ? 'percentage'
            : 'price',
          apply_to_whole_cart: EditData?.apply_to_whole_cart
            ? 'cart'
            : 'individual',
          apply_to_food: EditData?.apply_to_food ? 'yes' : 'no',
          event_id: EditData?.event_id && {
            ...EditData?.event_id,
            label: EditData?.event_id?.name,
            value: EditData?.event_id?.id,
          },
        });
    }
  }, [EditData]);

  const onFinish = (values) => {
    const data = {
      ...values,
      qty: parseInt(values?.qty) || undefined,
      discount: parseInt(values?.discount) || undefined,
      percentage_discount: values?.percentage_discount === 'percentage',
      apply_to_food: values?.apply_to_food === 'yes' ? true : false,
      apply_to_whole_cart:
        values?.apply_to_whole_cart === 'cart' ? true : false,
      event_id: values?.event_id?.value || values?.event_id || null,
      start_date: values?.start_date
        ? dayjs(values?.start_date).format('YYYY-MM-DD')
        : null,
      end_date: values?.end_date
        ? dayjs(values?.end_date).format('YYYY-MM-DD')
        : null,
    };

    if (id) {
      updateCouponCodes(data, EditData.id);
    } else {
      postCouponCodes(data);
    }

    setSubmit(true);
  };
  const onFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };
  const onReset = () => {
    form.resetFields();
    setSubmit(false);
  };
  const backToCouponCodes = () => {
    navigate('/admin/couponCodes');
  };

  const ValueAddon = () => {
    const selectedOption = form.getFieldValue('numberOrPercentage');

    return selectedOption === 'percentage' ? '%' : null;
  };

  const handleEventAllowClear = () => {
    dispatch(
      getAllEventsByQueryStringSearch({
        page: 1,
        pagecount: 10,
      }),
    );
  };

  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='basic'
          onFinish={onFinish}
          // initialValues={{
          //   apply_to_food: false,
          //   apply_to_whole_cart: false,
          // }}
          layout='vertical'
          className='two-column-form'
          onFinishFailed={onFinishFailed}
          autoComplete='off'>
          <div className='form-column'>
            <Form.Item
              label={
                <div className='couponCodeLabelAndTextMessageContainer'>
                  <span className='detailsLabel'>Event ID</span>
                  <span className='detailsTextMessage'>
                    <InfoCircleOutlined /> Specifying an event means that this
                    coupon will only be applied to this event.
                  </span>
                </div>
              }
              name='event_id'
              initialValue={null}>
              <Select
                prefixCls='common-select-register'
                showSearch
                className='space'
                allowClear
                onClear={handleEventAllowClear}
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onSearch={handleEventSearch}
                notFoundContent={
                  fetching ? (
                    <Spin size='small' />
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                placeholder={'Select event for coupon code'}
                options={eventsList}></Select>
            </Form.Item>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Coupon Code'
                name='code'
                rules={[
                  {
                    required: true,
                    message: 'Coupon Code should not be empty',
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                  placeholder='Enter coupon code'
                />
              </Form.Item>
              <Form.Item
                label='Coupon Activation Date'
                name='start_date'
                rules={[
                  {required: true, message: 'Start date should not be empty'},
                ]}>
                <DatePicker
                  prefixCls='common-input-user'
                  className='genral-form width-100'
                  disabledDate={(current) =>
                    disablePrevDatesHandler(current, true)
                  }
                  placeholder='Select start date for coupon code'
                />
              </Form.Item>
            </div>

            <div className='form-column'>
              <Form.Item
                label='Quantity'
                name='qty'
                rules={[
                  {
                    required: true,
                    message: 'Quantity should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      NumberWithoutDecimalValidation(value, 'Quantity'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter quantity for coupon code'
                />
              </Form.Item>
              <Form.Item
                label='Coupon Expiration Date'
                name='end_date'
                rules={[
                  {
                    required: true,
                    message: 'End date should not be empty',
                  },
                  ({getFieldValue}) => ({
                    validator(_, value) {
                      const startDate = getFieldValue('start_date');
                      if (!startDate || !value) {
                        return Promise.resolve();
                      }
                      if (value && value.isAfter(startDate)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          'End Date must be after Start Date',
                        );
                      }
                    },
                  }),
                ]}>
                <DatePicker
                  prefixCls='common-input-user'
                  className='genral-form width-100'
                  disabledDate={(current) =>
                    disablePrevDatesHandler(current, true)
                  }
                  placeholder='Select end date for coupon code'
                />
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Discounted amount or percentage'
                name='percentage_discount'
                rules={[
                  {
                    required: true,
                    message: 'Please select price or percentage',
                  },
                ]}>
                <Radio.Group
                  prefixCls='common-radio-group'
                  onChange={(e) =>
                    form.setFieldsValue({percentage_discount: e.target.value})
                  }
                  buttonStyle='solid'>
                  <Radio.Button value='price'>Price</Radio.Button>
                  <Radio.Button value='percentage'>Percentage</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className='form-column'>
              <Form.Item
                label='Cart or Individual'
                name='apply_to_whole_cart'
                rules={[
                  {
                    required: true,
                    message: 'Please select cart or individual',
                  },
                ]}>
                <Radio.Group
                  prefixCls='common-radio-group'
                  onChange={(e) =>
                    form.setFieldsValue({apply_to_whole_cart: e.target.value})
                  }
                  buttonStyle='solid'>
                  <Radio.Button value='cart'>Cart</Radio.Button>
                  <Radio.Button value='individual'>Individual</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className='form-column'>
              {/* <Form.Item name='apply_to_food' valuePropName='checked'>
                <Checkbox
                  className='common-input-user'
                  style={{
                    alignItems: 'center',
                    width: 'fit-content',
                    padding: '0 1rem',
                  }}>
                  Food
                </Checkbox>
              </Form.Item> */}
              <Form.Item
                label='Apply to Food'
                name='apply_to_food'
                rules={[
                  {
                    required: true,
                    message: 'Please select apply to food or not',
                  },
                ]}>
                <Radio.Group
                  prefixCls='common-radio-group'
                  onChange={(e) =>
                    form.setFieldsValue({apply_to_food: e.target.value})
                  }
                  buttonStyle='solid'>
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Discount'
                name='discount'
                rules={[
                  {
                    required: true,
                    message: 'Discount should not be empty',
                  },
                  {
                    validator: (_, value) =>
                      validateDiscount(
                        form.getFieldValue('percentage_discount'),
                        _,
                        value,
                      ),
                  },
                ]}
                validateTrigger='onChange'>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter discount for coupon code'
                />
              </Form.Item>
              {/* <Form.Item name='apply_to_whole_cart' valuePropName='checked'>
                <Checkbox
                  className='common-input-user'
                  style={{
                    alignItems: 'center',
                    width: 'fit-content',
                    padding: '0 1rem',
                  }}>
                  Whole Cart
                </Checkbox>
              </Form.Item> */}
            </div>
          </div>
          <Form.Item>
            <div className='button-container'>
              <Button
                id='adminCouponCode-backButton-button'
                className='common-submit-login common-form-submit common-form-cancel'
                onClick={() => {
                  backToCouponCodes();
                }}>
                {ApplicationString['common.button.backForm']}
              </Button>
              <div className='form-flex-button'>
                <Button
                  id='adminCouponCode-submitButton-button'
                  type='primary'
                  htmlType='submit'
                  //disabled={submit ? true : false}
                  className='common-submit-login common-form-submit'>
                  {submitLabel}
                </Button>
                <Button
                  id='adminCouponCode-resetButton-button'
                  className='common-submit-login common-form-submit common-form-cancel'
                  htmlType='button'
                  onClick={onReset}>
                  {ApplicationString['dynamicSearch.button.reset']}
                </Button>{' '}
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CouponCodesFormComponent;

import React, {useState} from 'react';
import {Collapse} from 'antd';
import './style.css';
import FAQDownArrowSvg from '../../../Assets/svgComponents/FAQDownArrowSvg';
import FAQUpArrowSvg from '../../../Assets/svgComponents/FAQUpArrowSvg';

const {Panel} = Collapse;

const CustomCollapse = ({data, titleHide}) => {
  const [activeKey, setActiveKey] = useState(null);

  const handleCollapseChange = (keys) => {
    setActiveKey(keys[0]);
  };

  if (!data) {
    data = [
      {
        title: 'Is there a membership fee to join PBSS?',
        content: 'No. Membership is free.',
      },
      {
        title: 'What is PBSS policy on vendor speakers?',
        content: `We often receive interest from vendors in providing a speaker for one of our meetings. Unfortunately, in order to avoid any impression of commercial promotion or vendor favoritism, the PBSS policy generally prohibits the use of vendor speakers, except in rare cases where a vendor holds a unique technology that we are interested in hearing about. One compromise solution is that vendors can recommend a client scientist who is an expert on the vendor's technology.`,
      },
      {
        title: 'What is PBSS policy on membership information sharing?',
        content: `
        In order to protect the privacy of our members, the membership list is strictly used for PBSS business only and cannot be shared with anyone or any party outside PBSS management.
        `,
      },
      {
        title: 'Will PBSS provide an Attendance Certificate?',
        content: `<p>We can provide an electronic copy (PDF) of the attendance certificate upon special request at an extra cost, typically $50 for US-based events. Please email us at <a href="mailto:info@pbss.org">info@pbss.org</a> and indicate the date and title of the event. </p>`,
      },
    ];
  }

  return (
    <React.Fragment>
      <div className={`${!titleHide && 'collapse-container-e23'}`}>
        {!titleHide && <p className='collapse-title'>FAQ</p>}
        <Collapse
          expandIconPosition='end'
          prefixCls='custom-collapse'
          onChange={handleCollapseChange}>
          {data.map((item, index) => (
            <Panel
              id={`aboutUs-FAQCollapse-${index}`}
              header={item.title}
              key={index}
              className={activeKey === index ? 'active-panel' : ''}>
              {/* {item.content} */}
              <div
                dangerouslySetInnerHTML={{__html: item.content}}
                className='collapse-content'
              />
            </Panel>
          ))}
        </Collapse>
      </div>
    </React.Fragment>
  );
};

export default CustomCollapse;

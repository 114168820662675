import React, {useEffect, useState} from 'react';
import {
  Button,
  Form,
  Popconfirm,
  Select,
  Table,
  Col,
  Row,
  Divider,
  Input,
  Checkbox,
} from 'antd';
import {CSVLink} from 'react-csv';
import {ApplicationString} from '../../../Localization/Locales/en';
import {
  DescriptionItem,
  EventRegistrationStatus,
  OnlyAlphabetInputValidatorWithSpecialCharacters,
  PageSizeArray,
  PaginationPosition,
  ReturnNAOnEmpty,
  eventRegistrationsFilters,
  event_registration_type,
  formatCurrency,
  formatDate,
  getStatusInfoOfEventRegistration,
  registrationFilterOptions,
} from '../../../Util/commonUtility';
import {FilterOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import DynamicSearchForm from '../../CommonComponents/DynamicSearch';
import CommonTableColumnTextView from '../../CommonComponents/CommonTableColumnTextView';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import AdminViewDetailsDrawer from '../../CommonComponents/AdminViewDetailsDrawer';
import CommonUserModal from './../../CommonComponents/CommonUserModal/index';
import {memorizedInitiatedRefundAttendees} from '../../../Store/Actions/eventRegister';
import {editRefundStatusById} from '../../../Store/Actions/events';
import {Option} from 'antd/es/mentions';
import {usePermissions} from '../../../hooks/usePermission';

const EventRegistrationsComponent = (props) => {
  const {
    allEventRegistrationData,
    handlePaginationChange,
    foodOptions,
    allEventRegistrationDataForCSV,
    registrationOptions,
    UpdateRegistrationOptions,
    paging,
    sortParams,
    handleTableChange,
    cancelEventRegistrations,
    searchValues,
    fetchEventRegistrations,
    fetchEventRegistrationsForCSV,
    csvDataLoading,
  } = props;
  const ps = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.eventRegistrations,
  );
  const pn = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.eventRegistrations,
  );
  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [expanded, setExpanded] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.id === editingKey;
  const [selectedEventRegistration, setSelectedEventRegistration] = useState();
  const [
    selectedEventRegistrationWithRecord,
    setSelectedEventRegistrationWithRecord,
  ] = useState();
  const [selectionType, setSelectionType] = useState('checkbox');
  const [isCancelAndRefundAccessed, setIsCancelAndRefundAccessed] =
    useState(true);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showApproveCancelRegistration, setShowApproveCancelRegistration] =
    useState(false);
  const [open, setOpen] = useState(false);
  const [openDrawerDetails, setOpenDrawerDetails] = useState(false);
  const [attendeePresentationChecked, setAttendeePresentationChecked] =
    useState(false);
  const [attendeeDinnerChecked, setAttendeeDinnerChecked] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  let totalIncome = 0;
  let totalDiscounted = 0;

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const userRole = userInfo?.role;
  const {checkPermission} = usePermissions(userRole);

  useEffect(() => {
    if (
      !checkPermission('events_registrations', 'cancelAndRefundRegistration')
    ) {
      setIsCancelAndRefundAccessed(false);
    }
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  // Drawer functions ----------------------------------------------------
  const showDrawer = (record) => {
    setOpenDrawerDetails(record);
    setOpen(true);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const EventRegistrationDrawerContent = () => {
    const {
      attendee_first_name,
      attendee_last_name,
      user_id,
      event_id,
      attendee_email,
      attendee_company,
      is_sponsor,
      is_reps,
      registration_type,
      event_registration_options,
      total_cost,
      discount_amount,
      discount_code,
      status,
      created_at,
      user_local_chapter,
    } = openDrawerDetails || {};

    return (
      <>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Attendee Status'
              content={
                {
                  3: 'Paid',
                  4: 'Payment Confirmed',
                  6: 'Fee Wavier',
                  '-1': 'To be Deleted',
                  0: 'Payment Refunded',
                  1: 'To be Paid',
                  2: 'Refund Pending',
                  5: 'No Charge Event',
                }[status] || status
              }
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem title='User Id' content={user_id} />
          </Col>
          <Col span={12}>
            <DescriptionItem title='Event Id' content={event_id} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem title='First Name' content={attendee_first_name} />
          </Col>
          <Col span={12}>
            <DescriptionItem title='Last Name' content={attendee_last_name} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem title='Attendee Email' content={attendee_email} />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title='Attendee Company'
              content={attendee_company}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem
              title='Sponsorship'
              content={is_sponsor ? 'Yes' : 'No'}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title='Representative'
              content={is_reps ? 'Yes' : 'No'}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Registration Type'
              content={registration_type}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Registration Option'
              content={
                event_registration_options?.sponsorship?.name
                  ? event_registration_options?.sponsorship?.name
                  : event_registration_options?.registration?.name +
                    ' ' +
                    '-' +
                    ' ' +
                    formatCurrency(
                      event_registration_options?.registration?.price,
                      'USD',
                    )
              }
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Food Option'
              content={
                event_registration_options?.food?.name
                  ? event_registration_options?.food?.name +
                    ' ' +
                    '-' +
                    ' ' +
                    formatCurrency(
                      event_registration_options?.food?.price,
                      'USD',
                    )
                  : 'No Food'
              }
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem
              title='Total Cost'
              content={formatCurrency(total_cost ? total_cost : 0)}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title='Discounted Cost'
              content={formatCurrency(discount_amount ? discount_amount : 0)}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem
              title='Total Cost After Discount'
              content={formatCurrency(total_cost - discount_amount || 0)}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title='Coupon Code Applied'
              content={discount_code || 'N/A'}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem
              title='Registered Date'
              content={formatDate(created_at)}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title='User Local Chapter'
              content={user_local_chapter}
            />
          </Col>
        </Row>
        <Divider />
      </>
    );
  };

  useEffect(() => {
    searchValues && setExpanded(true);
  }, [searchValues]);

  const handleCancelGroupOfRegistration = () => {
    const eventIds = {
      eventRegistrationIds: selectedEventRegistration,
    };
    cancelEventRegistrations(eventIds, setSelectedRowKeys);
    setSelectedEventRegistration([]);
  };
  const handleInitiateRefund = () => {
    dispatch(
      memorizedInitiatedRefundAttendees(selectedEventRegistrationWithRecord),
    );
    if (!selectedEventRegistration || selectedEventRegistration?.length <= 0) {
      dispatch({type: FETCH_ERROR, payload: 'Please select attendee(s)'});
      return;
    }
    const Data = {
      eventRegistrationIds: selectedEventRegistration,
      status: 2,
    };
    dispatch(editRefundStatusById(Data));
    navigate(`/admin/eventRefund/${id}`);
  };

  const handleInitiateRefundVisible = () => {
    setIsModalVisible(true);
  };
  const hideModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (selectedRowKeys?.length > 0) {
      setShowApproveCancelRegistration(true);
      setSelectedEventRegistration(selectedRowKeys);
    } else {
      setShowApproveCancelRegistration(false);
      setSelectedEventRegistration([]);
    }
  }, [selectedRowKeys]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowIds, selectedKeys) => {
      setSelectedRowKeys(selectedKeys?.map((row) => row.id));
      setSelectedEventRegistrationWithRecord(selectedKeys);
    },
    getCheckboxProps: (record) => {
      const PaymentRefunded = EventRegistrationStatus.PAYMENT_REFUNDED;
      const RefundPending = EventRegistrationStatus.REFUND_PENDING;
      const ToBePaid = EventRegistrationStatus.TO_BE_PAID;
      const RegistrationCancelled = EventRegistrationStatus.TO_BE_DELETED;
      const disabledStatuses = [
        PaymentRefunded,
        RegistrationCancelled,
        RefundPending,
        ToBePaid,
      ];
      return {
        disabled: disabledStatuses.includes(record.status),
      };
    },
  };

  const footer = () => {
    return (
      <div>
        {showApproveCancelRegistration && (
          <div className='admin-registration-container'>
            <Popconfirm
              title='Cancel Selected Registration'
              description='Are you sure you want to cancel the selected registrations?'
              onConfirm={handleCancelGroupOfRegistration}
              okType='primary'
              icon={<QuestionCircleOutlined style={{color: 'warning'}} />}>
              <div className='common-cancel-button'>
                <Button
                  id='EventRefundComponent-cancelRegistration'
                  className='common-submit-login common-form-submit'>
                  Proceed to Cancel Selected Registration
                </Button>
              </div>
            </Popconfirm>
            <CommonUserModal
              visible={isModalVisible}
              onCancel={hideModal}
              onConfirm={handleInitiateRefund}
              confirmBtnText={'Proceed'}
              content={
                <React.Fragment>
                  Are you sure you want to initiate a refund for the selected
                  registration(s)?
                </React.Fragment>
              }
              usedForAdminSide={true}
            />

            <div className='common-cancel-button'>
              <Button
                onClick={handleInitiateRefundVisible}
                id='EventRefundComponent-cancelRegistration'
                className='common-submit-login common-form-submit'>
                Initiate Refund
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };
  const handleAttendeePresentationChange = (e) => {
    setAttendeePresentationChecked(e.target.checked);
  };
  const handleAttendeeDinnerChange = (e) => {
    setAttendeeDinnerChecked(e.target.checked);
  };
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const handleSearch = (values) => {
    fetchEventRegistrations(values);
  };

  const edit = (record) => {
    setAttendeeDinnerChecked(record?.attending_dinner);
    setAttendeePresentationChecked(record?.attending_presentation);
    const EditData = {
      ...record,
      food_option: record.event_registration_options?.food
        ? record.event_registration_options?.food?.event_option_id
        : 'no-food',
      registration_option:
        record.event_registration_options?.registration?.event_option_id,
    };
    form.setFieldsValue({
      ...EditData,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (record) => {
    const registrationId = record?.event_registration_options?.registration?.id;
    const foodId = record?.event_registration_options?.food?.id;

    try {
      const values = await form.validateFields();
      const updateOptions = {
        food_option:
          values.food_option === 'no-food'
            ? {}
            : {id: foodId, event_option_id: values.food_option},
        ...(values.registration_option && {
          registration_option: {
            id: registrationId ?? '',
            event_option_id: values.registration_option,
          },
        }),
        ...(values.attending_presentation && {
          attending_presentation: attendeePresentationChecked,
        }),
        ...(values.attending_dinner && {
          attending_dinner: attendeeDinnerChecked,
        }),
        registration_type: values.registration_type,
        attendee_email: values.attendee_email,
        attendee_first_name: values.attendee_first_name,
        attendee_last_name: values.attendee_last_name,
        attendee_company: values.attendee_company,
        status: values.status,
      };
      UpdateRegistrationOptions(updateOptions, record?.id);
      setEditingKey('');
    } catch (err) {
      console.error('Validate Failed:', err);
    }
  };

  const EventRegistrationStatusObj = [
    {
      label: 'Registration Cancelled',
      value: EventRegistrationStatus.TO_BE_DELETED,
    },
    {
      label: 'Payment Refunded',
      value: EventRegistrationStatus.PAYMENT_REFUNDED,
    },
    {label: 'To be Paid', value: EventRegistrationStatus.TO_BE_PAID},
    {label: 'Refund Pending', value: EventRegistrationStatus.REFUND_PENDING},
    {label: 'Paid', value: EventRegistrationStatus.PAID},
    {
      label: 'Payment Confirmed',
      value: EventRegistrationStatus.PAYMENT_CONFIRMED,
    },
    {label: 'No Charge Event', value: EventRegistrationStatus.NO_CHARGE_EVENT},
    {label: 'Fee Waiver', value: EventRegistrationStatus.FEE_WAIVER},
  ];

  const getStatusName = (statusId) => {
    const statusObj = EventRegistrationStatusObj.find(
      (status) => status.value === statusId,
    );
    return statusObj ? statusObj.label : '';
  };
  const shouldEditCell = (column, record) => {
    if (column.dataIndex === 'registration_option') {
      return !record.is_reps && !record.is_sponsor;
    }
    if (
      column.dataIndex === 'attending_presentation' ||
      column.dataIndex === 'attending_dinner'
    ) {
      return record.is_reps;
    }

    if (
      column.dataIndex === 'food_option' ||
      column.dataIndex === 'registration_type'
    ) {
      return !record.is_sponsor;
    }
    // Add conditions for other columns if needed
    return column.editable ? true : false; // Default to editable for other columns
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      fixed: 'left',
      width: '5%',
    },

    {
      title: 'Member Attendee Name',
      dataIndex: 'user_name',
      sorter: true,
      editable: false,
      width: '10%',
      render: (text, record) => {
        return <span>{text || 'N/A'}</span>;
      },
    },
    {
      title: 'Member Attendee Email',
      dataIndex: 'user_email',
      sorter: true,
      editable: false,
      width: '10%',
      render: (text, record) => {
        return <span>{text || 'N/A'}</span>;
      },
    },

    {
      title: 'Attendee First Name',
      dataIndex: 'attendee_first_name',
      sorter: true,
      editable: true,
      width: '10%',
      render: (text, record) => {
        return <CommonTableColumnTextView columnText={text} isToolTip={true} />;
      },
    },
    {
      title: 'Attendee Last Name',
      dataIndex: 'attendee_last_name',
      width: '10%',
      editable: true,
      sorter: true,
      render: (text, record) => {
        return <span>{text || 'N/A'}</span>;
      },
    },
    {
      title: 'Email',
      dataIndex: 'attendee_email',
      editable: true,
      sorter: true,
      width: '12%',
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: 'Attendee Type',
      dataIndex: 'type',
      editable: false,
      sorter: false,
      width: '10%',
      render: (text, record) => {
        const renderType = (record) => {
          let typeLabel;
          if (record?.is_reps) {
            typeLabel = 'Vendor Reps';
          } else if (record?.is_sponsor) {
            typeLabel = 'Sponsor';
          } else {
            typeLabel = 'Regular';
          }
          return typeLabel;
        };
        return <div>{renderType(record)}</div>;
      },
    },
    {
      title: 'Company',
      dataIndex: 'attendee_company',
      editable: true,
      width: '8%',
      sorter: true,
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: 'User Local Chapter',
      dataIndex: 'user_local_chapter',
      width: '6%',
    },
    {
      title: 'Registration Type',
      dataIndex: 'registration_type',
      editable: true,
      width: '8%',
      render: (text) => {
        const typeEnum = {
          in_person: 'In Person',
          webcast: 'Webcast',
          Workshop: 'Workshop',
        };
        return <span>{typeEnum[text] || text || 'N/A'}</span>;
      },
    },
    {
      title: 'Registration Option',
      dataIndex: 'registration_option',
      width: '12%',
      render: (text, record) => {
        const isOptionsSponsor =
          record.event_registration_options?.sponsorship?.name;
        return (
          <span>
            {/* {checkIsEditable(record)} */}
            {record?.is_reps || record?.is_sponsor
              ? 'N/A'
              : isOptionsSponsor
              ? isOptionsSponsor
              : record.event_registration_options?.registration?.name +
                ' ' +
                '-' +
                ' ' +
                formatCurrency(
                  record?.event_registration_options?.registration?.price,
                  'USD',
                )}
          </span>
        );
      },
    },
    {
      title: 'Food Option',
      editable: true,
      dataIndex: 'food_option',
      width: '10%',
      render: (text, record) => {
        const isOptionsFood = record.event_registration_options?.food?.name;
        const isOptionsPrice = record?.event_registration_options?.food?.price;
        return (
          <span>
            {isOptionsFood
              ? isOptionsFood +
                ' ' +
                '-' +
                ' ' +
                formatCurrency(isOptionsPrice, 'USD')
              : 'No Food'}
          </span>
        );
      },
    },
    {
      title: 'Attending Meeting Presentation?',
      dataIndex: 'attending_presentation',
      key: 'attending_presentation',
      width: '8%',
      editable: true,
      render: (text) => {
        return (
          <div className='checkbox-editableCell'>{text ? 'Yes' : 'No'}</div>
        );
      },
    },
    {
      title: 'Attending Dinner?',
      dataIndex: 'attending_dinner',
      key: 'attending_dinner',
      width: '5%',
      editable: true,
      render: (text) => {
        return (
          <div className='checkbox-editableCell'>{text ? 'Yes' : 'No'}</div>
        );
      },
    },
    {
      title: 'Registered Date',
      dataIndex: 'created_at',
      sorter: true,
      width: '9%',
      render: (text) => {
        return formatDate(text);
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '12%',
      editable: true,
      render: (status) => {
        const {color, label} = getStatusInfoOfEventRegistration(status);
        return <span style={{color: color}}>{label}</span>;
      },
    },
    {
      title: 'Coupon Code',
      dataIndex: 'discount_code',
      width: '6%',
      render: (text) => ReturnNAOnEmpty(text),
    },
    {
      title: 'Total Cost',
      dataIndex: 'total_cost',
      sorter: false,
      width: '6%',
      render: (text) => {
        return (
          <div className='total-cost'>
            {formatCurrency(text ? text : 0, 'USD')}
          </div>
        );
      },
    },
    {
      title: 'Discounted Amount',
      dataIndex: 'discount_amount',
      sorter: false,
      width: '6%',
      render: (text, record) => {
        return (
          <div className='total-cost'>
            {formatCurrency(text ? text : 0, 'USD')}
          </div>
        );
      },
    },
    {
      title: 'Paid Amount',
      dataIndex: 'paid_amount',
      sorter: false,
      width: '6%',
      render: (text, record) => {
        return (
          <div className='total-cost'>
            {formatCurrency(text ? text : 0, 'USD')}
          </div>
        );
      },
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      width: '8%',
      render: (_, record) => (
        <AdminActionsDropdown
          editBool={true}
          record={record}
          usedForTable
          viewBool={true}
          handleView={showDrawer}
          usedForAttendeeTable={true}
          handleCancelClick={cancel}
          attendeeEditingKey={editingKey}
          handleEditClick={edit}
          handleSaveClick={save}
          text='Registration'
          usedForAdminSide={true}
          resource='events_registrations'
        />
      ),
    },
  ];
  columns.forEach((column) => {
    if (column.key !== 'action') {
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });

  const mergedColumns = columns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record) && shouldEditCell(col, record),
        options: col.dataIndex,
      }),
    };
  });

  const formateEventRegistrationData = () => {
    let newEventRegistrations = [];
    allEventRegistrationDataForCSV?.map((item) => {
      let newEventRegistration = {
        id: item.id,
        user_id: item?.user_id,
        user_name: item?.user_name,
        user_email: item.user_email,
        event_id: item.event_id,
        attendee_name: item.attendee_first_name + ' ' + item.attendee_last_name,
        attendee_email: item.attendee_email,
        attendee_company: item.attendee_company,
        user_local_chapter: item.user_local_chapter,
        is_sponsor: item?.is_sponsor ? 'Yes' : 'No',
        is_reps: item?.is_reps ? 'Yes' : 'No',
        sponsorship:
          item?.event_registration_options?.sponsorship?.name || 'NA',
        registration_type: item?.registration_type,
        registration: item?.event_registration_options?.registration?.name,
        food: item?.event_registration_options?.food?.name || 'No Food',
        status: getStatusName(item.status),
        total_cost: item?.total_cost,
      };
      totalIncome = totalIncome + Number(item?.total_cost);
      totalDiscounted = totalDiscounted + Number(item?.discount_amount);
      newEventRegistrations.push(newEventRegistration);
    });

    return newEventRegistrations;
  };
  const formattedEventRegistrationData = formateEventRegistrationData();

  const headers = [
    {label: 'Id', key: 'id'},
    {label: 'User', key: 'user_id'},
    {label: 'Member Attendee Name', key: 'user_name'},
    {label: 'Member Attendee Email', key: 'user_email'},
    {label: 'Event', key: 'event_id'},
    {label: 'Attendee Name', key: 'attendee_name'},
    {label: 'Attendee Email', key: 'attendee_email'},
    {label: 'Attendee Company', key: 'attendee_company'},
    {label: 'User Local Chapter', key: 'user_local_chapter'},
    {label: 'Sponsor', key: 'is_sponsor'},
    {label: 'Representative', key: 'is_reps'},
    {label: 'SponsorShip Type', key: 'sponsorship'},
    {label: 'Registration Type', key: 'registration_type'},
    {label: 'Registration Option', key: 'registration'},
    {label: 'Food', key: 'food'},
    {label: 'Status', key: 'status'},
    {label: 'Total Cost', key: 'total_cost'},
  ];
  const subTotal = totalIncome - totalDiscounted;
  formattedEventRegistrationData.push(
    {
      id: '',
      user_id: '',
      user_name: '',
      user_email: '',
      event_id: '',
      attendee_name: '',
      attendee_email: '',
      attendee_company: '',
      user_local_chapter: '',
      is_sponsor: '',
      is_reps: '',
      sponsorship: '',
      registration_type: '',
      registration: '',
      food: '',
      status: '',
      total_cost: `${formatCurrency(totalIncome, 'USD')}`,
    },
    {
      id: '',
      user_id: '',
      user_name: '',
      user_email: '',
      event_id: '',
      attendee_name: '',
      attendee_email: '',
      attendee_company: '',
      user_local_chapter: '',
      is_sponsor: '',
      is_reps: '',
      sponsorship: '',
      registration_type: '',
      registration: '',
      food: '',
      status: '',
      total_cost: `- ${formatCurrency(totalDiscounted, 'USD')}`,
    },
    {
      id: '',
      user_id: '',
      user_name: '',
      user_email: '',
      event_id: '',
      attendee_name: '',
      attendee_email: '',
      attendee_company: '',
      user_local_chapter: '',
      is_sponsor: '',
      is_reps: '',
      sponsorship: '',
      registration_type: '',
      registration: '',
      food: '',
      status: '',
      total_cost: `= ${formatCurrency(subTotal, 'USD')}`,
    },
  );

  return (
    <div className='main-container'>
      <AdminViewDetailsDrawer
        content={EventRegistrationDrawerContent}
        visible={open}
        text={'Event Registration'}
        onCloseDrawer={onCloseDrawer}
        isEditable={false}
      />
      <div className='search-container' style={{gap: '10px'}}>
        <Button
          onClick={() => navigate('/admin/events')}
          id='EventTemplateSchedulerComponent-button-filterSearch'>
          Back To Events
        </Button>
        <Button
          onClick={toggleExpand}
          id='EventRegistration-button-filterSearch'>
          {expanded ? <FilterOutlined /> : <FilterOutlined />}{' '}
          {ApplicationString['dynamicSearch.button.heading']}
        </Button>
        {csvDataLoading && !allEventRegistrationDataForCSV && (
          <Button>Fetching Attendees..</Button>
        )}
        {!csvDataLoading && !allEventRegistrationDataForCSV?.length && (
          <Button
            csvDataLoading
            onClick={() => {
              fetchEventRegistrationsForCSV(searchValues);
            }}>
            Load CSV Data
          </Button>
        )}
        {!csvDataLoading && allEventRegistrationDataForCSV?.length > 0 && (
          <Button id='Event-register-button-downloadCsv'>
            <CSVLink
              data={formattedEventRegistrationData}
              headers={headers}
              filename={'Event_Report.csv'}>
              Download CSV
            </CSVLink>
          </Button>
        )}
      </div>
      {expanded && (
        <DynamicSearchForm
          columns={eventRegistrationsFilters}
          onSearch={handleSearch}
          savedFilteredValues={searchValues}
          title={'event_registrations'}
        />
      )}
      <div className='listing-container'>
        <Form form={form} component={false}>
          <Table
            bordered
            className='admin-table'
            components={{
              body: {
                cell: (props) => (
                  <EditableCell
                    foodOptions={foodOptions}
                    registrationOptions={registrationOptions}
                    handleAttendeePresentationChange={
                      handleAttendeePresentationChange
                    }
                    checkedAttendeePresentation={attendeePresentationChecked}
                    handleAttendeeDinnerChange={handleAttendeeDinnerChange}
                    checkedAttendeeDinner={attendeeDinnerChecked}
                    {...props}
                  />
                ),
              },
            }}
            rowSelection={
              isCancelAndRefundAccessed
                ? {
                    type: selectionType,
                    ...rowSelection,
                  }
                : undefined
            }
            footer={footer}
            columns={mergedColumns}
            dataSource={allEventRegistrationData}
            rowKey={'id'}
            onChange={handleTableChange}
            sticky={true}
            pagination={{
              onChange: handlePaginationChange,
              current: pn || paging.page,
              total: paging.totalCount,
              pageSize: ps || paging.pageCount,
              pageSizeOptions: PageSizeArray,
              showQuickJumper: true,
              showSizeChanger: true,
              onShowSizeChange: handlePaginationChange,
              showTotal: (total, range) => `Total ${total} items`,
              position: [PaginationPosition],
            }}
          />
        </Form>
      </div>
    </div>
  );
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  options,
  foodOptions,
  registrationOptions,
  handleAttendeePresentationChange,
  checkedAttendeePresentation,
  handleAttendeeDinnerChange,
  checkedAttendeeDinner,
  ...restProps
}) => {
  const inputNode = (() => {
    switch (dataIndex) {
      case 'food_option':
        return (
          <Select placeholder={`Select your options for ${title}`}>
            {foodOptions?.map((foodOption) => (
              <Option
                key={foodOption.label}
                value={foodOption.value}
                disabled={foodOption?.qty - foodOption?.used_qty === 0}>
                {foodOption.label}
              </Option>
            ))}
          </Select>
        );
      case 'status':
        return (
          <Select placeholder={`Select your status for ${title}`}>
            {registrationFilterOptions?.map((regOption) => (
              <Option
                key={regOption.label}
                value={regOption.value}
                disabled={regOption.value === 0 || regOption.value === -1}>
                {regOption.label}
              </Option>
            ))}
          </Select>
        );
      case 'registration_option':
        return (
          <Select placeholder={`Select your options for ${title}`}>
            {registrationOptions?.map((regOption) => (
              <Option
                key={regOption.label}
                value={regOption.value}
                disabled={regOption?.qty - regOption?.used_qty === 0}>
                {regOption.label}
              </Option>
            ))}
          </Select>
        );
      case 'registration_type':
        return (
          <Select
            placeholder={`Select your options for ${title}`}
            options={event_registration_type}></Select>
        );
      case 'attendee_email':
        return (
          <Form.Item
            name={dataIndex}
            className='no-margin'
            rules={[
              {
                required: true,
                message: `Please fill out ${title?.toLowerCase()}`,
              },
              {
                type: 'email',
                message: 'Please enter a valid email address',
              },
            ]}>
            <Input
              onCopy={(e) => e.preventDefault()}
              placeholder={`${`Enter attendee ${title?.toLowerCase()}`}`}
            />
          </Form.Item>
        );
      case 'attending_presentation':
        return (
          <Form.Item name={dataIndex} style={{margin: 0}}>
            <div className='checkbox-editableCell'>
              <Checkbox
                onChange={handleAttendeePresentationChange}
                checked={checkedAttendeePresentation}></Checkbox>
            </div>
          </Form.Item>
        );
        break;
      case 'attending_dinner':
        return (
          <Form.Item name={dataIndex} style={{margin: 0}}>
            <div className='checkbox-editableCell'>
              <Checkbox
                onChange={handleAttendeeDinnerChange}
                checked={checkedAttendeeDinner}></Checkbox>
            </div>
          </Form.Item>
        );
        break;
      default:
        return (
          <Form.Item
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `Please fill out ${title?.toLowerCase()}`,
              },
              {
                validator: (_, value) =>
                  OnlyAlphabetInputValidatorWithSpecialCharacters(value, title),
              },
            ]}
            style={{margin: 0}}>
            <Input
              placeholder={`${`Enter attendee ${title?.toLowerCase()}`}`}
            />
          </Form.Item>
        );
    }
  })();

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{margin: 0}}
          rules={[
            {
              required: dataIndex === 'registration_type' ? false : true,
              message: '',
            },
          ]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EventRegistrationsComponent;

import React, {useEffect, useState} from 'react';
import {
  deleteEventTemplateSchedulerById,
  editEventTemplateSchedulerStatusById,
  getAllEventTemplateSchedulers,
  getEventTemplateSchedulerByEventId,
  getUserDetails,
  sendDirectEmailByTemplateId,
  setEventStartDateForEventTemplateScheduler,
  setEventTemplateEdit,
} from '../../../Store/Actions';
import {
  DefaultPaginationValues,
  queryString,
} from '../../../Util/commonUtility';
import {useDispatch, useSelector} from 'react-redux';
import EventTemplateSchedulerComponent from '../../../Components/AdminComponents/EventTemplateScheduler';
import {setPageSize} from '../../../Store/Actions/pageSize';
import {useParams} from 'react-router-dom';

const EventTemplateSchedulerContainer = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {getEventTemplateSchedulersDataByEventId} = useSelector(
    ({eventTemplateSchedulers}) => eventTemplateSchedulers,
  );
  const [eventTemplateSchedulerData, setEventTemplateSchedulerData] =
    useState();
  const [pagination, setPagination] = useState({
    page: DefaultPaginationValues.PAGE,
    pageCount: DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [sortParams, setSortParams] = useState({
    field: 'id',
    order: 'descend',
  });
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);

  useEffect(() => {
    if (getEventTemplateSchedulersDataByEventId) {
      setEventTemplateSchedulerData(
        getEventTemplateSchedulersDataByEventId?.data?.eventEmailTemplates,
      );
      dispatch(
        setEventStartDateForEventTemplateScheduler(
          getEventTemplateSchedulersDataByEventId?.data?.event_start_date,
        ),
      );
      setPagination({
        ...pagination,
        page: getEventTemplateSchedulersDataByEventId?.data?.currentPage,
        totalPages: getEventTemplateSchedulersDataByEventId?.data?.totalPage,
        totalCount: getEventTemplateSchedulersDataByEventId?.data?.totalCount,
      });
    } else {
      fetchEventTempaltesByEventId(id);
    }
  }, [getEventTemplateSchedulersDataByEventId]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      fetchEventTempaltesByEventId(id);
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged]);

  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
  };

  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('eventTemplateSchedulers', pageSize));
    setPagination({
      ...pagination,
      page: current,
      pageCount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };

  const fetchEventTemplateSchedulers = async (searchValues) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(
      getAllEventTemplateSchedulers(
        sortField,
        sortOrder,
        searchQueryData,
        pagination,
      ),
    );
  };

  const fetchEventTempaltesByEventId = (eventId) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    dispatch(getEventTemplateSchedulerByEventId(sortField, sortOrder, eventId));
  };

  const DeleteEventTemplateScheduler = async (id) => {
    dispatch(deleteEventTemplateSchedulerById(id));
  };
  const handleEditTemplate = (editTemplateState) => {
    dispatch(setEventTemplateEdit(editTemplateState));
  };

  const sendDirectEmailByTemplateMethod = async (eventTemplateSchedulerId) => {
    dispatch(sendDirectEmailByTemplateId(eventTemplateSchedulerId));
  };

  return (
    <div>
      <EventTemplateSchedulerComponent
        eventTemplateSchedulerData={eventTemplateSchedulerData}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        DeleteEventTemplateScheduler={(id) => {
          DeleteEventTemplateScheduler(id);
        }}
        handleTableChange={handleTableChange}
        fetchEventTemplateSchedulers={(searchValues) => {
          fetchEventTemplateSchedulers(searchValues);
        }}
        fetchEventTempaltesByEventId={(eventId) => {
          fetchEventTempaltesByEventId(eventId);
        }}
        handleEditTemplate={(editTemplateState) => {
          handleEditTemplate(editTemplateState);
        }}
        sendDirectEmailByTemplateId={(eventTemplateSchedulerId) => {
          sendDirectEmailByTemplateMethod(eventTemplateSchedulerId);
        }}
        sortParams={sortParams}
      />
    </div>
  );
};
export default EventTemplateSchedulerContainer;

import Form from 'antd/es/form/Form';
import Input from 'antd/es/input/Input';
import {Col, Row, Button} from 'antd';
import {UserPasswordValidator} from '../../../Util/commonUtility';
import {useDispatch} from 'react-redux';
import {changePassword} from '../../../Store/Actions';
import {useNavigate} from 'react-router-dom';
const ChangePasswordForm = () => {
  const [changePassForm] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChangePass = (values) => {
    dispatch(changePassword(values, changePassForm, navigate));
  };
  return (
    <div className='update-pass-form'>
      <Form
        layout='vertical'
        name='update-pass'
        initialValues={''}
        onFinish={onChangePass}
        form={changePassForm}>
        <Row gutter={6}>
          <Col span={24}>
            <Form.Item
              name='existingPassword'
              label='Old Password'
              rules={[
                {
                  required: true,
                  message: 'Please input your old password',
                },
                {
                  validator: UserPasswordValidator,
                },
              ]}
              hasFeedback>
              <div className='updatePassword-form-input-container'>
                <Input.Password
                  placeholder={'Enter Old Password'}
                  prefixCls='common-input-user-login'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={24}>
            <Form.Item
              name='password'
              label='New Password'
              rules={[
                {
                  required: true,
                  message: 'Please enter your new password',
                },
                {
                  validator: UserPasswordValidator,
                },
              ]}>
              <div className='updatePassword-form-input-container'>
                <Input.Password
                  placeholder={'New password'}
                  prefixCls='common-input-user-login'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item>
              <Button
                id='submit-update-pass-page-button'
                type='primary'
                htmlType='submit'
                prefixCls='submit-update-pass-page'>
                Change Password
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ChangePasswordForm;

import React, {useState} from 'react';
import CommonJobListing from '../CommonJobListing';
import SectionLayout from '../../../CommonComponents/Layout/sectionLayout';
import dayjs from 'dayjs';
import PageTitleCard from '../../../CommonComponents/PageTitleCard';
import PageHeading from '../../../CommonComponents/PageHeading';
import {all} from 'axios';
import CommonJobsListingCard from '../CommonJobListing/CommonJobListingCard';
import {Button, Col, Row} from 'antd';
import DownArrowSvg from '../../../../Assets/svgComponents/DownArrowSvg';
import CommonHeroText from '../../../CommonComponents/CommonHeroText';
import {ApplicationString} from '../../../../Localization/Locales/en';
import CommonAddNewListingWithFilter from '../../../CommonComponents/Common_AddNewListing_with_Filter';
import {jobLocationOptions} from '../../../../Util/commonUtility';
import {getAllMyEvents} from '../../../../Store/Actions/events';
import {useDispatch} from 'react-redux';
import {getAllApprovedJobs, getAllJobs} from '../../../../Store/Actions';

const ApprovedJobListing = ({allApprovedJobsData, locationOptionsList}) => {
  const [selectedLocationValue, setSelectedLocationValue] = useState('');
  const [filteredPosition, setFilteredPosition] = useState(false);

  let dispatch = useDispatch();

  const handleChange = (value) => {
    if (!value) {
      dispatch(getAllApprovedJobs());
      setSelectedLocationValue('');
    } else {
      dispatch(getAllApprovedJobs(value));
      setSelectedLocationValue(value);
    }
  };

  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRowJobsListingPage'>
        <Col span={24}>
          <CommonHeroText
            heading={
              ApplicationString['userApprovedJobsListingComponent.heading']
            }
          />
        </Col>
        <Col span={24}>
          <SectionLayout>
            <span className='jobListingPageContentHeading'>
              {ApplicationString['userApprovedJobsListingComponent.subheading']}{' '}
              {/* <a href='/jobs' target='_blank' rel='noopener noreferrer'>
                clicking here.
              </a> */}
            </span>
          </SectionLayout>
        </Col>
        <Col span={24}>
          {setFilteredPosition && (
            <SectionLayout>
              <CommonAddNewListingWithFilter
                selectedLocationValue={selectedLocationValue}
                filteredPosition={setFilteredPosition}
                addNew={false}
                usedForMyEvents={false}
                usedForMyEventsCart={false}
                location
                search={false}
                status={false}
                selectPlaceholder={'Location'}
                categoryList={locationOptionsList}
                handleLocationFilter={handleChange}
                placeHolder='Search by event name'
              />
            </SectionLayout>
          )}
        </Col>
        <Col span={24}>
          <SectionLayout>
            <div className='common-jobs-listing-main-container'>
              {allApprovedJobsData.map((item, index) => {
                return (
                  <CommonJobsListingCard
                    key={item?.id}
                    jobId={item?.id}
                    jobLocation={item?.location}
                    jobLastDate={item?.created_at}
                    jobEmployer={item?.employer}
                    jobTitle={item?.position}
                    jobDescription={item?.description}
                    jobStatus={item?.status}
                  />
                );
              })}
            </div>
          </SectionLayout>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ApprovedJobListing;

import {Button, Table} from 'antd';
import React, {useEffect, useState} from 'react';
import plus from '../../../Assets/Plus.svg';
import {ApplicationString} from '../../../Localization/Locales/en';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {
  PageSizeArray,
  PaginationPosition,
  specialPaymentFilters,
} from '../../../Util/commonUtility';
import useDebouncedCallback from '../../../hooks/useDebounce';
import {FilterOutlined} from '@ant-design/icons';
import DynamicSearchForm from '../../CommonComponents/DynamicSearch';
import {CREATE} from '../../../Constant/enums';
import HeaderSearchCard from '../../CommonComponents/HeaderSearchCard';

const AdminSpecialPaymentComponent = ({
  allSpecialPaymentDetails,
  handleTableChange,
  handlePaginationChange,
  paging,
  sortParams,
  searchValues,
  handleSearch,
}) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const pageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.specialPayment,
  );
  const pageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.specialPayment,
  );

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '1%',
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '3%',
      sorter: true,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: '2%',
    },
    {
      title: 'Status',
      dataIndex: 'payment_status',
      width: '2%',
      sorter: true,
    },
    {
      title: 'Generated Date',
      dataIndex: 'formattedDateTime',
      width: '2%',
      render: (data) =>
        data?.payment_generated_date && data?.payment_generated_time ? (
          <span>{`${data?.payment_generated_date} ${data?.payment_generated_time}`}</span>
        ) : (
          'N/A'
        ),
    },
    {
      title: 'Completion Date',
      dataIndex: 'formattedDateTime',
      width: '2%',
      render: (data) =>
        data?.payment_completion_date && data?.payment_completion_time ? (
          <span>{`${data?.payment_completion_date} ${data?.payment_completion_time}`}</span>
        ) : (
          'N/A'
        ),
    },
  ];
  columns.forEach((col) => {
    col.sortOrder = sortParams.field === col.dataIndex && sortParams.order;
  });

  // debouncing the table & pagination changes to stop too many requests
  const debouncedHandlePaginationChange = useDebouncedCallback(
    handlePaginationChange,
    1000,
  );
  const debouncedHandleTableChange = useDebouncedCallback(
    handleTableChange,
    1000,
  );

  // filter expand logic
  useEffect(() => {
    searchValues && setExpanded(true);
  }, [searchValues]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className='main-container'>
      <HeaderSearchCard
        resource='special_payment'
        buttonTitle={ApplicationString['specialPayment.button.addForm']}
        toggleClick={toggleExpand}
        addClick={() => navigate('/admin/specialPayment/add')}
        dataTestId='adminSpecialPayment-button-addSpecialPayment'
        permission={CREATE}
        expanded={expanded}
      />
      {expanded && (
        <DynamicSearchForm
          columns={specialPaymentFilters}
          onSearch={handleSearch}
          savedFilteredValues={searchValues}
          title={'special_payment'}
        />
      )}
      <div className='listing-container'>
        <Table
          bordered
          className='admin-table'
          columns={columns}
          onChange={debouncedHandleTableChange}
          dataSource={allSpecialPaymentDetails}
          key={allSpecialPaymentDetails?.map((item) => item.id)}
          pagination={{
            onChange: debouncedHandlePaginationChange,
            current: pageNumber || paging.page,
            total: paging.totalCount,
            pageSizeOptions: PageSizeArray,
            pageSize: pageSize || paging.pageCount,
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: debouncedHandlePaginationChange,
            showTotal: (total, range) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>
    </div>
  );
};

export default AdminSpecialPaymentComponent;

import React from 'react';
import ChangePassword from '../../../Components/UserComponents/ChangePassword';

const ChangePasswordContainer = () => {
  return (
    <div>
      <ChangePassword />
    </div>
  );
};

export default ChangePasswordContainer;

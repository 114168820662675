import React, {useEffect, useState} from 'react';
import {Radio} from 'antd';
import {EventRegistrationType} from '../../../Util/commonUtility';
import './style.css';
import {useParams} from 'react-router-dom';

const EventTypeFilter = ({setEventType, isUsedForWaitingList = false}) => {
  const {registrationType} = useParams();
  const defaultEventType = registrationType || EventRegistrationType.regular;
  const [selectedEventType, setSelectedEventType] = useState(defaultEventType);

  const handleEventTypeChange = (e) => {
    const newEventType = e.target.value;
    setSelectedEventType(newEventType);
    setEventType(newEventType);
  };

  useEffect(() => {
    setEventType(defaultEventType);
    setSelectedEventType(defaultEventType); // Set default value from params or regular
  }, [registrationType, setEventType]);

  return (
    <Radio.Group
      prefixCls='user'
      onChange={handleEventTypeChange}
      value={selectedEventType} // Set the default selected value
    >
      <Radio.Button prefixCls='user' value={EventRegistrationType.regular}>
        Regular Registration
      </Radio.Button>
      <Radio.Button prefixCls='user' value={EventRegistrationType.sponsorship}>
        Sponsorship Registration
      </Radio.Button>
      {isUsedForWaitingList && (
        <Radio.Button
          prefixCls='user'
          value={EventRegistrationType.waitingList}>
          Waiting List Registrations
        </Radio.Button>
      )}
    </Radio.Group>
  );
};

export default EventTypeFilter;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import EventSponsorComponent from '../../../Components/UserComponents/EventSponsor';
import {addSponsorAttendees} from '../../../Store/Actions';
import {
  getEventOptionsByEventId,
  getEventOptionsById,
} from '../../../Store/Actions/eventOptions';
import {
  createSelectionOptionsUtil,
  createSelectionOptionsUtilForPerks,
} from '../../../Util/commonUtility';
import {
  UpdateAttendee,
  UpdateSponsorById,
  deleteAttendee,
  getAttendesByUserAndEventId,
  postAttendee,
} from '../../../Store/Actions/eventRegister';
import {useParams} from 'react-router-dom';

const EventSponsorContainer = () => {
  let dispatch = useDispatch();
  const {id, optionId} = useParams();
  const {allAttendees} = useSelector(({eventRegister}) => eventRegister);
  const {getEventOptionByEventId, getEventOptionsData} = useSelector(
    ({eventOptions}) => eventOptions,
  );
  const [eventOptionsFoodData, setEventOptionsFoodData] = useState([]);
  const [listSponsorAttendeesData, setListAttendeesData] = useState();
  const [allEventOptionsFoodData, setAllEventOptionsFoodData] = useState([]);
  const [totalCartPrice, setTotalCartPrice] = useState(0);
  const [eventName, setEventName] = useState('');
  const [sponsorData, setSponsorData] = useState([]);
  const [eventForDataLength, setEventForDataLength] = useState([]);
  const [attendeesData, setAttendeesData] = useState();
  const [freeLunchLimit, setFreeLunchLimit] = useState();
  const [freeLunchPrice, setFreeLunchPrice] = useState();
  const [eventOptionData, setEventOptionData] = useState([]);
  const [listAttendeesDataWithPerks, setListAttendeesDataWithPerks] =
    useState();
  const [eventType, setEventType] = useState('');

  useEffect(() => {
    if (getEventOptionByEventId) {
      const foodOptionsWithNoFood = getEventOptionByEventId?.data?.food || [];
      const perksUtilData = createSelectionOptionsUtilForPerks(
        foodOptionsWithNoFood,
        freeLunchLimit,
        freeLunchPrice,
        'id',
        'name',
        'price',
      );
      const regularUtilData = createSelectionOptionsUtil(
        foodOptionsWithNoFood,
        'id',
        'name',
        'price',
      );
      setEventOptionsFoodData([...perksUtilData, ...regularUtilData]);
    } else {
      dispatch(getEventOptionsByEventId(id));
    }
  }, [getEventOptionByEventId, freeLunchLimit, freeLunchPrice]);

  useEffect(() => {
    if (eventOptionsFoodData) {
      setAllEventOptionsFoodData([
        ...eventOptionsFoodData,
        {value: 'no-food', label: 'No Food'},
      ]);
    }
  }, [eventOptionsFoodData]);

  useEffect(() => {
    if (
      getEventOptionsData
        ? parseInt(optionId) === getEventOptionsData?.data?.id
        : getEventOptionsData
    ) {
      setEventOptionData(getEventOptionsData?.data);
      setFreeLunchLimit(
        getEventOptionsData?.data?.eventSponsorshipPerk?.free_lunch,
      );
      setFreeLunchPrice(
        getEventOptionsData?.data?.eventSponsorshipPerk?.lunch_price,
      );
    } else {
      SponsorAttendeeOptionById(optionId);
    }
  }, [getEventOptionsData]);

  useEffect(() => {
    if (id) {
      fetchSponsorAttendeesList(id);
    }
  }, [id]);
  useEffect(() => {
    if (optionId) {
      SponsorAttendeeOptionById(optionId);
    }
  }, [optionId]);

  useEffect(() => {
    if (allAttendees) {
      const commonDataVar = allAttendees?.data?.event_registrations;
      setEventName(allAttendees?.data?.event_name);
      setEventType(allAttendees?.data?.event_type_name);
      setListAttendeesData(
        commonDataVar?.attendees?.length > 0 ? commonDataVar.attendees : null,
      );
      setListAttendeesDataWithPerks(commonDataVar?.attendeesWithPerks);
      setSponsorData(commonDataVar?.sponsor);
      setTotalCartPrice({
        total_price_per_cart: allAttendees?.data?.total_price,
      });
      setEventForDataLength(commonDataVar);
    } else {
      fetchSponsorAttendeesList(id);
    }
  }, [allAttendees?.data?.event_registrations]);

  useEffect(() => {
    if (listSponsorAttendeesData) {
      const Data = Array.isArray(listSponsorAttendeesData)
        ? listSponsorAttendeesData?.map((item) => {
            return {
              ...item,
              food_option: item?.event_registration_options?.food,
              registration_option:
                item?.event_registration_options?.registration,
            };
          })
        : [];
      setAttendeesData(Data);
    }
  }, [listSponsorAttendeesData]);

  const postAttendeesById = async (
    Data,
    setEditingKey,
    form,
    setIsAddAttendee,
    setDisableAttendeeClicks,
    setDisablePaymentClicks,
    setDisableEditClicks,
  ) => {
    dispatch(
      postAttendee(
        Data,
        setEditingKey,
        form,
        setIsAddAttendee,
        setDisableAttendeeClicks,
        setDisablePaymentClicks,
        setDisableEditClicks,
      ),
    );
  };

  const postSponsorAttendees = async (Data, setSponsorCreatedSuccessFully) => {
    dispatch(addSponsorAttendees(Data, setSponsorCreatedSuccessFully));
  };

  const updateSponsorAttendee = async (
    Data,
    id,
    setEditingKey,
    form,
    setDisableAttendeeClicks,
    setDisablePaymentClicks,
    setDisableEditClicks,
  ) => {
    dispatch(
      UpdateAttendee(
        Data,
        id,
        setEditingKey,
        form,
        setDisableAttendeeClicks,
        setDisablePaymentClicks,
        setDisableEditClicks,
      ),
    );
  };

  const updateSponosor = async (Data, id, setEditDetails) => {
    dispatch(UpdateSponsorById(Data, id, setEditDetails));
  };

  const fetchSponsorAttendeesList = async (id) => {
    const attendeeType = 'sponsor';
    dispatch(getAttendesByUserAndEventId(id, attendeeType));
  };

  const deleteAttendeeById = (id) => {
    dispatch(deleteAttendee(id));
  };
  const SponsorAttendeeOptionById = async (id) => {
    dispatch(getEventOptionsById(id));
  };

  return (
    <div>
      <EventSponsorComponent
        eventName={eventName}
        attendeesDataWithPerks={listAttendeesDataWithPerks}
        sponsorPerksData={eventOptionData}
        setListAttendeesData={setListAttendeesData}
        totalCartPrice={totalCartPrice}
        sponsorData={sponsorData}
        allEventOptionsFoodData={allEventOptionsFoodData}
        SponsorAttendeeOptionById={(id) => {
          SponsorAttendeeOptionById(id);
        }}
        fetchSponsorAttendeesList={(id) => {
          fetchSponsorAttendeesList(id);
        }}
        deleteAttendeeById={(id) => {
          deleteAttendeeById(id);
        }}
        allSponsorAttendeesData={attendeesData}
        postSponsorAttendeesById={(Data, setSponsorCreatedSuccessFully) => {
          postSponsorAttendees(Data, setSponsorCreatedSuccessFully);
        }}
        postAttendeesById={(
          Data,
          setEditingKey,
          form,
          setIsAddAttendee,
          setDisableAttendeeClicks,
          setDisablePaymentClicks,
          setDisableEditClicks,
        ) => {
          postAttendeesById(
            Data,
            setEditingKey,
            form,
            setIsAddAttendee,
            setDisableAttendeeClicks,
            setDisablePaymentClicks,
            setDisableEditClicks,
          );
        }}
        updateSponosor={(Data, id, setEditDetails) => {
          updateSponosor(Data, id, setEditDetails);
        }}
        updateSponsorAttendee={(
          id,
          Data,
          setEditingKey,
          form,
          setDisableAttendeeClicks,
          setDisablePaymentClicks,
          setDisableEditClicks,
        ) => {
          updateSponsorAttendee(
            id,
            Data,
            setEditingKey,
            form,
            setDisableAttendeeClicks,
            setDisablePaymentClicks,
            setDisableEditClicks,
          );
        }}
        eventRegisterData={eventForDataLength}
        eventType={eventType}
        setAttendeesData={setAttendeesData}
        setListAttendeesDataWithPerks={setListAttendeesDataWithPerks}
      />
    </div>
  );
};

export default EventSponsorContainer;

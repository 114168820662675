import {Button, Form, Input, Modal} from 'antd';
import React, {useEffect} from 'react';
import {addChapter, updateChapterById} from '../../../Store/Actions';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import CommonEditor from '../../CommonComponents/TextEditor';

const EditChapterContent = ({
  chapterName = '',
  isModalOpen,
  setIsModalOpen,
  record,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    if (record) {
      form.setFieldsValue(record);
    }
  }, [record]);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = (values) => {
    const data = new FormData();

    data.append('name', record.name);
    data.append('nvp_user', record.nvp_user);
    data.append('pp_email', record.pp_email);
    data.append('latitude', record.latitude);
    data.append('longitude', record.longitude);
    data.append('order_number', record.order_number);
    data.append('details', record.details);
    data.append('normalized_name', record.normalized_name);
    data.append('is_visible_on_map', record.is_visible_on_map);
    data.append('payment_gateway', record.payment_gateway);
    data.append('time_zone', record.time_zone);
    data.append('local_currency', record.local_currency);
    data.append(
      'logo_ids',
      record?.logo_ids?.length > 0 ? `[${record.logo_ids[0].id}]` : '[]',
    );
    data.append(
      'dark_logo_ids',
      record?.dark_logo_ids?.length > 0
        ? `[${record.dark_logo_ids[0].id}]`
        : '[]',
    );
    data.append(
      'banner_ids',
      record?.banner_ids?.length > 0 ? `[${record.banner_ids[0].id}]` : '[]',
    );
    data.append('special_announcement', values?.special_announcement);
    data.append('sponsorship_info', record?.sponsorship_info);
    data.append('about_chapter', values?.about_chapter);
    data.append('mission', values?.mission);
    data.append('contact_us', values?.contact_us);
    dispatch(updateChapterById(data, record?.id, null, setIsModalOpen));
  };

  const onFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };
  return (
    <>
      <Modal
        title={`${chapterName} - Add/Edit Chapter Content`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}>
        <Form
          form={form}
          name='basic'
          layout='vertical'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          className='two-column-form'>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Special Announcement'
                name='special_announcement'>
                <Input.TextArea
                  rows={5}
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter special announcement for chapter'
                />
              </Form.Item>
              <Form.Item label='About Chapter' name='about_chapter'>
                <Input.TextArea
                  rows={5}
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter about chapter'
                />
              </Form.Item>
              <Form.Item label='Mission' name='mission'>
                <Input.TextArea
                  rows={5}
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter mission for chapter'
                />
              </Form.Item>
              <Form.Item label='Contact Us' name='contact_us'>
                <CommonEditor
                  rows={5}
                  prefixCls='common-input-user'
                  className='genral-form'
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item>
            <div className='button-container speaker-justify-right'>
              <div className='form-flex-button'>
                <Button
                  type='primary'
                  htmlType='submit'
                  className='view-button-edit'>
                  Submit
                </Button>
                <Button
                  className='common-cancel-button'
                  htmlType='button'
                  id='SpecialAnnouncementComponent-button-clear'
                  onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditChapterContent;

export const rolePermissions = {
  super_admin: {
    role: 'super_admin',
    permissions: {
      events: {
        create: true,
        read: true,
        update: true,
        delete: true,
        copy: true,
        status: true,
      },
      events_registrations: {
        create: true,
        read: true,
        update: true,
        delete: true,
        cancelAndRefundRegistration: true,
      },
      waiting_list: {
        read: true,
      },
      events_options: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      events_refund: {
        create: true,
        read: true,
        update: true,
        delete: true,
        refundRegistration: true,
      },
      events_email_scheduler: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      jobs: {
        create: true,
        read: true,
        status: true,
        update: true,
        delete: true,
      },
      ads: {
        create: true,
        read: true,
        status: true,
        update: true,
        delete: true,
      },
      sponsors: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      member_attendees: {
        create: true,
        read: true,
        update: true,
        delete: true,
        status: true,
      },
      special_payment: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      chapters: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      ad_fees: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      job_fees: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      interests: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      email_templates: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      companies: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      coupon_codes: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      event_types: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      stem: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      event_option_groups: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      speakers: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      email_codes: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      committee_members: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
    },
  },
  admin: {
    role: 'admin',
    permissions: {
      events: {
        create: true,
        read: true,
        update: true,
        delete: true,
        copy: true,
        status: true,
      },
      events_registrations: {
        create: true,
        read: true,
        update: true,
        delete: true,
        cancelAndRefundRegistration: true,
      },
      waiting_list: {
        read: true,
      },
      events_options: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      events_refund: {
        create: true,
        read: true,
        update: true,
        delete: true,
        refundRegistration: true,
      },
      events_email_scheduler: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      jobs: {
        create: true,
        read: true,
        status: true,
        update: true,
        delete: true,
      },
      ads: {
        create: true,
        read: true,
        status: true,
        update: true,
        delete: true,
      },
      sponsors: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      member_attendees: {
        create: true,
        read: true,
        update: true,
        delete: true,
        status: true,
      },
      special_payment: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      chapters: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      ad_fees: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      job_fees: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      interests: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      email_templates: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      companies: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      coupon_codes: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      event_types: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      stem: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      event_option_groups: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      speakers: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      email_codes: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      committee_members: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
    },
  },
  event_manager_admin: {
    role: 'event_manager_admin',
    permissions: {
      events: {
        create: true,
        read: true,
        update: true,
        delete: true,
        copy: true,
        status: true,
      },
      events_registrations: {
        create: false,
        read: true,
        update: false,
        delete: false,
        cancelAndRefundRegistration: false,
      },
      waiting_list: {
        read: true,
      },
      events_options: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      events_refund: {
        create: true,
        read: true,
        update: true,
        delete: true,
        refundRegistration: true,
      },
      events_email_scheduler: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      jobs: {
        create: true,
        read: true,
        status: true,
        update: true,
        delete: true,
      },
      ads: {
        create: true,
        read: true,
        status: true,
        update: true,
        delete: true,
      },
      sponsors: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      member_attendees: {
        create: true,
        read: true,
        update: true,
        delete: true,
        status: true,
      },
      special_payment: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      chapters: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      ad_fees: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      job_fees: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      interests: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      email_templates: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      companies: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      coupon_codes: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      event_types: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      stem: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      event_option_groups: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      speakers: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      email_codes: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      committee_members: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
    },
  },
  event_registration_admin: {
    role: 'event_registration_admin',
    permissions: {
      events: {
        create: false,
        read: true,
        update: false,
        delete: false,
        copy: false,
        status: false,
      },
      events_registrations: {
        create: false,
        read: true,
        update: false,
        delete: false,
        cancelAndRefundRegistration: false,
      },
      waiting_list: {
        read: true,
      },
      events_options: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      events_refund: {
        create: false,
        read: true,
        update: false,
        delete: false,
        refundRegistration: false,
      },
      events_email_scheduler: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      jobs: {
        create: true,
        read: true,
        status: true,
        update: true,
        delete: true,
      },
      ads: {
        create: true,
        read: true,
        status: true,
        update: true,
        delete: true,
      },
      sponsors: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      member_attendees: {
        create: true,
        read: true,
        update: true,
        delete: true,
        status: true,
      },
      special_payment: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      chapters: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      ad_fees: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      job_fees: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      interests: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      email_templates: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      companies: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      coupon_codes: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      event_types: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      stem: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      event_option_groups: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      speakers: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      email_codes: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      committee_members: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
    },
  },
  user: {
    role: 'user',
    permissions: {
      events: {
        create: false,
        read: true,
        update: false,
        delete: false,
        copy: false,
        status: false,
      },
      events_registrations: {
        create: false,
        read: true,
        update: false,
        delete: false,
        cancelAndRefundRegistration: false,
      },
      waiting_list: {
        read: true,
      },
      events_options: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      events_refund: {
        create: false,
        read: true,
        update: false,
        delete: false,
        refundRegistration: false,
      },
      events_email_scheduler: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      jobs: {
        create: true,
        read: true,
        status: true,
        update: true,
        delete: true,
      },
      ads: {
        create: true,
        read: true,
        update: true,
        status: true,
        delete: true,
      },
      sponsors: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      member_attendees: {
        create: true,
        read: true,
        update: true,
        delete: true,
        status: true,
      },
      special_payment: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      chapters: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      ad_fees: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      job_fees: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      interests: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      email_templates: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      companies: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      coupon_codes: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      event_types: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      stem: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      event_option_groups: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      speakers: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      email_codes: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      committee_members: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
    },
  },
};

export const urlModuleMapper = {
  waitingList: 'waiting_list',
  'waitingList/': 'waiting_list',
  chapters: 'chapters',
  emailTemplates: 'email_templates',
  eventTypes: 'event_types',
  couponCodes: 'coupon_codes',
  jobfees: 'job_fees',
  adfees: 'ad_fees',
  eventOptionGroups: 'event_option_groups',
  eventEmailTemplatesScheduler: 'events_email_scheduler',
  'eventEmailTemplatesScheduler/': 'events_email_scheduler',
  eventOptions: 'events_options',
  'eventOptions/': 'events_options',
  memberAttendees: 'member_attendees',
  jobs: 'jobs',
  eventRegistration: 'events_registrations',
  'eventRegistration/': 'events_registrations',
  ads: 'ads',
  stem: 'stem',
  sponsors: 'sponsors',
  speakers: 'speakers',
  events: 'events',
  companies: 'companies',
  interests: 'interests',
  specialPayment: 'special_payment',
  eventRefund: 'events_refund',
  'eventRefund/': 'events_refund',
  committeeMember: 'Committee Member',
  sponsorshipInfo: 'Sponsorship Info',
  pbssInternational: 'PBSS International',
};
export const reversedUrlModuleMapper = Object.fromEntries(
  Object.entries(urlModuleMapper).map(([key, value]) => [value, key]),
);
export const allModuleKeys = Object.keys(urlModuleMapper);
